<a [routerLink]="['/release-notes']" (click)="setReleaseNotesHaveBeenWatched()">
  <div class="relative">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon--large text-gray-700"
    >
      <use href="#release-notes"></use>
    </svg>
    <div *ngIf="releaseNoteItem && !releaseNoteItem.watched" class="indicator">
      1
    </div>
  </div>
</a>
