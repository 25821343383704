<div class="wrapper">
  <header>
    <fs-toolbar [authenticated]="authenticated"></fs-toolbar>
    <fs-maintenance-banner *ngIf="authenticated"></fs-maintenance-banner>
  </header>
  <main class="overflow-auto">
    <router-outlet *ngIf="authenticated"></router-outlet>
    <div *ngIf="!authenticated" class="auth-placeholder">
      <!-- Placeholder loading element displayed if user is not authenticated -->
      <div class="balls-indicator">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </main>
</div>
<fs-svg-icon-sheet></fs-svg-icon-sheet>
<fs-product-category-icon-sheet></fs-product-category-icon-sheet>
<fs-welcome-modal></fs-welcome-modal>
<fs-feedback-survey-notification
  *ngIf="authenticated"
></fs-feedback-survey-notification>
