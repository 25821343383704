import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCategoryIconComponent } from './components/product-category-icon/product-category-icon.component';
import { ProductCategoryIconSheetComponent } from './components/product-category-icon-sheet/product-category-icon-sheet.component';
import { SimplePopoverComponent } from '../../shared/components/simple-popover/simple-popover.component';
import { PopoverContentDirective } from '../../shared/components/simple-popover/popover-content.directive';

@NgModule({
  declarations: [
    ProductCategoryIconComponent,
    ProductCategoryIconSheetComponent,
  ],
  imports: [CommonModule, SimplePopoverComponent, PopoverContentDirective],
  exports: [ProductCategoryIconSheetComponent, ProductCategoryIconComponent],
})
export class ProductCategoryIconModule {}
