<a
  class="w-full cursor-pointer flex space-x-2 items-center hover:bg-gray-050"
  role="menuitem"
  [ngClass]="{
    'bg-gray-050': active,
    'invisible h-0': !visible,
    'px-4 py-2': visible
  }"
  (click)="handleItemClick()"
>
  <div class="flex-1 items-center text clamp-text clamp-text--one-line">
    <ng-content></ng-content>
  </div>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon text-gray-700"
    [ngClass]="{ invisible: !selected }"
  >
    <use href="#check"></use>
  </svg>
</a>
