<div class="bg-white z-[102] rounded p-6 shadow flex flex-col gap-4">
  <h6 i18n="@@Fsw_AskSkipTour">Wollen Sie die Tour wirklich beenden?</h6>
  <p class="text-sm text-gray-800 max-w-sm" i18n="@@Fsw_ExplRestartTour">
    Für den Fall, dass Sie die Tour später noch einmal anschauen möchten, finden
    Sie unter ihrem Nutzerprofil einen "Hilfe-Tour neu starten" Button.
  </p>
  <div class="flex justify-end gap-4">
    <button class="btn flex space-x-2" (click)="cancelSkip()">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon--small">
        <use href="#x"></use>
      </svg>
      <span i18n="@@Fsw_GeneralNo">Nein</span>
    </button>
    <button class="btn btn--primary flex space-x-2" (click)="confirmSkip()">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon--small">
        <use href="#check"></use>
      </svg>
      <span i18n="@@Fsw_GeneralYes">Ja</span>
    </button>
  </div>
</div>
