// noinspection JSMethodCanBeStatic

/**
 * FanScout-API-Gateway
 * API Gateway providing access on whole services from the FanScout domain
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ProductDetailsContainer } from '../model/productDetails';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { InstallationSpace } from '../model/installation-space';
import { FanPosition } from '../model/fan-position';
import { DimensionContainer } from '../model/dimension-container';
import { LocalizedUnit } from './localized-unit';
import { FileIoService } from './file-io.service';
import { v4 as uuidv4 } from 'uuid';
import {
  AcceptTypes,
  ApiHeaderHelperService,
} from './api-header-helper.service';

@Injectable()
export class ProductDetailsService {
  protected basePath: string;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    private apiHeaderHelper: ApiHeaderHelperService,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private fileIO: FileIoService,
    @Inject(LOCALE_ID) public localeId: string
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * get details to a specified product
   *
   * @param materialNumber pass the pk of a product
   * @param operatingPoints
   * @param airDensity
   * @param numberOfFans
   * @param numRedundantFans
   * @param installationSpaceHeight
   * @param installationSpaceWidth
   * @param installationLosses
   * @param calcMethod
   * @param redundantFansBackwardFlow
   * @param guardGrill
   * @param measurementId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param debug
   */
  public productDetailsMaterialNumberGet(
    materialNumber: string,
    operatingPoints?: {
      airFlow?: number;
      pressure?: number;
      operatingTime?: number;
    }[],
    airDensity?: number,
    numberOfFans?: number,
    numRedundantFans?: number,
    installationSpaceHeight?: number,
    installationSpaceWidth?: number,
    installationLosses?: boolean,
    calcMethod?: string,
    redundantFansBackwardFlow?: boolean,
    guardGrill?: string,
    measurementId?: string,
    observe?: 'body',
    reportProgress?: boolean,
    debug?: boolean
  ): Observable<ProductDetailsContainer> {
    let queryParameters = this.setupProductDetailsQueryParams(
      materialNumber,
      operatingPoints,
      airDensity,
      numberOfFans,
      numRedundantFans,
      installationSpaceHeight,
      installationSpaceWidth,
      installationLosses,
      calcMethod,
      redundantFansBackwardFlow,
      guardGrill,
      measurementId,
      debug
    );

    const headers = this.defaultHeaders
      .set('Accept', this.apiHeaderHelper.createAcceptHeader())
      .set('x-correlation-id', `${uuidv4()}`);

    return this.httpClient.get<ProductDetailsContainer>(
      `${this.basePath}/product-details/${encodeURIComponent(
        String(materialNumber)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  public productDetailsGetPdf(
    materialNumber: string,
    operatingPoints?: {
      airFlow?: number;
      pressure?: number;
      operatingTime?: number;
    }[],
    airDensity?: number,
    numberOfFans?: number,
    numRedundantFans?: number,
    installationSpaceHeight?: number,
    installationSpaceWidth?: number,
    installationLosses?: boolean,
    calcMethod?: string,
    redundantFansBackwardFlow?: boolean,
    guardGrill?: string,
    measurementId?: string,
    filename?: string,
    pdfLayoutChart?: boolean,
    pdfProjectData?: string,
    units?: string,
    selectedOperatingPoint?: number,
    detailUrl?: string,
    language?: string,
    additionalLines: string[] = [],
    additionalIsoLine: string = undefined
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      let queryParameters = this.setupProductDetailsQueryParams(
        materialNumber,
        operatingPoints,
        airDensity,
        numberOfFans,
        numRedundantFans,
        installationSpaceHeight,
        installationSpaceWidth,
        installationLosses,
        calcMethod,
        redundantFansBackwardFlow,
        guardGrill,
        measurementId
      );

      queryParameters = queryParameters.set(
        'pdf-layout-chart',
        !!pdfLayoutChart
      );

      if (pdfProjectData?.trim().length > 0) {
        const encodedPdfProjectData = encodeURIComponent(pdfProjectData.trim());
        queryParameters = queryParameters.set(
          'pdf-project-data',
          encodedPdfProjectData
        );
      }

      if (selectedOperatingPoint != null) {
        queryParameters = queryParameters.set(
          'selected-operating-point',
          selectedOperatingPoint
        );
      }

      if (detailUrl) {
        queryParameters = queryParameters.set(
          'pdf-detail-url',
          btoa(detailUrl)
        );
      }

      queryParameters = queryParameters.set(
        'language',
        language ?? this.localeId
      );

      for (const additionalLine of additionalLines) {
        if (additionalLine != null)
          queryParameters = queryParameters.append(
            'pdf-additional-lines',
            additionalLine
          );
      }

      if (additionalIsoLine != null)
        queryParameters = queryParameters.append(
          'pdf-additional-iso-line',
          additionalIsoLine
        );

      const acceptUnitsParam =
        units === LocalizedUnit.SI || units === LocalizedUnit.US
          ? (units as LocalizedUnit)
          : undefined;
      const headers = this.defaultHeaders
        .set(
          'Accept',
          this.apiHeaderHelper.createAcceptHeader(
            AcceptTypes.APPLICATION_PDF,
            acceptUnitsParam
          )
        )
        .set('x-correlation-id', `${uuidv4()}`);

      const subscription = this.httpClient
        .get(
          `${this.basePath}/product-details/${encodeURIComponent(
            String(materialNumber)
          )}`,
          {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: 'events',
            responseType: 'blob',
          }
        )
        .subscribe(
          (event) => {
            if (event.type === HttpEventType.Response) {
              this.fileIO.saveBlobAs(event.body, `${filename}.pdf`);
              subscription.unsubscribe();
              resolve();
            }
          },
          (error) => {
            subscription.unsubscribe();
            reject(error);
          }
        );
    });
  }

  private setupProductDetailsQueryParams(
    materialNumber: string,
    operatingPoints: {
      airFlow?: number;
      pressure?: number;
      operatingTime?: number;
    }[],
    airDensity: number,
    numberOfFans: number,
    numRedundantFans: number,
    installationSpaceHeight: number,
    installationSpaceWidth: number,
    installationLosses?: boolean,
    calcMethod?: string,
    redundantFansBackwardFlow?: boolean,
    guardGrill?: string,
    measurementId?: string,
    debug?: boolean
  ) {
    if (materialNumber === null || materialNumber === undefined) {
      throw new Error(
        'Required parameter materialNumber was null or undefined when calling productDetailsMaterialNumberGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (operatingPoints?.length > 0) {
      for (const operatingPoint of operatingPoints) {
        queryParameters = queryParameters.append(
          'airflow',
          <any>operatingPoint.airFlow ?? ''
        );
        queryParameters = queryParameters.append(
          'pressure',
          <any>operatingPoint.pressure ?? ''
        );
        queryParameters = queryParameters.append(
          'operating-time',
          <any>operatingPoint.operatingTime ?? ''
        );
      }
    }

    if (airDensity !== undefined && airDensity !== null) {
      queryParameters = queryParameters.set('air-density', <any>airDensity);
    }

    if (numberOfFans !== undefined && numberOfFans !== null) {
      queryParameters = queryParameters.set('num-fans', <any>numberOfFans);
    }

    if (numRedundantFans !== undefined && numRedundantFans !== null) {
      queryParameters = queryParameters.set(
        'num-redundant-fans',
        <any>numRedundantFans
      );
    }

    if (installationSpaceHeight != null) {
      queryParameters = queryParameters.set(
        'installation-space-height',
        installationSpaceHeight
      );
    }

    if (installationSpaceWidth != null) {
      queryParameters = queryParameters.set(
        'installation-space-width',
        installationSpaceWidth
      );
    }

    if (installationLosses != null) {
      queryParameters = queryParameters.set(
        'installation-losses',
        installationLosses
      );
    }

    if (calcMethod != null) {
      queryParameters = queryParameters.set('calc-method', calcMethod);
    }

    if (redundantFansBackwardFlow) {
      queryParameters = queryParameters.set(
        'backward-flow',
        redundantFansBackwardFlow
      );
    }

    if (guardGrill) {
      queryParameters = queryParameters.set('guard-grill', <any>guardGrill);
    }

    if (measurementId) {
      queryParameters = queryParameters.set('measurement-id', measurementId);
    }

    if (debug) {
      queryParameters = queryParameters.set('debug', debug);
    }

    return queryParameters;
  }

  /**
   * get image to a specified product
   *
   * @param materialNumber pass the pk of a product
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public productDetailsMaterialNumberImageGet(
    materialNumber: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob> {
    if (materialNumber === null || materialNumber === undefined) {
      throw new Error(
        'Required parameter materialNumber was null or undefined when calling productDetailsMaterialNumberImageGet.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['image/bmp'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Blob>(
      `${this.basePath}/product-details/material-number))}/image`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  drawingInstallation(
    installationSpace: InstallationSpace,
    fanPositions: FanPosition[],
    dimension: DimensionContainer
  ): Observable<any> {
    return this.httpClient.post(
      `${this.basePath}/product-details/drawing-installation`,
      {
        installationSpace,
        fanPositions,
        dimension,
      },
      {
        responseType: 'text',
      }
    );
  }
}
