import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SecurityContext,
  SimpleChanges,
} from '@angular/core';
import { MaintenanceMessage } from '../../../../fanscout-api/model/maintenance-message';
import { marked } from 'marked';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'fs-maintenance-message-modal',
  templateUrl: './maintenance-message-modal.component.html',
  styleUrls: ['./maintenance-message-modal.component.scss'],
})
export class MaintenanceMessageModalComponent implements OnChanges {
  @Input()
  maintenanceMessage: MaintenanceMessage;

  @Input()
  showModal = false;

  @Output()
  showModalChange = new EventEmitter<boolean>();

  convertedDescription: string;

  constructor(private sanitizer: DomSanitizer) {}

  closeModal() {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.maintenanceMessage?.currentValue != null) {
      const regex = /https:\/\/.*\/uploads/gi;
      this.convertedDescription = this.sanitizer.sanitize(
        SecurityContext.HTML,
        marked
          .parse(this.maintenanceMessage?.description ?? '', {
            breaks: true,
          })
          .replace(regex, '/uploads')
          .split('/uploads')
          .join('/api/uploads')
      );
    }
  }
}
