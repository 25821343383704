import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { LocalizedUnit } from './localized-unit';
import { SwitchUnitsService } from '../../modules/settings-switchers/services/switch-units/switch-units.service';

@Injectable({
  providedIn: 'root',
})
export class ApiHeaderHelperService {
  constructor(
    private switchUnitsService: SwitchUnitsService,
    @Inject(LOCALE_ID) public localeId: string
  ) {}

  public createAcceptHeader(
    acceptType = AcceptTypes.APPLICATION_JSON,
    customUnit?: LocalizedUnit
  ): string {
    const lang = this.localeId;

    let unit = customUnit;
    if (!unit) {
      const usUnit = this.switchUnitsService.areUnitsInUSFormat();
      unit = usUnit ? LocalizedUnit.US : LocalizedUnit.SI;
    }

    return `${acceptType};units=${unit};currency=eur;lang=${lang}`;
  }
}

export enum AcceptTypes {
  APPLICATION_JSON = 'application/json',
  APPLICATION_PDF = 'application/pdf',
}
