<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <symbol id="product-category-vma" viewBox="0 0 80.600042 54.8">
      <style type="text/css" id="style2">
        .pc-vma-icon-st0 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.25;
        }

        .pc-vma-icon-st1 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vma-icon-st2 {
          fill: #1e1e1c;
        }

        .pc-vma-icon-st3 {
          fill: #585857;
        }

        .pc-vma-icon-st4 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.4;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vma-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vma-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vma-icon-st7 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vma-icon-st8 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        }

        .pc-vma-icon-st9 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vma-icon-st10 {
          fill: #223a8f;
        }
      </style>
      <rect
        x="0.125"
        y="22.299988"
        class="pc-vma-icon-st0"
        width="5.3000002"
        height="10.1"
        id="rect4"
        style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.25"
      />
      <line
        class="pc-vma-icon-st1"
        x1="7.4250183"
        y1="44.399994"
        x2="7.4250183"
        y2="51"
        id="line6"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="4.125"
        y1="34.700012"
        x2="4.125"
        y2="51"
        id="line8"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="2.9250183"
        y1="36.200012"
        x2="2.9250183"
        y2="16.200012"
        id="line10"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="4.9250183"
        y1="32.899994"
        x2="4.9250183"
        y2="51"
        id="line12"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="5.8250122"
        y1="33.299988"
        x2="5.8250122"
        y2="51"
        id="line14"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="6.625"
        y1="33.299988"
        x2="6.625"
        y2="42.600006"
        id="line16"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="6.625"
        y1="46"
        x2="6.625"
        y2="51"
        id="line18"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="8.2250061"
        y1="44.799988"
        x2="8.2250061"
        y2="51"
        id="line20"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="9.125"
        y1="44.399994"
        x2="9.125"
        y2="51"
        id="line22"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="9.9250183"
        y1="44.799988"
        x2="9.9250183"
        y2="51"
        id="line24"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="4.125"
        y1="28.299988"
        x2="4.125"
        y2="33.5"
        id="line26"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="4.125"
        y1="27"
        x2="4.125"
        y2="27.700012"
        id="line28"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="7.4250183"
        y1="9.6000061"
        x2="7.4250183"
        y2="3.7999878"
        id="line30"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="4.125"
        y1="20.100006"
        x2="4.125"
        y2="3.7999878"
        id="line32"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="4.9250183"
        y1="21.799988"
        x2="4.9250183"
        y2="3.7999878"
        id="line34"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="5.8250122"
        y1="21.5"
        x2="5.8250122"
        y2="3.7999878"
        id="line36"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="6.625"
        y1="21.5"
        x2="6.625"
        y2="12.100006"
        id="line38"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="6.625"
        y1="8.7999878"
        x2="6.625"
        y2="3.7999878"
        id="line40"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="8.2250061"
        y1="10"
        x2="8.2250061"
        y2="3.7999878"
        id="line42"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="9.125"
        y1="10.100006"
        x2="9.125"
        y2="3.7999878"
        id="line44"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="9.9250183"
        y1="9.6000061"
        x2="9.9250183"
        y2="3.7999878"
        id="line46"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vma-icon-st1"
        x1="4.125"
        y1="26.5"
        x2="4.125"
        y2="21.299988"
        id="line48"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <path
        class="pc-vma-icon-st2"
        d="m 15.125006,24.9 -0.6,-2 -2.1,-1.7 h -0.9 V 20.9 H 7.4250061 v 0.7 h -2 c -0.2,0 -0.4,0.2 -0.4,0.4 v 0 10.9 c 0,0.2 0.2,0.4 0.4,0.5 h 2 v 0.7 h 4.0999999 v -0.3 h 0.9 l 2.1,-1.7 0.6,-2 z"
        id="path50"
        style="fill: #1e1e1c"
      />
      <path
        class="pc-vma-icon-st3"
        d="m 11.525006,20.8 1.1,-11.3 c 0,-0.3 -0.1,-0.5 -0.2,-0.7 -0.1,-0.2 -0.4,-0.3 -0.7,-0.3 H 7.2250061 c -0.5,0 -0.9,0.4 -0.9,0.9 0,0 0,0.1 0,0.1 l 1.5,11.2 z"
        id="path52"
        style="fill: #585857"
      />
      <path
        class="pc-vma-icon-st3"
        d="m 11.525006,33.9 1.1,11.3 c 0,0.3 -0.1,0.5 -0.2,0.7 -0.1,0.2 -0.4,0.3 -0.7,0.3 H 7.2250061 c -0.3,0 -0.5,-0.1 -0.7,-0.3 -0.2,-0.2 -0.3,-0.5 -0.2,-0.7 l 1.5,-11.2 h 3.6999999 z"
        id="path54"
        style="fill: #585857"
      />
      <path
        class="pc-vma-icon-st4"
        d="m 4.4250061,27.1 h -1.2 c -0.1,0 -0.3,-0.1 -0.3,-0.3 0,-0.1 0.1,-0.3 0.3,-0.3 h 1.5 c 0.1,0 0.3,0.1 0.3,0.3 V 28 c 0,0.1 -0.1,0.3 -0.3,0.3 h -1.5 c -0.1,0 -0.2,-0.2 -0.2,-0.3 0,-0.1 0.1,-0.2 0.2,-0.2 h 1.2 v 0"
        id="path56"
        style="
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.40000001;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <path
        class="pc-vma-icon-st4"
        d="m 4.4250061,32.8 c 0,0 0,0.1 0,0.1 l -1.4,3 c 0,0.1 -0.1,0.2 -0.1,0.3 v 14.6 c 0,0.4 0.4,0.8 0.8,0.8 h 7.1999999 c 0.1,0 0.3,0.1 0.3,0.3 v 0 2.5 c 0,0.1 0.2,0.2 0.3,0.2 0.1,0 0.2,-0.1 0.2,-0.2 v -2.5 c 0,-0.4 -0.3,-0.8 -0.8,-0.8 0,0 0,0 0,0 H 3.7250061 c -0.1,0 -0.3,-0.1 -0.3,-0.3 V 36.2 c 0,0 0,-0.1 0,-0.1 l 1.4,-3 c 0,-0.1 0.1,-0.2 0.1,-0.3 v -1.4 c 0,-0.1 -0.1,-0.3 -0.3,-0.3 -0.1,0 -0.3,0.1 -0.3,0.3 v 0 z"
        id="path58"
        style="
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.40000001;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <path
        class="pc-vma-icon-st4"
        d="m 4.4250061,22 c 0,0 0,-0.1 0,-0.1 l -1.4,-3 c 0,-0.1 -0.1,-0.2 -0.1,-0.3 V 4 c 0,-0.4 0.4,-0.8 0.8,-0.8 h 7.1999999 c 0.1,0 0.3,-0.1 0.3,-0.3 V 0.5 c 0,-0.1 0.1,-0.3 0.2,-0.3 0.1,0 0.3,0.1 0.3,0.2 0,0 0,0.1 0,0.1 V 3 c 0,0.4 -0.3,0.8 -0.8,0.8 H 3.7250061 c -0.1,0 -0.3,0.1 -0.3,0.3 v 14.6 c 0,0 0,0.1 0,0.1 l 1.4,3 c 0,0.1 0.1,0.2 0.1,0.3 v 1.4 c 0,0.1 -0.1,0.3 -0.3,0.3 -0.1,0 -0.3,-0.1 -0.3,-0.3 v 0 z"
        id="path60"
        style="
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.40000001;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <path
        class="pc-vma-icon-st3"
        d="m 55.525006,27.4 1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.4 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.4 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
        id="path62"
        style="fill: #585857"
      />
      <path
        class="pc-vma-icon-st3"
        d="m 54.425006,29.3 16.6,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.6 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.3,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.7,6.7 z"
        id="path64"
        style="fill: #585857"
      />
      <path
        class="pc-vma-icon-st3"
        d="m 51.825006,28.5 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.7 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.5 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 l -11.2,-12.9 z"
        id="path66"
        style="fill: #585857"
      />
      <path
        class="pc-vma-icon-st3"
        d="m 52.125006,26.3 -10.9,13.2 c -0.2,0.3 -0.4,0.6 -0.5,1 0,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.2 0.6,0.2 0.8,0 0.4,-0.2 0.6,-0.4 0.8,-0.8 l 8.8,-14.6 z"
        id="path68"
        style="fill: #585857"
      />
      <path
        class="pc-vma-icon-st3"
        d="m 53.925006,25.7 -15.9,-6.3 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.6,3.8 z"
        id="path70"
        style="fill: #585857"
      />
      <circle
        class="pc-vma-icon-st2"
        cx="53.524994"
        cy="27.399994"
        r="6.4000001"
        id="circle72"
        style="fill: #1e1e1c"
      />
      <circle
        class="pc-vma-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="19.700001"
        id="circle74"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vma-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="21.6"
        id="circle76"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vma-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="17.700001"
        id="circle78"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vma-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="15.9"
        id="circle80"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vma-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="14.3"
        id="circle82"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vma-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="12.6"
        id="circle84"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vma-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="15.9"
        id="circle86"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vma-icon-st6"
        cx="53.524994"
        cy="27.399994"
        r="24"
        id="circle88"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.60000002;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vma-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="10.8"
        id="circle90"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vma-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="8.8999996"
        id="circle92"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vma-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="7.0999999"
        id="circle94"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vma-icon-st7"
        x1="52.725006"
        y1="0.6000061"
        x2="52.725006"
        y2="53.899994"
        id="line96"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vma-icon-st7"
        x1="54.425018"
        y1="0.6000061"
        x2="54.425018"
        y2="53.899994"
        id="line98"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vma-icon-st7"
        x1="26.925018"
        y1="28.100006"
        x2="80.225006"
        y2="28.100006"
        id="line100"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vma-icon-st7"
        x1="26.925018"
        y1="26.399994"
        x2="80.225006"
        y2="26.399994"
        id="line102"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vma-icon-st5"
        x1="36.425018"
        y1="10.5"
        x2="70.425018"
        y2="44.5"
        id="line104"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vma-icon-st5"
        x1="36.425018"
        y1="44.200012"
        x2="70.325012"
        y2="10.399994"
        id="line106"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vma-icon-st8"
        x1="54.425018"
        y1="0.6000061"
        x2="52.725006"
        y2="0.6000061"
        id="line108"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vma-icon-st8"
        x1="54.425018"
        y1="53.899994"
        x2="52.725006"
        y2="53.899994"
        id="line110"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vma-icon-st8"
        x1="80.225006"
        y1="28.100006"
        x2="80.225006"
        y2="26.399994"
        id="line112"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vma-icon-st8"
        x1="26.925018"
        y1="28.100006"
        x2="26.925018"
        y2="26.399994"
        id="line114"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        "
      />
      <g id="g124" transform="translate(-380.27499,-270.5)">
        <g id="g122">
          <line
            class="pc-vma-icon-st9"
            x1="398.29999"
            y1="312.60001"
            x2="404.79999"
            y2="312.60001"
            id="line116"
            style="
              fill: none;
              stroke: #223a8f;
              stroke-width: 0.60000002;
              stroke-miterlimit: 10;
            "
          />
          <g id="g120">
            <polygon
              class="pc-vma-icon-st10"
              points="395.9,312.6 398.7,311 398.7,314.3 "
              id="polygon118"
              style="fill: #223a8f"
            />
          </g>
        </g>
      </g>
      <g id="g134" transform="translate(-380.27499,-270.5)">
        <g id="g132">
          <line
            class="pc-vma-icon-st9"
            x1="398.29999"
            y1="283.60001"
            x2="404.79999"
            y2="283.60001"
            id="line126"
            style="
              fill: none;
              stroke: #223a8f;
              stroke-width: 0.60000002;
              stroke-miterlimit: 10;
            "
          />
          <g id="g130">
            <polygon
              class="pc-vma-icon-st10"
              points="395.9,283.6 398.7,282 398.7,285.3 "
              id="polygon128"
              style="fill: #223a8f"
            />
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vna" viewBox="0 0 80.600042 54.8">
      <style type="text/css" id="style2">
        .pc-vna-icon-st0 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.25;
        }

        .pc-vna-icon-st1 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vna-icon-st2 {
          fill: #1e1e1c;
        }

        .pc-vna-icon-st3 {
          fill: #585857;
        }

        .pc-vna-icon-st4 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.4;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vna-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vna-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vna-icon-st7 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vna-icon-st8 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        }

        .pc-vna-icon-st9 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vna-icon-st10 {
          fill: #223a8f;
        }
      </style>
      <rect
        x="0.125"
        y="22.299988"
        class="pc-vna-icon-st0"
        width="5.3000002"
        height="10.1"
        id="rect4"
        style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.25"
      />
      <line
        class="pc-vna-icon-st1"
        x1="7.4250183"
        y1="44.399994"
        x2="7.4250183"
        y2="51"
        id="line6"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="4.125"
        y1="34.700012"
        x2="4.125"
        y2="51"
        id="line8"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="2.9250183"
        y1="36.200012"
        x2="2.9250183"
        y2="16.200012"
        id="line10"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="4.9250183"
        y1="32.899994"
        x2="4.9250183"
        y2="51"
        id="line12"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="5.8250122"
        y1="33.299988"
        x2="5.8250122"
        y2="51"
        id="line14"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="6.625"
        y1="33.299988"
        x2="6.625"
        y2="42.600006"
        id="line16"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="6.625"
        y1="46"
        x2="6.625"
        y2="51"
        id="line18"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="8.2250061"
        y1="44.799988"
        x2="8.2250061"
        y2="51"
        id="line20"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="9.125"
        y1="44.399994"
        x2="9.125"
        y2="51"
        id="line22"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="9.9250183"
        y1="44.799988"
        x2="9.9250183"
        y2="51"
        id="line24"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="4.125"
        y1="28.299988"
        x2="4.125"
        y2="33.5"
        id="line26"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="4.125"
        y1="27"
        x2="4.125"
        y2="27.700012"
        id="line28"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="7.4250183"
        y1="9.6000061"
        x2="7.4250183"
        y2="3.7999878"
        id="line30"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="4.125"
        y1="20.100006"
        x2="4.125"
        y2="3.7999878"
        id="line32"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="4.9250183"
        y1="21.799988"
        x2="4.9250183"
        y2="3.7999878"
        id="line34"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="5.8250122"
        y1="21.5"
        x2="5.8250122"
        y2="3.7999878"
        id="line36"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="6.625"
        y1="21.5"
        x2="6.625"
        y2="12.100006"
        id="line38"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="6.625"
        y1="8.7999878"
        x2="6.625"
        y2="3.7999878"
        id="line40"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="8.2250061"
        y1="10"
        x2="8.2250061"
        y2="3.7999878"
        id="line42"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="9.125"
        y1="10.100006"
        x2="9.125"
        y2="3.7999878"
        id="line44"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="9.9250183"
        y1="9.6000061"
        x2="9.9250183"
        y2="3.7999878"
        id="line46"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <line
        class="pc-vna-icon-st1"
        x1="4.125"
        y1="26.5"
        x2="4.125"
        y2="21.299988"
        id="line48"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <path
        class="pc-vna-icon-st2"
        d="m 15.125006,24.9 -0.6,-2 -2.1,-1.7 h -0.9 V 20.9 H 7.4250061 v 0.7 h -2 c -0.2,0 -0.4,0.2 -0.4,0.4 v 0 10.9 c 0,0.2 0.2,0.4 0.4,0.5 h 2 v 0.7 h 4.0999999 v -0.3 h 0.9 l 2.1,-1.7 0.6,-2 z"
        id="path50"
        style="fill: #1e1e1c"
      />
      <path
        class="pc-vna-icon-st3"
        d="m 11.525006,20.8 1.1,-11.3 c 0,-0.3 -0.1,-0.5 -0.2,-0.7 -0.1,-0.2 -0.4,-0.3 -0.7,-0.3 H 7.2250061 c -0.5,0 -0.9,0.4 -0.9,0.9 0,0 0,0.1 0,0.1 l 1.5,11.2 z"
        id="path52"
        style="fill: #585857"
      />
      <path
        class="pc-vna-icon-st3"
        d="m 11.525006,33.9 1.1,11.3 c 0,0.3 -0.1,0.5 -0.2,0.7 -0.1,0.2 -0.4,0.3 -0.7,0.3 H 7.2250061 c -0.3,0 -0.5,-0.1 -0.7,-0.3 -0.2,-0.2 -0.3,-0.5 -0.2,-0.7 l 1.5,-11.2 h 3.6999999 z"
        id="path54"
        style="fill: #585857"
      />
      <path
        class="pc-vna-icon-st4"
        d="m 4.4250061,27.1 h -1.2 c -0.1,0 -0.3,-0.1 -0.3,-0.3 0,-0.1 0.1,-0.3 0.3,-0.3 h 1.5 c 0.1,0 0.3,0.1 0.3,0.3 V 28 c 0,0.1 -0.1,0.3 -0.3,0.3 h -1.5 c -0.1,0 -0.2,-0.2 -0.2,-0.3 0,-0.1 0.1,-0.2 0.2,-0.2 h 1.2 v 0"
        id="path56"
        style="
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.40000001;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <path
        class="pc-vna-icon-st4"
        d="m 4.4250061,32.8 c 0,0 0,0.1 0,0.1 l -1.4,3 c 0,0.1 -0.1,0.2 -0.1,0.3 v 14.6 c 0,0.4 0.4,0.8 0.8,0.8 h 7.1999999 c 0.1,0 0.3,0.1 0.3,0.3 v 0 2.5 c 0,0.1 0.2,0.2 0.3,0.2 0.1,0 0.2,-0.1 0.2,-0.2 v -2.5 c 0,-0.4 -0.3,-0.8 -0.8,-0.8 0,0 0,0 0,0 H 3.7250061 c -0.1,0 -0.3,-0.1 -0.3,-0.3 V 36.2 c 0,0 0,-0.1 0,-0.1 l 1.4,-3 c 0,-0.1 0.1,-0.2 0.1,-0.3 v -1.4 c 0,-0.1 -0.1,-0.3 -0.3,-0.3 -0.1,0 -0.3,0.1 -0.3,0.3 v 0 z"
        id="path58"
        style="
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.40000001;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <path
        class="pc-vna-icon-st4"
        d="m 4.4250061,22 c 0,0 0,-0.1 0,-0.1 l -1.4,-3 c 0,-0.1 -0.1,-0.2 -0.1,-0.3 V 4 c 0,-0.4 0.4,-0.8 0.8,-0.8 h 7.1999999 c 0.1,0 0.3,-0.1 0.3,-0.3 V 0.5 c 0,-0.1 0.1,-0.3 0.2,-0.3 0.1,0 0.3,0.1 0.3,0.2 0,0 0,0.1 0,0.1 V 3 c 0,0.4 -0.3,0.8 -0.8,0.8 H 3.7250061 c -0.1,0 -0.3,0.1 -0.3,0.3 v 14.6 c 0,0 0,0.1 0,0.1 l 1.4,3 c 0,0.1 0.1,0.2 0.1,0.3 v 1.4 c 0,0.1 -0.1,0.3 -0.3,0.3 -0.1,0 -0.3,-0.1 -0.3,-0.3 v 0 z"
        id="path60"
        style="
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.40000001;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
      <path
        class="pc-vna-icon-st5"
        d="M 9.7250061,8.5"
        id="path62"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <path
        class="pc-vna-icon-st5"
        d="M 9.5250061,46.4"
        id="path64"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <path
        class="pc-vna-icon-st5"
        d="M 0.1250061,27.5"
        id="path66"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <path
        class="pc-vna-icon-st5"
        d="M 14.925006,27.5"
        id="path68"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <path
        class="pc-vna-icon-st3"
        d="m 55.525006,27.4 1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.4 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.4 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
        id="path70"
        style="fill: #585857"
      />
      <path
        class="pc-vna-icon-st3"
        d="m 54.425006,29.3 16.6,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.6 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.3,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.7,6.7 z"
        id="path72"
        style="fill: #585857"
      />
      <path
        class="pc-vna-icon-st3"
        d="m 51.825006,28.5 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.7 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.5 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 l -11.2,-12.9 z"
        id="path74"
        style="fill: #585857"
      />
      <path
        class="pc-vna-icon-st3"
        d="m 52.125006,26.3 -10.9,13.2 c -0.2,0.3 -0.4,0.6 -0.5,1 0,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.2 0.6,0.2 0.8,0 0.4,-0.2 0.6,-0.4 0.8,-0.8 l 8.8,-14.6 z"
        id="path76"
        style="fill: #585857"
      />
      <path
        class="pc-vna-icon-st3"
        d="m 53.925006,25.7 -15.9,-6.3 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.6,3.8 z"
        id="path78"
        style="fill: #585857"
      />
      <circle
        class="pc-vna-icon-st2"
        cx="53.524994"
        cy="27.399994"
        r="6.4000001"
        id="circle80"
        style="fill: #1e1e1c"
      />
      <circle
        class="pc-vna-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="19.700001"
        id="circle82"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vna-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="21.6"
        id="circle84"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vna-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="17.700001"
        id="circle86"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vna-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="15.9"
        id="circle88"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vna-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="14.3"
        id="circle90"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vna-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="12.6"
        id="circle92"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vna-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="15.9"
        id="circle94"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vna-icon-st6"
        cx="53.524994"
        cy="27.399994"
        r="24"
        id="circle96"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.60000002;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vna-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="10.8"
        id="circle98"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vna-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="8.8999996"
        id="circle100"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <circle
        class="pc-vna-icon-st5"
        cx="53.524994"
        cy="27.399994"
        r="7.0999999"
        id="circle102"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vna-icon-st7"
        x1="52.725006"
        y1="0.6000061"
        x2="52.725006"
        y2="53.899994"
        id="line104"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vna-icon-st7"
        x1="54.425018"
        y1="0.6000061"
        x2="54.425018"
        y2="53.899994"
        id="line106"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vna-icon-st7"
        x1="26.925018"
        y1="28.100006"
        x2="80.225006"
        y2="28.100006"
        id="line108"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vna-icon-st7"
        x1="26.925018"
        y1="26.399994"
        x2="80.225006"
        y2="26.399994"
        id="line110"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vna-icon-st5"
        x1="36.425018"
        y1="10.5"
        x2="70.425018"
        y2="44.5"
        id="line112"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vna-icon-st5"
        x1="36.425018"
        y1="44.200012"
        x2="70.325012"
        y2="10.399994"
        id="line114"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <path
        class="pc-vna-icon-st5"
        d="M 26.725006,27"
        id="path116"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <path
        class="pc-vna-icon-st5"
        d="M 80.425006,27"
        id="path118"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vna-icon-st8"
        x1="54.425018"
        y1="0.6000061"
        x2="52.725006"
        y2="0.6000061"
        id="line120"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vna-icon-st8"
        x1="54.425018"
        y1="53.899994"
        x2="52.725006"
        y2="53.899994"
        id="line122"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vna-icon-st8"
        x1="80.225006"
        y1="28.100006"
        x2="80.225006"
        y2="26.399994"
        id="line124"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vna-icon-st8"
        x1="26.925018"
        y1="28.100006"
        x2="26.925018"
        y2="26.399994"
        id="line126"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        "
      />
      <g id="g136" transform="translate(-380.27499,-270.5)">
        <g id="g134">
          <line
            class="pc-vna-icon-st9"
            x1="402.70001"
            y1="312.70001"
            x2="396.20001"
            y2="312.70001"
            id="line128"
            style="
              fill: none;
              stroke: #223a8f;
              stroke-width: 0.60000002;
              stroke-miterlimit: 10;
            "
          />
          <g id="g132">
            <polygon
              class="pc-vna-icon-st10"
              points="405.1,312.7 402.3,314.3 402.3,311 "
              id="polygon130"
              style="fill: #223a8f"
            />
          </g>
        </g>
      </g>
      <g id="g146" transform="translate(-380.27499,-270.5)">
        <g id="g144">
          <line
            class="pc-vna-icon-st9"
            x1="402.70001"
            y1="283.70001"
            x2="396.20001"
            y2="283.70001"
            id="line138"
            style="
              fill: none;
              stroke: #223a8f;
              stroke-width: 0.60000002;
              stroke-miterlimit: 10;
            "
          />
          <g id="g142">
            <polygon
              class="pc-vna-icon-st10"
              points="405.1,283.7 402.3,285.3 402.3,282 "
              id="polygon140"
              style="fill: #223a8f"
            />
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vmp" viewBox="0 0 74.675024 54.325895">
      <defs id="defs528" />
      <style id="style425" type="text/css">
        .pc-vmp-icon-st0 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vmp-icon-st1 {
          fill: #1e1e1c;
        }

        .pc-vmp-icon-st2 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.2;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vmp-icon-st3 {
          fill: #585857;
        }

        .pc-vmp-icon-st4 {
          fill: #ffffff;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vmp-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vmp-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vmp-icon-st7 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vmp-icon-st8 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        }

        .pc-vmp-icon-st9 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vmp-icon-st10 {
          fill: #223a8f;
        }
      </style>
      <g transform="translate(-378.5,-270.5)" id="g523">
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
          id="line427"
          y2="279"
          x2="381.29999"
          y1="271.79999"
          x1="381.29999"
          class="pc-vmp-icon-st0"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
          id="line429"
          y2="322.29999"
          x2="381.29999"
          y1="303.60001"
          x1="381.29999"
          class="pc-vmp-icon-st0"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
          id="line431"
          y2="291.89999"
          x2="381.29999"
          y1="272.29999"
          x1="381.29999"
          class="pc-vmp-icon-st0"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
          id="line433"
          y2="321.10001"
          x2="381.29999"
          y1="316.5"
          x1="381.29999"
          class="pc-vmp-icon-st0"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
          id="line435"
          y2="291.89999"
          x2="380.29999"
          y1="275.60001"
          x1="380.29999"
          class="pc-vmp-icon-st0"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
          id="line437"
          y2="303.60001"
          x2="380.29999"
          y1="319.89999"
          x1="380.29999"
          class="pc-vmp-icon-st0"
        />
        <path
          style="fill: #1e1e1c"
          id="path439"
          d="m 389.5,295.3 -0.6,-2 -2.1,-1.7 h -0.9 v -0.3 h -4.1 v 0.6 h -2 c -0.2,0 -0.4,0.2 -0.4,0.4 v 0 10.9 c 0,0.2 0.2,0.4 0.4,0.4 v 0 h 2 v 0.7 h 4.1 V 304 h 0.9 l 2.1,-1.7 0.6,-2 z"
          class="pc-vmp-icon-st1"
        />
        <path
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.2;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
          id="path441"
          d="m 381.3,271.1 c 0,-0.2 -0.1,-0.4 -0.3,-0.5 -0.2,0 -0.4,0.1 -0.5,0.3 v 0.1 2 c 0,0 0,0.1 0,0.2 l -1.8,3.9 c -0.1,0.1 -0.1,0.3 -0.1,0.5 v 15.1 c 0,0.2 0.1,0.4 0.3,0.5 0.2,0 0.4,-0.1 0.5,-0.3 v -0.1 -15.1 c 0,-0.1 0,-0.1 0,-0.2 l 1.8,-3.9 c 0.1,-0.1 0.1,-0.3 0.1,-0.5 z"
          class="pc-vmp-icon-st2"
        />
        <path
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.2;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
          id="path443"
          d="m 381.3,324.4 c 0,0.2 -0.2,0.4 -0.5,0.3 -0.2,0 -0.3,-0.2 -0.3,-0.3 v -2 c 0,0 0,-0.1 0,-0.2 l -1.8,-3.9 c -0.1,-0.1 -0.1,-0.3 -0.1,-0.5 v -15.2 c 0,-0.2 0.2,-0.4 0.5,-0.3 0.2,0 0.3,0.2 0.3,0.3 v 15.1 c 0,0 0,0.1 0,0.2 l 1.8,3.9 c 0.1,0.1 0.1,0.3 0.1,0.5 z"
          class="pc-vmp-icon-st2"
        />
        <path
          style="fill: #585857"
          id="path445"
          d="m 385.9,304.3 1.1,11.3 c 0,0.5 -0.4,1 -0.9,1 0,0 0,0 -0.1,0 h -4.5 c -0.5,0 -0.9,-0.4 -0.9,-0.9 v -0.1 l 1.5,-11.2 z"
          class="pc-vmp-icon-st3"
        />
        <path
          style="fill: #585857"
          id="path447"
          d="M 385.9,291.2 387,280 c 0,-0.5 -0.4,-1 -0.9,-1 v 0 h -4.5 c -0.3,0 -0.5,0.1 -0.7,0.3 -0.2,0.2 -0.3,0.5 -0.2,0.7 l 1.5,11.2 z"
          class="pc-vmp-icon-st3"
        />
        <circle
          style="
            fill: #ffffff;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
          id="circle449"
          r="19.700001"
          cy="297.89999"
          cx="426.10001"
          class="pc-vmp-icon-st4"
        />
        <path
          style="fill: #585857"
          id="path451"
          d="m 428.1,297.9 1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.4 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.3 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
          class="pc-vmp-icon-st3"
        />
        <path
          style="fill: #585857"
          id="path453"
          d="m 427,299.8 16.6,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.6 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.3,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.7,6.7 z"
          class="pc-vmp-icon-st3"
        />
        <path
          style="fill: #585857"
          id="path455"
          d="m 424.3,299.1 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.7 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.6 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 l -11.2,-12.9 z"
          class="pc-vmp-icon-st3"
        />
        <path
          style="fill: #585857"
          id="path457"
          d="M 424.7,296.8 413.8,310 c -0.2,0.3 -0.4,0.6 -0.5,1 -0.1,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.1 0.6,0.1 0.8,0 0.4,-0.2 0.6,-0.4 0.8,-0.8 l 8.8,-14.6 z"
          class="pc-vmp-icon-st3"
        />
        <path
          style="fill: #585857"
          id="path459"
          d="m 426.5,296.2 -15.9,-6.3 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.7,3.9 z"
          class="pc-vmp-icon-st3"
        />
        <circle
          style="fill: #1e1e1c"
          id="circle461"
          r="6.4000001"
          cy="297.89999"
          cx="426.10001"
          class="pc-vmp-icon-st1"
        />
        <circle
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
          id="circle463"
          r="21.6"
          cy="297.89999"
          cx="426.10001"
          class="pc-vmp-icon-st5"
        />
        <circle
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
          id="circle465"
          r="17.700001"
          cy="297.89999"
          cx="426.10001"
          class="pc-vmp-icon-st5"
        />
        <circle
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
          id="circle467"
          r="15.9"
          cy="297.89999"
          cx="426.10001"
          class="pc-vmp-icon-st5"
        />
        <circle
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
          id="circle469"
          r="14.3"
          cy="297.89999"
          cx="426.10001"
          class="pc-vmp-icon-st5"
        />
        <circle
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
          id="circle471"
          r="12.6"
          cy="297.89999"
          cx="426.10001"
          class="pc-vmp-icon-st5"
        />
        <circle
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
          id="circle473"
          r="15.9"
          cy="297.89999"
          cx="426.10001"
          class="pc-vmp-icon-st5"
        />
        <circle
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
          id="circle475"
          r="24"
          cy="297.89999"
          cx="426.10001"
          class="pc-vmp-icon-st6"
        />
        <circle
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
          id="circle477"
          r="10.8"
          cy="297.89999"
          cx="426.10001"
          class="pc-vmp-icon-st5"
        />
        <circle
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
          id="circle479"
          r="8.8999996"
          cy="297.89999"
          cx="426.10001"
          class="pc-vmp-icon-st5"
        />
        <circle
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
          id="circle481"
          r="7.0999999"
          cy="297.89999"
          cx="426.10001"
          class="pc-vmp-icon-st5"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
          id="line483"
          y2="324.39999"
          x2="425.29999"
          y1="271.10001"
          x1="425.29999"
          class="pc-vmp-icon-st7"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
          id="line485"
          y2="324.39999"
          x2="427"
          y1="271.10001"
          x1="427"
          class="pc-vmp-icon-st7"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
          id="line487"
          y2="298.60001"
          x2="452.79999"
          y1="298.60001"
          x1="399.5"
          class="pc-vmp-icon-st7"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
          id="line489"
          y2="297"
          x2="452.79999"
          y1="297"
          x1="399.5"
          class="pc-vmp-icon-st7"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
          id="line491"
          y2="315"
          x2="443"
          y1="281.10001"
          x1="409"
          class="pc-vmp-icon-st5"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
          id="line493"
          y2="280.89999"
          x2="442.89999"
          y1="314.79999"
          x1="409"
          class="pc-vmp-icon-st5"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
          id="line495"
          y2="271.10001"
          x2="425.29999"
          y1="271.10001"
          x1="427"
          class="pc-vmp-icon-st8"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
          id="line497"
          y2="324.39999"
          x2="425.29999"
          y1="324.39999"
          x1="427"
          class="pc-vmp-icon-st8"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
          id="line499"
          y2="297"
          x2="452.79999"
          y1="298.60001"
          x1="452.79999"
          class="pc-vmp-icon-st8"
        />
        <line
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
          id="line501"
          y2="297"
          x2="404.5"
          y1="298.60001"
          x1="404.5"
          class="pc-vmp-icon-st8"
        />
        <g id="g511">
          <g id="g509">
            <line
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
              id="line503"
              y2="312.20001"
              x2="398.5"
              y1="312.20001"
              x1="391.89999"
              class="pc-vmp-icon-st9"
            />
            <g id="g507">
              <polygon
                style="fill: #223a8f"
                id="polygon505"
                points="392.4,310.5 392.4,313.8 389.6,312.2 "
                class="pc-vmp-icon-st10"
              />
            </g>
          </g>
        </g>
        <g id="g521">
          <g id="g519">
            <line
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
              id="line513"
              y2="283.20001"
              x2="398.5"
              y1="283.20001"
              x1="391.89999"
              class="pc-vmp-icon-st9"
            />
            <g id="g517">
              <polygon
                style="fill: #223a8f"
                id="polygon515"
                points="392.4,281.5 392.4,284.8 389.6,283.2 "
                class="pc-vmp-icon-st10"
              />
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vmq" viewBox="0 0 73.099989 44.400002">
      <style type="text/css" id="style2">
        .pc-vmq-icon-st0 {
          fill: #ffffff;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vmq-icon-st1 {
          fill: #585857;
        }

        .pc-vmq-icon-st2 {
          fill: #1e1e1c;
        }

        .pc-vmq-icon-st3 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vmq-icon-st4 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vmq-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vmq-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vmq-icon-st7 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.4;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vmq-icon-st8 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vmq-icon-st9 {
          fill: #223a8f;
        }
      </style>
      <g id="g108" transform="translate(-374.7,-275.7)">
        <circle
          class="pc-vmq-icon-st0"
          cx="425.89999"
          cy="297.79999"
          r="19.700001"
          id="circle4"
          style="
            fill: #ffffff;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vmq-icon-st1"
          d="m 428,297.9 1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.4 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
          id="path6"
          style="fill: #585857"
        />
        <path
          class="pc-vmq-icon-st1"
          d="m 426.8,299.8 16.6,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.6 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.3,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.7,6.7 z"
          id="path8"
          style="fill: #585857"
        />
        <path
          class="pc-vmq-icon-st1"
          d="m 424.2,299 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.7 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.5 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 l -11.2,-12.9 z"
          id="path10"
          style="fill: #585857"
        />
        <path
          class="pc-vmq-icon-st1"
          d="M 424.5,296.8 413.6,310 c -0.2,0.3 -0.4,0.6 -0.5,1 -0.1,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.1 0.6,0.1 0.8,0 0.4,-0.2 0.6,-0.4 0.8,-0.8 l 8.8,-14.6 z"
          id="path12"
          style="fill: #585857"
        />
        <path
          class="pc-vmq-icon-st1"
          d="m 426.3,296.2 -15.9,-6.3 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.6,3.8 z"
          id="path14"
          style="fill: #585857"
        />
        <circle
          class="pc-vmq-icon-st2"
          cx="425.89999"
          cy="297.79999"
          r="6.4000001"
          id="circle16"
          style="fill: #1e1e1c"
        />
        <circle
          class="pc-vmq-icon-st3"
          cx="425.89999"
          cy="297.79999"
          r="21.6"
          id="circle18"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vmq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="17.700001"
          id="circle20"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vmq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="15.9"
          id="circle22"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vmq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="14.3"
          id="circle24"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vmq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="12.6"
          id="circle26"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vmq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="15.9"
          id="circle28"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vmq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="10.8"
          id="circle30"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vmq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="8.8999996"
          id="circle32"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vmq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="7.0999999"
          id="circle34"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vmq-icon-st5"
          x1="425.10001"
          y1="276.20001"
          x2="425.10001"
          y2="319.5"
          id="line36"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vmq-icon-st5"
          x1="426.79999"
          y1="276.20001"
          x2="426.79999"
          y2="319.5"
          id="line38"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vmq-icon-st5"
          x1="404.29999"
          y1="298.5"
          x2="447.60001"
          y2="298.5"
          id="line40"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vmq-icon-st5"
          x1="404.29999"
          y1="297"
          x2="447.60001"
          y2="297"
          id="line42"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vmq-icon-st4"
          x1="410.5"
          y1="282.70001"
          x2="441.10001"
          y2="313.29999"
          id="line44"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vmq-icon-st4"
          x1="410.5"
          y1="313"
          x2="441.10001"
          y2="282.39999"
          id="line46"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vmq-icon-st4"
          d="M 385.1,279.2"
          id="path48"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vmq-icon-st4"
          d="M 384.9,316.6"
          id="path50"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vmq-icon-st6"
          x1="376.79999"
          y1="285.70001"
          x2="376.79999"
          y2="310.10001"
          id="line52"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vmq-icon-st6"
          x1="378"
          y1="288.39999"
          x2="378"
          y2="297"
          id="line54"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vmq-icon-st6"
          x1="378.89999"
          y1="290.10001"
          x2="378.89999"
          y2="297"
          id="line56"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vmq-icon-st6"
          x1="378.89999"
          y1="306.89999"
          x2="378.89999"
          y2="319.5"
          id="line58"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vmq-icon-st6"
          x1="378"
          y1="308.60001"
          x2="378"
          y2="319.5"
          id="line60"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vmq-icon-st6"
          x1="378"
          y1="298.79999"
          x2="378"
          y2="307.39999"
          id="line62"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vmq-icon-st6"
          x1="378.89999"
          y1="298.79999"
          x2="378.89999"
          y2="305.70001"
          id="line64"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vmq-icon-st6"
          x1="378.89999"
          y1="297.5"
          x2="378.89999"
          y2="298.29999"
          id="line66"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vmq-icon-st6"
          x1="378"
          y1="297.5"
          x2="378"
          y2="298.29999"
          id="line68"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vmq-icon-st6"
          x1="378.89999"
          y1="288.89999"
          x2="378.89999"
          y2="276.29999"
          id="line70"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vmq-icon-st6"
          x1="378"
          y1="287.20001"
          x2="378"
          y2="276.29999"
          id="line72"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vmq-icon-st2"
          d="m 389.9,295.4 -1,-2 -3.1,-1.7 h -1.3 v -0.3 h -6.2 v 0.7 h -2.9 c -0.4,0 -0.7,0.2 -0.7,0.4 v 10.9 c 0,0.2 0.3,0.4 0.7,0.4 h 2.9 v 0.7 h 6.2 v -0.3 h 1.3 l 3.1,-1.7 1,-2 z"
          id="path74"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vmq-icon-st2"
          d="m 390.6,295.4 -0.6,-2 -2.1,-1.7 H 387 v -0.3 h -4.1 v 0.7 h -2 c -0.2,0 -0.4,0.2 -0.4,0.4 v 0 10.9 c 0,0.2 0.2,0.4 0.4,0.4 v 0 h 2 v 0.7 h 4.1 v -0.3 h 0.9 l 2.1,-1.7 0.6,-2 z"
          id="path76"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vmq-icon-st1"
          d="m 387,291.4 1.1,-11.3 c 0,-0.5 -0.4,-1 -0.9,-1 0,0 0,0 -0.1,0 h -4.5 c -0.3,0 -0.5,0.1 -0.7,0.3 -0.2,0.2 -0.3,0.5 -0.2,0.7 l 1.5,11.2 z"
          id="path78"
          style="fill: #585857"
        />
        <path
          class="pc-vmq-icon-st1"
          d="m 387,304.5 1.1,11.3 c 0,0.3 -0.1,0.5 -0.2,0.7 -0.2,0.2 -0.4,0.3 -0.7,0.3 h -4.5 c -0.5,0 -0.9,-0.4 -0.9,-0.9 v -0.1 l 1.5,-11.2 z"
          id="path80"
          style="fill: #585857"
        />
        <path
          class="pc-vmq-icon-st7"
          d="m 379.9,298.3 -2.8,0.1 c -0.1,0 -0.3,0.1 -0.3,0.3 0,0.1 0.1,0.3 0.3,0.3 l 3.1,-0.1 c 0.1,0 0.3,-0.1 0.3,-0.3 v -1.2 c 0,-0.1 -0.1,-0.3 -0.3,-0.3 l -3.1,-0.1 c -0.1,0 -0.3,0.1 -0.3,0.3 0,0.1 0.1,0.3 0.3,0.3 l 2.8,0.1 z"
          id="path82"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vmq-icon-st7"
          d="m 380.4,303.6 c 0,-0.1 0.1,-0.2 0.1,-0.3 v -1.4 c 0,-0.1 -0.1,-0.3 -0.2,-0.3 -0.1,0 -0.3,0.1 -0.3,0.2 v 0.1 1.4 0.1 l -3,6.4 c 0,0.1 -0.1,0.2 -0.1,0.3 v 9.1 c 0,0.2 0.1,0.4 0.2,0.5 0.1,0.1 0.3,0.2 0.5,0.2 h 3.3 v -0.5 h -3.3 c -0.1,0 -0.1,0 -0.2,-0.1 -0.1,-0.1 -0.1,-0.1 -0.1,-0.2 v -9.1 -0.1 z"
          id="path84"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vmq-icon-st7"
          d="m 380.4,292.2 c 0,0.1 0.1,0.2 0.1,0.3 v 1.4 c 0,0.1 -0.1,0.3 -0.2,0.3 -0.1,0 -0.3,-0.1 -0.3,-0.2 v -0.1 -1.3 -0.1 l -3,-6.4 c 0,-0.1 -0.1,-0.2 -0.1,-0.3 v -9.2 c 0,-0.2 0.1,-0.4 0.2,-0.5 0.1,-0.1 0.3,-0.2 0.5,-0.2 h 3.3 v 0.5 h -3.3 c -0.1,0 -0.1,0 -0.2,0.1 0,0 -0.1,0.1 -0.1,0.2 v 9.1 0.1 z"
          id="path86"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <g id="g96">
          <g id="g94">
            <line
              class="pc-vmq-icon-st8"
              x1="394.70001"
              y1="313"
              x2="401.29999"
              y2="313"
              id="line88"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g92">
              <polygon
                class="pc-vmq-icon-st9"
                points="392.4,313 395.2,311.4 395.2,314.7 "
                id="polygon90"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
        <g id="g106">
          <g id="g104">
            <line
              class="pc-vmq-icon-st8"
              x1="394.70001"
              y1="284"
              x2="401.29999"
              y2="284"
              id="line98"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g102">
              <polygon
                class="pc-vmq-icon-st9"
                points="392.4,284 395.2,282.4 395.2,285.7 "
                id="polygon100"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vnp" viewBox="0 0 74.675024 54.325895">
      <style type="text/css" id="style2">
        .pc-vnp-icon-st0 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vnp-icon-st1 {
          fill: #1e1e1c;
        }

        .pc-vnp-icon-st2 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.2;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vnp-icon-st3 {
          fill: #585857;
        }

        .pc-vnp-icon-st4 {
          fill: #ffffff;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vnp-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vnp-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vnp-icon-st7 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vnp-icon-st8 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        }

        .pc-vnp-icon-st9 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vnp-icon-st10 {
          fill: #223a8f;
        }
      </style>
      <g id="g100" transform="translate(-378.5,-270.5)">
        <line
          class="pc-vnp-icon-st0"
          x1="381.29999"
          y1="274.39999"
          x2="381.29999"
          y2="279"
          id="line4"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnp-icon-st0"
          x1="381.29999"
          y1="303.60001"
          x2="381.29999"
          y2="323"
          id="line6"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnp-icon-st0"
          x1="381.29999"
          y1="272.79999"
          x2="381.29999"
          y2="291.89999"
          id="line8"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnp-icon-st0"
          x1="381.29999"
          y1="316.5"
          x2="381.29999"
          y2="321.10001"
          id="line10"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnp-icon-st0"
          x1="380.29999"
          y1="275.60001"
          x2="380.29999"
          y2="291.89999"
          id="line12"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnp-icon-st0"
          x1="380.29999"
          y1="319.89999"
          x2="380.29999"
          y2="303.60001"
          id="line14"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vnp-icon-st1"
          d="m 389.5,295.3 -0.6,-2 -2.1,-1.7 h -0.9 v -0.3 h -4.1 v 0.6 h -2 c -0.2,0 -0.4,0.2 -0.4,0.4 v 0 10.9 c 0,0.2 0.2,0.4 0.4,0.4 v 0 h 2 v 0.7 h 4.1 V 304 h 0.9 l 2.1,-1.7 0.6,-2 z"
          id="path16"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vnp-icon-st2"
          d="m 381.3,271.1 c 0,-0.2 -0.1,-0.4 -0.3,-0.5 -0.2,0 -0.4,0.1 -0.5,0.3 v 0.1 2 c 0,0 0,0.1 0,0.2 l -1.8,3.9 c -0.1,0.1 -0.1,0.3 -0.1,0.5 v 15.1 c 0,0.2 0.1,0.4 0.3,0.5 0.2,0 0.4,-0.1 0.5,-0.3 v -0.1 -15.1 c 0,-0.1 0,-0.1 0,-0.2 l 1.8,-3.9 c 0.1,-0.1 0.1,-0.3 0.1,-0.5 z"
          id="path18"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.2;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vnp-icon-st2"
          d="m 381.3,324.4 c 0,0.2 -0.2,0.4 -0.5,0.3 -0.2,0 -0.3,-0.2 -0.3,-0.3 v -2 c 0,0 0,-0.1 0,-0.2 l -1.8,-3.9 c -0.1,-0.1 -0.1,-0.3 -0.1,-0.5 v -15.2 c 0,-0.2 0.2,-0.4 0.5,-0.3 0.2,0 0.3,0.2 0.3,0.3 v 15.1 c 0,0 0,0.1 0,0.2 l 1.8,3.9 c 0.1,0.1 0.1,0.3 0.1,0.5 z"
          id="path20"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.2;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vnp-icon-st3"
          d="m 385.9,304.3 1.1,11.3 c 0,0.5 -0.4,1 -0.9,1 0,0 0,0 -0.1,0 h -4.5 c -0.5,0 -0.9,-0.4 -0.9,-0.9 v -0.1 l 1.5,-11.2 z"
          id="path22"
          style="fill: #585857"
        />
        <path
          class="pc-vnp-icon-st3"
          d="M 385.9,291.2 387,280 c 0,-0.5 -0.4,-1 -0.9,-1 v 0 h -4.5 c -0.3,0 -0.5,0.1 -0.7,0.3 -0.2,0.2 -0.3,0.5 -0.2,0.7 l 1.5,11.2 z"
          id="path24"
          style="fill: #585857"
        />
        <circle
          class="pc-vnp-icon-st4"
          cx="426.10001"
          cy="297.89999"
          r="19.700001"
          id="circle26"
          style="
            fill: #ffffff;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vnp-icon-st3"
          d="m 428.1,297.9 1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.4 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.3 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
          id="path28"
          style="fill: #585857"
        />
        <path
          class="pc-vnp-icon-st3"
          d="m 427,299.8 16.6,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.6 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.3,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.7,6.7 z"
          id="path30"
          style="fill: #585857"
        />
        <path
          class="pc-vnp-icon-st3"
          d="m 424.3,299.1 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.7 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.6 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 l -11.2,-12.9 z"
          id="path32"
          style="fill: #585857"
        />
        <path
          class="pc-vnp-icon-st3"
          d="M 424.7,296.8 413.8,310 c -0.2,0.3 -0.4,0.6 -0.5,1 -0.1,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.1 0.6,0.1 0.8,0 0.4,-0.2 0.6,-0.4 0.8,-0.8 l 8.8,-14.6 z"
          id="path34"
          style="fill: #585857"
        />
        <path
          class="pc-vnp-icon-st3"
          d="m 426.5,296.2 -15.9,-6.3 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.7,3.9 z"
          id="path36"
          style="fill: #585857"
        />
        <circle
          class="pc-vnp-icon-st1"
          cx="426.10001"
          cy="297.89999"
          r="6.4000001"
          id="circle38"
          style="fill: #1e1e1c"
        />
        <circle
          class="pc-vnp-icon-st5"
          cx="426.10001"
          cy="297.89999"
          r="21.6"
          id="circle40"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnp-icon-st5"
          cx="426.10001"
          cy="297.89999"
          r="17.700001"
          id="circle42"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnp-icon-st5"
          cx="426.10001"
          cy="297.89999"
          r="15.9"
          id="circle44"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnp-icon-st5"
          cx="426.10001"
          cy="297.89999"
          r="14.3"
          id="circle46"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnp-icon-st5"
          cx="426.10001"
          cy="297.89999"
          r="12.6"
          id="circle48"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnp-icon-st5"
          cx="426.10001"
          cy="297.89999"
          r="15.9"
          id="circle50"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnp-icon-st6"
          cx="426.10001"
          cy="297.89999"
          r="24"
          id="circle52"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnp-icon-st5"
          cx="426.10001"
          cy="297.89999"
          r="10.8"
          id="circle54"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnp-icon-st5"
          cx="426.10001"
          cy="297.89999"
          r="8.8999996"
          id="circle56"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnp-icon-st5"
          cx="426.10001"
          cy="297.89999"
          r="7.0999999"
          id="circle58"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnp-icon-st7"
          x1="425.29999"
          y1="271.10001"
          x2="425.29999"
          y2="324.39999"
          id="line60"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnp-icon-st7"
          x1="427"
          y1="271.10001"
          x2="427"
          y2="324.39999"
          id="line62"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnp-icon-st7"
          x1="399.5"
          y1="298.60001"
          x2="452.79999"
          y2="298.60001"
          id="line64"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnp-icon-st7"
          x1="399.5"
          y1="297"
          x2="452.79999"
          y2="297"
          id="line66"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnp-icon-st5"
          x1="409"
          y1="281.10001"
          x2="443"
          y2="315"
          id="line68"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnp-icon-st5"
          x1="409"
          y1="314.79999"
          x2="442.89999"
          y2="280.89999"
          id="line70"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnp-icon-st8"
          x1="427"
          y1="271.10001"
          x2="425.29999"
          y2="271.10001"
          id="line72"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnp-icon-st8"
          x1="427"
          y1="324.39999"
          x2="425.29999"
          y2="324.39999"
          id="line74"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnp-icon-st8"
          x1="452.79999"
          y1="298.60001"
          x2="452.79999"
          y2="297"
          id="line76"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnp-icon-st8"
          x1="399.5"
          y1="298.60001"
          x2="399.5"
          y2="297"
          id="line78"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <g id="g88">
          <g id="g86">
            <line
              class="pc-vnp-icon-st9"
              x1="396.10001"
              y1="312.20001"
              x2="389.60001"
              y2="312.20001"
              id="line80"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g84">
              <polygon
                class="pc-vnp-icon-st10"
                points="398.5,312.2 395.7,313.8 395.7,310.5 "
                id="polygon82"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
        <g id="g98">
          <g id="g96">
            <line
              class="pc-vnp-icon-st9"
              x1="396.10001"
              y1="283.20001"
              x2="389.60001"
              y2="283.20001"
              id="line90"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g94">
              <polygon
                class="pc-vnp-icon-st10"
                points="398.5,283.2 395.7,284.8 395.7,281.5 "
                id="polygon92"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vnq" viewBox="0 0 73.099989 44.400002">
      <style type="text/css" id="style2">
        .pc-vnq-icon-st0 {
          fill: #ffffff;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vnq-icon-st1 {
          fill: #585857;
        }

        .pc-vnq-icon-st2 {
          fill: #1e1e1c;
        }

        .pc-vnq-icon-st3 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vnq-icon-st4 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vnq-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vnq-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vnq-icon-st7 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.4;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vnq-icon-st8 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vnq-icon-st9 {
          fill: #223a8f;
        }
      </style>
      <g id="g108" transform="translate(-374.7,-275.7)">
        <circle
          class="pc-vnq-icon-st0"
          cx="425.89999"
          cy="297.79999"
          r="19.700001"
          id="circle4"
          style="
            fill: #ffffff;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vnq-icon-st1"
          d="m 428,297.9 1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.4 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
          id="path6"
          style="fill: #585857"
        />
        <path
          class="pc-vnq-icon-st1"
          d="m 426.8,299.8 16.6,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.6 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.3,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.7,6.7 z"
          id="path8"
          style="fill: #585857"
        />
        <path
          class="pc-vnq-icon-st1"
          d="m 424.2,299 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.7 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.5 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 l -11.2,-12.9 z"
          id="path10"
          style="fill: #585857"
        />
        <path
          class="pc-vnq-icon-st1"
          d="M 424.5,296.8 413.6,310 c -0.2,0.3 -0.4,0.6 -0.5,1 -0.1,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.1 0.6,0.1 0.8,0 0.4,-0.2 0.6,-0.4 0.8,-0.8 l 8.8,-14.6 z"
          id="path12"
          style="fill: #585857"
        />
        <path
          class="pc-vnq-icon-st1"
          d="m 426.3,296.2 -15.9,-6.3 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.6,3.8 z"
          id="path14"
          style="fill: #585857"
        />
        <circle
          class="pc-vnq-icon-st2"
          cx="425.89999"
          cy="297.79999"
          r="6.4000001"
          id="circle16"
          style="fill: #1e1e1c"
        />
        <circle
          class="pc-vnq-icon-st3"
          cx="425.89999"
          cy="297.79999"
          r="21.6"
          id="circle18"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="17.700001"
          id="circle20"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="15.9"
          id="circle22"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="14.3"
          id="circle24"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="12.6"
          id="circle26"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="15.9"
          id="circle28"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="10.8"
          id="circle30"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="8.8999996"
          id="circle32"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vnq-icon-st4"
          cx="425.89999"
          cy="297.79999"
          r="7.0999999"
          id="circle34"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnq-icon-st5"
          x1="425.10001"
          y1="276.20001"
          x2="425.10001"
          y2="319.5"
          id="line36"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnq-icon-st5"
          x1="426.79999"
          y1="276.20001"
          x2="426.79999"
          y2="319.5"
          id="line38"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnq-icon-st5"
          x1="404.29999"
          y1="298.5"
          x2="447.60001"
          y2="298.5"
          id="line40"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnq-icon-st5"
          x1="404.29999"
          y1="297"
          x2="447.60001"
          y2="297"
          id="line42"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnq-icon-st4"
          x1="410.5"
          y1="282.70001"
          x2="441.10001"
          y2="313.29999"
          id="line44"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnq-icon-st4"
          x1="410.5"
          y1="313"
          x2="441.10001"
          y2="282.39999"
          id="line46"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vnq-icon-st4"
          d="M 385.1,279.2"
          id="path48"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vnq-icon-st4"
          d="M 384.9,316.6"
          id="path50"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vnq-icon-st6"
          x1="376.79999"
          y1="285.70001"
          x2="376.79999"
          y2="310.10001"
          id="line52"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnq-icon-st6"
          x1="378"
          y1="288.39999"
          x2="378"
          y2="297"
          id="line54"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnq-icon-st6"
          x1="378.89999"
          y1="290.10001"
          x2="378.89999"
          y2="297"
          id="line56"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnq-icon-st6"
          x1="378.89999"
          y1="306.89999"
          x2="378.89999"
          y2="319.5"
          id="line58"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnq-icon-st6"
          x1="378"
          y1="308.60001"
          x2="378"
          y2="319.5"
          id="line60"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnq-icon-st6"
          x1="378"
          y1="298.79999"
          x2="378"
          y2="307.39999"
          id="line62"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnq-icon-st6"
          x1="378.89999"
          y1="298.79999"
          x2="378.89999"
          y2="305.70001"
          id="line64"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnq-icon-st6"
          x1="378.89999"
          y1="297.5"
          x2="378.89999"
          y2="298.29999"
          id="line66"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnq-icon-st6"
          x1="378"
          y1="297.5"
          x2="378"
          y2="298.29999"
          id="line68"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnq-icon-st6"
          x1="378.89999"
          y1="288.89999"
          x2="378.89999"
          y2="276.29999"
          id="line70"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vnq-icon-st6"
          x1="378"
          y1="287.20001"
          x2="378"
          y2="276.29999"
          id="line72"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vnq-icon-st2"
          d="m 389.9,295.4 -1,-2 -3.1,-1.7 h -1.3 v -0.3 h -6.2 v 0.7 h -2.9 c -0.4,0 -0.7,0.2 -0.7,0.4 v 10.9 c 0,0.2 0.3,0.4 0.7,0.4 h 2.9 v 0.7 h 6.2 v -0.3 h 1.3 l 3.1,-1.7 1,-2 z"
          id="path74"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vnq-icon-st2"
          d="m 390.6,295.4 -0.6,-2 -2.1,-1.7 H 387 v -0.3 h -4.1 v 0.7 h -2 c -0.2,0 -0.4,0.2 -0.4,0.4 v 0 10.9 c 0,0.2 0.2,0.4 0.4,0.4 v 0 h 2 v 0.7 h 4.1 v -0.3 h 0.9 l 2.1,-1.7 0.6,-2 z"
          id="path76"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vnq-icon-st1"
          d="m 387,291.4 1.1,-11.3 c 0,-0.5 -0.4,-1 -0.9,-1 0,0 0,0 -0.1,0 h -4.5 c -0.3,0 -0.5,0.1 -0.7,0.3 -0.2,0.2 -0.3,0.5 -0.2,0.7 l 1.5,11.2 z"
          id="path78"
          style="fill: #585857"
        />
        <path
          class="pc-vnq-icon-st1"
          d="m 387,304.5 1.1,11.3 c 0,0.3 -0.1,0.5 -0.2,0.7 -0.2,0.2 -0.4,0.3 -0.7,0.3 h -4.5 c -0.5,0 -0.9,-0.4 -0.9,-0.9 v -0.1 l 1.5,-11.2 z"
          id="path80"
          style="fill: #585857"
        />
        <path
          class="pc-vnq-icon-st7"
          d="m 379.9,298.3 -2.8,0.1 c -0.1,0 -0.3,0.1 -0.3,0.3 0,0.1 0.1,0.3 0.3,0.3 l 3.1,-0.1 c 0.1,0 0.3,-0.1 0.3,-0.3 v -1.2 c 0,-0.1 -0.1,-0.3 -0.3,-0.3 l -3.1,-0.1 c -0.1,0 -0.3,0.1 -0.3,0.3 0,0.1 0.1,0.3 0.3,0.3 l 2.8,0.1 z"
          id="path82"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vnq-icon-st7"
          d="m 380.4,303.6 c 0,-0.1 0.1,-0.2 0.1,-0.3 v -1.4 c 0,-0.1 -0.1,-0.3 -0.2,-0.3 -0.1,0 -0.3,0.1 -0.3,0.2 v 0.1 1.4 0.1 l -3,6.4 c 0,0.1 -0.1,0.2 -0.1,0.3 v 9.1 c 0,0.2 0.1,0.4 0.2,0.5 0.1,0.1 0.3,0.2 0.5,0.2 h 3.3 v -0.5 h -3.3 c -0.1,0 -0.1,0 -0.2,-0.1 -0.1,-0.1 -0.1,-0.1 -0.1,-0.2 v -9.1 -0.1 z"
          id="path84"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vnq-icon-st7"
          d="m 380.4,292.2 c 0,0.1 0.1,0.2 0.1,0.3 v 1.4 c 0,0.1 -0.1,0.3 -0.2,0.3 -0.1,0 -0.3,-0.1 -0.3,-0.2 v -0.1 -1.3 -0.1 l -3,-6.4 c 0,-0.1 -0.1,-0.2 -0.1,-0.3 v -9.2 c 0,-0.2 0.1,-0.4 0.2,-0.5 0.1,-0.1 0.3,-0.2 0.5,-0.2 h 3.3 v 0.5 h -3.3 c -0.1,0 -0.1,0 -0.2,0.1 0,0 -0.1,0.1 -0.1,0.2 v 9.1 0.1 z"
          id="path86"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <g id="g96">
          <g id="g94">
            <line
              class="pc-vnq-icon-st8"
              x1="398.89999"
              y1="313"
              x2="392.39999"
              y2="313"
              id="line88"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g92">
              <polygon
                class="pc-vnq-icon-st9"
                points="401.3,313 398.5,314.7 398.5,311.4 "
                id="polygon90"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
        <g id="g106">
          <g id="g104">
            <line
              class="pc-vnq-icon-st8"
              x1="398.89999"
              y1="284"
              x2="392.39999"
              y2="284"
              id="line98"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g102">
              <polygon
                class="pc-vnq-icon-st9"
                points="401.3,284 398.5,285.7 398.5,282.4 "
                id="polygon100"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vua" viewBox="0 0 94.174985 63.550001">
      <style type="text/css" id="style2">
        .pc-vua-icon-st0 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vua-icon-st1 {
          fill: #1e1e1c;
        }

        .pc-vua-icon-st2 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vua-icon-st3 {
          fill: #585857;
        }

        .pc-vua-icon-st4 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.4;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vua-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vua-icon-st6 {
          fill: #ffffff;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vua-icon-st7 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vua-icon-st8 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        }

        .pc-vua-icon-st9 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vua-icon-st10 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vua-icon-st11 {
          fill: #223a8f;
        }
      </style>
      <g id="g140" transform="translate(-374.8,-266.32501)">
        <path
          class="pc-vua-icon-st0"
          d="M 384,279.3"
          id="path4"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vua-icon-st0"
          d="M 383.7,316.7"
          id="path6"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vua-icon-st1"
          d="m 389.2,295.5 -0.9,-2 -2.9,-1.7 h -1.3 v -0.3 h -5.9 v 0.7 h -2.8 c -0.4,0 -0.6,0.2 -0.6,0.4 v 10.9 c 0,0.3 0.3,0.5 0.6,0.5 h 2.8 v 0.7 h 5.9 v -0.4 h 1.3 l 2.9,-1.7 0.9,-2 z"
          id="path8"
          style="fill: #1e1e1c"
        />
        <line
          class="pc-vua-icon-st2"
          x1="390.89999"
          y1="276.70001"
          x2="390.89999"
          y2="319.29999"
          id="line10"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st2"
          x1="379.5"
          y1="274.60001"
          x2="379.5"
          y2="321.29999"
          id="line12"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vua-icon-st1"
          d="m 389.6,295.5 -0.6,-2 -2.1,-1.7 H 386 v -0.3 h -4.1 v 0.7 h -2 c -0.2,0 -0.4,0.2 -0.4,0.4 v 0 10.9 c 0,0.2 0.2,0.4 0.4,0.4 v 0 h 2 v 0.7 h 4.1 v -0.4 h 0.9 l 2.1,-1.7 0.6,-2 z"
          id="path14"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vua-icon-st3"
          d="m 386,291.4 1.1,-11.2 c 0,-0.5 -0.4,-1 -0.9,-1 v 0 h -4.5 c -0.3,0 -0.5,0.1 -0.7,0.3 -0.2,0.2 -0.3,0.5 -0.2,0.7 l 1.5,11.2 z"
          id="path16"
          style="fill: #585857"
        />
        <path
          class="pc-vua-icon-st3"
          d="m 386,304.5 1.1,11.3 c 0,0.3 -0.1,0.5 -0.2,0.7 -0.2,0.2 -0.4,0.3 -0.7,0.3 h -4.5 c -0.3,0 -0.5,-0.1 -0.7,-0.3 -0.2,-0.2 -0.3,-0.5 -0.2,-0.7 l 1.5,-11.2 z"
          id="path18"
          style="fill: #585857"
        />
        <path
          class="pc-vua-icon-st2"
          d="m 381.3,267 v -0.3 H 380 c -0.3,0 -0.5,0.2 -0.5,0.5 v 0 7.4 c 0,2 1.7,3.7 3.7,3.7 v 0 h 4.9 c 1.2,0 2.3,-0.7 2.8,-1.7 l -0.2,-0.1 c -0.5,1 -1.5,1.6 -2.6,1.6 h -4.8 c -2,0 -3.5,-1.6 -3.5,-3.5 v -7.3 c 0,-0.1 0.1,-0.3 0.3,-0.3 z"
          id="path20"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vua-icon-st2"
          d="m 381.3,328.9 v 0.3 H 380 c -0.3,0 -0.5,-0.2 -0.5,-0.5 v 0 -7.4 c 0,-2 1.7,-3.7 3.7,-3.7 v 0 h 4.9 c 1.2,0 2.3,0.6 2.8,1.7 l -0.2,0.1 c -0.5,-1 -1.5,-1.6 -2.6,-1.6 h -4.8 c -2,0 -3.5,1.6 -3.5,3.5 v 7.3 c 0,0.1 0.1,0.3 0.3,0.3 z"
          id="path22"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vua-icon-st4"
          d="m 377.8,297.1 h -1.2 c -0.1,0 -0.3,0.1 -0.3,0.2 0,0.1 0.1,0.3 0.2,0.3 h 0.1 1.2 1.2 v 0.7 h -1.2 -1.2 c -0.1,0 -0.3,0.1 -0.3,0.3 0,0.1 0.1,0.3 0.3,0.3 h 1.2 1.5 c 0.1,0 0.3,-0.1 0.3,-0.3 v -1.2 c 0,-0.1 -0.1,-0.3 -0.3,-0.3 z"
          id="path24"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vua-icon-st4"
          d="m 378,275.6 c 0.8,0 1.4,-0.6 1.4,-1.4 v -1.7 c 0,-0.1 -0.2,-0.2 -0.3,-0.2 -0.1,0 -0.2,0.1 -0.2,0.2 v 1.6 c 0,0.5 -0.5,1 -1,1 h -0.5 c -0.6,0 -1.2,0.5 -1.2,1.2 v 0 11.5 c 0,0.2 0,0.4 0.2,0.6 l 2.4,4.1 c 0.1,0.1 0.1,0.3 0.1,0.5 v 1.1 c 0,0.1 0.1,0.3 0.2,0.3 0.1,0 0.3,-0.1 0.3,-0.2 v -0.1 -1.1 c 0,-0.3 -0.1,-0.5 -0.2,-0.7 l -2.4,-4.1 c -0.1,-0.1 -0.1,-0.2 -0.1,-0.4 v -11.5 c 0,-0.4 0.3,-0.7 0.7,-0.7 z"
          id="path26"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vua-icon-st4"
          d="m 378,320.4 c 0.8,0 1.4,0.6 1.4,1.4 v 0 1.7 c 0,0.1 -0.1,0.3 -0.2,0.3 -0.1,0 -0.3,-0.1 -0.3,-0.2 v -0.1 -1.6 c 0,-0.5 -0.5,-1 -1,-1 h -0.5 c -0.6,0 -1.1,-0.5 -1.2,-1.1 v -11.5 c 0,-0.2 0,-0.4 0.2,-0.6 l 2.4,-4.1 c 0.1,-0.1 0.1,-0.3 0.1,-0.5 V 302 c 0,-0.1 0.2,-0.2 0.3,-0.2 0.1,0 0.2,0.1 0.2,0.2 v 1.1 c 0,0.3 -0.1,0.5 -0.2,0.7 l -2.4,4.1 c -0.1,0.1 -0.1,0.2 -0.1,0.4 v 11.4 c 0,0.4 0.3,0.7 0.7,0.7 z"
          id="path28"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="377.39999"
          y1="320.39999"
          x2="377.39999"
          y2="307"
          id="line30"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="378"
          y1="320.39999"
          x2="378"
          y2="305.89999"
          id="line32"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="378.70001"
          y1="320.5"
          x2="378.70001"
          y2="304.79999"
          id="line34"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="377.39999"
          y1="275.60001"
          x2="377.39999"
          y2="288.89999"
          id="line36"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="378"
          y1="275.60001"
          x2="378"
          y2="290"
          id="line38"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="378.70001"
          y1="275.39999"
          x2="378.70001"
          y2="291.10001"
          id="line40"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="376.20001"
          y1="287.70001"
          x2="376.20001"
          y2="308.20001"
          id="line42"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="377.39999"
          y1="306"
          x2="377.39999"
          y2="298.89999"
          id="line44"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="378"
          y1="304.89999"
          x2="378"
          y2="298.89999"
          id="line46"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="378.70001"
          y1="303.79999"
          x2="378.70001"
          y2="298.79999"
          id="line48"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="378.70001"
          y1="298.29999"
          x2="378.70001"
          y2="297.60001"
          id="line50"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="378"
          y1="298.29999"
          x2="378"
          y2="297.60001"
          id="line52"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="377.39999"
          y1="298.39999"
          x2="377.39999"
          y2="297.60001"
          id="line54"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="377.39999"
          y1="297"
          x2="377.39999"
          y2="290"
          id="line56"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="378"
          y1="297.10001"
          x2="378"
          y2="291.10001"
          id="line58"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vua-icon-st5"
          x1="378.70001"
          y1="297.10001"
          x2="378.70001"
          y2="292.20001"
          id="line60"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <circle
          class="pc-vua-icon-st6"
          cx="436.79999"
          cy="297.79999"
          r="19.700001"
          id="circle62"
          style="
            fill: #ffffff;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vua-icon-st0"
          cx="436.79999"
          cy="297.79999"
          r="21"
          id="circle64"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vua-icon-st3"
          d="m 438.8,297.8 1.1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.4 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.4 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
          id="path66"
          style="fill: #585857"
        />
        <path
          class="pc-vua-icon-st3"
          d="m 437.6,299.8 16.6,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.5 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.3,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.7,6.7 z"
          id="path68"
          style="fill: #585857"
        />
        <path
          class="pc-vua-icon-st3"
          d="m 435,299 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.8 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.5 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 L 437.9,297 Z"
          id="path70"
          style="fill: #585857"
        />
        <path
          class="pc-vua-icon-st3"
          d="M 435.4,296.8 424.5,310 c -0.2,0.3 -0.4,0.6 -0.5,1 0,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.2 0.6,0.2 0.8,0 0.4,-0.2 0.6,-0.4 0.8,-0.8 l 8.8,-14.7 z"
          id="path72"
          style="fill: #585857"
        />
        <path
          class="pc-vua-icon-st3"
          d="m 437.2,296.2 -15.9,-6.3 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.6,3.8 z"
          id="path74"
          style="fill: #585857"
        />
        <circle
          class="pc-vua-icon-st1"
          cx="436.79999"
          cy="297.79999"
          r="6.4000001"
          id="circle76"
          style="fill: #1e1e1c"
        />
        <circle
          class="pc-vua-icon-st7"
          cx="436.79999"
          cy="297.79999"
          r="22.700001"
          id="circle78"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vua-icon-st0"
          cx="436.79999"
          cy="297.79999"
          r="17.700001"
          id="circle80"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vua-icon-st0"
          cx="436.79999"
          cy="297.79999"
          r="15.9"
          id="circle82"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vua-icon-st0"
          cx="436.79999"
          cy="297.79999"
          r="14.3"
          id="circle84"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vua-icon-st0"
          cx="436.79999"
          cy="297.79999"
          r="12.6"
          id="circle86"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vua-icon-st0"
          cx="436.79999"
          cy="297.79999"
          r="15.9"
          id="circle88"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vua-icon-st0"
          cx="436.79999"
          cy="297.79999"
          r="10.8"
          id="circle90"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vua-icon-st0"
          cx="436.79999"
          cy="297.79999"
          r="8.8999996"
          id="circle92"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vua-icon-st0"
          cx="436.79999"
          cy="297.79999"
          r="7.0999999"
          id="circle94"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vua-icon-st0"
          x1="420.60001"
          y1="281.89999"
          x2="452.70001"
          y2="314"
          id="line96"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vua-icon-st0"
          x1="420.60001"
          y1="313.79999"
          x2="452.70001"
          y2="281.60001"
          id="line98"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vua-icon-st8"
          x1="435.89999"
          y1="323.20001"
          x2="437.70001"
          y2="323.20001"
          id="line100"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vua-icon-st9"
          x1="435.89999"
          y1="272.79999"
          x2="435.89999"
          y2="323.20001"
          id="line102"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vua-icon-st9"
          x1="437.70001"
          y1="272.79999"
          x2="437.70001"
          y2="323.20001"
          id="line104"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vua-icon-st8"
          x1="435.89999"
          y1="272.79999"
          x2="437.70001"
          y2="272.79999"
          id="line106"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vua-icon-st9"
          x1="411.60001"
          y1="298.89999"
          x2="462"
          y2="298.89999"
          id="line108"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vua-icon-st9"
          x1="411.60001"
          y1="297.10001"
          x2="462"
          y2="297.10001"
          id="line110"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vua-icon-st8"
          x1="411.60001"
          y1="298.89999"
          x2="411.60001"
          y2="297.10001"
          id="line112"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vua-icon-st8"
          x1="462"
          y1="297.10001"
          x2="462"
          y2="298.89999"
          id="line114"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <rect
          x="405.79999"
          y="266.70001"
          class="pc-vua-icon-st9"
          width="62.799999"
          height="62.799999"
          id="rect116"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <g id="g138">
          <g id="g126">
            <g id="g124">
              <line
                class="pc-vua-icon-st10"
                x1="400.39999"
                y1="312.5"
                x2="393.89999"
                y2="312.5"
                id="line118"
                style="
                  fill: none;
                  stroke: #223a8f;
                  stroke-width: 0.60000002;
                  stroke-miterlimit: 10;
                "
              />
              <g id="g122">
                <polygon
                  class="pc-vua-icon-st11"
                  points="402.8,312.5 400,314.3 400,311 "
                  id="polygon120"
                  style="fill: #223a8f"
                />
              </g>
            </g>
          </g>
          <g id="g136">
            <g id="g134">
              <line
                class="pc-vua-icon-st10"
                x1="400.39999"
                y1="283.5"
                x2="393.89999"
                y2="283.5"
                id="line128"
                style="
                  fill: none;
                  stroke: #223a8f;
                  stroke-width: 0.60000002;
                  stroke-miterlimit: 10;
                "
              />
              <g id="g132">
                <polygon
                  class="pc-vua-icon-st11"
                  points="402.8,283.5 400,285.3 400,282 "
                  id="polygon130"
                  style="fill: #223a8f"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vur" viewBox="0 0 91.49999 63.200014">
      <style type="text/css" id="style2">
        .pc-vur-icon-st0 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vur-icon-st1 {
          fill: #1e1e1c;
        }

        .pc-vur-icon-st2 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vur-icon-st3 {
          fill: #585857;
        }

        .pc-vur-icon-st4 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.4;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vur-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vur-icon-st6 {
          fill: #ffffff;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vur-icon-st7 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vur-icon-st8 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        }

        .pc-vur-icon-st9 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vur-icon-st10 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vur-icon-st11 {
          fill: #223a8f;
        }

        .pc-vur-icon-st12 {
          fill: none;
          stroke: #1d1d1b;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }
      </style>
      <g id="g140" transform="translate(-374.8,-266.29999)">
        <path
          class="pc-vur-icon-st0"
          d="M 384,279.3"
          id="path4"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vur-icon-st0"
          d="M 383.7,316.7"
          id="path6"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vur-icon-st1"
          d="m 389.2,295.5 -0.9,-2 -2.9,-1.7 h -1.3 v -0.3 h -5.9 v 0.7 h -2.8 c -0.4,0 -0.6,0.2 -0.6,0.4 v 10.9 c 0,0.3 0.3,0.5 0.6,0.5 h 2.8 v 0.7 h 5.9 v -0.4 h 1.3 l 2.9,-1.7 0.9,-2 z"
          id="path8"
          style="fill: #1e1e1c"
        />
        <line
          class="pc-vur-icon-st2"
          x1="390.89999"
          y1="276.70001"
          x2="390.89999"
          y2="319.29999"
          id="line10"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st2"
          x1="379.5"
          y1="274.60001"
          x2="379.5"
          y2="321.29999"
          id="line12"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vur-icon-st1"
          d="m 389.6,295.5 -0.6,-2 -2.1,-1.7 H 386 v -0.3 h -4.1 v 0.7 h -2 c -0.2,0 -0.4,0.2 -0.4,0.4 v 0 10.9 c 0,0.2 0.2,0.4 0.4,0.4 v 0 h 2 v 0.7 h 4.1 v -0.4 h 0.9 l 2.1,-1.7 0.6,-2 z"
          id="path14"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vur-icon-st3"
          d="m 386,291.4 1.1,-11.2 c 0,-0.5 -0.4,-1 -0.9,-1 v 0 h -4.5 c -0.3,0 -0.5,0.1 -0.7,0.3 -0.2,0.2 -0.3,0.5 -0.2,0.7 l 1.5,11.2 z"
          id="path16"
          style="fill: #585857"
        />
        <path
          class="pc-vur-icon-st3"
          d="m 386,304.5 1.1,11.3 c 0,0.3 -0.1,0.5 -0.2,0.7 -0.2,0.2 -0.4,0.3 -0.7,0.3 h -4.5 c -0.3,0 -0.5,-0.1 -0.7,-0.3 -0.2,-0.2 -0.3,-0.5 -0.2,-0.7 l 1.5,-11.2 z"
          id="path18"
          style="fill: #585857"
        />
        <path
          class="pc-vur-icon-st2"
          d="m 381.3,267 v -0.3 H 380 c -0.3,0 -0.5,0.2 -0.5,0.5 v 0 7.4 c 0,2 1.7,3.7 3.7,3.7 v 0 h 4.9 c 1.2,0 2.3,-0.7 2.8,-1.7 l -0.2,-0.1 c -0.5,1 -1.5,1.6 -2.6,1.6 h -4.8 c -2,0 -3.5,-1.6 -3.5,-3.5 v -7.3 c 0,-0.1 0.1,-0.3 0.3,-0.3 z"
          id="path20"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vur-icon-st2"
          d="m 381.3,328.9 v 0.3 H 380 c -0.3,0 -0.5,-0.2 -0.5,-0.5 v 0 -7.4 c 0,-2 1.7,-3.7 3.7,-3.7 v 0 h 4.9 c 1.2,0 2.3,0.6 2.8,1.7 l -0.2,0.1 c -0.5,-1 -1.5,-1.6 -2.6,-1.6 h -4.8 c -2,0 -3.5,1.6 -3.5,3.5 v 7.3 c 0,0.1 0.1,0.3 0.3,0.3 z"
          id="path22"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vur-icon-st4"
          d="m 377.8,297.1 h -1.2 c -0.1,0 -0.3,0.1 -0.3,0.2 0,0.1 0.1,0.3 0.2,0.3 h 0.1 1.2 1.2 v 0.7 h -1.2 -1.2 c -0.1,0 -0.3,0.1 -0.3,0.3 0,0.1 0.1,0.3 0.3,0.3 h 1.2 1.5 c 0.1,0 0.3,-0.1 0.3,-0.3 v -1.2 c 0,-0.1 -0.1,-0.3 -0.3,-0.3 z"
          id="path24"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vur-icon-st4"
          d="m 378,275.6 c 0.8,0 1.4,-0.6 1.4,-1.4 v -1.7 c 0,-0.1 -0.2,-0.2 -0.3,-0.2 -0.1,0 -0.2,0.1 -0.2,0.2 v 1.6 c 0,0.5 -0.5,1 -1,1 h -0.5 c -0.6,0 -1.2,0.5 -1.2,1.2 v 0 11.5 c 0,0.2 0,0.4 0.2,0.6 l 2.4,4.1 c 0.1,0.1 0.1,0.3 0.1,0.5 v 1.1 c 0,0.1 0.1,0.3 0.2,0.3 0.1,0 0.3,-0.1 0.3,-0.2 v -0.1 -1.1 c 0,-0.3 -0.1,-0.5 -0.2,-0.7 l -2.4,-4.1 c -0.1,-0.1 -0.1,-0.2 -0.1,-0.4 v -11.5 c 0,-0.4 0.3,-0.7 0.7,-0.7 z"
          id="path26"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vur-icon-st4"
          d="m 378,320.4 c 0.8,0 1.4,0.6 1.4,1.4 v 0 1.7 c 0,0.1 -0.1,0.3 -0.2,0.3 -0.1,0 -0.3,-0.1 -0.3,-0.2 v -0.1 -1.6 c 0,-0.5 -0.5,-1 -1,-1 h -0.5 c -0.6,0 -1.1,-0.5 -1.2,-1.1 v -11.5 c 0,-0.2 0,-0.4 0.2,-0.6 l 2.4,-4.1 c 0.1,-0.1 0.1,-0.3 0.1,-0.5 V 302 c 0,-0.1 0.2,-0.2 0.3,-0.2 0.1,0 0.2,0.1 0.2,0.2 v 1.1 c 0,0.3 -0.1,0.5 -0.2,0.7 l -2.4,4.1 c -0.1,0.1 -0.1,0.2 -0.1,0.4 v 11.4 c 0,0.4 0.3,0.7 0.7,0.7 z"
          id="path28"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="377.39999"
          y1="320.39999"
          x2="377.39999"
          y2="307"
          id="line30"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="378"
          y1="320.39999"
          x2="378"
          y2="305.89999"
          id="line32"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="378.70001"
          y1="320.5"
          x2="378.70001"
          y2="304.79999"
          id="line34"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="377.39999"
          y1="275.60001"
          x2="377.39999"
          y2="288.89999"
          id="line36"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="378"
          y1="275.60001"
          x2="378"
          y2="290"
          id="line38"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="378.70001"
          y1="275.39999"
          x2="378.70001"
          y2="291.10001"
          id="line40"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="376.20001"
          y1="287.70001"
          x2="376.20001"
          y2="308.20001"
          id="line42"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="377.39999"
          y1="306"
          x2="377.39999"
          y2="298.89999"
          id="line44"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="378"
          y1="304.89999"
          x2="378"
          y2="298.89999"
          id="line46"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="378.70001"
          y1="303.79999"
          x2="378.70001"
          y2="298.79999"
          id="line48"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="378.70001"
          y1="298.29999"
          x2="378.70001"
          y2="297.60001"
          id="line50"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="378"
          y1="298.29999"
          x2="378"
          y2="297.60001"
          id="line52"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="377.39999"
          y1="298.39999"
          x2="377.39999"
          y2="297.60001"
          id="line54"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="377.39999"
          y1="297"
          x2="377.39999"
          y2="290"
          id="line56"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="378"
          y1="297.10001"
          x2="378"
          y2="291.10001"
          id="line58"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vur-icon-st5"
          x1="378.70001"
          y1="297.10001"
          x2="378.70001"
          y2="292.20001"
          id="line60"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <circle
          class="pc-vur-icon-st6"
          cx="434.79999"
          cy="297.79999"
          r="19.700001"
          id="circle62"
          style="
            fill: #ffffff;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vur-icon-st0"
          cx="434.79999"
          cy="297.79999"
          r="21"
          id="circle64"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vur-icon-st3"
          d="m 436.8,297.8 1.1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.4 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.4 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
          id="path66"
          style="fill: #585857"
        />
        <path
          class="pc-vur-icon-st3"
          d="m 435.6,299.8 16.6,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.5 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.3,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.7,6.7 z"
          id="path68"
          style="fill: #585857"
        />
        <path
          class="pc-vur-icon-st3"
          d="m 433,299 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.8 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.5 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 L 435.9,297 Z"
          id="path70"
          style="fill: #585857"
        />
        <path
          class="pc-vur-icon-st3"
          d="M 433.4,296.8 422.5,310 c -0.2,0.3 -0.4,0.6 -0.5,1 0,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.2 0.6,0.2 0.8,0 0.4,-0.2 0.6,-0.4 0.8,-0.8 l 8.8,-14.7 z"
          id="path72"
          style="fill: #585857"
        />
        <path
          class="pc-vur-icon-st3"
          d="m 435.2,296.2 -15.9,-6.3 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.6,3.8 z"
          id="path74"
          style="fill: #585857"
        />
        <circle
          class="pc-vur-icon-st1"
          cx="434.79999"
          cy="297.79999"
          r="6.4000001"
          id="circle76"
          style="fill: #1e1e1c"
        />
        <circle
          class="pc-vur-icon-st7"
          cx="434.79999"
          cy="297.79999"
          r="22.700001"
          id="circle78"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vur-icon-st0"
          cx="434.79999"
          cy="297.79999"
          r="17.700001"
          id="circle80"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vur-icon-st0"
          cx="434.79999"
          cy="297.79999"
          r="15.9"
          id="circle82"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vur-icon-st0"
          cx="434.79999"
          cy="297.79999"
          r="14.3"
          id="circle84"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vur-icon-st0"
          cx="434.79999"
          cy="297.79999"
          r="12.6"
          id="circle86"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vur-icon-st0"
          cx="434.79999"
          cy="297.79999"
          r="15.9"
          id="circle88"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vur-icon-st0"
          cx="434.79999"
          cy="297.79999"
          r="10.8"
          id="circle90"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vur-icon-st0"
          cx="434.79999"
          cy="297.79999"
          r="8.8999996"
          id="circle92"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vur-icon-st0"
          cx="434.79999"
          cy="297.79999"
          r="7.0999999"
          id="circle94"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vur-icon-st0"
          x1="418.60001"
          y1="281.89999"
          x2="450.70001"
          y2="314"
          id="line96"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vur-icon-st0"
          x1="418.60001"
          y1="313.79999"
          x2="450.70001"
          y2="281.60001"
          id="line98"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vur-icon-st8"
          x1="433.89999"
          y1="323.20001"
          x2="435.70001"
          y2="323.20001"
          id="line100"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vur-icon-st9"
          x1="433.89999"
          y1="272.79999"
          x2="433.89999"
          y2="323.20001"
          id="line102"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vur-icon-st9"
          x1="435.70001"
          y1="272.79999"
          x2="435.70001"
          y2="323.20001"
          id="line104"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vur-icon-st8"
          x1="433.89999"
          y1="272.79999"
          x2="435.70001"
          y2="272.79999"
          id="line106"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vur-icon-st9"
          x1="409.60001"
          y1="298.89999"
          x2="460"
          y2="298.89999"
          id="line108"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vur-icon-st9"
          x1="409.60001"
          y1="297.10001"
          x2="460"
          y2="297.10001"
          id="line110"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vur-icon-st8"
          x1="409.60001"
          y1="298.89999"
          x2="409.60001"
          y2="297.10001"
          id="line112"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vur-icon-st8"
          x1="460"
          y1="297.10001"
          x2="460"
          y2="298.89999"
          id="line114"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <g id="g136">
          <g id="g124">
            <g id="g122">
              <line
                class="pc-vur-icon-st10"
                x1="400.29999"
                y1="312.70001"
                x2="393.79999"
                y2="312.70001"
                id="line116"
                style="
                  fill: none;
                  stroke: #223a8f;
                  stroke-width: 0.60000002;
                  stroke-miterlimit: 10;
                "
              />
              <g id="g120">
                <polygon
                  class="pc-vur-icon-st11"
                  points="402.7,312.7 399.9,314.4 399.9,311.1 "
                  id="polygon118"
                  style="fill: #223a8f"
                />
              </g>
            </g>
          </g>
          <g id="g134">
            <g id="g132">
              <line
                class="pc-vur-icon-st10"
                x1="400.29999"
                y1="283.70001"
                x2="393.79999"
                y2="283.70001"
                id="line126"
                style="
                  fill: none;
                  stroke: #223a8f;
                  stroke-width: 0.60000002;
                  stroke-miterlimit: 10;
                "
              />
              <g id="g130">
                <polygon
                  class="pc-vur-icon-st11"
                  points="402.7,283.7 399.9,285.4 399.9,282.1 "
                  id="polygon128"
                  style="fill: #223a8f"
                />
              </g>
            </g>
          </g>
        </g>
        <circle
          class="pc-vur-icon-st12"
          cx="434.79999"
          cy="297.79999"
          r="31.200001"
          id="circle138"
          style="
            fill: none;
            stroke: #1d1d1b;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
        />
      </g>
    </symbol>

    <symbol id="product-category-vwa" viewBox="0 0 95.974988 63.550001">
      <style type="text/css" id="style2">
        .pc-vwa-icon-st0 {
          fill: #ffffff;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vwa-icon-st1 {
          fill: #585857;
        }

        .pc-vwa-icon-st2 {
          fill: #1e1e1c;
        }

        .pc-vwa-icon-st3 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vwa-icon-st4 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vwa-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vwa-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.4;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vwa-icon-st7 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vwa-icon-st8 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.4;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vwa-icon-st9 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vwa-icon-st10 {
          fill: #223a8f;
        }
      </style>
      <g id="g120" transform="translate(-375.1,-266.22501)">
        <circle
          class="pc-vwa-icon-st0"
          cx="439.5"
          cy="297.79999"
          r="19.700001"
          id="circle4"
          style="
            fill: #ffffff;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwa-icon-st1"
          d="m 441.5,297.8 1.1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.4 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.4 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
          id="path6"
          style="fill: #585857"
        />
        <path
          class="pc-vwa-icon-st1"
          d="m 440.3,299.8 16.6,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.5 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.4,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.8,6.7 z"
          id="path8"
          style="fill: #585857"
        />
        <path
          class="pc-vwa-icon-st1"
          d="m 437.7,299 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.7 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.5 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 L 440.6,297 Z"
          id="path10"
          style="fill: #585857"
        />
        <path
          class="pc-vwa-icon-st1"
          d="M 438.1,296.8 427.2,310 c -0.2,0.3 -0.4,0.6 -0.5,1 0,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.1 0.6,0.1 0.8,0 0.4,-0.2 0.6,-0.5 0.8,-0.8 l 8.8,-14.6 z"
          id="path12"
          style="fill: #585857"
        />
        <path
          class="pc-vwa-icon-st1"
          d="M 439.9,296.1 424,289.8 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.7,3.8 z"
          id="path14"
          style="fill: #585857"
        />
        <circle
          class="pc-vwa-icon-st2"
          cx="439.5"
          cy="297.79999"
          r="6.4000001"
          id="circle16"
          style="fill: #1e1e1c"
        />
        <circle
          class="pc-vwa-icon-st3"
          cx="439.5"
          cy="297.79999"
          r="21.6"
          id="circle18"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwa-icon-st4"
          cx="439.5"
          cy="297.79999"
          r="17.700001"
          id="circle20"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwa-icon-st4"
          cx="439.5"
          cy="297.79999"
          r="15.9"
          id="circle22"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwa-icon-st4"
          cx="439.5"
          cy="297.79999"
          r="14.3"
          id="circle24"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwa-icon-st4"
          cx="439.5"
          cy="297.79999"
          r="12.6"
          id="circle26"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwa-icon-st4"
          cx="439.5"
          cy="297.79999"
          r="15.9"
          id="circle28"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwa-icon-st4"
          cx="439.5"
          cy="297.79999"
          r="10.8"
          id="circle30"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwa-icon-st4"
          cx="439.5"
          cy="297.79999"
          r="8.8999996"
          id="circle32"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwa-icon-st4"
          cx="439.5"
          cy="297.79999"
          r="7.0999999"
          id="circle34"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwa-icon-st5"
          x1="438.70001"
          y1="276.20001"
          x2="438.70001"
          y2="319.5"
          id="line36"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwa-icon-st5"
          x1="440.29999"
          y1="276.20001"
          x2="440.29999"
          y2="319.5"
          id="line38"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwa-icon-st5"
          x1="417.79999"
          y1="298.5"
          x2="461.10001"
          y2="298.5"
          id="line40"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwa-icon-st5"
          x1="417.79999"
          y1="297"
          x2="461.10001"
          y2="297"
          id="line42"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwa-icon-st4"
          x1="424"
          y1="282.70001"
          x2="454.60001"
          y2="313.29999"
          id="line44"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwa-icon-st4"
          x1="424"
          y1="313"
          x2="454.60001"
          y2="282.39999"
          id="line46"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwa-icon-st6"
          x1="379.70001"
          y1="277.5"
          x2="379.70001"
          y2="318.5"
          id="line48"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwa-icon-st4"
          d="M 384.7,279.3"
          id="path50"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwa-icon-st4"
          d="M 384.5,316.7"
          id="path52"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwa-icon-st2"
          d="m 390.4,295.5 -1,-2 -3.1,-1.7 H 385 v -0.3 h -6.2 v 0.6 h -3 c -0.4,0 -0.7,0.2 -0.7,0.4 v 10.9 c 0,0.2 0.3,0.4 0.7,0.4 h 3 v 0.7 h 6.2 v -0.3 h 1.3 l 3.1,-1.7 1,-2 z"
          id="path54"
          style="fill: #1e1e1c"
        />
        <line
          class="pc-vwa-icon-st3"
          x1="391.89999"
          y1="274.70001"
          x2="391.89999"
          y2="321.29999"
          id="line56"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwa-icon-st7"
          x1="376.60001"
          y1="285.70001"
          x2="376.60001"
          y2="310.20001"
          id="line58"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwa-icon-st7"
          x1="377.79999"
          y1="288.39999"
          x2="377.79999"
          y2="297"
          id="line60"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwa-icon-st7"
          x1="378.70001"
          y1="290.20001"
          x2="378.70001"
          y2="297.10001"
          id="line62"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwa-icon-st7"
          x1="378.70001"
          y1="306.89999"
          x2="378.70001"
          y2="319.60001"
          id="line64"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwa-icon-st7"
          x1="377.79999"
          y1="308.70001"
          x2="377.79999"
          y2="319.60001"
          id="line66"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwa-icon-st7"
          x1="377.79999"
          y1="298.89999"
          x2="377.79999"
          y2="307.5"
          id="line68"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwa-icon-st7"
          x1="378.70001"
          y1="298.89999"
          x2="378.70001"
          y2="305.70001"
          id="line70"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwa-icon-st7"
          x1="378.70001"
          y1="297.60001"
          x2="378.70001"
          y2="298.29999"
          id="line72"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwa-icon-st7"
          x1="377.79999"
          y1="297.60001"
          x2="377.79999"
          y2="298.39999"
          id="line74"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwa-icon-st7"
          x1="378.70001"
          y1="289"
          x2="378.70001"
          y2="276.29999"
          id="line76"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwa-icon-st7"
          x1="377.79999"
          y1="287.20001"
          x2="377.79999"
          y2="276.29999"
          id="line78"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwa-icon-st2"
          d="m 390.4,295.5 -0.6,-2 -2.1,-1.7 h -0.9 v -0.3 h -4.1 v 0.6 h -2 c -0.2,0 -0.4,0.2 -0.4,0.4 v 0 10.9 c 0,0.2 0.2,0.4 0.4,0.4 v 0 h 2 v 0.7 h 4.1 v -0.3 h 0.9 l 2.1,-1.7 0.6,-2 z"
          id="path80"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vwa-icon-st1"
          d="m 386.8,291.4 1.1,-11.3 c 0,-0.5 -0.4,-1 -0.9,-1 0,0 0,0 -0.1,0 h -4.5 c -0.5,0 -0.9,0.4 -0.9,0.9 v 0.1 l 1.5,11.2 z"
          id="path82"
          style="fill: #585857"
        />
        <path
          class="pc-vwa-icon-st1"
          d="m 386.8,304.5 1.1,11.3 c 0,0.3 -0.1,0.5 -0.2,0.7 -0.2,0.2 -0.4,0.3 -0.7,0.3 h -4.5 c -0.3,0 -0.5,-0.1 -0.7,-0.3 -0.2,-0.2 -0.3,-0.5 -0.2,-0.7 l 1.5,-11.2 z"
          id="path84"
          style="fill: #585857"
        />
        <path
          class="pc-vwa-icon-st8"
          d="m 391.5,328.9 c 0.1,0 0.3,-0.1 0.3,-0.3 v -7.3 c 0,-2 -1.6,-3.5 -3.5,-3.5 v 0 h -2 c -1.1,0 -2.3,0.2 -3.4,0.6 -0.6,0.2 -1.3,0.3 -2,0.3 h -1.1 v -0.2 h 1.1 c 0.7,0 1.3,-0.1 1.9,-0.3 1.1,-0.4 2.3,-0.6 3.5,-0.6 h 2.1 c 2,0 3.7,1.7 3.7,3.7 v 0 7.4 c 0,0.3 -0.2,0.5 -0.5,0.5 h -1.3 V 329 h 1.2 z"
          id="path86"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwa-icon-st8"
          d="m 391.5,267 h -1.3 v -0.2 h 1.3 c 0.3,0 0.5,0.2 0.5,0.5 v 7.4 c 0,2 -1.7,3.7 -3.7,3.7 v 0 h -2.1 c -1.2,0 -2.3,-0.2 -3.5,-0.6 -0.6,-0.2 -1.3,-0.3 -1.9,-0.3 h -1.1 v -0.3 h 1.1 c 0.7,0 1.4,0.1 2,0.4 1.1,0.4 2.2,0.6 3.4,0.6 h 2.1 c 2,0 3.5,-1.6 3.5,-3.5 v -7.3 c -0.1,-0.2 -0.2,-0.3 -0.3,-0.4 z"
          id="path88"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwa-icon-st8"
          d="m 379.7,298.3 -2.8,0.1 c -0.1,0 -0.2,0.2 -0.2,0.3 0,0.1 0.1,0.2 0.2,0.2 l 3.1,-0.1 c 0.1,0 0.3,-0.1 0.3,-0.3 v -1.2 c 0,-0.1 -0.1,-0.3 -0.3,-0.3 l -3.1,-0.1 c -0.1,0 -0.3,0.1 -0.3,0.3 0,0.2 0.1,0.3 0.2,0.3 l 2.8,0.1 z"
          id="path90"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwa-icon-st8"
          d="m 380.2,303.7 c 0,-0.1 0.1,-0.2 0.1,-0.3 V 302 c 0,-0.1 -0.1,-0.3 -0.3,-0.3 -0.2,0 -0.3,0.1 -0.3,0.3 v 1.4 0.1 l -3,6.4 c 0,0.1 -0.1,0.2 -0.1,0.3 v 9.1 c 0,0.2 0.1,0.4 0.2,0.5 0.1,0.1 0.4,0.2 0.5,0.2 h 3.3 v -0.5 h -3.3 c -0.1,0 -0.3,-0.1 -0.3,-0.3 v -9.1 -0.1 z"
          id="path92"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwa-icon-st8"
          d="m 380.2,292.2 c 0,0.1 0.1,0.2 0.1,0.3 v 1.4 c 0,0.1 -0.1,0.3 -0.3,0.3 -0.2,0 -0.3,-0.1 -0.3,-0.3 v 0 -1.4 -0.1 l -3,-6.4 c 0,-0.1 -0.1,-0.2 -0.1,-0.3 v -9.1 c 0,-0.2 0.1,-0.4 0.2,-0.5 0.1,-0.1 0.3,-0.2 0.5,-0.2 h 3.3 v 0.5 h -3.3 c -0.1,0 -0.1,0 -0.2,0.1 0,0 -0.1,0.1 -0.1,0.2 v 9.1 0.1 z"
          id="path94"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <rect
          x="407.89999"
          y="266.60001"
          class="pc-vwa-icon-st5"
          width="62.799999"
          height="62.799999"
          id="rect96"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwa-icon-st4"
          d="M 387.7,298"
          id="path98"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <g id="g108">
          <g id="g106">
            <line
              class="pc-vwa-icon-st9"
              x1="397.60001"
              y1="312.5"
              x2="404.20001"
              y2="312.5"
              id="line100"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g104">
              <polygon
                class="pc-vwa-icon-st10"
                points="395.3,312.5 398.1,310.9 398.1,314.1 "
                id="polygon102"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
        <g id="g118">
          <g id="g116">
            <line
              class="pc-vwa-icon-st9"
              x1="397.60001"
              y1="283.5"
              x2="404.20001"
              y2="283.5"
              id="line110"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g114">
              <polygon
                class="pc-vwa-icon-st10"
                points="395.3,283.5 398.1,281.9 398.1,285.1 "
                id="polygon112"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vwr" viewBox="0 0 92.100011 63.199991">
      <style type="text/css" id="style2">
        .pc-vwr-icon-st0 {
          fill: #ffffff;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vwr-icon-st1 {
          fill: #585857;
        }

        .pc-vwr-icon-st2 {
          fill: #1e1e1c;
        }

        .pc-vwr-icon-st3 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vwr-icon-st4 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vwr-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vwr-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.4;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vwr-icon-st7 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vwr-icon-st8 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.4;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vwr-icon-st9 {
          fill: none;
          stroke: #1d1d1b;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vwr-icon-st10 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vwr-icon-st11 {
          fill: #223a8f;
        }
      </style>
      <g id="g120" transform="translate(-375.1,-266.20001)">
        <circle
          class="pc-vwr-icon-st0"
          cx="435.5"
          cy="297.79999"
          r="19.700001"
          id="circle4"
          style="
            fill: #ffffff;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwr-icon-st1"
          d="m 437.5,297.8 1.1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.4 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.4 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
          id="path6"
          style="fill: #585857"
        />
        <path
          class="pc-vwr-icon-st1"
          d="m 436.3,299.8 16.6,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.5 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.4,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.8,6.7 z"
          id="path8"
          style="fill: #585857"
        />
        <path
          class="pc-vwr-icon-st1"
          d="m 433.7,299 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.7 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.5 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 L 436.6,297 Z"
          id="path10"
          style="fill: #585857"
        />
        <path
          class="pc-vwr-icon-st1"
          d="M 434.1,296.8 423.2,310 c -0.2,0.3 -0.4,0.6 -0.5,1 0,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.1 0.6,0.1 0.8,0 0.4,-0.2 0.6,-0.5 0.8,-0.8 l 8.8,-14.6 z"
          id="path12"
          style="fill: #585857"
        />
        <path
          class="pc-vwr-icon-st1"
          d="M 435.9,296.1 420,289.8 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.7,3.8 z"
          id="path14"
          style="fill: #585857"
        />
        <circle
          class="pc-vwr-icon-st2"
          cx="435.5"
          cy="297.79999"
          r="6.4000001"
          id="circle16"
          style="fill: #1e1e1c"
        />
        <circle
          class="pc-vwr-icon-st3"
          cx="435.5"
          cy="297.79999"
          r="21.6"
          id="circle18"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwr-icon-st4"
          cx="435.5"
          cy="297.79999"
          r="17.700001"
          id="circle20"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwr-icon-st4"
          cx="435.5"
          cy="297.79999"
          r="15.9"
          id="circle22"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwr-icon-st4"
          cx="435.5"
          cy="297.79999"
          r="14.3"
          id="circle24"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwr-icon-st4"
          cx="435.5"
          cy="297.79999"
          r="12.6"
          id="circle26"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwr-icon-st4"
          cx="435.5"
          cy="297.79999"
          r="15.9"
          id="circle28"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwr-icon-st4"
          cx="435.5"
          cy="297.79999"
          r="10.8"
          id="circle30"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwr-icon-st4"
          cx="435.5"
          cy="297.79999"
          r="8.8999996"
          id="circle32"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwr-icon-st4"
          cx="435.5"
          cy="297.79999"
          r="7.0999999"
          id="circle34"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwr-icon-st5"
          x1="434.70001"
          y1="276.20001"
          x2="434.70001"
          y2="319.5"
          id="line36"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwr-icon-st5"
          x1="436.29999"
          y1="276.20001"
          x2="436.29999"
          y2="319.5"
          id="line38"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwr-icon-st5"
          x1="413.79999"
          y1="298.5"
          x2="457.10001"
          y2="298.5"
          id="line40"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwr-icon-st5"
          x1="413.79999"
          y1="297"
          x2="457.10001"
          y2="297"
          id="line42"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwr-icon-st4"
          x1="420"
          y1="282.70001"
          x2="450.60001"
          y2="313.29999"
          id="line44"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwr-icon-st4"
          x1="420"
          y1="313"
          x2="450.60001"
          y2="282.39999"
          id="line46"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwr-icon-st6"
          x1="379.70001"
          y1="277.5"
          x2="379.70001"
          y2="318.5"
          id="line48"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwr-icon-st4"
          d="M 384.7,279.3"
          id="path50"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwr-icon-st4"
          d="M 384.5,316.7"
          id="path52"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwr-icon-st2"
          d="m 390.4,295.5 -1,-2 -3.1,-1.7 H 385 v -0.3 h -6.2 v 0.6 h -3 c -0.4,0 -0.7,0.2 -0.7,0.4 v 10.9 c 0,0.2 0.3,0.4 0.7,0.4 h 3 v 0.7 h 6.2 v -0.3 h 1.3 l 3.1,-1.7 1,-2 z"
          id="path54"
          style="fill: #1e1e1c"
        />
        <line
          class="pc-vwr-icon-st3"
          x1="391.89999"
          y1="274.70001"
          x2="391.89999"
          y2="321.29999"
          id="line56"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwr-icon-st7"
          x1="376.60001"
          y1="285.70001"
          x2="376.60001"
          y2="310.20001"
          id="line58"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwr-icon-st7"
          x1="377.79999"
          y1="288.39999"
          x2="377.79999"
          y2="297"
          id="line60"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwr-icon-st7"
          x1="378.70001"
          y1="290.20001"
          x2="378.70001"
          y2="297.10001"
          id="line62"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwr-icon-st7"
          x1="378.70001"
          y1="306.89999"
          x2="378.70001"
          y2="319.60001"
          id="line64"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwr-icon-st7"
          x1="377.79999"
          y1="308.70001"
          x2="377.79999"
          y2="319.60001"
          id="line66"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwr-icon-st7"
          x1="377.79999"
          y1="298.89999"
          x2="377.79999"
          y2="307.5"
          id="line68"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwr-icon-st7"
          x1="378.70001"
          y1="298.89999"
          x2="378.70001"
          y2="305.70001"
          id="line70"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwr-icon-st7"
          x1="378.70001"
          y1="297.60001"
          x2="378.70001"
          y2="298.29999"
          id="line72"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwr-icon-st7"
          x1="377.79999"
          y1="297.60001"
          x2="377.79999"
          y2="298.39999"
          id="line74"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwr-icon-st7"
          x1="378.70001"
          y1="289"
          x2="378.70001"
          y2="276.29999"
          id="line76"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwr-icon-st7"
          x1="377.79999"
          y1="287.20001"
          x2="377.79999"
          y2="276.29999"
          id="line78"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwr-icon-st2"
          d="m 390.4,295.5 -0.6,-2 -2.1,-1.7 h -0.9 v -0.3 h -4.1 v 0.6 h -2 c -0.2,0 -0.4,0.2 -0.4,0.4 v 0 10.9 c 0,0.2 0.2,0.4 0.4,0.4 v 0 h 2 v 0.7 h 4.1 v -0.3 h 0.9 l 2.1,-1.7 0.6,-2 z"
          id="path80"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vwr-icon-st1"
          d="m 386.8,291.4 1.1,-11.3 c 0,-0.5 -0.4,-1 -0.9,-1 0,0 0,0 -0.1,0 h -4.5 c -0.5,0 -0.9,0.4 -0.9,0.9 v 0.1 l 1.5,11.2 z"
          id="path82"
          style="fill: #585857"
        />
        <path
          class="pc-vwr-icon-st1"
          d="m 386.8,304.5 1.1,11.3 c 0,0.3 -0.1,0.5 -0.2,0.7 -0.2,0.2 -0.4,0.3 -0.7,0.3 h -4.5 c -0.3,0 -0.5,-0.1 -0.7,-0.3 -0.2,-0.2 -0.3,-0.5 -0.2,-0.7 l 1.5,-11.2 z"
          id="path84"
          style="fill: #585857"
        />
        <path
          class="pc-vwr-icon-st8"
          d="m 391.5,328.9 c 0.1,0 0.3,-0.1 0.3,-0.3 v -7.3 c 0,-2 -1.6,-3.5 -3.5,-3.5 v 0 h -2 c -1.1,0 -2.3,0.2 -3.4,0.6 -0.6,0.2 -1.3,0.3 -2,0.3 h -1.1 v -0.2 h 1.1 c 0.7,0 1.3,-0.1 1.9,-0.3 1.1,-0.4 2.3,-0.6 3.5,-0.6 h 2.1 c 2,0 3.7,1.7 3.7,3.7 v 0 7.4 c 0,0.3 -0.2,0.5 -0.5,0.5 h -1.3 V 329 h 1.2 z"
          id="path86"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwr-icon-st8"
          d="m 391.5,267 h -1.3 v -0.2 h 1.3 c 0.3,0 0.5,0.2 0.5,0.5 v 7.4 c 0,2 -1.7,3.7 -3.7,3.7 v 0 h -2.1 c -1.2,0 -2.3,-0.2 -3.5,-0.6 -0.6,-0.2 -1.3,-0.3 -1.9,-0.3 h -1.1 v -0.3 h 1.1 c 0.7,0 1.4,0.1 2,0.4 1.1,0.4 2.2,0.6 3.4,0.6 h 2.1 c 2,0 3.5,-1.6 3.5,-3.5 v -7.3 c -0.1,-0.2 -0.2,-0.3 -0.3,-0.4 z"
          id="path88"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwr-icon-st8"
          d="m 379.7,298.3 -2.8,0.1 c -0.1,0 -0.2,0.2 -0.2,0.3 0,0.1 0.1,0.2 0.2,0.2 l 3.1,-0.1 c 0.1,0 0.3,-0.1 0.3,-0.3 v -1.2 c 0,-0.1 -0.1,-0.3 -0.3,-0.3 l -3.1,-0.1 c -0.1,0 -0.3,0.1 -0.3,0.3 0,0.2 0.1,0.3 0.2,0.3 l 2.8,0.1 z"
          id="path90"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwr-icon-st8"
          d="m 380.2,303.7 c 0,-0.1 0.1,-0.2 0.1,-0.3 V 302 c 0,-0.1 -0.1,-0.3 -0.3,-0.3 -0.2,0 -0.3,0.1 -0.3,0.3 v 1.4 0.1 l -3,6.4 c 0,0.1 -0.1,0.2 -0.1,0.3 v 9.1 c 0,0.2 0.1,0.4 0.2,0.5 0.1,0.1 0.4,0.2 0.5,0.2 h 3.3 v -0.5 h -3.3 c -0.1,0 -0.3,-0.1 -0.3,-0.3 v -9.1 -0.1 z"
          id="path92"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwr-icon-st8"
          d="m 380.2,292.2 c 0,0.1 0.1,0.2 0.1,0.3 v 1.4 c 0,0.1 -0.1,0.3 -0.3,0.3 -0.2,0 -0.3,-0.1 -0.3,-0.3 v 0 -1.4 -0.1 l -3,-6.4 c 0,-0.1 -0.1,-0.2 -0.1,-0.3 v -9.1 c 0,-0.2 0.1,-0.4 0.2,-0.5 0.1,-0.1 0.3,-0.2 0.5,-0.2 h 3.3 v 0.5 h -3.3 c -0.1,0 -0.1,0 -0.2,0.1 0,0 -0.1,0.1 -0.1,0.2 v 9.1 0.1 z"
          id="path94"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.40000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <circle
          class="pc-vwr-icon-st9"
          cx="435.70001"
          cy="297.70001"
          r="31.200001"
          id="circle96"
          style="
            fill: none;
            stroke: #1d1d1b;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
        />
        <g id="g118">
          <g id="g106">
            <g id="g104">
              <line
                class="pc-vwr-icon-st10"
                x1="396.89999"
                y1="312.79999"
                x2="403.5"
                y2="312.79999"
                id="line98"
                style="
                  fill: none;
                  stroke: #223a8f;
                  stroke-width: 0.60000002;
                  stroke-miterlimit: 10;
                "
              />
              <g id="g102">
                <polygon
                  class="pc-vwr-icon-st11"
                  points="394.6,312.8 397.4,311.1 397.4,314.4 "
                  id="polygon100"
                  style="fill: #223a8f"
                />
              </g>
            </g>
          </g>
          <g id="g116">
            <g id="g114">
              <line
                class="pc-vwr-icon-st10"
                x1="396.89999"
                y1="283.79999"
                x2="403.5"
                y2="283.79999"
                id="line108"
                style="
                  fill: none;
                  stroke: #223a8f;
                  stroke-width: 0.60000002;
                  stroke-miterlimit: 10;
                "
              />
              <g id="g112">
                <polygon
                  class="pc-vwr-icon-st11"
                  points="394.6,283.8 397.4,282.1 397.4,285.4 "
                  id="polygon110"
                  style="fill: #223a8f"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vay" viewBox="0 0 54.339228 37.599999">
      <style type="text/css" id="style2">
        .pc-vay-icon-st0 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 5e-2;
          stroke-miterlimit: 10;
          stroke-dasharray: 9.81, 9.81, 9.81, 9.81, 9.81, 9.81;
        }

        .pc-vay-icon-st1 {
          fill: #1e1e1c;
        }

        .pc-vay-icon-st2 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vay-icon-st3 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vay-icon-st4 {
          fill: #223a8f;
        }
      </style>
      <g id="g48" transform="translate(-393.4,-279.1)">
        <circle
          class="pc-vay-icon-st0"
          cx="429"
          cy="297.89999"
          r="18.700001"
          id="circle4"
        />
        <path
          class="pc-vay-icon-st1"
          d="m 431.1,297.9 1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.4 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.4 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
          id="path6"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vay-icon-st1"
          d="m 429.9,299.8 16.6,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.5 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.3,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.8,6.6 z"
          id="path8"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vay-icon-st1"
          d="m 427.3,299 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.7 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.5 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 L 430.2,297 Z"
          id="path10"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vay-icon-st1"
          d="M 427.6,296.8 416.8,310 c -0.2,0.3 -0.4,0.6 -0.5,1 -0.1,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.2 0.6,0.2 0.8,0 0.4,-0.2 0.6,-0.5 0.8,-0.8 l 8.8,-14.7 z"
          id="path12"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vay-icon-st1"
          d="m 429.4,296.2 -15.9,-6.3 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.7,3.8 z"
          id="path14"
          style="fill: #1e1e1c"
        />
        <circle
          class="pc-vay-icon-st1"
          cx="429"
          cy="297.89999"
          r="6.4000001"
          id="circle16"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vay-icon-st1"
          d="m 403.3,295.4 -0.6,-2 -2,-1.7 h -0.9 v -0.3 h -4.1 v 0.6 h -1.9 c -0.2,0 -0.4,0.2 -0.4,0.4 v 0 10.9 c 0,0.2 0.2,0.4 0.4,0.4 v 0 h 1.9 v 0.7 h 4.1 v -0.3 h 0.9 l 2,-1.7 0.6,-2 z"
          id="path18"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vay-icon-st1"
          d="m 399.8,291.4 1,-11.3 c 0,-0.3 -0.1,-0.5 -0.2,-0.7 -0.2,-0.2 -0.4,-0.3 -0.7,-0.3 h -4.4 c -0.3,0 -0.5,0.1 -0.7,0.3 -0.2,0.2 -0.3,0.5 -0.2,0.7 l 1.5,11.3 z"
          id="path20"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vay-icon-st1"
          d="m 399.8,304.4 1,11.3 c 0,0.3 -0.1,0.5 -0.2,0.7 -0.2,0.2 -0.4,0.3 -0.7,0.3 h -4.4 c -0.3,0 -0.5,-0.1 -0.7,-0.3 -0.2,-0.2 -0.3,-0.5 -0.2,-0.7 l 1.5,-11.2 z"
          id="path22"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vay-icon-st2"
          d="M 393.4,297.7"
          id="path24"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vay-icon-st2"
          d="M 403.1,297.7"
          id="path26"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <g id="g36">
          <g id="g34">
            <line
              class="pc-vay-icon-st3"
              x1="409.79999"
              y1="312.79999"
              x2="403.29999"
              y2="312.79999"
              id="line28"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g32">
              <polygon
                class="pc-vay-icon-st4"
                points="412.2,312.8 409.4,314.5 409.4,311.2 "
                id="polygon30"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
        <g id="g46">
          <g id="g44">
            <line
              class="pc-vay-icon-st3"
              x1="409.79999"
              y1="283.79999"
              x2="403.29999"
              y2="283.79999"
              id="line38"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g42">
              <polygon
                class="pc-vay-icon-st4"
                points="412.2,283.8 409.4,285.5 409.4,282.2 "
                id="polygon40"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vvy" viewBox="0 0 54.339228 37.599999">
      <style type="text/css" id="style2">
        .pc-vvy-icon-st0 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 5e-2;
          stroke-miterlimit: 10;
          stroke-dasharray: 9.81, 9.81, 9.81, 9.81, 9.81, 9.81;
        }

        .pc-vvy-icon-st1 {
          fill: #1e1e1c;
        }

        .pc-vvy-icon-st2 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vvy-icon-st3 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vvy-icon-st4 {
          fill: #223a8f;
        }
      </style>
      <g id="g48" transform="translate(-393.4,-279.1)">
        <circle
          class="pc-vvy-icon-st0"
          cx="429"
          cy="297.89999"
          r="18.700001"
          id="circle4"
        />
        <path
          class="pc-vvy-icon-st1"
          d="m 431.1,297.9 1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.4 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.4 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
          id="path6"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vvy-icon-st1"
          d="m 429.9,299.8 16.6,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.5 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.3,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.8,6.6 z"
          id="path8"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vvy-icon-st1"
          d="m 427.3,299 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.7 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.5 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 L 430.2,297 Z"
          id="path10"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vvy-icon-st1"
          d="M 427.6,296.8 416.8,310 c -0.2,0.3 -0.4,0.6 -0.5,1 -0.1,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.2 0.6,0.2 0.8,0 0.4,-0.2 0.6,-0.5 0.8,-0.8 l 8.8,-14.7 z"
          id="path12"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vvy-icon-st1"
          d="m 429.4,296.2 -15.9,-6.3 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.7,3.8 z"
          id="path14"
          style="fill: #1e1e1c"
        />
        <circle
          class="pc-vvy-icon-st1"
          cx="429"
          cy="297.89999"
          r="6.4000001"
          id="circle16"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vvy-icon-st1"
          d="m 403.3,295.4 -0.6,-2 -2,-1.7 h -0.9 v -0.3 h -4.1 v 0.6 h -1.9 c -0.2,0 -0.4,0.2 -0.4,0.4 v 0 10.9 c 0,0.2 0.2,0.4 0.4,0.4 v 0 h 1.9 v 0.7 h 4.1 v -0.3 h 0.9 l 2,-1.7 0.6,-2 z"
          id="path18"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vvy-icon-st1"
          d="m 399.8,291.4 1,-11.3 c 0,-0.3 -0.1,-0.5 -0.2,-0.7 -0.2,-0.2 -0.4,-0.3 -0.7,-0.3 h -4.4 c -0.3,0 -0.5,0.1 -0.7,0.3 -0.2,0.2 -0.3,0.5 -0.2,0.7 l 1.5,11.3 z"
          id="path20"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vvy-icon-st1"
          d="m 399.8,304.4 1,11.3 c 0,0.3 -0.1,0.5 -0.2,0.7 -0.2,0.2 -0.4,0.3 -0.7,0.3 h -4.4 c -0.3,0 -0.5,-0.1 -0.7,-0.3 -0.2,-0.2 -0.3,-0.5 -0.2,-0.7 l 1.5,-11.2 z"
          id="path22"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vvy-icon-st2"
          d="M 393.4,297.7"
          id="path24"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vvy-icon-st2"
          d="M 403.1,297.7"
          id="path26"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <g id="g36">
          <g id="g34">
            <line
              class="pc-vvy-icon-st3"
              x1="405"
              y1="312.29999"
              x2="411.60001"
              y2="312.29999"
              id="line28"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g32">
              <polygon
                class="pc-vvy-icon-st4"
                points="402.7,312.3 405.5,310.6 405.5,313.9 "
                id="polygon30"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
        <g id="g46">
          <g id="g44">
            <line
              class="pc-vvy-icon-st3"
              x1="405"
              y1="283.29999"
              x2="411.60001"
              y2="283.29999"
              id="line38"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g42">
              <polygon
                class="pc-vvy-icon-st4"
                points="402.7,283.3 405.5,281.6 405.5,284.9 "
                id="polygon40"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vwg" viewBox="0 0 75.70001 45.704308">
      <style type="text/css" id="style2">
        .pc-vwg-icon-st0 {
          fill: #1b181c;
          stroke: #1b181c;
          stroke-width: 1e-2;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vwg-icon-st1 {
          fill: none;
          stroke: #1b181c;
          stroke-width: 0.6;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vwg-icon-st2 {
          fill: #1b181c;
        }

        .pc-vwg-icon-st3 {
          fill: #5a595b;
        }

        .pc-vwg-icon-st4 {
          fill: none;
          stroke: #1b181c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vwg-icon-st5 {
          fill: none;
          stroke: #1b181c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vwg-icon-st6 {
          fill: none;
          stroke: #1b181c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vwg-icon-st7 {
          fill: none;
          stroke: #1b181c;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vwg-icon-st8 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vwg-icon-st9 {
          fill: #223a8f;
        }
      </style>
      <g id="Ebene_2_1_" transform="translate(-382.7,-274.99572)">
        <path
          class="pc-vwg-icon-st0"
          d="m 386.6,320.2 c 0,0.2 -0.1,0.4 -0.3,0.5 -0.2,0 -0.4,-0.1 -0.5,-0.3 0,0 0,-0.1 0,-0.1 v -2 c 0,0 0,-0.1 0,-0.2 l -1.8,-3.9 c -0.1,-0.1 -0.1,-0.3 -0.1,-0.5 v -15.2 c 0,-0.2 0.2,-0.4 0.5,-0.3 0.2,0 0.3,0.2 0.3,0.3 v 15.1 c 0,0.1 0,0.1 0,0.2 l 1.8,3.9 c 0.1,0.1 0.1,0.3 0.1,0.5 z"
          id="path4"
        />
        <path
          class="pc-vwg-icon-st0"
          d="m 386.6,275.6 c 0,-0.2 -0.2,-0.4 -0.5,-0.3 -0.2,0 -0.3,0.2 -0.3,0.3 v 2 c 0,0.1 0,0.1 0,0.2 l -1.8,3.9 c -0.1,0.1 -0.1,0.3 -0.1,0.5 v 15.1 c 0,0.2 0.1,0.4 0.3,0.5 0.2,0 0.4,-0.1 0.5,-0.3 0,0 0,-0.1 0,-0.1 v -15.1 c 0,0 0,-0.1 0,-0.2 l 1.8,-3.9 c 0.1,-0.1 0.1,-0.3 0.1,-0.5 z"
          id="path6"
        />
        <line
          class="pc-vwg-icon-st1"
          x1="399.79999"
          y1="320.29999"
          x2="399.79999"
          y2="275.39999"
          id="line8"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwg-icon-st1"
          x1="391.79999"
          y1="320.29999"
          x2="391.79999"
          y2="275.39999"
          id="line10"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwg-icon-st1"
          x1="386.39999"
          y1="320.29999"
          x2="386.39999"
          y2="275.29999"
          id="line12"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwg-icon-st2"
          d="m 397.5,300.4 -0.9,2 -3,1.7 h -1.3 v 0.3 h -6.1 v -0.7 h -2.9 c -0.4,0 -0.6,-0.2 -0.6,-0.4 v -10.9 c 0,-0.2 0.3,-0.4 0.6,-0.4 h 2.9 v -0.7 h 6.1 v 0.4 h 1.3 l 3,1.7 0.9,2 z"
          id="path14"
          style="fill: #1b181c"
        />
        <path
          class="pc-vwg-icon-st3"
          d="m 393.9,304.4 1.1,11.3 c 0,0.5 -0.4,1 -0.9,1 0,0 0,0 -0.1,0 h -4.5 c -0.3,0 -0.5,-0.1 -0.7,-0.3 -0.2,-0.2 -0.3,-0.5 -0.2,-0.7 l 1.5,-11.2 h 3.8 z"
          id="path16"
          style="fill: #5a595b"
        />
        <path
          class="pc-vwg-icon-st3"
          d="M 393.9,291.3 395,280 c 0,-0.3 -0.1,-0.5 -0.2,-0.7 -0.2,-0.2 -0.4,-0.3 -0.7,-0.3 h -4.5 c -0.3,0 -0.5,0.1 -0.7,0.3 -0.2,0.2 -0.3,0.5 -0.2,0.7 l 1.5,11.2 z"
          id="path18"
          style="fill: #5a595b"
        />
        <line
          class="pc-vwg-icon-st4"
          x1="384.89999"
          y1="315.20001"
          x2="384.89999"
          y2="280.5"
          id="line20"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwg-icon-st4"
          x1="385.60001"
          y1="316.70001"
          x2="385.60001"
          y2="278.89999"
          id="line22"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <rect
          x="391.5"
          y="303.10001"
          class="pc-vwg-icon-st2"
          width="2.4000001"
          height="1.4"
          id="rect24"
          style="fill: #1b181c"
        />
        <rect
          x="391.5"
          y="291.10001"
          class="pc-vwg-icon-st2"
          width="2.4000001"
          height="1.4"
          id="rect26"
          style="fill: #1b181c"
        />
        <line
          class="pc-vwg-icon-st1"
          x1="399.79999"
          y1="320.39999"
          x2="386"
          y2="320.39999"
          id="line28"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwg-icon-st1"
          x1="399.79999"
          y1="275.29999"
          x2="386"
          y2="275.29999"
          id="line30"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwg-icon-st5"
          d="M 392,278.9"
          id="path32"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwg-icon-st5"
          d="M 391.8,316.8"
          id="path34"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwg-icon-st5"
          d="M 382.8,302.4"
          id="path36"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwg-icon-st5"
          d="M 400,302.4"
          id="path38"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwg-icon-st3"
          d="m 437.7,297.9 1.1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.4 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.4 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
          id="path40"
          style="fill: #5a595b"
        />
        <path
          class="pc-vwg-icon-st3"
          d="m 436.5,299.8 16.5,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.6 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.3,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.7,6.7 z"
          id="path42"
          style="fill: #5a595b"
        />
        <path
          class="pc-vwg-icon-st3"
          d="m 433.9,299 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.7 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.5 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 L 436.8,297 Z"
          id="path44"
          style="fill: #5a595b"
        />
        <path
          class="pc-vwg-icon-st3"
          d="M 434.3,296.8 423.4,310 c -0.2,0.3 -0.4,0.6 -0.5,1 0,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.2 0.6,0.2 0.8,0 0.4,-0.2 0.6,-0.4 0.8,-0.8 l 8.8,-14.7 z"
          id="path46"
          style="fill: #5a595b"
        />
        <path
          class="pc-vwg-icon-st3"
          d="m 436.1,296.2 -15.9,-6.3 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.6,3.8 z"
          id="path48"
          style="fill: #5a595b"
        />
        <circle
          class="pc-vwg-icon-st2"
          cx="435.70001"
          cy="297.89999"
          r="6.4000001"
          id="circle50"
          style="fill: #1b181c"
        />
        <circle
          class="pc-vwg-icon-st6"
          cx="435.70001"
          cy="297.89999"
          r="19.700001"
          id="circle52"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwg-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="17.700001"
          id="circle54"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwg-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="15.9"
          id="circle56"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwg-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="14.3"
          id="circle58"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwg-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="12.6"
          id="circle60"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwg-icon-st6"
          cx="435.70001"
          cy="297.89999"
          r="15.9"
          id="circle62"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwg-icon-st7"
          cx="435.70001"
          cy="297.89999"
          r="22.4"
          id="circle64"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwg-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="10.8"
          id="circle66"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwg-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="8.8999996"
          id="circle68"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwg-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="7.0999999"
          id="circle70"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwg-icon-st6"
          x1="434.89999"
          y1="275.29999"
          x2="434.89999"
          y2="320.29999"
          id="line72"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwg-icon-st6"
          x1="436.5"
          y1="275.29999"
          x2="436.5"
          y2="320.29999"
          id="line74"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwg-icon-st6"
          x1="413.20001"
          y1="298.70001"
          x2="458.10001"
          y2="298.70001"
          id="line76"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwg-icon-st6"
          x1="413.20001"
          y1="297"
          x2="458.10001"
          y2="297"
          id="line78"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwg-icon-st5"
          x1="419.60001"
          y1="282"
          x2="451.29999"
          y2="313.70001"
          id="line80"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwg-icon-st5"
          x1="419.60001"
          y1="313.70001"
          x2="451.29999"
          y2="282"
          id="line82"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwg-icon-st5"
          d="M 413.1,297.9"
          id="path84"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwg-icon-st5"
          d="M 458.3,297.9"
          id="path86"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwg-icon-st5"
          d="M 435.7,320.5"
          id="path88"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
      </g>
      <g id="g99" transform="translate(-382.7,-274.99572)">
        <g id="g97">
          <line
            class="pc-vwg-icon-st8"
            x1="411.79999"
            y1="312"
            x2="405.29999"
            y2="312"
            id="line91"
            style="
              fill: none;
              stroke: #223a8f;
              stroke-width: 0.60000002;
              stroke-miterlimit: 10;
            "
          />
          <g id="g95">
            <polygon
              class="pc-vwg-icon-st9"
              points="402.9,312 405.7,310.3 405.7,313.6 "
              id="polygon93"
              style="fill: #223a8f"
            />
          </g>
        </g>
      </g>
      <g id="g109" transform="translate(-382.7,-274.99572)">
        <g id="g107">
          <line
            class="pc-vwg-icon-st8"
            x1="411.79999"
            y1="283"
            x2="405.29999"
            y2="283"
            id="line101"
            style="
              fill: none;
              stroke: #223a8f;
              stroke-width: 0.60000002;
              stroke-miterlimit: 10;
            "
          />
          <g id="g105">
            <polygon
              class="pc-vwg-icon-st9"
              points="402.9,283 405.7,281.3 405.7,284.6 "
              id="polygon103"
              style="fill: #223a8f"
            />
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vwt" viewBox="0 0 75.70001 45.704308">
      <style type="text/css" id="style2">
        .pc-vwt-icon-st0 {
          fill: #1b181c;
          stroke: #1b181c;
          stroke-width: 1e-2;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vwt-icon-st1 {
          fill: none;
          stroke: #1b181c;
          stroke-width: 0.6;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vwt-icon-st2 {
          fill: #1b181c;
        }

        .pc-vwt-icon-st3 {
          fill: #5a595b;
        }

        .pc-vwt-icon-st4 {
          fill: none;
          stroke: #1b181c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vwt-icon-st5 {
          fill: none;
          stroke: #1b181c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vwt-icon-st6 {
          fill: none;
          stroke: #1b181c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vwt-icon-st7 {
          fill: none;
          stroke: #1b181c;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vwt-icon-st8 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vwt-icon-st9 {
          fill: #223a8f;
        }
      </style>
      <g id="Ebene_2_1_" transform="translate(-382.7,-274.99572)">
        <path
          class="pc-vwt-icon-st0"
          d="m 386.6,320.2 c 0,0.2 -0.1,0.4 -0.3,0.5 -0.2,0 -0.4,-0.1 -0.5,-0.3 0,0 0,-0.1 0,-0.1 v -2 c 0,0 0,-0.1 0,-0.2 l -1.8,-3.9 c -0.1,-0.1 -0.1,-0.3 -0.1,-0.5 v -15.2 c 0,-0.2 0.2,-0.4 0.5,-0.3 0.2,0 0.3,0.2 0.3,0.3 v 15.1 c 0,0.1 0,0.1 0,0.2 l 1.8,3.9 c 0.1,0.1 0.1,0.3 0.1,0.5 z"
          id="path4"
        />
        <path
          class="pc-vwt-icon-st0"
          d="m 386.6,275.6 c 0,-0.2 -0.2,-0.4 -0.5,-0.3 -0.2,0 -0.3,0.2 -0.3,0.3 v 2 c 0,0.1 0,0.1 0,0.2 l -1.8,3.9 c -0.1,0.1 -0.1,0.3 -0.1,0.5 v 15.1 c 0,0.2 0.1,0.4 0.3,0.5 0.2,0 0.4,-0.1 0.5,-0.3 0,0 0,-0.1 0,-0.1 v -15.1 c 0,0 0,-0.1 0,-0.2 l 1.8,-3.9 c 0.1,-0.1 0.1,-0.3 0.1,-0.5 z"
          id="path6"
        />
        <line
          class="pc-vwt-icon-st1"
          x1="399.79999"
          y1="320.29999"
          x2="399.79999"
          y2="275.39999"
          id="line8"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwt-icon-st1"
          x1="391.79999"
          y1="320.29999"
          x2="391.79999"
          y2="275.39999"
          id="line10"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwt-icon-st1"
          x1="386.39999"
          y1="320.29999"
          x2="386.39999"
          y2="275.29999"
          id="line12"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwt-icon-st2"
          d="m 397.5,300.4 -0.9,2 -3,1.7 h -1.3 v 0.3 h -6.1 v -0.7 h -2.9 c -0.4,0 -0.6,-0.2 -0.6,-0.4 v -10.9 c 0,-0.2 0.3,-0.4 0.6,-0.4 h 2.9 v -0.7 h 6.1 v 0.4 h 1.3 l 3,1.7 0.9,2 z"
          id="path14"
          style="fill: #1b181c"
        />
        <path
          class="pc-vwt-icon-st3"
          d="m 393.9,304.4 1.1,11.3 c 0,0.5 -0.4,1 -0.9,1 0,0 0,0 -0.1,0 h -4.5 c -0.3,0 -0.5,-0.1 -0.7,-0.3 -0.2,-0.2 -0.3,-0.5 -0.2,-0.7 l 1.5,-11.2 h 3.8 z"
          id="path16"
          style="fill: #5a595b"
        />
        <path
          class="pc-vwt-icon-st3"
          d="M 393.9,291.3 395,280 c 0,-0.3 -0.1,-0.5 -0.2,-0.7 -0.2,-0.2 -0.4,-0.3 -0.7,-0.3 h -4.5 c -0.3,0 -0.5,0.1 -0.7,0.3 -0.2,0.2 -0.3,0.5 -0.2,0.7 l 1.5,11.2 z"
          id="path18"
          style="fill: #5a595b"
        />
        <line
          class="pc-vwt-icon-st4"
          x1="384.89999"
          y1="315.20001"
          x2="384.89999"
          y2="280.5"
          id="line20"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwt-icon-st4"
          x1="385.60001"
          y1="316.70001"
          x2="385.60001"
          y2="278.89999"
          id="line22"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <rect
          x="391.5"
          y="303.10001"
          class="pc-vwt-icon-st2"
          width="2.4000001"
          height="1.4"
          id="rect24"
          style="fill: #1b181c"
        />
        <rect
          x="391.5"
          y="291.10001"
          class="pc-vwt-icon-st2"
          width="2.4000001"
          height="1.4"
          id="rect26"
          style="fill: #1b181c"
        />
        <line
          class="pc-vwt-icon-st1"
          x1="399.79999"
          y1="320.39999"
          x2="386"
          y2="320.39999"
          id="line28"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vwt-icon-st1"
          x1="399.79999"
          y1="275.29999"
          x2="386"
          y2="275.29999"
          id="line30"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vwt-icon-st5"
          d="M 392,278.9"
          id="path32"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwt-icon-st5"
          d="M 391.8,316.8"
          id="path34"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwt-icon-st5"
          d="M 382.8,302.4"
          id="path36"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwt-icon-st5"
          d="M 400,302.4"
          id="path38"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwt-icon-st3"
          d="m 437.7,297.9 1.1,-17.1 c 0,-0.4 0,-0.8 -0.2,-1.1 -0.1,-0.3 -0.4,-0.4 -0.7,-0.5 h -4.3 c -0.3,0 -0.6,0.2 -0.7,0.5 -0.2,0.4 -0.3,0.7 -0.2,1.1 l 1.5,17 z"
          id="path40"
          style="fill: #5a595b"
        />
        <path
          class="pc-vwt-icon-st3"
          d="m 436.5,299.8 16.5,-4.3 c 0.4,-0.1 0.7,-0.3 1,-0.6 0.2,-0.2 0.3,-0.5 0.2,-0.8 l -1.3,-4.1 c -0.1,-0.3 -0.4,-0.5 -0.7,-0.5 -0.4,-0.1 -0.8,0 -1.1,0.1 l -15.7,6.7 z"
          id="path42"
          style="fill: #5a595b"
        />
        <path
          class="pc-vwt-icon-st3"
          d="m 433.9,299 9.2,14.4 c 0.2,0.3 0.5,0.6 0.8,0.7 0.3,0.1 0.6,0.1 0.8,0 l 3.5,-2.5 c 0.2,-0.2 0.3,-0.5 0.3,-0.8 -0.1,-0.4 -0.2,-0.7 -0.5,-1 L 436.8,297 Z"
          id="path44"
          style="fill: #5a595b"
        />
        <path
          class="pc-vwt-icon-st3"
          d="M 434.3,296.8 423.4,310 c -0.2,0.3 -0.4,0.6 -0.5,1 0,0.3 0,0.6 0.3,0.8 l 3.5,2.5 c 0.3,0.2 0.6,0.2 0.8,0 0.4,-0.2 0.6,-0.4 0.8,-0.8 l 8.8,-14.7 z"
          id="path46"
          style="fill: #5a595b"
        />
        <path
          class="pc-vwt-icon-st3"
          d="m 436.1,296.2 -15.9,-6.3 c -0.4,-0.1 -0.7,-0.2 -1.1,-0.1 -0.3,0 -0.5,0.2 -0.6,0.5 l -1.3,4.1 c -0.1,0.3 0,0.6 0.3,0.8 0.3,0.3 0.6,0.5 1,0.5 l 16.6,3.8 z"
          id="path48"
          style="fill: #5a595b"
        />
        <circle
          class="pc-vwt-icon-st2"
          cx="435.70001"
          cy="297.89999"
          r="6.4000001"
          id="circle50"
          style="fill: #1b181c"
        />
        <circle
          class="pc-vwt-icon-st6"
          cx="435.70001"
          cy="297.89999"
          r="19.700001"
          id="circle52"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwt-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="17.700001"
          id="circle54"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwt-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="15.9"
          id="circle56"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwt-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="14.3"
          id="circle58"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwt-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="12.6"
          id="circle60"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwt-icon-st6"
          cx="435.70001"
          cy="297.89999"
          r="15.9"
          id="circle62"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwt-icon-st7"
          cx="435.70001"
          cy="297.89999"
          r="22.4"
          id="circle64"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.60000002;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwt-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="10.8"
          id="circle66"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwt-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="8.8999996"
          id="circle68"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vwt-icon-st5"
          cx="435.70001"
          cy="297.89999"
          r="7.0999999"
          id="circle70"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwt-icon-st6"
          x1="434.89999"
          y1="275.29999"
          x2="434.89999"
          y2="320.29999"
          id="line72"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwt-icon-st6"
          x1="436.5"
          y1="275.29999"
          x2="436.5"
          y2="320.29999"
          id="line74"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwt-icon-st6"
          x1="413.20001"
          y1="298.70001"
          x2="458.10001"
          y2="298.70001"
          id="line76"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwt-icon-st6"
          x1="413.20001"
          y1="297"
          x2="458.10001"
          y2="297"
          id="line78"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwt-icon-st5"
          x1="419.60001"
          y1="282"
          x2="451.29999"
          y2="313.70001"
          id="line80"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vwt-icon-st5"
          x1="419.60001"
          y1="313.70001"
          x2="451.29999"
          y2="282"
          id="line82"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwt-icon-st5"
          d="M 413.1,297.9"
          id="path84"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwt-icon-st5"
          d="M 458.3,297.9"
          id="path86"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vwt-icon-st5"
          d="M 435.7,320.5"
          id="path88"
          style="
            fill: none;
            stroke: #1b181c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
      </g>
      <g id="g99" transform="translate(-382.7,-274.99572)">
        <g id="g97">
          <line
            class="pc-vwt-icon-st8"
            x1="411.79999"
            y1="312"
            x2="405.29999"
            y2="312"
            id="line91"
            style="
              fill: none;
              stroke: #223a8f;
              stroke-width: 0.60000002;
              stroke-miterlimit: 10;
            "
          />
          <g id="g95">
            <polygon
              class="pc-vwt-icon-st9"
              points="402.9,312 405.7,310.3 405.7,313.6 "
              id="polygon93"
              style="fill: #223a8f"
            />
          </g>
        </g>
      </g>
      <g id="g109" transform="translate(-382.7,-274.99572)">
        <g id="g107">
          <line
            class="pc-vwt-icon-st8"
            x1="411.79999"
            y1="283"
            x2="405.29999"
            y2="283"
            id="line101"
            style="
              fill: none;
              stroke: #223a8f;
              stroke-width: 0.60000002;
              stroke-miterlimit: 10;
            "
          />
          <g id="g105">
            <polygon
              class="pc-vwt-icon-st9"
              points="402.9,283 405.7,281.3 405.7,284.6 "
              id="polygon103"
              style="fill: #223a8f"
            />
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vdy" viewBox="0 0 58.850012 43.200002">
      <style type="text/css" id="style2">
        .pc-vdy-icon-st0 {
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 9.9992;
        }

        .pc-vdy-icon-st1 {
          fill: #e3e3e2;
        }

        .pc-vdy-icon-st2 {
          fill: #b3b2b2;
        }

        .pc-vdy-icon-st3 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vdy-icon-st4 {
          fill: #1e1e1c;
        }

        .pc-vdy-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vdy-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vdy-icon-st7 {
          fill: #e3e3e2;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vdy-icon-st8 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 9.9992;
        }

        .pc-vdy-icon-st9 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vdy-icon-st10 {
          fill: #223a8f;
        }
      </style>
      <g id="g86" transform="translate(-390.7,-276.3)">
        <g id="g64">
          <circle
            class="pc-vdy-icon-st0"
            cx="428.70001"
            cy="298.10001"
            id="ellipse4"
            r="20.6"
            style="
              fill: #b3b2b2;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 9.99919987;
            "
          />
          <path
            class="pc-vdy-icon-st1"
            d="m 392.8,282.2 v 31.7 h 7.8 v -2.4 c 0,-1.5 1.2,-2.7 2.7,-2.7 v 0 h 0.4 v -21.4 h -0.4 c -1.5,0 -2.7,-1.2 -2.7,-2.7 v 0 -2.4 z"
            id="path6"
            style="fill: #e3e3e2"
          />
          <path
            class="pc-vdy-icon-st2"
            d="m 400.8,310.4 c -0.2,0.4 -0.3,0.8 -0.3,1.2 v 2.4 h -0.2 v 4.4 l -2.5,-1.4 -3.1,-1.9 -1.2,-0.6 -0.8,-0.4 v -10.3 c 4.1,-0.4 7.6,2.6 8.1,6.6 z"
            id="path8"
            style="fill: #b3b2b2"
          />
          <path
            class="pc-vdy-icon-st2"
            d="m 400.8,285.8 c -0.2,-0.4 -0.3,-0.8 -0.3,-1.2 v -2.4 h -0.2 v -4.4 l -2.4,1.4 -3.1,1.9 -1.2,0.6 -0.8,0.4 v 10.2 c 4,0.4 7.5,-2.5 8,-6.5 z"
            id="path10"
            style="fill: #b3b2b2"
          />
          <line
            class="pc-vdy-icon-st3"
            x1="392.79999"
            y1="282"
            x2="392.79999"
            y2="294.10001"
            id="line12"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vdy-icon-st3"
            x1="400.5"
            y1="277.39999"
            x2="400.5"
            y2="284.70001"
            id="line14"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vdy-icon-st3"
            x1="403.60001"
            y1="287.39999"
            x2="403.29999"
            y2="287.39999"
            id="line16"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vdy-icon-st3"
            d="m 400.6,284.7 c 0,1.5 1.2,2.7 2.7,2.7 v 0"
            id="path18"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vdy-icon-st3"
            d="m 392.8,292.5 c 4,0.2 7.5,-2.7 8,-6.7"
            id="path20"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vdy-icon-st3"
            x1="403.60001"
            y1="287.39999"
            x2="403.60001"
            y2="298.10001"
            id="line22"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vdy-icon-st3"
            x1="403.60001"
            y1="308.79999"
            x2="403.60001"
            y2="298.10001"
            id="line24"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vdy-icon-st3"
            d="m 400.8,310.4 c -0.5,-4 -4,-6.9 -8,-6.7"
            id="path26"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vdy-icon-st3"
            d="m 403.3,308.8 c -1.5,0 -2.7,1.2 -2.7,2.7 v 0"
            id="path28"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vdy-icon-st3"
            x1="403.60001"
            y1="308.79999"
            x2="403.29999"
            y2="308.79999"
            id="line30"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vdy-icon-st3"
            x1="392.79999"
            y1="314.20001"
            x2="392.79999"
            y2="302.10001"
            id="line32"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vdy-icon-st4"
            d="m 398.5,296.6 -0.5,-1.2 -1.6,-1 h -0.7 v -0.2 h -3.2 v 0.4 H 391 c -0.2,0 -0.3,0.1 -0.3,0.3 v 6.7 c 0,0.2 0.2,0.3 0.3,0.3 h 1.5 v 0.4 h 3.2 v -0.2 h 0.7 l 1.6,-1 0.5,-1.2 z"
            id="path34"
            style="fill: #1e1e1c"
          />
          <line
            class="pc-vdy-icon-st3"
            x1="392.79999"
            y1="282.20001"
            x2="400.5"
            y2="277.70001"
            id="line36"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vdy-icon-st3"
            x1="400.5"
            y1="318.70001"
            x2="400.5"
            y2="311.5"
            id="line38"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vdy-icon-st3"
            x1="392.79999"
            y1="313.89999"
            x2="400.5"
            y2="318.5"
            id="line40"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <circle
            class="pc-vdy-icon-st5"
            cx="428.70001"
            cy="298.10001"
            r="16.5"
            id="circle42"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vdy-icon-st6"
            d="m 408.9,296.5 c 2.6,-5.8 8.4,-8.4 13.5,-6.2"
            id="path44"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vdy-icon-st6"
            d="m 418.9,281 c 6.3,-1.1 11.7,2.2 12.8,7.8"
            id="path46"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vdy-icon-st6"
            d="m 448.5,297.7 c -1.9,6.1 -7.4,9.3 -12.8,7.8"
            id="path48"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vdy-icon-st6"
            d="m 438.9,281.3 c 4.1,4.8 3.9,11.2 -0.3,14.9"
            id="path50"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vdy-icon-st6"
            d="m 416.2,313.7 c -3.4,-5.4 -2.3,-11.7 2.4,-14.7"
            id="path52"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vdy-icon-st6"
            d="m 437,316.2 c -6.3,0.6 -11.4,-3 -12.2,-8.7"
            id="path54"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <circle
            class="pc-vdy-icon-st5"
            cx="428.70001"
            cy="298.10001"
            r="6.1999998"
            id="circle56"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <circle
            class="pc-vdy-icon-st7"
            cx="428.70001"
            cy="298.10001"
            r="6.1999998"
            id="circle58"
            style="
              fill: #e3e3e2;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <circle
            class="pc-vdy-icon-st8"
            cx="428.70001"
            cy="298.10001"
            id="ellipse60"
            r="20.6"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 9.99919987;
            "
          />
          <circle
            class="pc-vdy-icon-st4"
            cx="428.70001"
            cy="298.10001"
            r="4.9000001"
            id="circle62"
            style="fill: #1e1e1c"
          />
        </g>
        <g id="g74">
          <g id="g72">
            <line
              class="pc-vdy-icon-st9"
              x1="394.60001"
              y1="317.60001"
              x2="398.39999"
              y2="312.29999"
              id="line66"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g70">
              <polygon
                class="pc-vdy-icon-st10"
                points="393.2,319.5 393.5,316.3 396.2,318.2 "
                id="polygon68"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
        <g id="g84">
          <g id="g82">
            <line
              class="pc-vdy-icon-st9"
              x1="395.60001"
              y1="278.39999"
              x2="398.70001"
              y2="284.10001"
              id="line76"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g80">
              <polygon
                class="pc-vdy-icon-st10"
                points="394.4,276.3 397.2,278 394.3,279.6 "
                id="polygon78"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vey" viewBox="0 0 58.849993 43.200002">
      <style type="text/css" id="style2">
        .pc-vey-icon-st0 {
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 9.9992;
        }

        .pc-vey-icon-st1 {
          fill: #e3e3e2;
        }

        .pc-vey-icon-st2 {
          fill: #b3b2b2;
        }

        .pc-vey-icon-st3 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vey-icon-st4 {
          fill: #1e1e1c;
        }

        .pc-vey-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vey-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vey-icon-st7 {
          fill: #e3e3e2;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vey-icon-st8 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 9.9992;
        }

        .pc-vey-icon-st9 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vey-icon-st10 {
          fill: #223a8f;
        }
      </style>
      <g id="g86" transform="translate(-390.9,-276.2)">
        <g id="g64">
          <circle
            class="pc-vey-icon-st0"
            cx="428.89999"
            cy="297.79999"
            id="ellipse4"
            r="20.6"
            style="
              fill: #b3b2b2;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 9.99919987;
            "
          />
          <path
            class="pc-vey-icon-st1"
            d="m 393,281.9 v 31.7 h 7.8 v -2.4 c 0,-1.5 1.2,-2.7 2.7,-2.7 v 0 h 0.4 v -21.4 h -0.4 c -1.5,0 -2.7,-1.2 -2.7,-2.7 v 0 -2.4 z"
            id="path6"
            style="fill: #e3e3e2"
          />
          <path
            class="pc-vey-icon-st2"
            d="m 401,310.1 c -0.2,0.4 -0.3,0.8 -0.3,1.2 v 2.4 h -0.2 v 4.4 l -2.5,-1.4 -3.1,-1.9 -1.2,-0.6 -0.8,-0.4 v -10.3 c 4.1,-0.4 7.6,2.6 8.1,6.6 z"
            id="path8"
            style="fill: #b3b2b2"
          />
          <path
            class="pc-vey-icon-st2"
            d="m 401,285.5 c -0.2,-0.4 -0.3,-0.8 -0.3,-1.2 v -2.4 h -0.2 v -4.4 l -2.4,1.4 -3.1,1.9 -1.2,0.6 -0.8,0.4 V 292 c 4,0.4 7.5,-2.5 8,-6.5 z"
            id="path10"
            style="fill: #b3b2b2"
          />
          <line
            class="pc-vey-icon-st3"
            x1="393"
            y1="281.70001"
            x2="393"
            y2="293.79999"
            id="line12"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vey-icon-st3"
            x1="400.70001"
            y1="277.10001"
            x2="400.70001"
            y2="284.39999"
            id="line14"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vey-icon-st3"
            x1="403.79999"
            y1="287.10001"
            x2="403.5"
            y2="287.10001"
            id="line16"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vey-icon-st3"
            d="m 400.8,284.4 c 0,1.5 1.2,2.7 2.7,2.7 v 0"
            id="path18"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vey-icon-st3"
            d="m 393,292.2 c 4,0.2 7.5,-2.7 8,-6.7"
            id="path20"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vey-icon-st3"
            x1="403.79999"
            y1="287.10001"
            x2="403.79999"
            y2="297.79999"
            id="line22"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vey-icon-st3"
            x1="403.79999"
            y1="308.5"
            x2="403.79999"
            y2="297.79999"
            id="line24"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vey-icon-st3"
            d="m 401,310.1 c -0.5,-4 -4,-6.9 -8,-6.7"
            id="path26"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vey-icon-st3"
            d="m 403.5,308.5 c -1.5,0 -2.7,1.2 -2.7,2.7 v 0"
            id="path28"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vey-icon-st3"
            x1="403.79999"
            y1="308.5"
            x2="403.5"
            y2="308.5"
            id="line30"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vey-icon-st3"
            x1="393"
            y1="313.89999"
            x2="393"
            y2="301.79999"
            id="line32"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vey-icon-st4"
            d="m 398.7,296.3 -0.5,-1.2 -1.6,-1 h -0.7 v -0.2 h -3.2 v 0.4 h -1.5 c -0.2,0 -0.3,0.1 -0.3,0.3 v 6.7 c 0,0.2 0.2,0.3 0.3,0.3 h 1.5 v 0.4 h 3.2 v -0.2 h 0.7 l 1.6,-1 0.5,-1.2 z"
            id="path34"
            style="fill: #1e1e1c"
          />
          <line
            class="pc-vey-icon-st3"
            x1="393"
            y1="281.89999"
            x2="400.70001"
            y2="277.39999"
            id="line36"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vey-icon-st3"
            x1="400.70001"
            y1="318.39999"
            x2="400.70001"
            y2="311.20001"
            id="line38"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            class="pc-vey-icon-st3"
            x1="393"
            y1="313.60001"
            x2="400.70001"
            y2="318.20001"
            id="line40"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.60000002;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <circle
            class="pc-vey-icon-st5"
            cx="428.89999"
            cy="297.79999"
            r="16.5"
            id="circle42"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vey-icon-st6"
            d="m 409.1,296.2 c 2.6,-5.8 8.4,-8.4 13.5,-6.2"
            id="path44"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vey-icon-st6"
            d="m 419.1,280.7 c 6.3,-1.1 11.7,2.2 12.8,7.8"
            id="path46"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vey-icon-st6"
            d="m 448.7,297.4 c -1.9,6.1 -7.4,9.3 -12.8,7.8"
            id="path48"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vey-icon-st6"
            d="m 439.1,281 c 4.1,4.8 3.9,11.2 -0.3,14.9"
            id="path50"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vey-icon-st6"
            d="m 416.4,313.4 c -3.4,-5.4 -2.3,-11.7 2.4,-14.7"
            id="path52"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vey-icon-st6"
            d="m 437.2,315.9 c -6.3,0.6 -11.4,-3 -12.2,-8.7"
            id="path54"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <circle
            class="pc-vey-icon-st5"
            cx="428.89999"
            cy="297.79999"
            r="6.1999998"
            id="circle56"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <circle
            class="pc-vey-icon-st7"
            cx="428.89999"
            cy="297.79999"
            r="6.1999998"
            id="circle58"
            style="
              fill: #e3e3e2;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <circle
            class="pc-vey-icon-st8"
            cx="428.89999"
            cy="297.79999"
            id="ellipse60"
            r="20.6"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 9.99919987;
            "
          />
          <circle
            class="pc-vey-icon-st4"
            cx="428.89999"
            cy="297.79999"
            r="4.9000001"
            id="circle62"
            style="fill: #1e1e1c"
          />
        </g>
        <g id="g74">
          <g id="g72">
            <line
              class="pc-vey-icon-st9"
              x1="394.70001"
              y1="317.39999"
              x2="398.5"
              y2="312.20001"
              id="line66"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g70">
              <polygon
                class="pc-vey-icon-st10"
                points="393.3,319.4 393.7,316.2 396.4,318.1 "
                id="polygon68"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
        <g id="g84">
          <g id="g82">
            <line
              class="pc-vey-icon-st9"
              x1="395.70001"
              y1="278.29999"
              x2="398.79999"
              y2="284"
              id="line76"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g80">
              <polygon
                class="pc-vey-icon-st10"
                points="394.6,276.2 397.3,277.9 394.4,279.5 "
                id="polygon78"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vjh" viewBox="0 0 65.675001 44.750001">
      <style type="text/css" id="style2">
        .pc-vjh-icon-st0 {
          fill: #e3e3e2;
          stroke: #1e1e1c;
          stroke-width: 0.71;
          stroke-miterlimit: 10;
        }

        .pc-vjh-icon-st1 {
          fill: #b3b2b2;
        }

        .pc-vjh-icon-st2 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vjh-icon-st3 {
          fill: #1e1e1c;
        }

        .pc-vjh-icon-st4 {
          fill: #ffffff;
        }

        .pc-vjh-icon-st5 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vjh-icon-st6 {
          fill: #223a8f;
        }

        .pc-vjh-icon-st7 {
          fill: #e3e3e2;
          stroke: #1e1e1c;
          stroke-width: 0.5;
        }

        .pc-vjh-icon-st8 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vjh-icon-st9 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vjh-icon-st10 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
          stroke-dasharray: 7.16, 7.16, 7.16, 7.16, 7.16, 7.16;
        }

        .pc-vjh-icon-st11 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
          stroke-dasharray: 5.39, 5.39, 5.39, 5.39, 5.39, 5.39;
        }

        .pc-vjh-icon-st12 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vjh-icon-st13 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        }

        .pc-vjh-icon-st14 {
          fill: #1d1d1b;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }
      </style>
      <g id="g98" transform="translate(-387.3,-275.52499)">
        <rect
          x="388.10001"
          y="276.10001"
          class="pc-vjh-icon-st0"
          width="14.7"
          height="43.5"
          id="rect4"
          style="
            fill: #e3e3e2;
            stroke: #1e1e1c;
            stroke-width: 0.70999998;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vjh-icon-st1"
          d="m 401.6,306.4 c -0.2,0.5 -0.3,1.1 -0.3,1.7 v 3.5 h -0.2 v 6.4 l -2.8,-2 -3.5,-2.8 -1.3,-0.9 -0.9,-0.6 v -15 c 4.5,-0.4 8.4,3.9 9,9.7 z"
          id="path6"
          style="fill: #b3b2b2"
        />
        <path
          class="pc-vjh-icon-st1"
          d="m 401.6,289.3 c -0.2,-0.5 -0.3,-1.1 -0.3,-1.7 v -3.5 h -0.2 v -6.4 l -2.8,2 -3.5,2.8 -1.3,0.9 -0.9,0.6 v 14.9 c 4.5,0.4 8.4,-3.8 9,-9.6 z"
          id="path8"
          style="fill: #b3b2b2"
        />
        <line
          class="pc-vjh-icon-st2"
          x1="392.60001"
          y1="284.10001"
          x2="392.60001"
          y2="302.5"
          id="line10"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vjh-icon-st2"
          x1="401.29999"
          y1="277.39999"
          x2="401.29999"
          y2="296.39999"
          id="line12"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vjh-icon-st2"
          x1="392.60001"
          y1="311.60001"
          x2="392.60001"
          y2="293.29999"
          id="line14"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vjh-icon-st3"
          d="m 409.6,294.5 -1.4,-2.8 -4.6,-2.4 h -1.9 v -0.4 h -9.1 v 0.9 h -4.3 c -0.5,0 -1,0.3 -1,0.6 v 14.9 c 0,0.3 0.4,0.6 1,0.6 h 4.3 v 0.9 h 9.1 v -0.4 h 1.9 l 4.6,-2.3 1.4,-2.8 z"
          id="path16"
          style="fill: #1e1e1c"
        />
        <line
          class="pc-vjh-icon-st2"
          x1="392.60001"
          y1="284.10001"
          x2="401.29999"
          y2="277.39999"
          id="line18"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vjh-icon-st2"
          x1="401.29999"
          y1="318.20001"
          x2="401.29999"
          y2="300.39999"
          id="line20"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vjh-icon-st2"
          x1="392.60001"
          y1="311.60001"
          x2="401.29999"
          y2="318.29999"
          id="line22"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <rect
          x="403.29999"
          y="286.79999"
          class="pc-vjh-icon-st4"
          width="10.2"
          height="21.799999"
          id="rect24"
          style="fill: #ffffff"
        />
        <g id="g34">
          <g id="g32">
            <line
              class="pc-vjh-icon-st5"
              x1="393.60001"
              y1="316.5"
              x2="398"
              y2="311.60001"
              id="line26"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g30">
              <polygon
                class="pc-vjh-icon-st6"
                points="392.1,318.3 392.7,315.1 395.2,317.3 "
                id="polygon28"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
        <g id="g44">
          <g id="g42">
            <line
              class="pc-vjh-icon-st5"
              x1="394.70001"
              y1="279.70001"
              x2="398.5"
              y2="284.89999"
              id="line36"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g40">
              <polygon
                class="pc-vjh-icon-st6"
                points="393.2,277.8 396.2,279 393.5,281 "
                id="polygon38"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
        <g id="g96">
          <path
            class="pc-vjh-icon-st7"
            d="m 408.5,297.9 c 0,-12.3 9.9,-22.1 22,-22.1 12.1,0 22.1,9.9 22.1,22.1 0,12.1 -9.9,22 -22.1,22 v 0 c -12.1,0.1 -22,-9.7 -22,-22 z"
            id="path46"
            style="fill: #e3e3e2; stroke: #1e1e1c; stroke-width: 0.5"
          />
          <path
            class="pc-vjh-icon-st1"
            d="m 410,297.9 c 0,-11.4 9.2,-20.6 20.5,-20.6 11.3,0 20.6,9.2 20.6,20.6 0,11.3 -9.2,20.5 -20.6,20.5 v 0 c -11.2,0 -20.5,-9.1 -20.5,-20.5 z"
            id="path48"
            style="fill: #b3b2b2"
          />
          <g id="g62">
            <path
              class="pc-vjh-icon-st8"
              d="m 410.1,296.3 c 2.8,-6.1 8.8,-8.8 14.2,-6.5"
              id="path50"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 0.75;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vjh-icon-st8"
              d="m 420.7,280 c 6.6,-1.1 12.2,2.3 13.5,8.2"
              id="path52"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 0.75;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vjh-icon-st8"
              d="m 451.6,297.5 c -2,6.4 -7.7,9.8 -13.5,8.2"
              id="path54"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 0.75;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vjh-icon-st8"
              d="m 441.6,280.3 c 4.3,5.1 4.1,11.8 -0.3,15.7"
              id="path56"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 0.75;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vjh-icon-st8"
              d="m 417.8,314.4 c -3.5,-5.6 -2.4,-12.2 2.5,-15.4"
              id="path58"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 0.75;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vjh-icon-st8"
              d="m 439.6,317 c -6.6,0.7 -12,-3.2 -12.8,-9.2"
              id="path60"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 0.75;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
          </g>
          <circle
            class="pc-vjh-icon-st9"
            cx="430.60001"
            cy="297.89999"
            r="22"
            id="circle64"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-miterlimit: 10;
            "
          />
          <circle
            class="pc-vjh-icon-st10"
            cx="430.60001"
            cy="297.89999"
            r="13.7"
            id="circle66"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.25;
              stroke-miterlimit: 10;
              stroke-dasharray: 7.16, 7.16, 7.16, 7.16, 7.16, 7.16;
            "
          />
          <circle
            class="pc-vjh-icon-st11"
            cx="430.60001"
            cy="298"
            r="20.6"
            id="circle68"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.25;
              stroke-miterlimit: 10;
              stroke-dasharray: 5.39, 5.39, 5.39, 5.39, 5.39, 5.39;
            "
          />
          <circle
            class="pc-vjh-icon-st12"
            cx="430.60001"
            cy="297.89999"
            r="17.4"
            id="circle70"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.25;
              stroke-miterlimit: 10;
            "
          />
          <circle
            class="pc-vjh-icon-st0"
            cx="430.60001"
            cy="297.89999"
            r="10.8"
            id="circle72"
            style="
              fill: #e3e3e2;
              stroke: #1e1e1c;
              stroke-width: 0.70999998;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vjh-icon-st9"
            x1="429.79999"
            y1="275.89999"
            x2="429.79999"
            y2="320"
            id="line74"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vjh-icon-st9"
            x1="431.5"
            y1="275.89999"
            x2="431.5"
            y2="320"
            id="line76"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vjh-icon-st9"
            x1="408.5"
            y1="298.60001"
            x2="452.60001"
            y2="298.60001"
            id="line78"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vjh-icon-st9"
            x1="408.5"
            y1="297"
            x2="452.60001"
            y2="297"
            id="line80"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vjh-icon-st12"
            x1="414.89999"
            y1="282.39999"
            x2="446.10001"
            y2="313.60001"
            id="line82"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.25;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vjh-icon-st12"
            x1="414.89999"
            y1="313.39999"
            x2="446"
            y2="282.29999"
            id="line84"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.25;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vjh-icon-st13"
            x1="431.5"
            y1="276.10001"
            x2="429.79999"
            y2="276.10001"
            id="line86"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vjh-icon-st13"
            x1="431.5"
            y1="319.79999"
            x2="429.79999"
            y2="319.79999"
            id="line88"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vjh-icon-st13"
            x1="452.5"
            y1="298.60001"
            x2="452.5"
            y2="297"
            id="line90"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vjh-icon-st13"
            x1="408.70001"
            y1="298.60001"
            x2="408.70001"
            y2="297"
            id="line92"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.75;
              stroke-linecap: round;
              stroke-miterlimit: 10;
            "
          />
          <circle
            class="pc-vjh-icon-st14"
            cx="430.60001"
            cy="297.89999"
            r="9.3999996"
            id="circle94"
            style="
              fill: #1d1d1b;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vgr" viewBox="0 0 89.484037 44.599999">
      <style type="text/css" id="style2">
        .pc-vgr-icon-st0 {
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-linejoin: round;
        }

        .pc-vgr-icon-st1 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
        }

        .pc-vgr-icon-st2 {
          fill: #1e1e1c;
        }

        .pc-vgr-icon-st3 {
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.7;
        }

        .pc-vgr-icon-st4 {
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vgr-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-miterlimit: 10;
        }

        .pc-vgr-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }

        .pc-vgr-icon-st7 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 6;
          stroke-miterlimit: 10;
        }

        .pc-vgr-icon-st8 {
          fill: #dadada;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }

        .pc-vgr-icon-st9 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vgr-icon-st10 {
          fill: none;
          stroke: #ffffff;
          stroke-width: 3;
          stroke-miterlimit: 10;
        }
      </style>
      <path
        class="pc-vgr-icon-st0"
        d="m 3.9,27 c 0.3,0 0.5,-0.2 0.5,-0.5 0,-0.1 0,-0.1 0,-0.2 C 3.8,24.6 3.4,22.9 3.3,21.1 3.1,16.2 4.8,11.4 8.1,7.7 11.6,3.9 16.4,1.7 21.5,1.4 h 0.8 c 5.6,0 11,2.3 14.9,6.4 l 0.3,0.4 c 3.1,3.6 5,8.1 5.2,12.8 0.2,5.5 -1.7,10.9 -5.3,15.1 l -0.3,0.4 c -4,4.3 -9.6,6.9 -15.5,7.1 H 1 V 26.9 Z"
        id="path4"
        style="
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-linejoin: round;
        "
      />
      <path
        class="pc-vgr-icon-st1"
        d="m 9,21.1 c 0,8 6.5,14.6 14.6,14.6 8.1,0 14.6,-6.5 14.6,-14.6 C 38.2,13 31.7,6.5 23.6,6.5 v 0 C 15.5,6.5 9,13 9,21.1 Z"
        id="path6"
        style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.69999999"
      />
      <rect
        x="8.7000065"
        y="20.700012"
        class="pc-vgr-icon-st2"
        width="14.8"
        height="15.2"
        id="rect8"
        style="fill: #1e1e1c"
      />
      <path
        class="pc-vgr-icon-st3"
        d="m 14.4,20.2 c 0,5.1 4.1,9.2 9.2,9.2 5.1,0 9.2,-4.1 9.2,-9.2 0,-5.1 -4.1,-9.2 -9.2,-9.2 v 0 c -5.1,0 -9.2,4.1 -9.2,9.2 z"
        id="path10"
        style="fill: none; stroke: #ffffff; stroke-width: 0.69999999"
      />
      <rect
        x="58.700005"
        y="0.5"
        class="pc-vgr-icon-st4"
        width="20.799999"
        height="25.5"
        id="rect12"
        style="
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vgr-icon-st5"
        x1="58.700005"
        y1="1.1000061"
        x2="80.300011"
        y2="1.1000061"
        id="line14"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vgr-icon-st5"
        x1="58.700005"
        y1="43.299988"
        x2="80.300011"
        y2="43.299988"
        id="line16"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vgr-icon-st5"
        x1="58.700005"
        y1="0"
        x2="58.700005"
        y2="44.299988"
        id="line18"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vgr-icon-st6"
        x1="57.899986"
        y1="8.6000061"
        x2="57.899986"
        y2="37.899994"
        id="line20"
        style="fill: none; stroke: #1e1e1c; stroke-miterlimit: 10"
      />
      <line
        class="pc-vgr-icon-st5"
        x1="79.499992"
        y1="0"
        x2="79.499992"
        y2="44.299988"
        id="line22"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vgr-icon-st7"
        x1="83.300011"
        y1="6.5"
        x2="83.300011"
        y2="35.600006"
        id="line24"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 6;
          stroke-miterlimit: 10;
        "
      />
      <rect
        x="58.700005"
        y="26.5"
        class="pc-vgr-icon-st8"
        width="20.1"
        height="16.4"
        id="rect26"
        style="fill: #dadada; stroke: #1e1e1c; stroke-miterlimit: 10"
      />
      <line
        class="pc-vgr-icon-st5"
        x1="58.700005"
        y1="43.100006"
        x2="78.800011"
        y2="43.100006"
        id="line28"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vgr-icon-st9"
        x1="58.700005"
        y1="28.399994"
        x2="78.800011"
        y2="28.399994"
        id="line30"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vgr-icon-st9"
        x1="58.700005"
        y1="37.5"
        x2="78.800011"
        y2="37.5"
        id="line32"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vgr-icon-st10"
        x1="88.300011"
        y1="32.799988"
        x2="84.099998"
        y2="38.200012"
        id="line34"
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 3;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vgr-icon-st10"
        x1="88.300011"
        y1="32.799988"
        x2="84.099998"
        y2="38.200012"
        id="line36"
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 3;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vgr-icon-st10"
        x1="84.099998"
        y1="3.7999878"
        x2="88.300011"
        y2="9.2000122"
        id="line38"
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 3;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vgr-icon-st9"
        x1="58.700005"
        y1="27"
        x2="78.800011"
        y2="27"
        id="line40"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vgr-icon-st9"
        x1="58.700005"
        y1="31.100006"
        x2="78.800011"
        y2="31.100006"
        id="line42"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        "
      />
      <line
        class="pc-vgr-icon-st9"
        x1="58.700005"
        y1="34.299988"
        x2="78.800011"
        y2="34.299988"
        id="line44"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        "
      />
    </symbol>

    <symbol id="product-category-vtd" viewBox="0 0 95.910001 19.120011">
      <style type="text/css" id="style2">
        .pc-vtd-icon-st0 {
          fill: #1e1e1c;
        }

        .pc-vtd-icon-st1 {
          fill: #e3e3e2;
        }

        .pc-vtd-icon-st2 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.28;
        }

        .pc-vtd-icon-st3 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.42;
        }

        .pc-vtd-icon-st4 {
          fill: #878787;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }

        .pc-vtd-icon-st5 {
          fill: #585857;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }
      </style>
      <path
        class="pc-vtd-icon-st0"
        d="m 53.410012,11.21 0.8,2 2.7,1.7 h 1.2 v 0.3 h 5.3 v -0.6 h 2.5 c 0.3,0 0.5,-0.2 0.6,-0.4 V 3.51 c 0,-0.3 -0.3,-0.5 -0.6,-0.4 h -2.5 v -0.6 h -5.3 v 0.3 h -1.1 l -2.7,1.7 -0.8,2 z"
        id="path4"
        style="fill: #1e1e1c"
      />
      <rect
        x="0.70999998"
        y="1.5099878"
        class="pc-vtd-icon-st1"
        width="59.400002"
        height="14.4"
        id="rect6"
        style="fill: #e3e3e2"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="15.910012"
        x2="60.110023"
        y2="15.910012"
        id="line8"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="14.609994"
        x2="60.110023"
        y2="14.609994"
        id="line10"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="13.310006"
        x2="60.110023"
        y2="13.310006"
        id="line12"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="12.310006"
        x2="60.110023"
        y2="12.310006"
        id="line14"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="11.21"
        x2="60.110023"
        y2="11.21"
        id="line16"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="10.21"
        x2="60.110023"
        y2="10.21"
        id="line18"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="9.21"
        x2="60.110023"
        y2="9.21"
        id="line20"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="8.1099939"
        x2="60.110023"
        y2="8.1099939"
        id="line22"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="7.1099939"
        x2="60.110023"
        y2="7.1099939"
        id="line24"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="6.0099878"
        x2="60.110023"
        y2="6.0099878"
        id="line26"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="4.9100122"
        x2="60.110023"
        y2="4.9100122"
        id="line28"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="3.9100122"
        x2="60.110023"
        y2="3.9100122"
        id="line30"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="2.6099939"
        x2="60.110023"
        y2="2.6099939"
        id="line32"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="60.110023"
        y1="15.910012"
        x2="60.110023"
        y2="10.410012"
        id="line34"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="15.910012"
        x2="0.70999998"
        y2="8.3100061"
        id="line36"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="1.5099878"
        x2="0.70999998"
        y2="8.8100061"
        id="line38"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st3"
        x1="0.70999998"
        y1="18.410011"
        x2="0.70999998"
        y2="8.1099939"
        id="line40"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vtd-icon-st3"
        x1="0.70999998"
        y1="0.20999998"
        x2="0.70999998"
        y2="8.71"
        id="line42"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vtd-icon-st3"
        x1="59.910011"
        y1="18.910011"
        x2="59.910011"
        y2="8.1099939"
        id="line44"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vtd-icon-st3"
        x1="59.910011"
        y1="0.20999998"
        x2="59.910011"
        y2="8.71"
        id="line46"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="60.110023"
        y1="1.5099878"
        x2="60.110023"
        y2="6.9100122"
        id="line48"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st2"
        x1="0.70999998"
        y1="1.5099878"
        x2="60.110023"
        y2="1.5099878"
        id="line50"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtd-icon-st3"
        x1="0.010018289"
        y1="0.70999998"
        x2="60.610023"
        y2="0.70999998"
        id="line52"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vtd-icon-st3"
        x1="0.010018289"
        y1="18.410011"
        x2="60.610023"
        y2="18.410011"
        id="line54"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <rect
        x="77.709999"
        y="0.70999998"
        class="pc-vtd-icon-st4"
        width="17.700001"
        height="17.700001"
        id="rect56"
        style="fill: #878787; stroke: #1e1e1c; stroke-miterlimit: 10"
      />
      <circle
        class="pc-vtd-icon-st5"
        cx="86.610023"
        cy="9.6099939"
        r="2.8"
        id="circle58"
        style="fill: #585857; stroke: #1e1e1c; stroke-miterlimit: 10"
      />
    </symbol>

    <symbol id="product-category-vtm" viewBox="0 0 95.910001 19.120011">
      <style type="text/css" id="style2">
        .pc-vtm-icon-st0 {
          fill: #1e1e1c;
        }

        .pc-vtm-icon-st1 {
          fill: #e3e3e2;
        }

        .pc-vtm-icon-st2 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.28;
        }

        .pc-vtm-icon-st3 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.42;
        }

        .pc-vtm-icon-st4 {
          fill: #878787;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }

        .pc-vtm-icon-st5 {
          fill: #585857;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }
      </style>
      <path
        class="pc-vtm-icon-st0"
        d="m 53.410012,11.21 0.8,2 2.7,1.7 h 1.2 v 0.3 h 5.3 v -0.6 h 2.5 c 0.3,0 0.5,-0.2 0.6,-0.4 V 3.51 c 0,-0.3 -0.3,-0.5 -0.6,-0.4 h -2.5 v -0.6 h -5.3 v 0.3 h -1.1 l -2.7,1.7 -0.8,2 z"
        id="path4"
        style="fill: #1e1e1c"
      />
      <rect
        x="0.70999998"
        y="1.5099878"
        class="pc-vtm-icon-st1"
        width="59.400002"
        height="14.4"
        id="rect6"
        style="fill: #e3e3e2"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="15.910012"
        x2="60.110023"
        y2="15.910012"
        id="line8"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="14.609994"
        x2="60.110023"
        y2="14.609994"
        id="line10"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="13.310006"
        x2="60.110023"
        y2="13.310006"
        id="line12"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="12.310006"
        x2="60.110023"
        y2="12.310006"
        id="line14"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="11.21"
        x2="60.110023"
        y2="11.21"
        id="line16"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="10.21"
        x2="60.110023"
        y2="10.21"
        id="line18"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="9.21"
        x2="60.110023"
        y2="9.21"
        id="line20"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="8.1099939"
        x2="60.110023"
        y2="8.1099939"
        id="line22"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="7.1099939"
        x2="60.110023"
        y2="7.1099939"
        id="line24"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="6.0099878"
        x2="60.110023"
        y2="6.0099878"
        id="line26"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="4.9100122"
        x2="60.110023"
        y2="4.9100122"
        id="line28"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="3.9100122"
        x2="60.110023"
        y2="3.9100122"
        id="line30"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="2.6099939"
        x2="60.110023"
        y2="2.6099939"
        id="line32"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="60.110023"
        y1="15.910012"
        x2="60.110023"
        y2="10.410012"
        id="line34"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="15.910012"
        x2="0.70999998"
        y2="8.3100061"
        id="line36"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="1.5099878"
        x2="0.70999998"
        y2="8.8100061"
        id="line38"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st3"
        x1="0.70999998"
        y1="18.410011"
        x2="0.70999998"
        y2="8.1099939"
        id="line40"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vtm-icon-st3"
        x1="0.70999998"
        y1="0.20999998"
        x2="0.70999998"
        y2="8.71"
        id="line42"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vtm-icon-st3"
        x1="59.910011"
        y1="18.910011"
        x2="59.910011"
        y2="8.1099939"
        id="line44"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vtm-icon-st3"
        x1="59.910011"
        y1="0.20999998"
        x2="59.910011"
        y2="8.71"
        id="line46"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="60.110023"
        y1="1.5099878"
        x2="60.110023"
        y2="6.9100122"
        id="line48"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st2"
        x1="0.70999998"
        y1="1.5099878"
        x2="60.110023"
        y2="1.5099878"
        id="line50"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vtm-icon-st3"
        x1="0.010018289"
        y1="0.70999998"
        x2="60.610023"
        y2="0.70999998"
        id="line52"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vtm-icon-st3"
        x1="0.010018289"
        y1="18.410011"
        x2="60.610023"
        y2="18.410011"
        id="line54"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <rect
        x="77.709999"
        y="0.70999998"
        class="pc-vtm-icon-st4"
        width="17.700001"
        height="17.700001"
        id="rect56"
        style="fill: #878787; stroke: #1e1e1c; stroke-miterlimit: 10"
      />
      <circle
        class="pc-vtm-icon-st5"
        cx="86.610023"
        cy="9.6099939"
        r="2.8"
        id="circle58"
        style="fill: #585857; stroke: #1e1e1c; stroke-miterlimit: 10"
      />
    </symbol>

    <symbol id="product-category-vts" viewBox="0 0 95.910001 19.120011">
      <style type="text/css" id="style2">
        .pc-vts-icon-st0 {
          fill: #1e1e1c;
        }

        .pc-vts-icon-st1 {
          fill: #e3e3e2;
        }

        .pc-vts-icon-st2 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.28;
        }

        .pc-vts-icon-st3 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.42;
        }

        .pc-vts-icon-st4 {
          fill: #878787;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }

        .pc-vts-icon-st5 {
          fill: #585857;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }
      </style>
      <path
        class="pc-vts-icon-st0"
        d="m 53.410012,11.21 0.8,2 2.7,1.7 h 1.2 v 0.3 h 5.3 v -0.6 h 2.5 c 0.3,0 0.5,-0.2 0.6,-0.4 V 3.51 c 0,-0.3 -0.3,-0.5 -0.6,-0.4 h -2.5 v -0.6 h -5.3 v 0.3 h -1.1 l -2.7,1.7 -0.8,2 z"
        id="path4"
        style="fill: #1e1e1c"
      />
      <rect
        x="0.70999998"
        y="1.5099878"
        class="pc-vts-icon-st1"
        width="59.400002"
        height="14.4"
        id="rect6"
        style="fill: #e3e3e2"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="15.910012"
        x2="60.110023"
        y2="15.910012"
        id="line8"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="14.609994"
        x2="60.110023"
        y2="14.609994"
        id="line10"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="13.310006"
        x2="60.110023"
        y2="13.310006"
        id="line12"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="12.310006"
        x2="60.110023"
        y2="12.310006"
        id="line14"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="11.21"
        x2="60.110023"
        y2="11.21"
        id="line16"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="10.21"
        x2="60.110023"
        y2="10.21"
        id="line18"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="9.21"
        x2="60.110023"
        y2="9.21"
        id="line20"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="8.1099939"
        x2="60.110023"
        y2="8.1099939"
        id="line22"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="7.1099939"
        x2="60.110023"
        y2="7.1099939"
        id="line24"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="6.0099878"
        x2="60.110023"
        y2="6.0099878"
        id="line26"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="4.9100122"
        x2="60.110023"
        y2="4.9100122"
        id="line28"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="3.9100122"
        x2="60.110023"
        y2="3.9100122"
        id="line30"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="2.6099939"
        x2="60.110023"
        y2="2.6099939"
        id="line32"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="60.110023"
        y1="15.910012"
        x2="60.110023"
        y2="10.410012"
        id="line34"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="15.910012"
        x2="0.70999998"
        y2="8.3100061"
        id="line36"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="1.5099878"
        x2="0.70999998"
        y2="8.8100061"
        id="line38"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st3"
        x1="0.70999998"
        y1="18.410011"
        x2="0.70999998"
        y2="8.1099939"
        id="line40"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vts-icon-st3"
        x1="0.70999998"
        y1="0.20999998"
        x2="0.70999998"
        y2="8.71"
        id="line42"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vts-icon-st3"
        x1="59.910011"
        y1="18.910011"
        x2="59.910011"
        y2="8.1099939"
        id="line44"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vts-icon-st3"
        x1="59.910011"
        y1="0.20999998"
        x2="59.910011"
        y2="8.71"
        id="line46"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vts-icon-st2"
        x1="60.110023"
        y1="1.5099878"
        x2="60.110023"
        y2="6.9100122"
        id="line48"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st2"
        x1="0.70999998"
        y1="1.5099878"
        x2="60.110023"
        y2="1.5099878"
        id="line50"
        style="fill: none; stroke: #1e1e1c; stroke-width: 0.28"
      />
      <line
        class="pc-vts-icon-st3"
        x1="0.010018289"
        y1="0.70999998"
        x2="60.610023"
        y2="0.70999998"
        id="line52"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <line
        class="pc-vts-icon-st3"
        x1="0.010018289"
        y1="18.410011"
        x2="60.610023"
        y2="18.410011"
        id="line54"
        style="fill: none; stroke: #1e1e1c; stroke-width: 1.41999996"
      />
      <rect
        x="77.709999"
        y="0.70999998"
        class="pc-vts-icon-st4"
        width="17.700001"
        height="17.700001"
        id="rect56"
        style="fill: #878787; stroke: #1e1e1c; stroke-miterlimit: 10"
      />
      <circle
        class="pc-vts-icon-st5"
        cx="86.610023"
        cy="9.6099939"
        r="2.8"
        id="circle58"
        style="fill: #585857; stroke: #1e1e1c; stroke-miterlimit: 10"
      />
    </symbol>

    <symbol id="product-category-vcs" viewBox="0 0 135.8 44.200013">
      <style type="text/css" id="style2">
        .pc-vcs-icon-st0 {
          fill: #dadada;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st1 {
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st2 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st3 {
          fill: none;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st4 {
          fill: #e3e3e2;
        }

        .pc-vcs-icon-st5 {
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-linejoin: round;
        }

        .pc-vcs-icon-st6 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
        }

        .pc-vcs-icon-st7 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vcs-icon-st8 {
          fill: #1e1e1c;
        }

        .pc-vcs-icon-st9 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.5;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vcs-icon-st10 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st11 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st12 {
          fill: none;
          stroke: #1d1d1b;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }
      </style>
      <g id="g116" transform="translate(-347.5,-275.79999)">
        <g id="g82">
          <rect
            x="398.39999"
            y="276.79999"
            class="pc-vcs-icon-st0"
            width="34.200001"
            height="16.4"
            id="rect4"
            style="fill: #dadada; stroke: #1e1e1c; stroke-miterlimit: 10"
          />
          <rect
            x="398.39999"
            y="293.39999"
            class="pc-vcs-icon-st1"
            width="34.200001"
            height="25.5"
            id="rect6"
            style="
              fill: #b3b2b2;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcs-icon-st2"
            x1="398.39999"
            y1="276.79999"
            x2="432.70001"
            y2="276.79999"
            id="line8"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 2;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcs-icon-st2"
            x1="398.39999"
            y1="293.39999"
            x2="432.70001"
            y2="293.39999"
            id="line10"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 2;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcs-icon-st2"
            x1="398.39999"
            y1="319"
            x2="432.70001"
            y2="319"
            id="line12"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 2;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcs-icon-st2"
            x1="398.39999"
            y1="275.79999"
            x2="398.39999"
            y2="320"
            id="line14"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 2;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcs-icon-st3"
            x1="397.29999"
            y1="283.70001"
            x2="397.29999"
            y2="314.29999"
            id="line16"
            style="fill: none; stroke: #1e1e1c; stroke-miterlimit: 10"
          />
          <line
            class="pc-vcs-icon-st2"
            x1="432.70001"
            y1="275.79999"
            x2="432.70001"
            y2="320"
            id="line18"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 2;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcs-icon-st3"
            x1="433.79999"
            y1="283.70001"
            x2="433.79999"
            y2="314.29999"
            id="line20"
            style="fill: none; stroke: #1e1e1c; stroke-miterlimit: 10"
          />
          <g id="g50">
            <path
              class="pc-vcs-icon-st4"
              d="m 477.1,299.2 c 0,-7.9 -6.4,-14.3 -14.3,-14.3 -7.9,0 -14.3,6.4 -14.3,14.3 0,7.9 6.4,14.3 14.3,14.3 7.8,0.1 14.3,-6.4 14.3,-14.3 z"
              id="path22"
              style="fill: #e3e3e2"
            />
            <path
              class="pc-vcs-icon-st5"
              d="m 479.3,293.4 c -0.3,0 -0.5,0.2 -0.5,0.5 v 0 c 0,0.1 0,0.1 0,0.2 0.6,1.7 1,3.4 1.1,5.2 0.2,4.9 -1.5,9.7 -4.8,13.4 -3.5,3.8 -8.3,6 -13.4,6.3 h -0.8 c -5.6,0 -11,-2.3 -14.8,-6.4 l -0.3,-0.4 c -3.1,-3.6 -5,-8.1 -5.2,-12.8 -0.2,-5.5 1.7,-10.9 5.3,-15.1 l 0.3,-0.4 c 4,-4.3 9.6,-6.9 15.5,-7.1 h 20.6 v 16.7 z m -5.6,5.8 c 0,-6.1 -4.9,-11 -11,-11 -6.1,0 -11,4.9 -11,11 0,6.1 4.9,11 11,11 v 0 c 6.1,0 11,-4.9 11,-11 z"
              id="path24"
              style="
                fill: #b3b2b2;
                stroke: #1e1e1c;
                stroke-width: 2;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st6"
              d="m 470.4,299.2 c 0,-4.2 -3.4,-7.7 -7.7,-7.7 -4.3,0 -7.7,3.4 -7.7,7.7 0,4.3 3.4,7.7 7.7,7.7 4.3,0 7.7,-3.4 7.7,-7.7 z"
              id="path26"
              style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.69999999"
            />
            <path
              class="pc-vcs-icon-st4"
              d="m 448.2,299 c 0,-8 6.5,-14.5 14.5,-14.5 8,0 14.5,6.5 14.5,14.5 0,8 -6.5,14.5 -14.5,14.5 -8,0 -14.5,-6.5 -14.5,-14.5 z"
              id="path28"
              style="fill: #e3e3e2"
            />
            <circle
              class="pc-vcs-icon-st6"
              cx="462.70001"
              cy="299"
              r="5.8000002"
              id="circle30"
              style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.69999999"
            />
            <path
              class="pc-vcs-icon-st7"
              d="m 447.4,299 c 0,-8.4 6.9,-15.3 15.3,-15.3 8.4,0 15.3,6.8 15.3,15.3 0,8.5 -6.9,15.3 -15.3,15.3 -8.4,0 -15.3,-6.9 -15.3,-15.3 z m 15.3,14.5 c 8,0 14.5,-6.5 14.5,-14.5 0,-8 -6.5,-14.5 -14.5,-14.5 -8,0 -14.5,6.5 -14.5,14.5 v 0 c 0,8 6.5,14.5 14.5,14.5 z"
              id="path32"
              style="
                fill: #1e1e1c;
                stroke: #1e1e1c;
                stroke-width: 0.69999999;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st8"
              d="m 453.7,299 c 0,-5 4.1,-9.1 9.1,-9.1 5,0 9.1,4.1 9.1,9.1 0,5 -4.1,9.1 -9.1,9.1 v 0 c -5.1,0 -9.1,-4.1 -9.1,-9.1 z m 9,8.6 c 4.8,0 8.6,-3.8 8.6,-8.6 0,-4.8 -3.8,-8.6 -8.6,-8.6 -4.8,0 -8.6,3.8 -8.6,8.6 v 0 c 0,4.8 3.9,8.6 8.6,8.6 z"
              id="path34"
              style="fill: #1e1e1c"
            />
            <path
              class="pc-vcs-icon-st9"
              d="m 448.3,297.8 c 1.9,-4.2 6.1,-6.2 9.9,-4.5"
              id="path36"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 1.5;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st9"
              d="m 455.7,286.4 c 4.6,-0.8 8.5,1.6 9.3,5.7"
              id="path38"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 1.5;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st9"
              d="m 477.2,298.6 c -1.4,4.4 -5.4,6.8 -9.3,5.7"
              id="path40"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 1.5;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st9"
              d="m 470.2,286.6 c 3,3.6 2.9,8.2 -0.3,10.9"
              id="path42"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 1.5;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st9"
              d="m 453.7,310.3 c -2.5,-3.9 -1.7,-8.5 1.8,-10.8"
              id="path44"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 1.5;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st9"
              d="m 468.8,312.1 c -4.6,0.5 -8.4,-2.3 -8.9,-6.4"
              id="path46"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 1.5;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st10"
              d="M 483.2,285"
              id="path48"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 0.25;
                stroke-miterlimit: 10;
              "
            />
          </g>
          <g id="g80">
            <path
              class="pc-vcs-icon-st4"
              d="m 368,313.5 c 7.9,0 14.3,-6.4 14.3,-14.3 0,-7.9 -6.4,-14.3 -14.3,-14.3 -7.9,0 -14.3,6.4 -14.3,14.3 0,7.9 6.5,14.4 14.3,14.3 z"
              id="path52"
              style="fill: #e3e3e2"
            />
            <path
              class="pc-vcs-icon-st5"
              d="m 348.5,293.5 v -16.7 h 20.6 c 5.9,0.2 11.5,2.8 15.5,7.1 l 0.3,0.4 c 3.6,4.2 5.5,9.6 5.3,15.1 -0.2,4.7 -2.1,9.2 -5.2,12.8 l -0.3,0.4 c -3.8,4.1 -9.2,6.4 -14.8,6.4 h -0.8 c -5.1,-0.3 -9.9,-2.5 -13.4,-6.3 -3.3,-3.7 -5,-8.5 -4.8,-13.4 0.1,-1.8 0.5,-3.5 1.1,-5.2 0,-0.1 0,-0.1 0,-0.2 v 0 c 0,-0.3 -0.2,-0.5 -0.5,-0.5 z m 19.6,16.7 v 0 c 6.1,0 11,-4.9 11,-11 0,-6.1 -4.9,-11 -11,-11 -6.1,0 -11,4.9 -11,11 0,6.1 4.9,11 11,11 z"
              id="path54"
              style="
                fill: #b3b2b2;
                stroke: #1e1e1c;
                stroke-width: 2;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st6"
              d="m 360.4,299.2 c 0,4.3 3.4,7.7 7.7,7.7 4.3,0 7.7,-3.4 7.7,-7.7 0,-4.3 -3.4,-7.7 -7.7,-7.7 -4.3,0 -7.7,3.5 -7.7,7.7 z"
              id="path56"
              style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.69999999"
            />
            <path
              class="pc-vcs-icon-st4"
              d="m 382.6,299 c 0,8 -6.5,14.5 -14.5,14.5 -8,0 -14.5,-6.5 -14.5,-14.5 0,-8 6.5,-14.5 14.5,-14.5 8,0 14.5,6.5 14.5,14.5 z"
              id="path58"
              style="fill: #e3e3e2"
            />
            <circle
              class="pc-vcs-icon-st6"
              cx="368.10001"
              cy="299"
              r="5.8000002"
              id="circle60"
              style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.69999999"
            />
            <path
              class="pc-vcs-icon-st7"
              d="m 368.1,314.3 c -8.4,0 -15.3,-6.8 -15.3,-15.3 0,-8.5 6.9,-15.3 15.3,-15.3 8.4,0 15.3,6.9 15.3,15.3 0,8.4 -6.9,15.3 -15.3,15.3 z M 382.6,299 v 0 c 0,-8 -6.5,-14.5 -14.5,-14.5 -8,0 -14.5,6.5 -14.5,14.5 0,8 6.5,14.5 14.5,14.5 8,0 14.5,-6.5 14.5,-14.5 z"
              id="path62"
              style="
                fill: #1e1e1c;
                stroke: #1e1e1c;
                stroke-width: 0.69999999;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st8"
              d="m 368,308.1 v 0 c -5,0 -9.1,-4.1 -9.1,-9.1 0,-5 4.1,-9.1 9.1,-9.1 5,0 9.1,4.1 9.1,9.1 0,5 -4,9.1 -9.1,9.1 z m 0.1,-0.5 c 4.7,0 8.6,-3.8 8.6,-8.6 v 0 c 0,-4.8 -3.8,-8.6 -8.6,-8.6 -4.8,0 -8.6,3.8 -8.6,8.6 0,4.8 3.8,8.6 8.6,8.6 z"
              id="path64"
              style="fill: #1e1e1c"
            />
            <path
              class="pc-vcs-icon-st9"
              d="m 372.6,293.3 c 3.8,-1.7 8,0.3 9.9,4.5"
              id="path66"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 1.5;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st9"
              d="m 365.8,292.1 c 0.8,-4.1 4.7,-6.5 9.3,-5.7"
              id="path68"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 1.5;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st9"
              d="m 362.9,304.3 c -3.9,1.1 -7.9,-1.3 -9.3,-5.7"
              id="path70"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 1.5;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st9"
              d="m 360.9,297.5 c -3.2,-2.7 -3.3,-7.3 -0.3,-10.9"
              id="path72"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 1.5;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st9"
              d="m 375.3,299.5 c 3.5,2.3 4.3,6.9 1.8,10.8"
              id="path74"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 1.5;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st9"
              d="m 370.9,305.7 c -0.5,4.1 -4.3,6.9 -8.9,6.4"
              id="path76"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 1.5;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <path
              class="pc-vcs-icon-st10"
              d="M 347.6,285"
              id="path78"
              style="
                fill: none;
                stroke: #1e1e1c;
                stroke-width: 0.25;
                stroke-miterlimit: 10;
              "
            />
          </g>
        </g>
        <g id="g114">
          <path
            class="pc-vcs-icon-st11"
            d="M 431.9,283.4"
            id="path84"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcs-icon-st11"
            d="M 399.1,283.4"
            id="path86"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcs-icon-st11"
            d="M 431.9,286.6"
            id="path88"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcs-icon-st11"
            d="M 399.1,286.6"
            id="path90"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcs-icon-st11"
            d="M 431.9,289.8"
            id="path92"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcs-icon-st11"
            d="M 399.1,289.8"
            id="path94"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcs-icon-st11"
            d="M 431.9,285"
            id="path96"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcs-icon-st11"
            d="M 399.1,285"
            id="path98"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcs-icon-st11"
            d="M 431.9,288.2"
            id="path100"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcs-icon-st11"
            d="M 399.1,288.2"
            id="path102"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcs-icon-st11"
            d="M 399.1,291.4"
            id="path104"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcs-icon-st12"
            x1="401.39999"
            y1="283.29999"
            x2="429.39999"
            y2="283.29999"
            id="line106"
            style="
              fill: none;
              stroke: #1d1d1b;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcs-icon-st12"
            x1="401.60001"
            y1="283.29999"
            x2="401.60001"
            y2="293.29999"
            id="line108"
            style="
              fill: none;
              stroke: #1d1d1b;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcs-icon-st12"
            x1="429.29999"
            y1="283.29999"
            x2="429.29999"
            y2="293.29999"
            id="line110"
            style="
              fill: none;
              stroke: #1d1d1b;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcs-icon-st12"
            x1="415.60001"
            y1="283.29999"
            x2="415.60001"
            y2="293.29999"
            id="line112"
            style="
              fill: none;
              stroke: #1d1d1b;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vhd" viewBox="0 0 133.6 44.200013">
      <style type="text/css" id="style2">
        .pc-vhd-icon-st0 {
          fill: #e3e3e2;
        }

        .pc-vhd-icon-st1 {
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-linejoin: round;
        }

        .pc-vhd-icon-st2 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
        }

        .pc-vhd-icon-st3 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vhd-icon-st4 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.5;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vhd-icon-st5 {
          fill: #dadada;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }

        .pc-vhd-icon-st6 {
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vhd-icon-st7 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-miterlimit: 10;
        }

        .pc-vhd-icon-st8 {
          fill: none;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }

        .pc-vhd-icon-st9 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vhd-icon-st10 {
          fill: none;
          stroke: #1d1d1b;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }
      </style>
      <g id="g244" transform="translate(-350.6,-275.79999)">
        <g id="g92">
          <path
            class="pc-vhd-icon-st0"
            d="m 478,299.2 c 0,-7.9 -6.4,-14.3 -14.3,-14.3 -7.9,0 -14.3,6.4 -14.3,14.3 0,7.9 6.4,14.3 14.3,14.3 v 0 c 7.9,0 14.3,-6.4 14.3,-14.3 z"
            id="path4"
            style="fill: #e3e3e2"
          />
          <path
            class="pc-vhd-icon-st1"
            d="m 480.3,293.4 c -0.3,0 -0.5,0.2 -0.5,0.5 0,0.1 0,0.1 0,0.2 0.6,1.7 1,3.4 1.1,5.2 0.2,4.9 -1.5,9.7 -4.8,13.4 -3.5,3.8 -8.3,6 -13.4,6.3 H 462 c -5.6,0 -11,-2.3 -14.9,-6.4 l -0.3,-0.4 c -3.1,-3.6 -5,-8.1 -5.2,-12.8 -0.2,-5.5 1.7,-10.9 5.3,-15.1 l 0.3,-0.4 c 4,-4.3 9.6,-6.9 15.4,-7.1 h 20.6 v 16.7 z m -5.6,5.8 c 0,-6.1 -4.9,-11 -11,-11 -6.1,0 -11,4.9 -11,11 0,6.1 4.9,11 11,11 v 0 c 6.1,0 11,-4.9 11,-11 z"
            id="path6"
            style="
              fill: #b3b2b2;
              stroke: #1e1e1c;
              stroke-width: 2;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st2"
            d="m 471.4,299.2 c 0,-4.2 -3.4,-7.7 -7.7,-7.7 -4.3,0 -7.7,3.4 -7.7,7.7 0,4.3 3.4,7.7 7.7,7.7 4.3,0 7.7,-3.4 7.7,-7.7 z"
            id="path8"
            style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.69999999"
          />
          <path
            class="pc-vhd-icon-st0"
            d="m 478.8,299.2 c 0,-8.4 -6.8,-15.1 -15.1,-15.1 -8.4,0 -15.1,6.8 -15.1,15.1 0,8.3 6.8,15.1 15.1,15.1 v 0 c 8.4,0.1 15.1,-6.7 15.1,-15.1 z"
            id="path10"
            style="fill: #e3e3e2"
          />
          <circle
            class="pc-vhd-icon-st2"
            cx="463.60001"
            cy="299.20001"
            r="6"
            id="circle12"
            style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.69999999"
          />
          <path
            class="pc-vhd-icon-st3"
            d="m 463.7,315.1 c -8.8,0 -15.9,-7.1 -15.9,-15.9 0,-8.8 7.1,-15.9 15.9,-15.9 8.8,0 15.9,7.1 15.9,15.9 v 0 c 0,8.8 -7.1,15.9 -15.9,15.9 z m 15.1,-15.9 c 0,-8.4 -6.8,-15.1 -15.1,-15.1 -8.4,0 -15.1,6.8 -15.1,15.1 0,8.3 6.8,15.1 15.1,15.1 v 0 c 8.3,0.1 15.1,-6.7 15.1,-15.1 z"
            id="path14"
            style="
              fill: #1e1e1c;
              stroke: #1e1e1c;
              stroke-width: 0.69999999;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 465,284.1 c -1,0.4 -1.7,1.4 -1.5,2.5"
            id="path16"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 462.5,284.1 c -1,0.5 -1.4,1.7 -1,2.7"
            id="path18"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 460,284.5 c -0.9,0.7 -1.1,1.9 -0.6,2.8"
            id="path20"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 457.6,285.4 c -0.8,0.8 -0.8,2 -0.1,2.9"
            id="path22"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 455.4,286.5 c -0.6,0.9 -0.5,2.1 0.4,2.9"
            id="path24"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 453.5,288.1 c -0.5,1 -0.1,2.2 0.8,2.8"
            id="path26"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 451.8,289.9 c -0.3,1.1 0.3,2.2 1.3,2.6"
            id="path28"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 450.4,292 c -0.1,1.1 0.6,2.1 1.7,2.4"
            id="path30"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 449.4,294.3 c 0.1,1.1 1,1.9 2.1,2"
            id="path32"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 448.8,296.7 c 0.3,1.1 1.3,1.8 2.4,1.7"
            id="path34"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 448.6,299.2 c 0.4,1 1.5,1.5 2.6,1.3"
            id="path36"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 448.8,301.7 c 0.6,0.9 1.8,1.3 2.8,0.8"
            id="path38"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 449.4,304.1 c 0.7,0.8 2,1 2.9,0.4"
            id="path40"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 450.4,306.4 c 0.9,0.7 2.1,0.6 2.9,-0.1"
            id="path42"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 451.8,308.5 c 1,0.5 2.2,0.3 2.9,-0.6"
            id="path44"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 453.5,310.3 c 1,0.4 2.2,-0.1 2.7,-1.1"
            id="path46"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 455.4,311.9 c 1.1,0.2 2.1,-0.5 2.5,-1.5"
            id="path48"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 457.6,313.1 c 1.1,0 2,-0.8 2.2,-1.9"
            id="path50"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 460,313.9 c 1.1,-0.2 1.9,-1.1 1.9,-2.2"
            id="path52"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 462.4,314.3 c 1,-0.4 1.7,-1.4 1.5,-2.5"
            id="path54"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 464.9,314.3 c 1,-0.5 1.4,-1.7 1,-2.7"
            id="path56"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 467.4,313.9 c 0.9,-0.7 1.1,-1.9 0.6,-2.8"
            id="path58"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 469.8,313.1 c 0.8,-0.8 0.8,-2 0.1,-2.9"
            id="path60"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 472,311.9 c 0.6,-0.9 0.5,-2.1 -0.4,-2.9"
            id="path62"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 473.9,310.4 c 0.5,-1 0.1,-2.2 -0.8,-2.8"
            id="path64"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 475.6,308.5 c 0.3,-1.1 -0.3,-2.2 -1.3,-2.6"
            id="path66"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 477,306.4 c 0.1,-1.1 -0.6,-2.1 -1.7,-2.4"
            id="path68"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 478,304.1 c -0.1,-1.1 -1,-1.9 -2.1,-2"
            id="path70"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 478.6,301.7 c -0.3,-1.1 -1.3,-1.8 -2.4,-1.7"
            id="path72"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 478.8,299.2 c -0.4,-1 -1.5,-1.5 -2.6,-1.3"
            id="path74"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 478.6,296.7 c -0.6,-0.9 -1.8,-1.3 -2.8,-0.8"
            id="path76"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 478,294.3 c -0.8,-0.8 -2,-1 -2.9,-0.3"
            id="path78"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 477,292 c -0.9,-0.7 -2.1,-0.6 -2.9,0.1"
            id="path80"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 475.6,289.9 c -1,-0.5 -2.2,-0.3 -2.8,0.6"
            id="path82"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 474,288.1 c -1,-0.4 -2.2,0.1 -2.7,1.1"
            id="path84"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 472,286.5 c -1.1,-0.2 -2.1,0.5 -2.5,1.5"
            id="path86"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 469.8,285.4 c -1.1,0 -2,0.8 -2.2,1.9"
            id="path88"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vhd-icon-st4"
            d="m 467.4,284.5 c -1.1,0.2 -1.9,1.1 -1.9,2.2"
            id="path90"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
        </g>
        <path
          class="pc-vhd-icon-st0"
          d="m 356.8,299.2 c 0,7.9 6.4,14.3 14.3,14.3 v 0 c 7.9,0 14.3,-6.4 14.3,-14.3 0,-7.9 -6.4,-14.3 -14.3,-14.3 -7.9,0 -14.3,6.4 -14.3,14.3 z"
          id="path94"
          style="fill: #e3e3e2"
        />
        <path
          class="pc-vhd-icon-st1"
          d="m 351.6,293.5 v -16.7 h 20.6 c 5.8,0.2 11.4,2.8 15.4,7.1 l 0.3,0.4 c 3.6,4.2 5.5,9.6 5.3,15.1 -0.2,4.7 -2.1,9.2 -5.2,12.8 l -0.3,0.4 c -3.9,4.1 -9.3,6.4 -14.9,6.4 h -0.7 c -5.1,-0.3 -9.9,-2.5 -13.4,-6.3 -3.3,-3.7 -5,-8.5 -4.8,-13.4 0.1,-1.8 0.5,-3.5 1.1,-5.2 0,-0.1 0,-0.1 0,-0.2 0,-0.3 -0.2,-0.5 -0.5,-0.5 z m 8.5,5.7 c 0,6.1 4.9,11 11,11 v 0 c 6.1,0 11,-4.9 11,-11 0,-6.1 -4.9,-11 -11,-11 -6.1,0 -11,4.9 -11,11 z"
          id="path96"
          style="
            fill: #b3b2b2;
            stroke: #1e1e1c;
            stroke-width: 2;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st2"
          d="m 363.4,299.2 c 0,4.3 3.4,7.7 7.7,7.7 4.3,0 7.7,-3.4 7.7,-7.7 0,-4.3 -3.4,-7.7 -7.7,-7.7 -4.3,0 -7.7,3.5 -7.7,7.7 z"
          id="path98"
          style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.69999999"
        />
        <path
          class="pc-vhd-icon-st0"
          d="m 356,299.2 c 0,8.4 6.7,15.2 15.1,15.1 v 0 c 8.3,0 15.1,-6.8 15.1,-15.1 0,-8.3 -6.7,-15.1 -15.1,-15.1 -8.3,0 -15.1,6.7 -15.1,15.1 z"
          id="path100"
          style="fill: #e3e3e2"
        />
        <circle
          class="pc-vhd-icon-st2"
          cx="371.20001"
          cy="299.20001"
          r="6"
          id="circle102"
          style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.69999999"
        />
        <path
          class="pc-vhd-icon-st3"
          d="m 355.2,299.2 v 0 c 0,-8.8 7.1,-15.9 15.9,-15.9 8.8,0 15.9,7.1 15.9,15.9 0,8.8 -7.1,15.9 -15.9,15.9 -8.8,0 -15.9,-7.1 -15.9,-15.9 z m 0.8,0 v 0 c 0,8.4 6.8,15.2 15.1,15.1 v 0 c 8.3,0 15.1,-6.8 15.1,-15.1 0,-8.3 -6.7,-15.1 -15.1,-15.1 -8.3,0 -15.1,6.7 -15.1,15.1 z"
          id="path104"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 371.3,286.6 c 0.2,-1.1 -0.5,-2.1 -1.5,-2.5"
          id="path106"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 373.3,286.8 c 0.4,-1 0,-2.2 -1,-2.7"
          id="path108"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 375.4,287.3 c 0.5,-0.9 0.3,-2.1 -0.6,-2.8"
          id="path110"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 377.3,288.3 c 0.7,-0.9 0.7,-2.1 -0.1,-2.9"
          id="path112"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 379,289.4 c 0.9,-0.8 1,-2 0.4,-2.9"
          id="path114"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 380.5,290.9 c 0.9,-0.6 1.3,-1.8 0.8,-2.8"
          id="path116"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 381.7,292.5 c 1,-0.4 1.6,-1.5 1.3,-2.6"
          id="path118"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 382.7,294.4 c 1.1,-0.3 1.8,-1.3 1.7,-2.4"
          id="path120"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 383.3,296.3 c 1.1,-0.1 2,-0.9 2.1,-2"
          id="path122"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 383.6,298.4 c 1.1,0.1 2.1,-0.6 2.4,-1.7"
          id="path124"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 383.6,300.5 c 1.1,0.2 2.2,-0.3 2.6,-1.3"
          id="path126"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 383.2,302.5 c 1,0.5 2.2,0.1 2.8,-0.8"
          id="path128"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 382.5,304.5 c 0.9,0.6 2.2,0.4 2.9,-0.4"
          id="path130"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 381.5,306.3 c 0.8,0.7 2,0.8 2.9,0.1"
          id="path132"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 380.1,307.9 c 0.7,0.9 1.9,1.1 2.9,0.6"
          id="path134"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 378.6,309.2 c 0.5,1 1.7,1.5 2.7,1.1"
          id="path136"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 376.9,310.4 c 0.4,1 1.4,1.7 2.5,1.5"
          id="path138"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 375,311.2 c 0.2,1.1 1.1,1.9 2.2,1.9"
          id="path140"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 372.9,311.7 c 0,1.1 0.8,2 1.9,2.2"
          id="path142"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 370.9,311.8 c -0.2,1.1 0.5,2.1 1.5,2.5"
          id="path144"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 368.9,311.6 c -0.4,1 0,2.2 1,2.7"
          id="path146"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 366.8,311.1 c -0.5,0.9 -0.3,2.1 0.6,2.8"
          id="path148"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 364.9,310.2 c -0.7,0.9 -0.7,2.1 0.1,2.9"
          id="path150"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 363.2,309 c -0.9,0.8 -1,2 -0.4,2.9"
          id="path152"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 361.7,307.6 c -0.9,0.6 -1.3,1.8 -0.8,2.8"
          id="path154"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 360.5,305.9 c -1,0.4 -1.6,1.5 -1.3,2.6"
          id="path156"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 359.5,304 c -1.1,0.3 -1.8,1.3 -1.7,2.4"
          id="path158"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 358.9,302.1 c -1.1,0.1 -2,0.9 -2.1,2"
          id="path160"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 358.6,300 c -1.1,-0.1 -2.1,0.6 -2.4,1.7"
          id="path162"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 358.6,297.9 c -1.1,-0.2 -2.2,0.3 -2.6,1.3"
          id="path164"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 359,295.9 c -1,-0.5 -2.2,-0.1 -2.8,0.8"
          id="path166"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 359.7,294 c -0.9,-0.7 -2.1,-0.5 -2.9,0.3"
          id="path168"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 360.7,292.1 c -0.8,-0.7 -2,-0.8 -2.9,-0.1"
          id="path170"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 362,290.5 c -0.6,-0.9 -1.8,-1.1 -2.8,-0.6"
          id="path172"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 363.5,289.2 c -0.5,-1 -1.7,-1.5 -2.7,-1.1"
          id="path174"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 365.3,288 c -0.4,-1 -1.4,-1.7 -2.5,-1.5"
          id="path176"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 367.2,287.3 c -0.2,-1.1 -1.1,-1.9 -2.2,-1.9"
          id="path178"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vhd-icon-st4"
          d="m 369.3,286.7 c 0,-1.1 -0.8,-2 -1.9,-2.2"
          id="path180"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <rect
          x="401"
          y="276.79999"
          class="pc-vhd-icon-st5"
          width="32.799999"
          height="16.4"
          id="rect182"
          style="fill: #dadada; stroke: #1e1e1c; stroke-miterlimit: 10"
        />
        <rect
          x="401"
          y="293.39999"
          class="pc-vhd-icon-st6"
          width="32.799999"
          height="25.5"
          id="rect184"
          style="
            fill: #b3b2b2;
            stroke: #1e1e1c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vhd-icon-st7"
          x1="401"
          y1="276.79999"
          x2="433.70001"
          y2="276.79999"
          id="line186"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 2;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vhd-icon-st7"
          x1="401"
          y1="293.39999"
          x2="433.70001"
          y2="293.39999"
          id="line188"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 2;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vhd-icon-st7"
          x1="401"
          y1="319"
          x2="433.70001"
          y2="319"
          id="line190"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 2;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vhd-icon-st7"
          x1="401"
          y1="275.79999"
          x2="401"
          y2="320"
          id="line192"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 2;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vhd-icon-st8"
          x1="399.89999"
          y1="282.89999"
          x2="399.89999"
          y2="315.60001"
          id="line194"
          style="fill: none; stroke: #1e1e1c; stroke-miterlimit: 10"
        />
        <line
          class="pc-vhd-icon-st7"
          x1="433.70001"
          y1="275.79999"
          x2="433.70001"
          y2="320"
          id="line196"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 2;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vhd-icon-st8"
          x1="434.79999"
          y1="282.89999"
          x2="434.79999"
          y2="315.60001"
          id="line198"
          style="fill: none; stroke: #1e1e1c; stroke-miterlimit: 10"
        />
        <path
          class="pc-vhd-icon-st9"
          d="M 433.7,282.9"
          id="path200"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vhd-icon-st9"
          d="M 401,282.9"
          id="path202"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vhd-icon-st9"
          d="M 433.7,286.1"
          id="path204"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vhd-icon-st9"
          d="M 401,286.1"
          id="path206"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vhd-icon-st9"
          d="M 433.7,289.3"
          id="path208"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vhd-icon-st9"
          d="M 401,289.3"
          id="path210"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vhd-icon-st9"
          d="M 433.7,284.5"
          id="path212"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vhd-icon-st9"
          d="M 401,284.5"
          id="path214"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vhd-icon-st9"
          d="M 433.7,287.7"
          id="path216"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vhd-icon-st9"
          d="M 401,287.7"
          id="path218"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vhd-icon-st9"
          d="M 433.7,290.9"
          id="path220"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vhd-icon-st9"
          d="M 401,290.9"
          id="path222"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <g id="g236">
          <line
            class="pc-vhd-icon-st10"
            x1="403.20001"
            y1="282.79999"
            x2="431.29999"
            y2="282.79999"
            id="line224"
            style="
              fill: none;
              stroke: #1d1d1b;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vhd-icon-st10"
            x1="403.20001"
            y1="286"
            x2="431.29999"
            y2="286"
            id="line226"
            style="
              fill: none;
              stroke: #1d1d1b;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vhd-icon-st10"
            x1="403.20001"
            y1="289.20001"
            x2="431.29999"
            y2="289.20001"
            id="line228"
            style="
              fill: none;
              stroke: #1d1d1b;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vhd-icon-st10"
            x1="403.20001"
            y1="284.29999"
            x2="431.29999"
            y2="284.29999"
            id="line230"
            style="
              fill: none;
              stroke: #1d1d1b;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vhd-icon-st10"
            x1="403.20001"
            y1="287.5"
            x2="431.29999"
            y2="287.5"
            id="line232"
            style="
              fill: none;
              stroke: #1d1d1b;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vhd-icon-st10"
            x1="403.20001"
            y1="290.70001"
            x2="431.29999"
            y2="290.70001"
            id="line234"
            style="
              fill: none;
              stroke: #1d1d1b;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
        </g>
        <line
          class="pc-vhd-icon-st10"
          x1="403.5"
          y1="282.79999"
          x2="403.5"
          y2="292.79999"
          id="line238"
          style="
            fill: none;
            stroke: #1d1d1b;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vhd-icon-st10"
          x1="431.10001"
          y1="282.79999"
          x2="431.10001"
          y2="292.79999"
          id="line240"
          style="
            fill: none;
            stroke: #1d1d1b;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vhd-icon-st10"
          x1="417.5"
          y1="282.79999"
          x2="417.5"
          y2="292.79999"
          id="line242"
          style="
            fill: none;
            stroke: #1d1d1b;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
          "
        />
      </g>
    </symbol>

    <symbol id="product-category-vcd" viewBox="0 0 102.5 44.200013">
      <style type="text/css" id="style2">
        .pc-vcd-icon-st0 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vcd-icon-st1 {
          fill: #dadada;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }

        .pc-vcd-icon-st2 {
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vcd-icon-st3 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-miterlimit: 10;
        }

        .pc-vcd-icon-st4 {
          fill: none;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }

        .pc-vcd-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }

        .pc-vcd-icon-st6 {
          fill: #e3e3e2;
        }

        .pc-vcd-icon-st7 {
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-linejoin: round;
        }

        .pc-vcd-icon-st8 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
        }

        .pc-vcd-icon-st9 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vcd-icon-st10 {
          fill: #1e1e1c;
        }

        .pc-vcd-icon-st11 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.5;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vcd-icon-st12 {
          fill: none;
          stroke: #1d1d1b;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }
      </style>
      <path
        class="pc-vcd-icon-st0"
        d="M 102.50001,25.600014"
        id="path4"
        style="
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        "
      />
      <g id="g96" transform="translate(-377.79999,-275.79999)">
        <g id="g62">
          <rect
            x="379.10001"
            y="276.79999"
            class="pc-vcd-icon-st1"
            width="24.799999"
            height="16.4"
            id="rect6"
            style="fill: #dadada; stroke: #1e1e1c; stroke-miterlimit: 10"
          />
          <rect
            x="379.10001"
            y="293.39999"
            class="pc-vcd-icon-st2"
            width="24.799999"
            height="25.5"
            id="rect8"
            style="
              fill: #b3b2b2;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcd-icon-st3"
            x1="379.10001"
            y1="276.79999"
            x2="403.89999"
            y2="276.79999"
            id="line10"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 2;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcd-icon-st3"
            x1="379.10001"
            y1="293.39999"
            x2="403.89999"
            y2="293.39999"
            id="line12"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 2;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcd-icon-st3"
            x1="379.10001"
            y1="319"
            x2="403.89999"
            y2="319"
            id="line14"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 2;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcd-icon-st3"
            x1="379.10001"
            y1="275.79999"
            x2="379.10001"
            y2="320"
            id="line16"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 2;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcd-icon-st4"
            x1="378.29999"
            y1="283.70001"
            x2="378.29999"
            y2="314.29999"
            id="line18"
            style="fill: none; stroke: #1e1e1c; stroke-miterlimit: 10"
          />
          <line
            class="pc-vcd-icon-st3"
            x1="403.89999"
            y1="275.79999"
            x2="403.89999"
            y2="320"
            id="line20"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 2;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcd-icon-st4"
            x1="404.70001"
            y1="290.29999"
            x2="404.70001"
            y2="308.10001"
            id="line22"
            style="fill: none; stroke: #1e1e1c; stroke-miterlimit: 10"
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 403.9,283.7"
            id="path24"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 379.1,283.7"
            id="path26"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 403.9,286.9"
            id="path28"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 379.1,286.9"
            id="path30"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 403.9,290.1"
            id="path32"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 379.1,290.1"
            id="path34"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st6"
            d="m 457,299.2 c 0,-7.9 -6.4,-14.3 -14.3,-14.4 -7.9,-0.1 -14.3,6.4 -14.4,14.3 0,7.9 6.4,14.3 14.3,14.4 v 0 c 8,0 14.4,-6.4 14.4,-14.3 z"
            id="path36"
            style="fill: #e3e3e2"
          />
          <path
            class="pc-vcd-icon-st7"
            d="m 459.3,293.4 c -0.3,0 -0.5,0.2 -0.5,0.5 0,0.1 0,0.1 0,0.2 0.6,1.7 1,3.4 1.1,5.2 0.2,4.9 -1.5,9.7 -4.8,13.4 -3.5,3.8 -8.3,6 -13.4,6.3 h -0.8 c -5.6,0 -11,-2.3 -14.9,-6.4 l -0.3,-0.4 c -3.1,-3.6 -5,-8.1 -5.2,-12.8 -0.2,-5.5 1.7,-10.9 5.3,-15.1 l 0.3,-0.4 c 4,-4.3 9.6,-6.9 15.5,-7.1 h 20.6 v 16.7 z m -5.6,5.8 c 0,-6.1 -4.9,-11 -11,-11 -6.1,0 -11,4.9 -11,11 0,6.1 4.9,11 11,11 v 0 c 6.1,0 11,-4.9 11,-11 z"
            id="path38"
            style="
              fill: #b3b2b2;
              stroke: #1e1e1c;
              stroke-width: 2;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vcd-icon-st8"
            d="m 450.3,299.2 c 0,-4.2 -3.4,-7.7 -7.7,-7.7 -4.3,0 -7.7,3.4 -7.7,7.7 0,4.3 3.4,7.7 7.7,7.7 4.3,0 7.7,-3.4 7.7,-7.7 z"
            id="path40"
            style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.69999999"
          />
          <path
            class="pc-vcd-icon-st6"
            d="m 428.1,299 c 0,-8 6.5,-14.5 14.5,-14.5 8,0 14.5,6.5 14.5,14.5 0,8 -6.5,14.5 -14.5,14.5 v 0 c -8,0 -14.5,-6.5 -14.5,-14.5 z"
            id="path42"
            style="fill: #e3e3e2"
          />
          <circle
            class="pc-vcd-icon-st8"
            cx="442.70001"
            cy="298.89999"
            r="5.8000002"
            id="circle44"
            style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.69999999"
          />
          <path
            class="pc-vcd-icon-st9"
            d="m 427.4,299 c 0,-8.4 6.9,-15.3 15.3,-15.3 8.4,0 15.3,6.8 15.3,15.3 0,8.5 -6.9,15.3 -15.3,15.3 v 0 c -8.5,-0.1 -15.3,-6.9 -15.3,-15.3 z m 15.3,14.5 c 8,0 14.5,-6.5 14.5,-14.5 0,-8 -6.5,-14.5 -14.5,-14.5 -8,0 -14.5,6.5 -14.5,14.5 v 0 c -0.1,8 6.4,14.5 14.5,14.5 z"
            id="path46"
            style="
              fill: #1e1e1c;
              stroke: #1e1e1c;
              stroke-width: 0.69999999;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vcd-icon-st10"
            d="m 433.7,299 c 0,-5 4.1,-9.1 9.1,-9.1 5,0 9.1,4.1 9.1,9.1 0,5 -4.1,9.1 -9.1,9.1 v 0 c -5.1,-0.1 -9.1,-4.1 -9.1,-9.1 z m 9,8.6 c 4.8,0 8.6,-3.9 8.6,-8.6 0,-4.7 -3.9,-8.6 -8.6,-8.6 -4.8,0 -8.6,3.9 -8.6,8.6 v 0 c 0,4.7 3.9,8.6 8.6,8.6 z"
            id="path48"
            style="fill: #1e1e1c"
          />
          <path
            class="pc-vcd-icon-st11"
            d="m 428.3,297.7 c 1.9,-4.2 6.1,-6.2 9.9,-4.5"
            id="path50"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vcd-icon-st11"
            d="m 435.6,286.3 c 4.6,-0.8 8.5,1.6 9.3,5.7"
            id="path52"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vcd-icon-st11"
            d="m 457.2,298.6 c -1.4,4.4 -5.4,6.8 -9.3,5.7"
            id="path54"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vcd-icon-st11"
            d="m 450.2,286.6 c 3,3.6 2.9,8.2 -0.3,10.9"
            id="path56"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vcd-icon-st11"
            d="m 433.6,310.3 c -2.5,-3.9 -1.7,-8.5 1.8,-10.8"
            id="path58"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <path
            class="pc-vcd-icon-st11"
            d="m 448.9,312.1 c -4.6,0.5 -8.4,-2.3 -8.9,-6.4"
            id="path60"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 1.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
        </g>
        <g id="g94">
          <path
            class="pc-vcd-icon-st5"
            d="M 403.9,282.5"
            id="path64"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 379.1,282.5"
            id="path66"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 403.9,285.8"
            id="path68"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 379.1,285.8"
            id="path70"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 403.9,289.1"
            id="path72"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 379.1,289.1"
            id="path74"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 403.9,284.1"
            id="path76"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 379.1,284.1"
            id="path78"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 403.9,287.4"
            id="path80"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 379.1,287.4"
            id="path82"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 403.9,290.7"
            id="path84"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <path
            class="pc-vcd-icon-st5"
            d="M 379.1,290.7"
            id="path86"
            style="
              fill: none;
              stroke: #1e1e1c;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcd-icon-st12"
            x1="381"
            y1="282.70001"
            x2="401.89999"
            y2="282.70001"
            id="line88"
            style="
              fill: none;
              stroke: #1d1d1b;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcd-icon-st12"
            x1="381.20001"
            y1="282.5"
            x2="381.20001"
            y2="292.60001"
            id="line90"
            style="
              fill: none;
              stroke: #1d1d1b;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
          <line
            class="pc-vcd-icon-st12"
            x1="401.70001"
            y1="282.5"
            x2="401.70001"
            y2="292.60001"
            id="line92"
            style="
              fill: none;
              stroke: #1d1d1b;
              stroke-width: 0.5;
              stroke-miterlimit: 10;
            "
          />
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vhs" viewBox="0 0 84 45">
      <g clip-path="url(#clip0_1489_37106)">
        <path
          d="M78.7001 23.4001C78.7001 15.5001 72.3001 9.1001 64.4001 9.1001C56.5001 9.1001 50.1001 15.5001 50.1001 23.4001C50.1001 31.3001 56.5001 37.7001 64.4001 37.7001C72.3001 37.7001 78.7001 31.3001 78.7001 23.4001Z"
          fill="#E3E3E2"
        />
        <path
          d="M80.9999 17.6C80.6999 17.6 80.4999 17.8 80.4999 18.1C80.4999 18.1 80.4999 18.2 80.4999 18.3C81.0999 20 81.4999 21.7 81.5999 23.5C81.7999 28.4 80.0999 33.2 76.7999 36.9C73.2999 40.7 68.4999 42.9 63.3999 43.2H62.5999C56.9999 43.2 51.5999 40.9 47.6999 36.8L47.3999 36.4C44.2999 32.8 42.3999 28.3 42.1999 23.6C41.9999 18.1 43.8999 12.7 47.4999 8.5L47.7999 8.10001C51.7999 3.80001 57.3999 1.2 63.2999 1H83.8999V17.7L80.9999 17.6ZM75.3999 23.4C75.3999 17.3 70.4999 12.4 64.3999 12.4C58.2999 12.4 53.3999 17.3 53.3999 23.4C53.3999 29.5 58.2999 34.4 64.3999 34.4C70.4999 34.4 75.3999 29.5 75.3999 23.4Z"
          fill="#B3B2B2"
          stroke="#1E1E1C"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M72.0001 23.4C72.0001 19.2 68.6001 15.7 64.3001 15.7C60.0001 15.7 56.6001 19.1 56.6001 23.4C56.6001 27.7 60.0001 31.0999 64.3001 31.0999C68.6001 31.0999 72.0001 27.7 72.0001 23.4Z"
          fill="#1E1E1C"
          stroke="#1E1E1C"
          stroke-width="0.7"
        />
        <path
          d="M79.4998 23.4001C79.4998 15.0001 72.6998 8.30005 64.3998 8.30005C55.9998 8.30005 49.2998 15.1001 49.2998 23.4001C49.2998 31.8001 56.0998 38.5 64.3998 38.5C72.6998 38.5 79.4998 31.8001 79.4998 23.4001Z"
          fill="#E3E3E2"
        />
        <path
          d="M64.2998 29.3999C67.6135 29.3999 70.2998 26.7136 70.2998 23.3999C70.2998 20.0862 67.6135 17.3999 64.2998 17.3999C60.9861 17.3999 58.2998 20.0862 58.2998 23.3999C58.2998 26.7136 60.9861 29.3999 64.2998 29.3999Z"
          fill="#1E1E1C"
          stroke="#1E1E1C"
          stroke-width="0.7"
        />
        <path
          d="M64.4 39.3C55.6 39.3 48.5 32.2 48.5 23.4C48.5 14.6 55.6 7.5 64.4 7.5C73.2 7.5 80.3 14.6 80.3 23.4C80.3 32.2 73.2 39.3 64.4 39.3ZM79.5 23.4C79.5 15 72.7 8.30002 64.4 8.30002C56 8.30002 49.3 15.1 49.3 23.4C49.3 31.8 56.1 38.5 64.4 38.5C72.7 38.5 79.5 31.8 79.5 23.4Z"
          fill="#1E1E1C"
          stroke="#1E1E1C"
          stroke-width="0.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M65.6001 8.30005C64.6001 8.70005 63.9001 9.70005 64.1001 10.8"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M63.1001 8.30005C62.1001 8.80005 61.7001 10 62.1001 11"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M60.6999 8.69995C59.7999 9.39995 59.5999 10.5999 60.0999 11.5999"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M58.3001 9.5C57.5001 10.3 57.5001 11.5 58.2001 12.4"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M56.0999 10.7C55.4999 11.6 55.5999 12.7999 56.4999 13.5999"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M54.1001 12.3C53.6001 13.3 54.0002 14.5 54.9002 15.1"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M52.4002 14.1001C52.1002 15.2001 52.7002 16.3001 53.7002 16.7001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M51.0998 16.2C50.9998 17.3 51.6998 18.2999 52.7998 18.5999"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M50.1001 18.5C50.2001 19.6 51.1001 20.5 52.2001 20.5"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M49.3999 21C49.6999 22.1 50.6999 22.8 51.7999 22.7"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M49.2002 23.3999C49.6002 24.3999 50.7002 24.8999 51.8002 24.6999"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M49.3999 25.8999C49.9999 26.7999 51.1999 27.1999 52.1999 26.6999"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M50.1001 28.3C50.8001 29.1 52.1001 29.3 53.0001 28.7"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M51.1001 30.6C52.0001 31.3 53.2001 31.2 54.0001 30.5"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M52.3999 32.7001C53.3999 33.2001 54.5999 33.0001 55.1999 32.1001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M54.1001 34.4999C55.1001 34.8999 56.3001 34.3999 56.8001 33.3999"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M56.1001 36.1001C57.2001 36.3001 58.2001 35.6001 58.6001 34.6001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M58.2998 37.2999C59.3998 37.2999 60.2998 36.4999 60.4998 35.3999"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M60.6001 38.0999C61.7001 37.8999 62.5001 36.9999 62.5001 35.8999"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M63.1001 38.5C64.1001 38.1 64.8001 37.1 64.6001 36"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M65.6001 38.5C66.6001 38 67.0001 36.8 66.6001 35.8"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M68.1001 38.0999C69.0001 37.3999 69.2001 36.2 68.7001 35.2"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M70.3999 37.2999C71.1999 36.4999 71.1999 35.2999 70.4999 34.3999"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M72.6002 36.0999C73.2002 35.1999 73.1002 34 72.2002 33.2"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M74.5998 34.6C75.0998 33.6 74.6998 32.4 73.7998 31.8"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M76.3 32.7001C76.6 31.6001 76 30.5001 75 30.1001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M77.7 30.5999C77.8 29.4999 77.1 28.5 76 28.2"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M78.7001 28.3C78.6001 27.2 77.7001 26.3 76.6001 26.3"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M79.2999 26C78.9999 24.9 77.9999 24.2 76.8999 24.3"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M79.4999 23.4C79.0999 22.4 77.9999 21.8 76.8999 22.1"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M79.3 21C78.7 20.1 77.5 19.7 76.5 20.2"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M78.6998 18.5001C77.8998 17.7001 76.6998 17.5001 75.7998 18.1001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M77.6998 16.2001C76.7998 15.5001 75.5998 15.6001 74.7998 16.3001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M76.3 14.0999C75.3 13.5999 74.1 13.7999 73.5 14.6999"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M74.5999 12.2999C73.5999 11.8999 72.3999 12.4 71.8999 13.4"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M72.7002 10.7C71.6002 10.5 70.6002 11.2 70.2002 12.2"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M70.4998 9.6001C69.3998 9.6001 68.4998 10.4001 68.2998 11.5001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M68.1002 8.69995C67.0002 8.89995 66.2002 9.79996 66.2002 10.9"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.5998 1H0.799805V17.4H25.5998V1Z"
          fill="#DADADA"
          stroke="#1E1E1C"
          stroke-miterlimit="10"
        />
        <path
          d="M25.5998 17.6001H0.799805V43.1001H25.5998V17.6001Z"
          fill="#B3B2B2"
          stroke="#1E1E1C"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M0.799805 1H25.5998"
          stroke="#1E1E1C"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M0.799805 17.6001H25.5998"
          stroke="#1E1E1C"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M0.799805 43.2H25.5998"
          stroke="#1E1E1C"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M0.799805 0V44.2"
          stroke="#1E1E1C"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path d="M0 7.1001V39.8001" stroke="#1E1E1C" stroke-miterlimit="10" />
        <path
          d="M25.6001 0V44.2"
          stroke="#1E1E1C"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path d="M26.3999 14.5V32.3" stroke="#1E1E1C" stroke-miterlimit="10" />
        <path
          d="M2.7002 7.30005H23.6002"
          stroke="#1D1D1B"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M2.7002 10.5H23.6002"
          stroke="#1D1D1B"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M2.7002 13.7H23.6002"
          stroke="#1D1D1B"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M2.7002 8.90015H23.6002"
          stroke="#1D1D1B"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M2.7002 12.1001H23.6002"
          stroke="#1D1D1B"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M2.7002 15.3H23.6002"
          stroke="#1D1D1B"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M2.8999 7.1001V16.9001"
          stroke="#1D1D1B"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M23.3999 7.1001V16.9001"
          stroke="#1D1D1B"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_1489_37106">
          <rect width="83.9" height="44.2" fill="white" />
        </clipPath>
      </defs>
    </symbol>

    <symbol id="product-category-vbh" viewBox="0 0 93.61131 61.254976">
      <style type="text/css" id="style2">
        .pc-vbh-icon-st0 {
          fill: #3d3d3b;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vbh-icon-st1 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.5;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vbh-icon-st2 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.5;
          stroke-linejoin: round;
        }

        .pc-vbh-icon-st3 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.7;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vbh-icon-st4 {
          fill: #e3e3e2;
        }

        .pc-vbh-icon-st5 {
          fill: #b3b2b2;
        }

        .pc-vbh-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vbh-icon-st7 {
          fill: #1e1e1c;
        }

        .pc-vbh-icon-st8 {
          fill: none;
        }

        .pc-vbh-icon-st9 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vbh-icon-st10 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
          stroke-miterlimit: 4.0001;
        }

        .pc-vbh-icon-st11 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vbh-icon-st12 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vbh-icon-st13 {
          fill: none;
          stroke: #ffffff;
          stroke-width: 3;
          stroke-miterlimit: 10;
        }

        .pc-vbh-icon-st14 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.5;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        }

        .pc-vbh-icon-st15 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vbh-icon-st16 {
          fill: #223a8f;
        }
      </style>
      <g id="g134" transform="translate(-373.74934,-267.23933)">
        <rect
          x="374.5"
          y="290"
          class="pc-vbh-icon-st0"
          width="1.7"
          height="15.9"
          id="rect4"
          style="
            fill: #3d3d3b;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vbh-icon-st1"
          x1="374.5"
          y1="300"
          x2="374.5"
          y2="278.60001"
          id="line6"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st2"
          x1="377.89999"
          y1="275.20001"
          x2="393.89999"
          y2="275.20001"
          id="line8"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st3"
          x1="393.89999"
          y1="302.29999"
          x2="393.89999"
          y2="269.29999"
          id="line10"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st1"
          d="m 377.9,275.2 c -1.9,0 -3.3,1.5 -3.4,3.3"
          id="path12"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st1"
          d="m 374.5,317 c 0,1.8 1.5,3.3 3.3,3.3"
          id="path14"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st3"
          x1="393.89999"
          y1="293.29999"
          x2="393.89999"
          y2="326.29999"
          id="line16"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st2"
          x1="377.89999"
          y1="320.39999"
          x2="394"
          y2="320.39999"
          id="line18"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st1"
          x1="374.5"
          y1="295.89999"
          x2="374.5"
          y2="317"
          id="line20"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st4"
          d="m 377.3,278.3 v 39.1 h 9.6 v -3 c 0,-1.9 1.5,-3.4 3.4,-3.4 h 0.4 v -26.4 h -0.4 c -1.9,0 -3.4,-1.5 -3.4,-3.4 v -3 z"
          id="path22"
          style="fill: #e3e3e2"
        />
        <path
          class="pc-vbh-icon-st5"
          d="m 387.2,282.7 c -0.2,-0.5 -0.3,-0.9 -0.3,-1.4 v -3 h -9.6 v 12.6 c 4.9,0.2 9.2,-3.3 9.9,-8.2 z"
          id="path24"
          style="fill: #b3b2b2"
        />
        <path
          class="pc-vbh-icon-st5"
          d="m 387.2,313 c -0.2,0.5 -0.3,0.9 -0.3,1.4 v 3 h -9.6 v -12.7 c 4.9,-0.3 9.2,3.3 9.9,8.3 z"
          id="path26"
          style="fill: #b3b2b2"
        />
        <line
          class="pc-vbh-icon-st6"
          x1="386.79999"
          y1="278.29999"
          x2="377.29999"
          y2="278.29999"
          id="line28"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st6"
          x1="377.29999"
          y1="278"
          x2="377.29999"
          y2="292.79999"
          id="line30"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st6"
          x1="386.79999"
          y1="278"
          x2="386.79999"
          y2="281.20001"
          id="line32"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st6"
          x1="390.60001"
          y1="284.60001"
          x2="390.20001"
          y2="284.60001"
          id="line34"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st6"
          d="m 386.9,281.2 c 0,1.9 1.5,3.4 3.4,3.4"
          id="path36"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st6"
          d="m 377.3,290.9 c 5,0.3 9.3,-3.3 9.9,-8.3"
          id="path38"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st6"
          x1="390.60001"
          y1="284.60001"
          x2="390.60001"
          y2="297.79999"
          id="line40"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st6"
          x1="390.60001"
          y1="311.10001"
          x2="390.60001"
          y2="297.79999"
          id="line42"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st6"
          d="m 387.2,313 c -0.6,-4.9 -4.9,-8.5 -9.9,-8.3"
          id="path44"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st6"
          d="m 390.2,311.1 c -1.9,0 -3.4,1.5 -3.4,3.4"
          id="path46"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st6"
          x1="390.60001"
          y1="311.10001"
          x2="390.20001"
          y2="311.10001"
          id="line48"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st6"
          x1="386.79999"
          y1="317.70001"
          x2="386.79999"
          y2="314.39999"
          id="line50"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st6"
          x1="377.29999"
          y1="317.70001"
          x2="377.29999"
          y2="302.79999"
          id="line52"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st6"
          x1="386.79999"
          y1="317.39999"
          x2="377.29999"
          y2="317.39999"
          id="line54"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st7"
          d="m 384.3,295.9 -0.6,-1.5 -2,-1.3 h -0.8 v -0.3 h -4 v 0.5 H 375 c -0.2,0 -0.4,0.1 -0.4,0.3 v 0 8.2 c 0,0.2 0.2,0.4 0.4,0.3 v 0 h 1.9 v 0.5 h 4 v -0.2 h 0.8 l 2,-1.3 0.6,-1.5 z"
          id="path56"
          style="fill: #1e1e1c"
        />
        <line
          class="pc-vbh-icon-st8"
          x1="390.10001"
          y1="310.5"
          x2="390.10001"
          y2="285.20001"
          id="line58"
          style="fill: none"
        />
        <path
          class="pc-vbh-icon-st5"
          d="m 390.1,310.4 c 2.1,0 3.8,1.7 3.8,3.8 v 0 -32.8 0 c 0,2.1 -1.7,3.8 -3.8,3.8"
          id="path60"
          style="fill: #b3b2b2"
        />
        <line
          class="pc-vbh-icon-st6"
          x1="393.89999"
          y1="319.29999"
          x2="393.89999"
          y2="276.39999"
          id="line62"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st6"
          x1="390.10001"
          y1="310.5"
          x2="390.10001"
          y2="285.20001"
          id="line64"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st6"
          d="m 393.9,314.2 c 0,-2.1 -1.7,-3.8 -3.8,-3.8"
          id="path66"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st6"
          d="m 393.9,281.4 c 0,2.1 -1.7,3.8 -3.8,3.8"
          id="path68"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st3"
          x1="392.29999"
          y1="269.29999"
          x2="393.89999"
          y2="269.29999"
          id="line70"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st3"
          x1="392.29999"
          y1="326.29999"
          x2="393.89999"
          y2="326.29999"
          id="line72"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st9"
          d="M 386.8,277.8"
          id="path74"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbh-icon-st9"
          d="M 386.8,317.8"
          id="path76"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbh-icon-st9"
          d="M 373.9,307.9"
          id="path78"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbh-icon-st9"
          d="M 394.1,307.9"
          id="path80"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <rect
          x="408.20001"
          y="269.29999"
          class="pc-vbh-icon-st5"
          width="57"
          height="57"
          id="rect82"
          style="fill: #b3b2b2"
        />
        <path
          class="pc-vbh-icon-st4"
          d="m 417.6,297.8 c 0,-10.5 8.5,-18.9 18.9,-18.9 10.4,0 18.9,8.5 18.9,18.9 0,10.4 -8.5,18.9 -18.9,18.9 v 0 c -10.4,0 -18.9,-8.5 -18.9,-18.9 z"
          id="path84"
          style="fill: #e3e3e2"
        />
        <circle
          class="pc-vbh-icon-st10"
          cx="436.60001"
          cy="297.70001"
          id="ellipse86"
          r="7.5"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-miterlimit: 4.00010014;
          "
        />
        <path
          class="pc-vbh-icon-st11"
          d="m 416.6,297.8 c 0,-11 8.9,-19.9 19.9,-19.9 11,0 19.9,8.9 19.9,19.9 0,11 -8.9,19.9 -19.9,19.9 v 0 c -11,0 -19.9,-8.9 -19.9,-19.9 0,0 0,0 0,0 z m 19.9,18.9 c 10.5,0 18.9,-8.5 18.9,-18.9 0,-10.4 -8.5,-18.9 -18.9,-18.9 -10.4,0 -18.9,8.5 -18.9,18.9 v 0 c 0,10.4 8.5,18.9 18.9,18.9 z"
          id="path88"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st7"
          d="m 424.8,297.8 c 0,-6.5 5.3,-11.8 11.8,-11.8 6.5,0 11.8,5.3 11.8,11.8 0,6.5 -5.3,11.8 -11.8,11.8 -6.5,0 -11.8,-5.3 -11.8,-11.8 0,0 0,0 0,0 z m 11.8,11.2 c 6.2,0 11.2,-5 11.2,-11.2 0,-6.2 -5,-11.2 -11.2,-11.2 -6.2,0 -11.2,5 -11.2,11.2 v 0 c 0,6.2 5,11.2 11.2,11.2 0,0 0,0 0,0 z"
          id="path90"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vbh-icon-st1"
          d="m 417.8,296.2 c 2.5,-5.5 8,-8 12.9,-5.9"
          id="path92"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st1"
          d="m 427.4,281.4 c 6,-1 11.1,2.1 12.2,7.4"
          id="path94"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st1"
          d="m 455.5,297.3 c -1.8,5.8 -7,8.9 -12.2,7.4"
          id="path96"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st1"
          d="m 446.3,281.6 c 3.9,4.6 3.7,10.7 -0.3,14.2"
          id="path98"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st1"
          d="m 424.8,312.5 c -3.2,-5.1 -2.2,-11.1 2.3,-14"
          id="path100"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbh-icon-st1"
          d="m 444.5,314.9 c -6,0.6 -10.9,-2.9 -11.6,-8.3"
          id="path102"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbh-icon-st12"
          x1="408.20001"
          y1="270.89999"
          x2="408.20001"
          y2="324.79999"
          id="line104"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vbh-icon-st12"
          x1="465.20001"
          y1="270.89999"
          x2="465.20001"
          y2="324.79999"
          id="line106"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vbh-icon-st12"
          x1="409.79999"
          y1="269.29999"
          x2="463.60001"
          y2="269.29999"
          id="line108"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vbh-icon-st12"
          x1="409.79999"
          y1="326.29999"
          x2="463.60001"
          y2="326.29999"
          id="line110"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vbh-icon-st13"
          x1="408.70001"
          y1="268.29999"
          x2="407.20001"
          y2="269.89999"
          id="line112"
          style="
            fill: none;
            stroke: #ffffff;
            stroke-width: 3;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vbh-icon-st13"
          x1="464.70001"
          y1="268.29999"
          x2="466.20001"
          y2="269.79999"
          id="line114"
          style="
            fill: none;
            stroke: #ffffff;
            stroke-width: 3;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vbh-icon-st13"
          x1="466.29999"
          y1="325.89999"
          x2="464.79999"
          y2="327.39999"
          id="line116"
          style="
            fill: none;
            stroke: #ffffff;
            stroke-width: 3;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vbh-icon-st13"
          x1="408.70001"
          y1="327.39999"
          x2="407.10001"
          y2="325.89999"
          id="line118"
          style="
            fill: none;
            stroke: #ffffff;
            stroke-width: 3;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbh-icon-st9"
          d="M 408,294.8"
          id="path120"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbh-icon-st9"
          d="M 465.5,294.8"
          id="path122"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbh-icon-st9"
          d="M 435.9,326.6"
          id="path124"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbh-icon-st9"
          d="M 433.9,269.1"
          id="path126"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vbh-icon-st14"
          x1="414"
          y1="275.29999"
          x2="459.10001"
          y2="320.39999"
          id="line128"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vbh-icon-st14"
          x1="414"
          y1="320.39999"
          x2="459.10001"
          y2="275.29999"
          id="line130"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <rect
          x="428.79999"
          y="290"
          class="pc-vbh-icon-st0"
          width="15.9"
          height="15.9"
          id="rect132"
          style="
            fill: #3d3d3b;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
      </g>
      <g id="g144" transform="translate(-373.74934,-267.23933)">
        <g id="g142">
          <line
            class="pc-vbh-icon-st15"
            x1="382.5"
            y1="325"
            x2="382.39999"
            y2="318.5"
            id="line136"
            style="
              fill: none;
              stroke: #223a8f;
              stroke-width: 0.60000002;
              stroke-miterlimit: 10;
            "
          />
          <g id="g140">
            <polygon
              class="pc-vbh-icon-st16"
              points="382.5,327.4 380.9,324.6 384.2,324.6 "
              id="polygon138"
              style="fill: #223a8f"
            />
          </g>
        </g>
      </g>
      <g id="g154" transform="translate(-373.74934,-267.23933)">
        <g id="g152">
          <line
            class="pc-vbh-icon-st15"
            x1="382.39999"
            y1="270.39999"
            x2="382.39999"
            y2="276.89999"
            id="line146"
            style="
              fill: none;
              stroke: #223a8f;
              stroke-width: 0.60000002;
              stroke-miterlimit: 10;
            "
          />
          <g id="g150">
            <polygon
              class="pc-vbh-icon-st16"
              points="382.4,268 384,270.8 380.7,270.8 "
              id="polygon148"
              style="fill: #223a8f"
            />
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vbs" viewBox="0 0 61.15 40.708554">
      <style type="text/css" id="style2">
        .pc-vbs-icon-st0 {
          fill: #e3e3e2;
        }

        .pc-vbs-icon-st1 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
        }

        .pc-vbs-icon-st2 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vbs-icon-st3 {
          fill: #1e1e1c;
        }

        .pc-vbs-icon-st4 {
          fill: #b3b2b2;
        }

        .pc-vbs-icon-st5 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vbs-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.5;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      </style>
      <g id="g60" transform="translate(-390,-277.49572)">
        <path
          class="pc-vbs-icon-st0"
          d="m 411.9,297.8 c 0,-10.5 8.5,-18.9 18.9,-18.9 10.4,0 18.9,8.5 18.9,18.9 0,10.4 -8.5,18.9 -18.9,18.9 v 0 c -10.4,0 -18.9,-8.4 -18.9,-18.9 z"
          id="path4"
          style="fill: #e3e3e2"
        />
        <circle
          class="pc-vbs-icon-st1"
          cx="430.89999"
          cy="297.79999"
          r="7.5"
          id="circle6"
          style="fill: #1e1e1c; stroke: #1e1e1c; stroke-width: 0.69999999"
        />
        <path
          class="pc-vbs-icon-st2"
          d="m 411,297.8 c 0,-11 8.9,-19.9 19.9,-19.9 11,0 19.9,8.9 19.9,19.9 0,11 -8.9,19.9 -19.9,19.9 -11,0 -19.9,-8.9 -19.9,-19.9 z m 19.9,18.9 c 10.5,0 18.9,-8.5 18.9,-18.9 0,-10.4 -8.5,-18.9 -18.9,-18.9 -10.4,0 -18.9,8.5 -18.9,18.9 v 0 c -0.1,10.5 8.4,18.9 18.9,18.9 0,0 0,0 0,0 z"
          id="path8"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbs-icon-st3"
          d="m 419.1,297.9 c 0,-6.5 5.3,-11.8 11.8,-11.8 6.5,0 11.8,5.3 11.8,11.8 0,6.5 -5.3,11.8 -11.8,11.8 v 0 c -6.5,-0.1 -11.8,-5.3 -11.8,-11.8 z m 11.8,11.2 c 6.2,0 11.2,-5 11.2,-11.2 0,-6.2 -5,-11.2 -11.2,-11.2 -6.2,0 -11.2,5 -11.2,11.2 v 0 c 0,6.1 5,11.2 11.2,11.2 z"
          id="path10"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vbs-icon-st0"
          d="m 392.7,278.1 v 39.6 h 9.7 v -3 c 0,-1.9 1.5,-3.4 3.4,-3.4 h 0.4 v -26.8 h -0.4 c -1.9,0 -3.4,-1.5 -3.4,-3.4 v 0 -3 z"
          id="path12"
          style="fill: #e3e3e2"
        />
        <path
          class="pc-vbs-icon-st4"
          d="m 402.7,282.5 c -0.2,-0.5 -0.3,-1 -0.3,-1.5 v -3 h -9.7 v 12.8 c 5,0.4 9.4,-3.3 10,-8.3 z"
          id="path14"
          style="fill: #b3b2b2"
        />
        <path
          class="pc-vbs-icon-st4"
          d="m 402.7,313.2 c -0.2,0.5 -0.3,1 -0.3,1.5 v 3 h -9.7 v -12.8 c 5,-0.4 9.4,3.3 10,8.3 z"
          id="path16"
          style="fill: #b3b2b2"
        />
        <line
          class="pc-vbs-icon-st5"
          x1="402.39999"
          y1="278"
          x2="392.70001"
          y2="278"
          id="line18"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbs-icon-st5"
          x1="392.70001"
          y1="277.79999"
          x2="392.70001"
          y2="292.79999"
          id="line20"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbs-icon-st5"
          x1="402.39999"
          y1="277.79999"
          x2="402.39999"
          y2="281"
          id="line22"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbs-icon-st5"
          x1="406.20001"
          y1="284.39999"
          x2="405.79999"
          y2="284.39999"
          id="line24"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbs-icon-st5"
          d="m 402.4,281.1 c 0,1.9 1.5,3.4 3.4,3.4 v 0"
          id="path26"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbs-icon-st5"
          d="m 392.7,290.9 c 5,0.3 9.4,-3.4 10,-8.4"
          id="path28"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbs-icon-st5"
          x1="406.20001"
          y1="284.39999"
          x2="406.20001"
          y2="297.89999"
          id="line30"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbs-icon-st5"
          x1="406.20001"
          y1="311.29999"
          x2="406.20001"
          y2="297.89999"
          id="line32"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbs-icon-st5"
          d="m 402.7,313.2 c -0.6,-5 -5,-8.6 -10,-8.4"
          id="path34"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbs-icon-st5"
          d="m 405.8,311.3 c -1.9,0 -3.4,1.5 -3.4,3.4"
          id="path36"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbs-icon-st5"
          x1="406.20001"
          y1="311.29999"
          x2="405.79999"
          y2="311.29999"
          id="line38"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbs-icon-st5"
          x1="402.39999"
          y1="317.89999"
          x2="402.39999"
          y2="314.60001"
          id="line40"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbs-icon-st5"
          x1="392.70001"
          y1="317.89999"
          x2="392.70001"
          y2="302.89999"
          id="line42"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbs-icon-st5"
          x1="402.39999"
          y1="317.60001"
          x2="392.70001"
          y2="317.60001"
          id="line44"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbs-icon-st3"
          d="m 399.8,295.9 -0.6,-1.5 -2,-1.3 h -0.9 v -0.3 h -4 v 0.5 h -1.9 c -0.2,0 -0.4,0.1 -0.4,0.3 v 8.4 c 0,0.2 0.2,0.4 0.4,0.3 h 1.9 v 0.5 h 4 v -0.3 h 0.9 l 2,-1.3 0.6,-1.5 z"
          id="path46"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vbs-icon-st6"
          d="m 412.1,296.2 c 2.5,-5.5 8,-8 12.9,-5.9"
          id="path48"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbs-icon-st6"
          d="m 421.7,281.4 c 6,-1 11.1,2.1 12.2,7.4"
          id="path50"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbs-icon-st6"
          d="m 449.8,297.3 c -1.8,5.8 -7,8.9 -12.2,7.4"
          id="path52"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbs-icon-st6"
          d="m 440.7,281.7 c 3.9,4.6 3.7,10.7 -0.3,14.2"
          id="path54"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbs-icon-st6"
          d="m 419.1,312.6 c -3.2,-5.1 -2.2,-11.1 2.3,-14"
          id="path56"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbs-icon-st6"
          d="m 438.9,315 c -6,0.6 -10.9,-2.9 -11.6,-8.3"
          id="path58"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
      </g>
    </symbol>

    <!--     Started HERE for some reason-->
    <symbol id="product-category-vbf" viewBox="0 0 92.400006 61.220224">
      <style type="text/css" id="style2">
        .pc-vbf-icon-st0 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 4;
          stroke-linecap: square;
          stroke-linejoin: round;
        }

        .pc-vbf-icon-st1 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 3;
          stroke-linejoin: round;
        }

        .pc-vbf-icon-st2 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 2;
          stroke-linecap: square;
          stroke-linejoin: round;
        }

        .pc-vbf-icon-st3 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.5;
          stroke-linejoin: round;
        }

        .pc-vbf-icon-st4 {
          fill: #e3e3e2;
        }

        .pc-vbf-icon-st5 {
          fill: #b3b2b2;
        }

        .pc-vbf-icon-st6 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vbf-icon-st7 {
          fill: #1e1e1c;
        }

        .pc-vbf-icon-st8 {
          fill: none;
        }

        .pc-vbf-icon-st9 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vbf-icon-st10 {
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vbf-icon-st11 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
          stroke-miterlimit: 4.0001;
        }

        .pc-vbf-icon-st12 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vbf-icon-st13 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.5;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vbf-icon-st14 {
          fill: none;
          stroke: #ffffff;
          stroke-width: 3;
          stroke-miterlimit: 10;
        }

        .pc-vbf-icon-st15 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.5;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        }

        .pc-vbf-icon-st16 {
          fill: #3d3d3b;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vbf-icon-st17 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vbf-icon-st18 {
          fill: #585857;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }

        .pc-vbf-icon-st19 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vbf-icon-st20 {
          fill: #223a8f;
        }
      </style>
      <g id="g150" transform="translate(-374.39999,-267.27408)">
        <line
          class="pc-vbf-icon-st0"
          x1="376.39999"
          y1="300.39999"
          x2="376.39999"
          y2="273.5"
          id="line4"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 4;
            stroke-linecap: square;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st1"
          x1="376.39999"
          y1="273.5"
          x2="396"
          y2="273.5"
          id="line6"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 3;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st2"
          x1="396"
          y1="303"
          x2="396"
          y2="272.79999"
          id="line8"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 2;
            stroke-linecap: square;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st3"
          x1="377.60001"
          y1="274.89999"
          x2="381.39999"
          y2="273.5"
          id="line10"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st3"
          x1="377"
          y1="274.60001"
          x2="378.60001"
          y2="274.10001"
          id="line12"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st3"
          x1="396"
          y1="277.10001"
          x2="389.79999"
          y2="273.39999"
          id="line14"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st3"
          x1="395.79999"
          y1="274.60001"
          x2="391.5"
          y2="274.10001"
          id="line16"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st3"
          x1="395.70001"
          y1="275.60001"
          x2="393.29999"
          y2="275.5"
          id="line18"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st0"
          x1="376.39999"
          y1="300.5"
          x2="376.39999"
          y2="273.70001"
          id="line20"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 4;
            stroke-linecap: square;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st2"
          x1="396"
          y1="303.10001"
          x2="396"
          y2="272.89999"
          id="line22"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 2;
            stroke-linecap: square;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st4"
          d="m 379.3,278.2 v 39.2 h 9.6 v -3 c 0,-1.9 1.5,-3.4 3.4,-3.4 h 0.4 v -26.6 h -0.4 c -1.9,0 -3.4,-1.5 -3.4,-3.4 v -3 z"
          id="path24"
          style="fill: #e3e3e2"
        />
        <path
          class="pc-vbf-icon-st5"
          d="m 389.2,282.6 c -0.2,-0.5 -0.3,-0.9 -0.3,-1.4 v -3 h -9.6 v 12.7 c 5,0.3 9.3,-3.3 9.9,-8.3 z"
          id="path26"
          style="fill: #b3b2b2"
        />
        <path
          class="pc-vbf-icon-st5"
          d="m 389.2,313 c -0.2,0.5 -0.3,1 -0.3,1.5 v 3 h -9.6 v -12.7 c 5,-0.3 9.3,3.3 9.9,8.2 z"
          id="path28"
          style="fill: #b3b2b2"
        />
        <line
          class="pc-vbf-icon-st6"
          x1="388.89999"
          y1="278.20001"
          x2="379.29999"
          y2="278.20001"
          id="line30"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st6"
          x1="379.29999"
          y1="277.89999"
          x2="379.29999"
          y2="292.79999"
          id="line32"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st6"
          x1="388.89999"
          y1="277.89999"
          x2="388.89999"
          y2="281.20001"
          id="line34"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st6"
          x1="392.70001"
          y1="284.5"
          x2="392.29999"
          y2="284.5"
          id="line36"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st6"
          d="m 388.9,281.2 c 0,1.9 1.5,3.4 3.4,3.4"
          id="path38"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st6"
          d="m 379.3,290.9 c 5,0.3 9.3,-3.3 9.9,-8.3"
          id="path40"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st6"
          x1="392.70001"
          y1="284.5"
          x2="392.70001"
          y2="297.79999"
          id="line42"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st6"
          x1="392.70001"
          y1="311.10001"
          x2="392.70001"
          y2="297.79999"
          id="line44"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st6"
          d="m 389.2,313 c -0.6,-4.9 -5,-8.5 -9.9,-8.3"
          id="path46"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st6"
          d="m 392.3,311.1 c -1.9,0 -3.4,1.5 -3.4,3.4"
          id="path48"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st6"
          x1="392.70001"
          y1="311.10001"
          x2="392.29999"
          y2="311.10001"
          id="line50"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st6"
          x1="388.89999"
          y1="317.79999"
          x2="388.89999"
          y2="314.5"
          id="line52"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st6"
          x1="379.29999"
          y1="317.79999"
          x2="379.29999"
          y2="302.89999"
          id="line54"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st6"
          x1="388.89999"
          y1="317.5"
          x2="379.29999"
          y2="317.5"
          id="line56"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st7"
          d="m 386.3,295.9 -0.6,-1.5 -2,-1.3 h -0.8 v -0.2 h -4 v 0.5 H 377 c -0.2,0 -0.4,0.1 -0.4,0.3 v 8.3 c 0,0.2 0.2,0.4 0.4,0.3 h 1.9 v 0.5 h 4 v -0.3 h 0.8 l 2,-1.3 0.6,-1.5 z"
          id="path58"
          style="fill: #1e1e1c"
        />
        <line
          class="pc-vbf-icon-st0"
          x1="376.39999"
          y1="295.10001"
          x2="376.39999"
          y2="322"
          id="line60"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 4;
            stroke-linecap: square;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st1"
          x1="376.39999"
          y1="322"
          x2="396"
          y2="322"
          id="line62"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 3;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st2"
          x1="396"
          y1="292.60001"
          x2="396"
          y2="322.70001"
          id="line64"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 2;
            stroke-linecap: square;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st8"
          x1="392.20001"
          y1="310.5"
          x2="392.20001"
          y2="285.20001"
          id="line66"
          style="fill: none"
        />
        <path
          class="pc-vbf-icon-st5"
          d="m 392.2,310.5 c 2.1,0 3.8,1.7 3.8,3.8 v 0 0 -32.9 0 c 0,2.1 -1.7,3.8 -3.8,3.8"
          id="path68"
          style="fill: #b3b2b2"
        />
        <line
          class="pc-vbf-icon-st6"
          x1="396"
          y1="319.39999"
          x2="396"
          y2="276.29999"
          id="line70"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st6"
          x1="392.20001"
          y1="310.5"
          x2="392.20001"
          y2="285.20001"
          id="line72"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st6"
          d="m 396,314.3 c 0,-2.1 -1.7,-3.8 -3.8,-3.8 v 0"
          id="path74"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st6"
          d="m 396,281.4 c 0,2.1 -1.7,3.8 -3.8,3.8"
          id="path76"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st3"
          x1="377.60001"
          y1="320.60001"
          x2="381.39999"
          y2="322"
          id="line78"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st3"
          x1="377"
          y1="321"
          x2="378.70001"
          y2="321.39999"
          id="line80"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st3"
          x1="396"
          y1="318.39999"
          x2="389.79999"
          y2="322.10001"
          id="line82"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st3"
          x1="395.79999"
          y1="320.89999"
          x2="391.5"
          y2="321.39999"
          id="line84"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st3"
          x1="395.70001"
          y1="320"
          x2="393.39999"
          y2="320"
          id="line86"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st9"
          d="M 388.8,277.8"
          id="path88"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbf-icon-st9"
          d="M 388.8,317.8"
          id="path90"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbf-icon-st9"
          d="M 374.5,307.9"
          id="path92"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbf-icon-st9"
          d="M 396.9,307.9"
          id="path94"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <rect
          x="409.79999"
          y="271.89999"
          class="pc-vbf-icon-st10"
          width="51.799999"
          height="51.799999"
          id="rect96"
          style="
            fill: #b3b2b2;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbf-icon-st4"
          d="m 416.6,297.8 c 0,-10.5 8.5,-18.9 18.9,-18.9 10.4,0 18.9,8.5 18.9,18.9 0,10.4 -8.5,18.9 -18.9,18.9 -10.5,0 -18.9,-8.5 -18.9,-18.9 0,0 0,0 0,0 z"
          id="path98"
          style="fill: #e3e3e2"
        />
        <circle
          class="pc-vbf-icon-st11"
          cx="435.60001"
          cy="297.70001"
          id="ellipse100"
          r="7.5"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-miterlimit: 4.00010014;
          "
        />
        <path
          class="pc-vbf-icon-st12"
          d="m 415.6,297.8 c 0,-11 8.9,-19.9 19.9,-19.9 11,0 19.9,8.9 19.9,19.9 0,11 -8.9,19.9 -19.9,19.9 -11,0 -19.9,-8.9 -19.9,-19.9 0,0 0,0 0,0 z m 19.9,18.9 c 10.5,0 18.9,-8.5 18.9,-18.9 0,-10.4 -8.5,-18.9 -18.9,-18.9 -10.4,0 -18.9,8.5 -18.9,18.9 v 0 c 0,10.4 8.4,18.9 18.9,18.9 z"
          id="path102"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st7"
          d="m 423.8,297.8 c 0,-6.5 5.3,-11.8 11.8,-11.8 6.5,0 11.8,5.3 11.8,11.8 0,6.5 -5.3,11.8 -11.8,11.8 v 0 c -6.6,0 -11.8,-5.3 -11.8,-11.8 z m 11.8,11.2 c 6.2,0 11.2,-5 11.2,-11.2 0,-6.2 -5,-11.2 -11.2,-11.2 -6.2,0 -11.2,5 -11.2,11.2 0,0 0,0 0,0 0,6.2 5,11.2 11.2,11.2 z"
          id="path104"
          style="fill: #1e1e1c"
        />
        <path
          class="pc-vbf-icon-st13"
          d="m 416.8,296.2 c 2.5,-5.5 8,-8 12.9,-5.9"
          id="path106"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st13"
          d="m 426.4,281.3 c 6,-1 11.1,2.1 12.2,7.4"
          id="path108"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st13"
          d="m 454.4,297.3 c -1.8,5.8 -7,8.9 -12.2,7.4"
          id="path110"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st13"
          d="m 445.3,281.6 c 3.9,4.6 3.7,10.7 -0.3,14.2"
          id="path112"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st13"
          d="m 423.7,312.5 c -3.2,-5.1 -2.2,-11.1 2.3,-14"
          id="path114"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vbf-icon-st13"
          d="m 443.5,314.9 c -6,0.6 -10.9,-2.9 -11.6,-8.3"
          id="path116"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vbf-icon-st14"
          x1="410.70001"
          y1="268.29999"
          x2="409.20001"
          y2="269.89999"
          id="line118"
          style="
            fill: none;
            stroke: #ffffff;
            stroke-width: 3;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbf-icon-st14"
          d="M 466.7,268.3"
          id="path120"
          style="
            fill: none;
            stroke: #ffffff;
            stroke-width: 3;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbf-icon-st14"
          d="M 466.8,327.4"
          id="path122"
          style="
            fill: none;
            stroke: #ffffff;
            stroke-width: 3;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vbf-icon-st14"
          x1="410.70001"
          y1="327.39999"
          x2="409.10001"
          y2="325.89999"
          id="line124"
          style="
            fill: none;
            stroke: #ffffff;
            stroke-width: 3;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbf-icon-st9"
          d="M 409.3,319.2"
          id="path126"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbf-icon-st9"
          d="M 462.1,318.9"
          id="path128"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbf-icon-st9"
          d="M 457.2,324.1"
          id="path130"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vbf-icon-st9"
          d="M 457.6,271.4"
          id="path132"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vbf-icon-st15"
          x1="413"
          y1="275.29999"
          x2="458.10001"
          y2="320.39999"
          id="line134"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vbf-icon-st15"
          x1="413"
          y1="320.39999"
          x2="458.10001"
          y2="275.29999"
          id="line136"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <rect
          x="427.79999"
          y="290"
          class="pc-vbf-icon-st16"
          width="15.9"
          height="15.9"
          id="rect138"
          style="
            fill: #3d3d3b;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <rect
          x="412.39999"
          y="274.5"
          class="pc-vbf-icon-st17"
          width="46.700001"
          height="46.700001"
          id="rect140"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <rect
          x="409.70001"
          y="271.89999"
          class="pc-vbf-icon-st18"
          width="4.4000001"
          height="4.5999999"
          id="rect142"
          style="fill: #585857; stroke: #1e1e1c; stroke-miterlimit: 10"
        />
        <rect
          x="457.29999"
          y="271.79999"
          class="pc-vbf-icon-st18"
          width="4.4000001"
          height="4.5999999"
          id="rect144"
          style="fill: #585857; stroke: #1e1e1c; stroke-miterlimit: 10"
        />
        <rect
          x="409.70001"
          y="319.20001"
          class="pc-vbf-icon-st18"
          width="4.4000001"
          height="4.5999999"
          id="rect146"
          style="fill: #585857; stroke: #1e1e1c; stroke-miterlimit: 10"
        />
        <rect
          x="457.29999"
          y="319.10001"
          class="pc-vbf-icon-st18"
          width="4.4000001"
          height="4.5999999"
          id="rect148"
          style="fill: #585857; stroke: #1e1e1c; stroke-miterlimit: 10"
        />
      </g>
      <g id="g160" transform="translate(-374.39999,-267.27408)">
        <g id="g158">
          <line
            class="pc-vbf-icon-st19"
            x1="384.20001"
            y1="325.60001"
            x2="384.10001"
            y2="319.10001"
            id="line152"
            style="
              fill: none;
              stroke: #223a8f;
              stroke-width: 0.60000002;
              stroke-miterlimit: 10;
            "
          />
          <g id="g156">
            <polygon
              class="pc-vbf-icon-st20"
              points="382.6,325.3 385.9,325.2 384.3,328 "
              id="polygon154"
              style="fill: #223a8f"
            />
          </g>
        </g>
      </g>
      <g id="g170" transform="translate(-374.39999,-267.27408)">
        <g id="g168">
          <line
            class="pc-vbf-icon-st19"
            x1="384.20001"
            y1="270.10001"
            x2="384.20001"
            y2="276.60001"
            id="line162"
            style="
              fill: none;
              stroke: #223a8f;
              stroke-width: 0.60000002;
              stroke-miterlimit: 10;
            "
          />
          <g id="g166">
            <polygon
              class="pc-vbf-icon-st20"
              points="385.8,270.5 382.5,270.5 384.2,267.7 "
              id="polygon164"
              style="fill: #223a8f"
            />
          </g>
        </g>
      </g>
    </symbol>

    <symbol id="product-category-vfs" viewBox="0 0 65 41">
      <g clip-path="url(#clip0_1491_37170)">
        <path
          d="M63.6003 20.9C63.6003 10.4 55.1003 2 44.7003 2C34.3003 2 25.8003 10.5 25.8003 20.9C25.8003 31.3 34.3003 39.8 44.7003 39.8C55.1003 39.8 63.6003 31.3 63.6003 20.9Z"
          fill="#E3E3E2"
        />
        <path
          d="M44.6 31.0001C50.1781 31.0001 54.7 26.4781 54.7 20.9001C54.7 15.322 50.1781 10.8 44.6 10.8C39.0219 10.8 34.5 15.322 34.5 20.9001C34.5 26.4781 39.0219 31.0001 44.6 31.0001Z"
          fill="#1E1E1C"
          stroke="#1E1E1C"
          stroke-width="0.7"
        />
        <path
          d="M44.6002 40.8C33.6002 40.8 24.7002 31.9 24.7002 20.9C24.7002 9.89999 33.6002 1 44.6002 1C55.6002 1 64.5002 9.89999 64.5002 20.9C64.5002 31.9 55.6002 40.8 44.6002 40.8ZM63.6002 20.9C63.6002 10.4 55.1002 2 44.7002 2C34.3002 2 25.8002 10.5 25.8002 20.9C25.8002 31.3 34.3002 39.8 44.7002 39.8C55.1002 39.8 63.6002 31.3 63.6002 20.9Z"
          fill="#1E1E1C"
          stroke="#1E1E1C"
          stroke-width="0.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M46.2004 2C44.9004 2.4 44.1004 3.80001 44.4004 5.10001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M43.1004 2C41.9004 2.6 41.3004 4.09999 41.8004 5.39999"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40.0004 2.5C38.9004 3.3 38.6004 4.9 39.3004 6"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37 3.5C36.1 4.5 36 6.10001 36.9 7.10001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.3001 5C33.5001 6.1 33.7001 7.70001 34.8001 8.60001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.8001 7C31.2001 8.3 31.7001 9.7 32.8001 10.5"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.7002 9.2002C29.3002 10.5002 30.0002 11.9002 31.3002 12.5002"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.0003 11.8C27.9003 13.2 28.8003 14.4 30.1003 14.8"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.8003 14.7002C26.9003 16.1002 28.0003 17.2002 29.4003 17.3002"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26 17.7002C26.3 19.1002 27.6 20.0002 29 19.8002"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.7002 20.8C26.2002 22.1 27.6002 22.7001 29.0002 22.4001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26 24C26.8 25.1 28.2 25.6 29.5 25"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.7002 27C27.6002 28 29.2002 28.2 30.3002 27.4"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28 29.9002C29.1 30.8002 30.6 30.7002 31.6 29.7002"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.7002 32.5002C30.9002 33.2002 32.4002 32.8002 33.3002 31.7002"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.8003 34.8C33.1003 35.3 34.5003 34.7 35.2003 33.5"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.3003 36.7001C35.7003 36.9001 37.0003 36.1 37.4003 34.8"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37 38.2001C38.4 38.2001 39.5 37.2 39.8 35.8"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40 39.2002C41.4 39.0002 42.4 37.8001 42.3 36.4001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M43.1001 39.7001C44.4001 39.3001 45.2001 37.9001 44.9001 36.6001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M46.2002 39.7001C47.4002 39.1001 48.0002 37.6 47.5002 36.3"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M49.3003 39.2001C50.4003 38.4001 50.7003 36.8001 50.0003 35.6001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M52.2002 38.2001C53.1002 37.2001 53.2002 35.6001 52.3002 34.6001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M55 36.7001C55.8 35.6001 55.6 34.0001 54.5 33.1001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M57.5 34.8C58.1 33.5 57.6 32.1 56.5 31.3"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M59.6 32.5002C60 31.2002 59.3 29.8002 58 29.2002"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M61.3002 29.9001C61.4002 28.5001 60.5002 27.3001 59.2002 26.9001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.5004 27.0002C62.4004 25.6002 61.3004 24.5001 59.9004 24.4001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M63.3003 24.0001C63.0003 22.6001 61.7003 21.7001 60.3003 21.9001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M63.6003 20.9001C63.1003 19.6001 61.7003 19.0001 60.3003 19.3001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M63.3003 17.8001C62.5003 16.7001 61.1003 16.2001 59.8003 16.8001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.5004 14.7C61.6004 13.7 60.0004 13.5 58.9004 14.3"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M61.3002 11.9001C60.2002 11.0001 58.7002 11.1001 57.7002 12.1001"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M59.6 9.30014C58.4 8.60014 56.9 9.00016 56 10.1002"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M57.5001 7.00008C56.2001 6.50008 54.8001 7.10006 54.1001 8.30006"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M55.0004 5C53.6004 4.8 52.3004 5.6 51.9004 6.9"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M52.3 3.5C50.9 3.5 49.7 4.49999 49.5 5.89999"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M49.3 2.5C47.9 2.7 46.9 3.89999 47 5.29999"
          stroke="#1E1E1C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M19.3004 1.5H2.90039V5.5H19.3004V1.5Z" fill="#B3B2B2" />
        <path
          d="M19.3004 36.1001H2.90039V40.6001H19.3004V36.1001Z"
          fill="#B3B2B2"
        />
        <path
          d="M19.3004 5.6001H2.90039V36.2001H19.3004V5.6001Z"
          fill="#E3E3E2"
        />
        <path
          d="M19.3004 1.5H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 5.6001H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 8.30005H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 10.9001H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 13.2002H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 15.4001H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 17.6001H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 19.9001H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 22.1001H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 24.3H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 26.5H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 28.8H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 31H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 33.7002H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.90039 0.800049V15.8"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3003 0.800049V21.8"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3003 40.9V20.5"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.90039 40.9001V25.9001"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 40.6001H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3004 36.1001H2.90039"
          stroke="#1E1E1C"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.0002 17.6002L9.40018 14.9002L7.40018 12.6002H6.50018V12.2002H2.50018V13.1002H0.600189C0.400189 13.1002 0.200195 13.4002 0.200195 13.7002V28.3002C0.200195 28.6002 0.400189 28.9002 0.600189 28.9002H2.50018V29.8002H6.50018V29.4002H7.40018L9.40018 27.1002L10.0002 24.4002V17.6002Z"
          fill="#1E1E1C"
        />
      </g>
      <defs>
        <clipPath id="clip0_1491_37170">
          <rect
            width="64.3"
            height="40.1"
            fill="white"
            transform="translate(0.200195 0.800049)"
          />
        </clipPath>
      </defs>
    </symbol>

    <symbol id="product-category-vcs" viewBox="0 0 109.42501 75.300005">
      <style type="text/css" id="style2">
        .pc-vcs-icon-st0 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.5;
          stroke-linejoin: round;
        }

        .pc-vcs-icon-st1 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.7;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vcs-icon-st2 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.5;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vcs-icon-st3 {
          fill: #b3b2b2;
        }

        .pc-vcs-icon-st4 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vcs-icon-st5 {
          fill: #1e1e1c;
        }

        .pc-vcs-icon-st6 {
          fill: none;
        }

        .pc-vcs-icon-st7 {
          fill: #b3b2b2;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st8 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.6;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .pc-vcs-icon-st9 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st10 {
          fill: #1e1e1c;
          stroke: #1e1e1c;
          stroke-width: 0.7;
          stroke-miterlimit: 4.0001;
        }

        .pc-vcs-icon-st11 {
          fill: #b3b2b2;
          stroke: #1d1d1b;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st12 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st13 {
          fill: none;
          stroke: #1e1e1c;
          stroke-width: 1.5;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st14 {
          fill: #3d3d3b;
          stroke: #1e1e1c;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st15 {
          fill: none;
          stroke: #223a8f;
          stroke-width: 0.6;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st16 {
          fill: #223a8f;
        }

        .pc-vcs-icon-st17 {
          fill: none;
          stroke: #1d1d1b;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st18 {
          fill: none;
          stroke: #1e1e1c;
          stroke-miterlimit: 10;
        }

        .pc-vcs-icon-st19 {
          fill: none;
          stroke: #1d1d1b;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
      </style>
      <g id="g216" transform="translate(-365.17499,-260.2)">
        <line
          class="pc-vcs-icon-st0"
          x1="370.89999"
          y1="269.5"
          x2="372.20001"
          y2="269.5"
          id="line4"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st1"
          x1="391.89999"
          y1="302.29999"
          x2="391.89999"
          y2="265.29999"
          id="line6"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st2"
          d="m 370.9,269.5 c -1.9,0 -3.3,1.5 -3.4,3.3"
          id="path8"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st2"
          d="m 367.5,321.9 c 0,1.8 1.5,3.3 3.3,3.3"
          id="path10"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st1"
          x1="391.89999"
          y1="293.29999"
          x2="391.89999"
          y2="330.29999"
          id="line12"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st0"
          x1="370.89999"
          y1="325.20001"
          x2="372"
          y2="325.20001"
          id="line14"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st3"
          d="m 375.3,278.3 v 39.1 h 9.6 v -3 c 0,-1.9 1.5,-3.4 3.4,-3.4 h 0.4 v -26.4 h -0.4 c -1.9,0 -3.4,-1.5 -3.4,-3.4 v -3 z"
          id="path16"
          style="fill: #b3b2b2"
        />
        <path
          class="pc-vcs-icon-st3"
          d="m 385.2,282.7 c -0.2,-0.5 -0.3,-0.9 -0.3,-1.4 v -3 h -9.6 v 12.6 c 4.9,0.2 9.2,-3.3 9.9,-8.2 z"
          id="path18"
          style="fill: #b3b2b2"
        />
        <path
          class="pc-vcs-icon-st3"
          d="m 385.2,313 c -0.2,0.5 -0.3,0.9 -0.3,1.4 v 3 h -9.6 v -12.7 c 4.9,-0.3 9.2,3.3 9.9,8.3 z"
          id="path20"
          style="fill: #b3b2b2"
        />
        <line
          class="pc-vcs-icon-st4"
          x1="384.79999"
          y1="278.29999"
          x2="375.29999"
          y2="278.29999"
          id="line22"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st4"
          x1="375.29999"
          y1="278"
          x2="375.29999"
          y2="292.79999"
          id="line24"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st4"
          x1="384.79999"
          y1="278"
          x2="384.79999"
          y2="281.20001"
          id="line26"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st4"
          x1="388.60001"
          y1="284.60001"
          x2="388.20001"
          y2="284.60001"
          id="line28"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st4"
          d="m 384.9,281.2 c 0,1.9 1.5,3.4 3.4,3.4"
          id="path30"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st4"
          d="m 375.3,290.9 c 5,0.3 9.3,-3.3 9.9,-8.3"
          id="path32"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st4"
          x1="388.60001"
          y1="284.60001"
          x2="388.60001"
          y2="297.79999"
          id="line34"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st4"
          x1="388.60001"
          y1="311.10001"
          x2="388.60001"
          y2="297.79999"
          id="line36"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st4"
          d="m 385.2,313 c -0.6,-4.9 -4.9,-8.5 -9.9,-8.3"
          id="path38"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st4"
          d="m 388.2,311.1 c -1.9,0 -3.4,1.5 -3.4,3.4"
          id="path40"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st4"
          x1="388.60001"
          y1="311.10001"
          x2="388.20001"
          y2="311.10001"
          id="line42"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st4"
          x1="384.79999"
          y1="317.70001"
          x2="384.79999"
          y2="314.39999"
          id="line44"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st4"
          x1="375.29999"
          y1="317.70001"
          x2="375.29999"
          y2="302.79999"
          id="line46"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st4"
          x1="384.79999"
          y1="317.39999"
          x2="375.29999"
          y2="317.39999"
          id="line48"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st5"
          d="m 382.3,295.9 -0.8,-1.5 -2.7,-1.3 h -1.1 v -0.3 h -5.4 v 0.5 h -2.6 c -0.3,0 -0.5,0.1 -0.5,0.3 v 0 8.2 c 0,0.2 0.3,0.4 0.5,0.3 v 0 h 2.6 v 0.5 h 5.4 v -0.2 h 1.1 l 2.7,-1.3 0.8,-1.5 z"
          id="path50"
          style="fill: #1e1e1c"
        />
        <line
          class="pc-vcs-icon-st6"
          x1="388.10001"
          y1="310.5"
          x2="388.10001"
          y2="285.20001"
          id="line52"
          style="fill: none"
        />
        <path
          class="pc-vcs-icon-st7"
          d="m 388.1,310.4 c 2.1,0 3.8,1.7 3.8,3.8 v 0 -32.8 0 c 0,2.1 -1.7,3.8 -3.8,3.8"
          id="path54"
          style="
            fill: #b3b2b2;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vcs-icon-st8"
          x1="391.89999"
          y1="319.29999"
          x2="391.89999"
          y2="276.39999"
          id="line56"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st1"
          x1="371.89999"
          y1="302.29999"
          x2="371.89999"
          y2="265.29999"
          id="line58"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st1"
          x1="371.89999"
          y1="293.29999"
          x2="371.89999"
          y2="330.29999"
          id="line60"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st8"
          x1="371.89999"
          y1="319.29999"
          x2="371.89999"
          y2="276.39999"
          id="line62"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.60000002;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st4"
          x1="388.10001"
          y1="310.5"
          x2="388.10001"
          y2="285.20001"
          id="line64"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st4"
          d="m 391.9,314.2 c 0,-2.1 -1.7,-3.8 -3.8,-3.8"
          id="path66"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st4"
          d="m 391.9,281.4 c 0,2.1 -1.7,3.8 -3.8,3.8"
          id="path68"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st1"
          x1="371.89999"
          y1="265.29999"
          x2="391.89999"
          y2="265.29999"
          id="line70"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st1"
          x1="371.89999"
          y1="330.29999"
          x2="391.89999"
          y2="330.29999"
          id="line72"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st1"
          x1="371.89999"
          y1="326.60001"
          x2="391.89999"
          y2="326.60001"
          id="line74"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st9"
          d="M 384.8,277.8"
          id="path76"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vcs-icon-st9"
          d="M 384.8,317.8"
          id="path78"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vcs-icon-st9"
          d="M 392.1,307.9"
          id="path80"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vcs-icon-st3"
          d="m 418.2,302.7 c -2.7,-10.1 3.3,-20.5 13.4,-23.1 10,-2.7 20.5,3.3 23.1,13.4 2.6,10.1 -3.3,20.5 -13.4,23.1 v 0 c -10,2.7 -20.4,-3.3 -23.1,-13.4 z"
          id="path82"
          style="fill: #b3b2b2"
        />
        <circle
          class="pc-vcs-icon-st10"
          cx="436.60001"
          cy="297.70001"
          id="ellipse84"
          r="7.5"
          style="
            fill: #1e1e1c;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-miterlimit: 4.00010014;
          "
        />
        <path
          class="pc-vcs-icon-st11"
          d="m 417.3,303 c -2.8,-10.6 3.4,-21.5 14.1,-24.4 10.7,-2.9 21.5,3.4 24.4,14.1 2.8,10.6 -3.4,21.5 -14.1,24.4 v 0 C 431,319.9 420.1,313.6 417.3,303 Z m 24.1,13.1 c 10.1,-2.7 16.1,-13.1 13.4,-23.1 -2.7,-10 -13.1,-16.1 -23.1,-13.4 -10,2.7 -16.1,13.1 -13.4,23.1 v 0 c 2.6,10.1 13,16.1 23.1,13.4 z"
          id="path86"
          style="
            fill: #b3b2b2;
            stroke: #1d1d1b;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vcs-icon-st11"
          d="m 425.2,300.8 c -1.7,-6.3 2.1,-12.8 8.3,-14.5 6.3,-1.7 12.8,2.1 14.5,8.3 1.7,6.3 -2.1,12.8 -8.3,14.5 -6.2,1.7 -12.8,-2 -14.5,-8.3 z m 14.3,7.8 c 6,-1.6 9.5,-7.7 7.9,-13.7 -1.6,-6 -7.7,-9.5 -13.7,-7.9 -6,1.6 -9.5,7.7 -7.9,13.7 v 0 c 1.6,6 7.7,9.5 13.7,7.9 z"
          id="path88"
          style="
            fill: #b3b2b2;
            stroke: #1d1d1b;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vcs-icon-st4"
          d="m 418,301.1 c 1,-6 5.7,-9.8 10.9,-9"
          id="path90"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st4"
          d="m 423.5,284.3 c 5.5,-2.5 11.3,-0.8 13.7,4"
          id="path92"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st4"
          d="m 454.7,292.4 c -0.2,6.1 -4.5,10.4 -9.9,10.3"
          id="path94"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st4"
          d="m 441.8,279.6 c 5,3.4 6.3,9.4 3.4,13.8"
          id="path96"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st4"
          d="m 429,315 c -4.4,-4.1 -5,-10.2 -1.4,-14.1"
          id="path98"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st4"
          d="m 448.6,312.3 c -5.6,2.1 -11.3,0 -13.4,-5"
          id="path100"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st9"
          d="M 408,294.8"
          id="path102"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vcs-icon-st9"
          d="M 465.5,294.8"
          id="path104"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vcs-icon-st12"
          x1="404.29999"
          y1="265.39999"
          x2="404.29999"
          y2="330.10001"
          id="line106"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vcs-icon-st12"
          x1="469.10001"
          y1="265.29999"
          x2="469.10001"
          y2="330.10001"
          id="line108"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vcs-icon-st12"
          x1="404"
          y1="265.39999"
          x2="469.5"
          y2="265.39999"
          id="line110"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vcs-icon-st12"
          x1="404"
          y1="330.10001"
          x2="469.39999"
          y2="330.10001"
          id="line112"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.75;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vcs-icon-st9"
          d="M 435.8,330.4"
          id="path114"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <path
          class="pc-vcs-icon-st9"
          d="M 433.9,269.1"
          id="path116"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vcs-icon-st13"
          x1="408.89999"
          y1="281.89999"
          x2="464.20001"
          y2="313.79999"
          id="line118"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vcs-icon-st13"
          x1="420.60001"
          y1="325.5"
          x2="452.5"
          y2="270.20001"
          id="line120"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          "
        />

        <rect
          x="428.79999"
          y="289.89999"
          transform="matrix(0.9659,-0.2588,0.2588,0.9659,-62.2183,123.1947)"
          class="pc-vcs-icon-st14"
          width="15.9"
          height="15.9"
          id="rect122"
          style="
            fill: #3d3d3b;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <g id="g132">
          <g id="g130">
            <line
              class="pc-vcs-icon-st15"
              x1="472.20001"
              y1="321.29999"
              x2="465.70001"
              y2="321.39999"
              id="line124"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g128">
              <polygon
                class="pc-vcs-icon-st16"
                points="474.6,321.3 471.8,322.9 471.8,319.6 "
                id="polygon126"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
        <g id="g142">
          <g id="g140">
            <line
              class="pc-vcs-icon-st15"
              x1="401"
              y1="273.5"
              x2="407.5"
              y2="273.5"
              id="line134"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g138">
              <polygon
                class="pc-vcs-icon-st16"
                points="398.6,273.5 401.4,271.9 401.4,275.2 "
                id="polygon136"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
        <line
          class="pc-vcs-icon-st1"
          x1="371.89999"
          y1="279.60001"
          x2="391.89999"
          y2="279.60001"
          id="line144"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st1"
          x1="371.89999"
          y1="300.89999"
          x2="391.89999"
          y2="300.89999"
          id="line146"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st1"
          x1="371.89999"
          y1="286"
          x2="391.89999"
          y2="286"
          id="line148"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.69999999;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="pc-vcs-icon-st9"
          d="M 366.9,307.9"
          id="path150"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <rect
          x="365.29999"
          y="288.39999"
          class="pc-vcs-icon-st14"
          width="6.0999999"
          height="19.5"
          id="rect152"
          style="
            fill: #3d3d3b;
            stroke: #1e1e1c;
            stroke-width: 0.25;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vcs-icon-st2"
          x1="367.5"
          y1="297.60001"
          x2="367.5"
          y2="272.89999"
          id="line154"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="pc-vcs-icon-st2"
          x1="367.5"
          y1="296.5"
          x2="367.5"
          y2="321.89999"
          id="line156"
          style="
            fill: none;
            stroke: #1e1e1c;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <g id="g162">
          <line
            class="pc-vcs-icon-st17"
            x1="404.29999"
            y1="326.60001"
            x2="421.89999"
            y2="323.29999"
            id="line158"
            style="fill: none; stroke: #1d1d1b; stroke-miterlimit: 10"
          />
          <line
            class="pc-vcs-icon-st17"
            x1="421.89999"
            y1="323.29999"
            x2="439.79999"
            y2="330.10001"
            id="line160"
            style="fill: none; stroke: #1d1d1b; stroke-miterlimit: 10"
          />
        </g>
        <g id="g168">
          <line
            class="pc-vcs-icon-st17"
            x1="465.39999"
            y1="330.10001"
            x2="462.10001"
            y2="312.5"
            id="line164"
            style="fill: none; stroke: #1d1d1b; stroke-miterlimit: 10"
          />
          <line
            class="pc-vcs-icon-st17"
            x1="462.10001"
            y1="312.5"
            x2="468.89999"
            y2="294.5"
            id="line166"
            style="fill: none; stroke: #1d1d1b; stroke-miterlimit: 10"
          />
        </g>
        <g id="g174">
          <line
            class="pc-vcs-icon-st17"
            x1="469.39999"
            y1="268.79999"
            x2="451.89999"
            y2="272.10001"
            id="line170"
            style="fill: none; stroke: #1d1d1b; stroke-miterlimit: 10"
          />
          <line
            class="pc-vcs-icon-st17"
            x1="451.89999"
            y1="272.10001"
            x2="433.89999"
            y2="265.29999"
            id="line172"
            style="fill: none; stroke: #1d1d1b; stroke-miterlimit: 10"
          />
        </g>
        <g id="g180">
          <line
            class="pc-vcs-icon-st17"
            x1="408.10001"
            y1="265.5"
            x2="411.39999"
            y2="283.10001"
            id="line176"
            style="fill: none; stroke: #1d1d1b; stroke-miterlimit: 10"
          />
          <line
            class="pc-vcs-icon-st17"
            x1="411.39999"
            y1="283.10001"
            x2="404.60001"
            y2="301.10001"
            id="line178"
            style="fill: none; stroke: #1d1d1b; stroke-miterlimit: 10"
          />
        </g>
        <line
          class="pc-vcs-icon-st18"
          x1="418.60001"
          y1="323.89999"
          x2="423.5"
          y2="330.29999"
          id="line182"
          style="fill: none; stroke: #1e1e1c; stroke-miterlimit: 10"
        />
        <line
          class="pc-vcs-icon-st19"
          x1="427"
          y1="292.29999"
          x2="431.10001"
          y2="307.60001"
          id="line184"
          style="
            fill: none;
            stroke: #1d1d1b;
            stroke-width: 1.5;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vcs-icon-st19"
          x1="442.39999"
          y1="288.20001"
          x2="446.5"
          y2="303.5"
          id="line186"
          style="
            fill: none;
            stroke: #1d1d1b;
            stroke-width: 1.5;
            stroke-miterlimit: 10;
          "
        />
        <circle
          class="pc-vcs-icon-st19"
          cx="436.79999"
          cy="298"
          r="5.1999998"
          id="circle188"
          style="
            fill: none;
            stroke: #1d1d1b;
            stroke-width: 1.5;
            stroke-miterlimit: 10;
          "
        />
        <line
          class="pc-vcs-icon-st17"
          x1="462.89999"
          y1="316.70001"
          x2="469.10001"
          y2="308.79999"
          id="line190"
          style="fill: none; stroke: #1d1d1b; stroke-miterlimit: 10"
        />
        <line
          class="pc-vcs-icon-st17"
          x1="454.70001"
          y1="271.60001"
          x2="449.20001"
          y2="265.39999"
          id="line192"
          style="fill: none; stroke: #1d1d1b; stroke-miterlimit: 10"
        />
        <line
          class="pc-vcs-icon-st17"
          x1="410.79999"
          y1="279.60001"
          x2="404.29999"
          y2="286.5"
          id="line194"
          style="fill: none; stroke: #1d1d1b; stroke-miterlimit: 10"
        />
        <g id="g204">
          <g id="g202">
            <line
              class="pc-vcs-icon-st15"
              x1="460.70001"
              y1="262.60001"
              x2="460.79999"
              y2="269.10001"
              id="line196"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g200">
              <polygon
                class="pc-vcs-icon-st16"
                points="460.7,260.2 462.3,263 459,263 "
                id="polygon198"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
        <g id="g214">
          <g id="g212">
            <line
              class="pc-vcs-icon-st15"
              x1="413.10001"
              y1="333.10001"
              x2="413.10001"
              y2="326.60001"
              id="line206"
              style="
                fill: none;
                stroke: #223a8f;
                stroke-width: 0.60000002;
                stroke-miterlimit: 10;
              "
            />
            <g id="g210">
              <polygon
                class="pc-vcs-icon-st16"
                points="413.1,335.5 411.5,332.7 414.8,332.7 "
                id="polygon208"
                style="fill: #223a8f"
              />
            </g>
          </g>
        </g>
      </g>
    </symbol>
  </defs>
</svg>
