import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  autoUpdate,
  computePosition,
  flip,
  Placement,
  shift,
  size,
  Strategy,
} from '@floating-ui/dom';

@Component({
  selector: 'fs-simple-popover',
  standalone: true,
  imports: [CommonModule, OverlayModule],
  templateUrl: './simple-popover.component.html',
  styleUrls: ['./simple-popover.component.scss'],
})
export class SimplePopoverComponent implements OnChanges {
  @Input()
  disabled = false;

  @Input()
  placement: Placement = 'bottom';

  @Input()
  strategy: Strategy = 'fixed';

  @Input()
  type: 'onHover' | 'onClick' = 'onHover';

  @Input()
  isDisplayed = false;

  @Input()
  hasBackdrop = true;

  @Input()
  closeOnBackdropClick = true;

  @Input()
  isBackdropVisible = true;

  @Output()
  isDisplayedChange = new EventEmitter<boolean>();

  @ViewChild('trigger') hoverTrigger: ElementRef | undefined = undefined;
  @ViewChild('tooltip') tooltip: ElementRef | undefined = undefined;

  cleanupTooltip: () => void;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDisplayed) {
      if (changes.isDisplayed.currentValue) {
        this.showTooltip();
      } else {
        this.hideTooltip();
      }
    }
  }

  showTooltip() {
    if (!this.disabled && this.tooltip) {
      this.isDisplayed = true;
      this.isDisplayedChange.emit(this.isDisplayed);
      this.tooltip.nativeElement.style.display = 'block';
      this.setUpTooltip();
    }
  }

  hideTooltip() {
    this.isDisplayed = false;
    this.isDisplayedChange.emit(this.isDisplayed);
    if (this.tooltip) {
      this.tooltip.nativeElement.style.display = 'none';
    }
    if (this.cleanupTooltip) {
      this.cleanupTooltip();
    }
  }

  setUpTooltip() {
    if (this.hoverTrigger && this.tooltip) {
      this.cleanupTooltip = autoUpdate(
        this.hoverTrigger.nativeElement,
        this.tooltip.nativeElement,
        () => {
          computePosition(
            this.hoverTrigger.nativeElement,
            this.tooltip.nativeElement,
            {
              strategy: this.strategy,
              placement: this.placement,
              middleware: [
                flip(),
                shift({
                  padding: 8,
                }),
                size({
                  apply({ availableWidth, availableHeight, elements }) {
                    Object.assign(elements.floating.style, {
                      maxWidth: `${availableWidth}px`,
                      maxHeight: `${availableHeight}px`,
                    });
                  },
                }),
              ],
            }
          ).then(({ x, y }) => {
            if (this.tooltip) {
              Object.assign(this.tooltip.nativeElement.style, {
                left: `${x}px`,
                top: `${y}px`,
              });
            }
          });
        }
      );
    }
  }
}
