import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { Configuration } from '../fanscout-api/configuration';
import { ApiModule } from '../fanscout-api/api.module';
import { SvgIconSheetComponent } from './svg-icon-sheet/svg-icon-sheet.component';
import localeDe from '@angular/common/locales/de';
import { SkipTourConfirmationDialogComponent } from './onboarding/skip-tour-confirmation-dialog/skip-tour-confirmation-dialog.component';
import { DialogModule } from '@angular/cdk/dialog';

export const API_PATH = '/api';

export function fanScoutClientSDKConfig(): Configuration {
  return new Configuration({
    basePath: API_PATH,
  });
}

/**
 * Contains all central elements that are required only once for this application
 * @author ebm-papst 2018 Industry Solutions - Web Development Services (dvreinwm)
 */
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DialogModule,
    ApiModule.forRoot(fanScoutClientSDKConfig),
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
        allowedUrls: [API_PATH],
      },
    }),
  ],
  declarations: [SvgIconSheetComponent, SkipTourConfirmationDialogComponent],
  exports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SvgIconSheetComponent,
  ],
})
export class CoreModule {
  constructor() {
    registerLocaleData(localeDe, 'de');
  }
}
