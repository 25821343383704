import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalculationService } from '../../../../fanscout-api/api/calculation.service';
import { CalcServiceVersionInfo } from '../../../../fanscout-api/model/calc-service-version-info';

@Component({
  selector: 'fs-about-info-modal',
  templateUrl: './about-info-modal.component.html',
  styleUrls: ['./about-info-modal.component.scss'],
})
export class AboutInfoModalComponent implements OnInit {
  @Input()
  showModal = false;

  @Output()
  showModalChange = new EventEmitter<boolean>();

  calculationVersionInfo: CalcServiceVersionInfo;

  constructor(private calculationService: CalculationService) {}

  closeModal() {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
  }

  ngOnInit() {
    // noinspection JSIgnoredPromiseFromCall
    this.fetchCalcVersion();
  }

  private async fetchCalcVersion() {
    try {
      this.calculationVersionInfo =
        await this.calculationService.getVersionCached();
    } catch (e) {
      console.log('Failed to load calculation version', e);
    }
  }
}
