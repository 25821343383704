import { Component, Input, Optional } from '@angular/core';
import { FeedbackTypes } from '../../../../fanscout-api/model/feedback';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  UntypedFormControl,
} from '@angular/forms';

@Component({
  selector: 'fs-feedback-rating-radio-boxes',
  templateUrl: './feedback-rating-radio-boxes.component.html',
  styleUrls: ['./feedback-rating-radio-boxes.component.scss'],
})
export class FeedbackRatingRadioBoxesComponent {
  feedbackTypes = FeedbackTypes;

  @Input()
  controlName: string;

  formControl: FormControl<FeedbackTypes>;
  parentForm: FormGroup;

  constructor(@Optional() private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    if (this.controlContainer) {
      this.parentForm = this.controlContainer.control as FormGroup;
      this.formControl = this.parentForm.get(
        this.controlName
      ) as UntypedFormControl;
    }
  }

  get selectedFeedbackType(): FeedbackTypes {
    return this.formControl?.value;
  }

  selectFeedbackType(type: FeedbackTypes) {
    this.formControl.setValue(type);
  }
}
