import { Component, Input, OnInit } from '@angular/core';
import { AuthStateService } from '../../../../core/auth/auth-state.service';

@Component({
  selector: 'fs-navigation-items',
  templateUrl: './navigation-items.component.html',
  styleUrls: ['./navigation-items.component.scss'],
})
export class NavigationItemsComponent implements OnInit {
  @Input()
  language: string;

  isAdmin = false;

  constructor(private authStateService: AuthStateService) {}

  ngOnInit(): void {
    this.isAdmin = this.authStateService.isAdmin();
  }
}
