<div class="fixed bottom-6 left-1/2" *ngIf="showNotification">
  <div
    class="bg-white px-5 py-4 rounded shadow-xl -ml-[50%] mr-[50%] animation-slide-in-up border border-solid border-gray-100 min-w-[400px]"
  >
    <div class="flex gap-3 items-center" *ngIf="!expandToForm">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon--medium text-darkGreen-500"
      >
        <use href="#question-circle"></use>
      </svg>
      <span i18n="@@Fsw_AskTimeForFbSurvey"
        >Haben Sie Zeit für ein kurzes Feedback?</span
      >
      <button
        class="btn btn--quiet"
        (click)="closeNotification()"
        i18n="@@Fsw_NoThanks"
      >
        Nein, danke
      </button>
      <button
        class="btn btn--primary"
        (click)="expandNotificationToForm()"
        i18n="@@Fsw_GeneralYes"
      >
        Ja
      </button>
    </div>
    <form
      *ngIf="expandToForm"
      class="flex flex-col gap-5"
      [formGroup]="feedbackForm"
    >
      <div class="flex items-center gap-3">
        <span
          class="flex-1 text-xs text-gray-700"
          i18n="@@Fsw_AskTimeForFbSurvey"
          >Haben Sie Zeit für ein kurzes Feedback?</span
        >
        <button
          class="btn btn--quiet"
          (click)="collapseNotification()"
          role="button"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="icon">
            <use href="#chevron-down"></use>
          </svg>
        </button>
      </div>
      <p class="prose font-medium">{{ feedbackSurvey.question }}</p>

      <fs-feedback-rating-radio-boxes
        controlName="type"
      ></fs-feedback-rating-radio-boxes>

      <div class="field-wrapper">
        <label
          for="feedback-text"
          class="required"
          i18n="@@Fsw_FeedbackDescription"
          >Beschreibung</label
        >
        <div class="textfield !bg-white !px-0">
          <textarea
            class="py-2 px-3"
            id="feedback-text"
            formControlName="text"
          ></textarea>
        </div>
        <ul class="textfield__errors">
          <li
            *ngIf="
              feedbackForm.dirty && feedbackForm.controls.text.errors?.required
            "
            i18n="@@Fsw_ErrorsRequired"
          >
            Pflichtangabe
          </li>
        </ul>
      </div>
      <div class="flex justify-end">
        <button
          class="btn btn--primary gap-1"
          (click)="submitFeedback()"
          [disabled]="submittingFeedbackInProgress"
          role="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon"
            *ngIf="!submittingFeedbackInProgress"
          >
            <use href="#check"></use>
          </svg>
          <div class="lds-dual-ring" *ngIf="submittingFeedbackInProgress"></div>
          <span i18n="@@Fsw_FeedbackSubmit">Abschicken</span>
        </button>
      </div>
    </form>
  </div>
</div>
