<div class="flex flex-col gap-1">
  <label i18n="@@Fsw_GeneralLanguage">Sprache</label>
  <fs-boxy-radio-group [formControl]="languageFormControl" id="usUnits">
    <fs-boxy-radio-group-option
      *ngFor="let language of possibleLanguages"
      [value]="language"
      >{{ language.toUpperCase() }}</fs-boxy-radio-group-option
    >
  </fs-boxy-radio-group>
</div>
