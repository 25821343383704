<button
  class="btn btn--secondary"
  (click)="openModal()"
  i18n="@@Fsw_FeedbackSubmitFeedback"
>
  Feedback geben
</button>

<div
  *ngIf="showModal"
  class="animation-fade-in animation-speed--75 text-gray-1000"
  (paste)="handlePasteImage($event)"
  data-html2canvas-ignore
>
  <section
    class="backdrop"
    (click)="closeModal(); $event.stopPropagation()"
  ></section>

  <section class="modal flex gap-3 !overflow-x-hidden min-w-[450px]">
    <div class="flex flex-col gap-2 w-full">
      <header class="flex space-x-3 items-center pt-6 px-6">
        <h6 class="flex-1">
          <span
            *ngIf="!showConfirmation && feedbackSelection"
            i18n="@@Fsw_FeedbackSubmitSelectionTitle"
            >Wie können wir Ihnen helfen?</span
          >
          <span
            *ngIf="
              !showConfirmation &&
              !feedbackSelection &&
              feedbackForm.getRawValue().type === feedbackTypes.IDEA
            "
            i18n="@@Fsw_FeedbackSubmissionTitle"
            >Was sind deine Gedanken?
          </span>
          <span
            *ngIf="
              !showConfirmation &&
              !feedbackSelection &&
              feedbackForm.getRawValue().type === feedbackTypes.ERROR
            "
            i18n="@@Fsw_FeedbackSubmissionErrorTitle"
            >Senden Sie Ihren Fehler</span
          >
          <span *ngIf="showConfirmation" i18n="@@Fsw_FeedbackSubmitResponse"
            >Danke für jedes wertvolle Feedback!
          </span>
        </h6>
        <button class="btn btn--quiet" (click)="closeModal()">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon">
            <use href="#x"></use>
          </svg>
        </button>
      </header>
      <div class="p-6" *ngIf="showConfirmation">
        <!--suppress HtmlUnknownTarget -->
        <img class="mt-4" src="/assets/images/thank-you.png" alt="" />
      </div>
      <ng-container *ngIf="!showConfirmation">
        <button
          class="btn btn--quiet btn--small gap-1 !text-xs self-end mr-6"
          *ngIf="reportedFeedbackList?.length > 0"
          (click)="isReportedFeedbackListOpen = !isReportedFeedbackListOpen"
        >
          <span i18n="@@Fsw_PreviousFeedbacks">Vorherige Feedbacks</span>
          <span>({{ reportedFeedbackList?.length }})</span>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon--tiny">
            <use
              href="#expand-arrow-right"
              *ngIf="!isReportedFeedbackListOpen"
            ></use>
            <use
              href="#expand-arrow-left"
              *ngIf="isReportedFeedbackListOpen"
            ></use>
          </svg>
        </button>
        <form
          [formGroup]="feedbackForm"
          class="flex flex-col space-y-5 flex-1 overflow-y-auto px-6"
        >
          <div
            class="field-wrapper"
            *ngIf="feedbackSelection; else description"
          >
            <fs-boxy-radio-group formControlName="type">
              <fs-boxy-radio-group-option [value]="feedbackTypes.IDEA"
                ><span class="text-sm" i18n="@@Fsw_FeedbackReportImprovement"
                  >Ich habe einen Verbesserungsvorschlag</span
                ></fs-boxy-radio-group-option
              >
              <fs-boxy-radio-group-option [value]="feedbackTypes.ERROR">
                <span class="text-sm" i18n="@@Fsw_FeedbackReportError"
                  >Ich möchte einen Fehler melden</span
                >
              </fs-boxy-radio-group-option>
            </fs-boxy-radio-group>
            <ul class="textfield__errors">
              <li
                *ngIf="
                  feedbackForm.dirty &&
                  feedbackForm.controls.type.errors?.required
                "
                i18n="@@Fsw_ErrorsRequired"
              >
                Pflichtangabe
              </li>
            </ul>
          </div>
          <ng-template #description>
            <div class="field-wrapper">
              <label
                for="feedback-text"
                class="required"
                i18n="@@Fsw_FeedbackDescription"
                >Beschreibung</label
              >
              <div class="textfield !px-0">
                <textarea
                  class="py-2 px-3"
                  id="feedback-text"
                  formControlName="text"
                ></textarea>
              </div>
              <ul class="textfield__errors">
                <li
                  *ngIf="
                    feedbackForm.dirty &&
                    feedbackForm.controls.text.errors?.required
                  "
                  i18n="@@Fsw_ErrorsRequired"
                >
                  Pflichtangabe
                </li>
              </ul>
            </div>

            <fs-boxy-radio-group formControlName="includeScreenshot">
              <fs-boxy-radio-group-option [value]="includeScreenshotTypes.NONE"
                ><span class="text-sm" i18n="@@Fsw_FeedbackDontAttachScreenshot"
                  >Keinen Screenshot anhängen</span
                ></fs-boxy-radio-group-option
              >
              <fs-boxy-radio-group-option
                [value]="includeScreenshotTypes.CLIPBOARD"
              >
                <div class="flex flex-col space-y-2">
                  <span class="text-sm" i18n="@@Fsw_FeedbackClipboardImage"
                    >Ein Bild aus der Zwischenablage hinzufügen</span
                  >
                  <ng-container
                    *ngIf="
                      selectedIncludeScreenshotState ===
                      includeScreenshotTypes.CLIPBOARD
                    "
                  >
                    <div
                      class="text-gray-1000 text-xs"
                      i18n="@@Fsw_FeedbackClipboardImageExplanation"
                    >
                      Strg + V drücken um ein Bild aus der Zwischenablage
                      einzufügen
                    </div>

                    <div
                      *ngIf="viewableClipboardScreenshot"
                      class="mt-1 screenshot-wrapper rounded flex flex-col items-center"
                    >
                      <img
                        [src]="viewableClipboardScreenshot"
                        alt="screenshot"
                      />
                    </div>
                  </ng-container>
                </div>
              </fs-boxy-radio-group-option>
              <fs-boxy-radio-group-option
                [value]="includeScreenshotTypes.SCREENSHOT"
              >
                <span class="text-sm" i18n="@@Fsw_FeedbackAttachCurrentScreen"
                  >Einen Screenshot der aktuellen Anwendung anhängen</span
                >
              </fs-boxy-radio-group-option>
              <fs-boxy-radio-group-option
                [value]="includeScreenshotTypes.CUSTOM"
              >
                <div class="flex flex-col space-y-2">
                  <span class="text-sm" i18n="@@Fsw_FeedbackAttachImage"
                    >Ein eigenes Bild hochladen</span
                  >
                  <ng-container
                    *ngIf="
                      selectedIncludeScreenshotState ===
                      includeScreenshotTypes.CUSTOM
                    "
                  >
                    <div class="field-wrapper text-gray-1000">
                      <input
                        type="file"
                        (change)="handleUpload($event)"
                        accept="image/png, image/jpeg"
                      />
                    </div>

                    <div
                      *ngIf="viewableCustomScreenshot"
                      class="mt-1 screenshot-wrapper rounded flex flex-col items-center"
                    >
                      <img [src]="viewableCustomScreenshot" alt="screenshot" />
                    </div>
                  </ng-container>
                </div>
              </fs-boxy-radio-group-option>
            </fs-boxy-radio-group>
          </ng-template>
        </form>

        <footer class="flex space-x-3 justify-end px-6 py-5">
          <button
            class="btn btn--primary gap-1"
            (click)="submitFeedback()"
            [disabled]="submittingFeedbackInProgress"
          >
            <div
              class="lds-dual-ring"
              *ngIf="submittingFeedbackInProgress"
            ></div>
            <span i18n="@@Fsw_FeedbackSubmit">Abschicken</span>
          </button>
        </footer>
      </ng-container>
    </div>
    <div
      [@expandReportedPanel]="isReportedFeedbackListOpen"
      *ngIf="!showConfirmation"
    >
      <div
        class="h-full flex flex-col gap-4 reported-feedback-panel py-6 border-l border-gray-100 border-solid"
      >
        <div class="flex items-center px-4">
          <span class="font-medium flex-1" i18n="@@Fsw_PreviousFeedbacks"
            >Vorherige Feedbacks</span
          >
          <button
            class="btn btn--quiet"
            (click)="isReportedFeedbackListOpen = false"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="icon">
              <use href="#expand-arrow-left"></use>
            </svg>
          </button>
        </div>
        <div class="flex flex-col gap-4 px-4 overflow-y-auto flex-1">
          <div
            *ngFor="let feedback of reportedFeedbackList"
            class="flex gap-3 items-center py-2 px-3 bg-white rounded-md shadow-2 border border-gray-050 border-solid"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon--tiny shrink-0"
              [ngClass]="{
                'text-yellow-600': feedback.type === feedbackTypes.IDEA,
                'text-red-500': feedback.type === feedbackTypes.ERROR,
              }"
            >
              <ng-container [ngSwitch]="feedback.type">
                <use
                  *ngSwitchCase="feedbackTypes.IDEA"
                  href="#light-bulb"
                ></use>
                <use *ngSwitchCase="feedbackTypes.ERROR" href="#error"></use>
                <use *ngSwitchDefault href="#x"></use>
              </ng-container>
            </svg>
            <div class="flex flex-col flex-1 gap-1">
              <span
                class="clamp-text clamp-text--three-lines text-sm"
                fsContentTooltip
                >{{ feedback.text }}</span
              >
              <span class="self-end text-xs text-gray-700">{{
                feedback.createdAt | date
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
