<div class="flex flex-col gap-1">
  <label i18n="@@Fsw_GeneralCurrency">Währung</label>
  <fs-combobox
    id="currency-selector"
    [multiple]="false"
    [formControl]="currencyFormControl"
  >
    <fs-combobox-item *ngFor="let currency of currencies" [value]="currency"
      >{{ currency }} ({{ currency | currencyByIsoCode }})</fs-combobox-item
    >
  </fs-combobox>
</div>
