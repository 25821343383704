<div class="dropdown" (click)="$event.stopPropagation()">
  <ng-content select="[trigger]"></ng-content>
  <div
    class="dropdown__overlay flex-col space-y-1 p-2 text-gray-800 text-sm !border-gray-100"
    [ngClass]="{
      'dropdown__overlay--visible': showMenu,
      'dropdown__overlay--anchor-right': !anchorLeft
    }"
    (click)="closeOnClick && (showMenu = false)"
    [ngStyle]="{ 'z-index': overwriteZIndex }"
  >
    <ng-content></ng-content>
  </div>
  <div
    class="dropdown__backdrop"
    id="dropdown__backdrop"
    *ngIf="showMenu"
    (click)="showMenu = false"
    [ngStyle]="{ 'z-index': overwriteZIndex - 1 }"
  ></div>
</div>
