import { Component, Input } from '@angular/core';

@Component({
  selector: 'fs-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent {
  @Input()
  language: string = 'en';

  get url() {
    return `https://www.ebmpapst.com/de/${this.language}/imprint.html`;
  }
}
