import { Component } from '@angular/core';

@Component({
  selector: 'fs-svg-icon-sheet',
  templateUrl: './svg-icon-sheet.component.html',
  styleUrls: ['./svg-icon-sheet.component.scss'],
})
export class SvgIconSheetComponent {
  constructor() {}
}
