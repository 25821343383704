import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
// noinspection ES6PreferShortImport
import { PreferencesService } from '../../../../fanscout-api/api/preferences.service';
import { distinctUntilChanged, shareReplay, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SwitchUnitsService {
  private unitsInUSFormat: BehaviorSubject<boolean>;

  private initializeValuePromise;

  constructor(private preferencesService: PreferencesService) {
    this.initializeValuePromise = this.preferencesService
      .getPreferencesCached()
      .then(async (preferences) => {
        this.unitsInUSFormat = new BehaviorSubject(preferences.usUnits);
      })
      .catch(() => {
        this.unitsInUSFormat = new BehaviorSubject(false);
      });
  }

  areUnitsInUSFormat(): boolean {
    return this.unitsInUSFormat?.getValue() ?? false;
  }

  areUnitsInUSFormatPromise(): Promise<boolean> {
    return this.initializeValuePromise.then(() => this.areUnitsInUSFormat());
  }

  areUnitsInUSFormatAsObservable(): Observable<boolean> {
    return from(this.initializeValuePromise).pipe(
      switchMap(() => this.unitsInUSFormat.asObservable()),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  setUnitsInUSFormat(isUSFormat: boolean) {
    this.preferencesService.lockPreferenceUI(true);
    this.preferencesService.getPreferencesCached().then(async (preferences) => {
      if (preferences.usUnits !== isUSFormat) {
        await this.preferencesService.storePreferencesIfChanged({
          ...preferences,
          usUnits: isUSFormat,
        });
      }
      setTimeout(() => this.preferencesService.lockPreferenceUI(false), 1000);
    });

    if (this.areUnitsInUSFormat() !== isUSFormat) {
      this.unitsInUSFormat.next(isUSFormat);
    }
  }

  public lockedPreferenceUIObservable(): Observable<boolean> {
    return this.preferencesService.lockedPreferenceUIObservable();
  }
}
