<fs-dropdown [closeOnClick]="false" [overwriteZIndex]="110">
  <button
    title="Einstellungen öffnen"
    i18n-title="@@Fsw_GeneralOpenSettingMenu"
    id="settings"
    class="btn space-x-3"
    trigger
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon--tiny text-gray-700"
    >
      <use href="#globus"></use>
    </svg>
    <span class="text--normal"
      >{{ currentLanguage }} <span class="text-gray-400">/</span>
      {{ currentUnit ? 'I-P' : 'SI' }}
      <span class="text-gray-400">/</span>
      {{ currentCurrency | currencyByIsoCode }}</span
    >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon--small text-gray-700"
    >
      <use href="#chevron-down"></use>
    </svg>
  </button>

  <div
    class="setting-content-wrapper flex flex-col p-4 gap-3 mt-1 overflow-y-auto"
  >
    <fs-language-switch></fs-language-switch>

    <fs-unit-switch></fs-unit-switch>

    <fs-currency-switch></fs-currency-switch>

    <a
      class="text-blue-800 hover:underline cursor-pointer hover:text-blue-900 flex gap-1 items-center justify-end"
      [routerLink]="['/user/preferences']"
      routerLinkActive="hidden"
    >
      <span i18n="@@Fsw_NavMenuUserPreferences">Einstellungen</span>
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon--tiny">
        <use href="#arrow-right"></use>
      </svg>
    </a>
  </div>
</fs-dropdown>
