import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlCleanerService {
  private queryParamsToRemove = ['state', 'code', 'session_state'];

  constructor() {}

  public retrieveCurrentUrl(
    additionalQueryParameters: object = {},
    url = window.location.href
  ): string {
    const baseUrl = url.split('?')[0];

    const currentQueryParams = Object.fromEntries(
      new URLSearchParams(url.split('?')[1]).entries()
    );

    const queryParams = {
      ...this.cleanUpQueryParams(currentQueryParams),
      ...additionalQueryParameters,
    };

    const queryParamArray = this.queryParamObjectToArray(queryParams);

    return (
      baseUrl +
      (queryParamArray.length > 0 ? `?${queryParamArray.join('&')}` : '')
    );
  }

  private cleanUpQueryParams(queryParams: object = {}): object {
    for (const key of this.queryParamsToRemove) {
      delete queryParams[key];
    }
    return queryParams;
  }

  private queryParamObjectToArray(queryParams: object = {}): string[] {
    const queryStringArray = [];

    for (const key of Object.keys(queryParams)) {
      queryStringArray.push(`${key}=${queryParams[key]}`);
    }

    return queryStringArray;
  }

  public copyToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  }

  updateMeasurementIdIfNecessary(str: string): string {
    let measurmentId = this.getMeasurementIdFromUrl(str);
    const splitted = measurmentId?.split('-');

    if (splitted?.length > 1 && !measurmentId?.includes('LU')) {
      const newMeasurementId = `LU-${splitted[1]}-${splitted[0]}`;
      str = this.replaceQueryParam(str, 'measurementId', newMeasurementId);
    }

    return str;
  }

  private replaceQueryParam(
    url: string,
    paramName: string,
    newValue: string
  ): string {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set(paramName, newValue);
      return urlObj.toString();
    } catch (error) {
      console.error('Invalid URL:', error);
      return url;
    }
  }

  private getMeasurementIdFromUrl(url: string): string | null {
    try {
      const urlObj = new URL(url);
      return urlObj.searchParams.get('measurementId');
    } catch (error) {
      console.error('Invalid URL:', error);
      return null;
    }
  }
}
