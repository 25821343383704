import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'fs-privacy-protection',
  templateUrl: './privacy-protection.component.html',
  styleUrls: ['./privacy-protection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyProtectionComponent {
  @Input()
  language: string = 'en';

  get url() {
    return `https://www.ebmpapst.com/de/${this.language}/privacy-protection.html`;
  }
}
