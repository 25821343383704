// noinspection JSUnusedLocalSymbols

import {
  AfterContentInit,
  Component,
  ContentChildren,
  NgZone,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BoxyRadioGroupOptionComponent } from './boxy-radiogroup-option/boxy-radio-group-option.component';
import { defer, merge, Observable, Subject } from 'rxjs';
import { startWith, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fs-boxy-radio-group',
  templateUrl: './boxy-radio-group.component.html',
  styleUrls: ['./boxy-radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BoxyRadioGroupComponent,
    },
  ],
})
export class BoxyRadioGroupComponent
  implements AfterContentInit, OnDestroy, ControlValueAccessor
{
  private destroySubject = new Subject();

  @ContentChildren(BoxyRadioGroupOptionComponent)
  options: QueryList<BoxyRadioGroupOptionComponent>;

  value: any;

  onChange = (selected) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  readonly optionSelectionChanges: Observable<any> = defer(() => {
    const options = this.options;

    if (options) {
      return options.changes.pipe(
        startWith(options),
        switchMap(() => merge(...options.map((option) => option.onSelect)))
      );
    }

    return this.zone.onStable.pipe(
      take(1),
      switchMap(() => this.optionSelectionChanges)
    );
  }) as Observable<any>;

  constructor(private zone: NgZone) {}

  writeValue(value: any): void {
    this.value = value;
    this.updateSelectedItem();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    this.options?.forEach((option) => {
      option.disabled = disabled;
    });
  }

  ngAfterContentInit() {
    this.options.changes
      .pipe(startWith(''), takeUntil(this.destroySubject))
      .subscribe(() => {
        this.resetOptions();
        this.initializeSelection();
      });
  }

  private resetOptions(): void {
    const changedOrDestroyed = merge(this.options.changes, this.destroySubject);

    this.optionSelectionChanges
      .pipe(takeUntil(changedOrDestroyed))
      .subscribe((value) => {
        this.select(value);
      });
  }

  private initializeSelection() {
    Promise.resolve().then(() => {
      this.updateSelectedItem();
    });
  }

  private select(newValue: any) {
    this.markAsTouched();
    this.value = newValue;
    this.onChange(newValue);
    this.updateSelectedItem();
  }

  private updateSelectedItem() {
    this.options?.forEach((option) => {
      option.selected = option.value === this.value;
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
