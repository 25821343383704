import { Location } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { EventType } from 'angular-oauth2-oidc/events';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthStateService } from './core/auth/auth-state.service';
import { authConfig } from './core/auth/auth.config';

import * as Sentry from '@sentry/angular';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { UrlCleanerService } from './core/services/url-cleaner/url-cleaner.service';
import {
  TrackingEvents,
  TrackingVariables,
  UserTrackingService,
} from './core/services/user-tracking/user-tracking.service';
import { PreferencesService } from './fanscout-api/api/preferences.service';
import { NewrelicScriptService } from './core/services/newrelic-script/newrelic-script.service';

@Component({
  selector: 'fs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  authenticated = false;

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private location: Location,
    private authStateService: AuthStateService,
    private urlCleanerService: UrlCleanerService,
    private preferencesService: PreferencesService,
    private userTrackingService: UserTrackingService,
    private newrelicScriptService: NewrelicScriptService,
    @Inject(LOCALE_ID) public localeId: string
  ) {
    this.newrelicScriptService.injectNewrelicScript();
    this.configureOAuth2();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroySubject)
      )
      .subscribe(() => this.trackExternalCustomer());
  }

  private destroySubject = new Subject();

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private trackExternalCustomer(): void {
    const emailAddress = this.authStateService.getAuthIdentity()?.email;
    if (emailAddress == null) {
      return;
    }
    const isExternalCustomer: boolean =
      !emailAddress.endsWith('.ebmpapst.com') &&
      !emailAddress.includes('@ebmpapst.');
    const additionalTrackingInfo: TrackingVariables = {
      external_customer: isExternalCustomer,
    };
    this.userTrackingService.pushEventToDataLayer(
      TrackingEvents.ADDITIONAL_PAGE_VIEW_DATA,
      additionalTrackingInfo
    );
  }

  private configureOAuth2(): void {
    try {
      this.oauthService.configure(authConfig);
      this.oauthService
        .loadDiscoveryDocument(environment.auth.discoveryDocument)
        .then(() => {
          return this.oauthService.tryLoginCodeFlow();
        })
        .then(async () => {
          const success = this.oauthService.hasValidAccessToken();

          if (!success) {
            this.oauthService.initCodeFlow(this.stateString);
          } else {
            const isUrlLanguageCorrect =
              await this.correctUrlBasedOnPreferences();

            if (!isUrlLanguageCorrect) {
              return;
            }

            this.oauthService.setupAutomaticSilentRefresh();
            if (this.oauthService.state) {
              await this.router.navigateByUrl(this.authStateObject?.path, {
                replaceUrl: true,
              });
            }

            this.authenticated = success;
            this.authStateService.markAsAuthenticated();

            const eventTypesRequiringReload: EventType[] = [
              'session_terminated',
              'session_error',
              'token_refresh_error',
              'code_error',
              'silent_refresh_error',
              'invalid_nonce_in_state',
            ];
            this.oauthService.events
              .pipe(filter((e) => eventTypesRequiringReload.includes(e.type)))
              .subscribe(() =>
                this.oauthService.initCodeFlow(this.stateString)
              );
          }
        })
        .catch((e) => {
          this.handlePasswordResetCodeError(e);

          if (
            e.type === 'invalid_nonce_in_state' ||
            e?.error?.error === 'invalid_grant' ||
            e.type === 'code_error' ||
            e?.error?.error === 'code_error'
          ) {
            this.oauthService.initCodeFlow(this.stateString);
          } else {
            Sentry.captureException(e?.error ?? e, {
              level: 'error',
              extra: {
                where: 'promise catch auth',
                fullError: e,
              },
            });
            console.error(e);
          }
        });
    } catch (e) {
      if (e?.error?.error === 'invalid_grant') {
        this.oauthService.initCodeFlow();
      } else {
        Sentry.captureException(e?.error ?? e, {
          level: 'error',
          extra: {
            where: 'try catch auth',
            fullError: e,
          },
        });
        console.error(e);
      }
    }
  }

  /**
   * In case of a password reset the error will contain an error code
   * If that's the case change the login url to redirect to the correct B2C password reset UI
   * @param e Auth Error object
   * @private
   */
  private handlePasswordResetCodeError(e) {
    if (this.userHasRequestedPasswordReset(e)) {
      // In this case we need to enter a different flow on the Azure AD B2C side.
      // This is still a valid Code + PKCE flow, but uses a different form to support self-service password reset
      this.oauthService.loginUrl = this.oauthService.loginUrl.replace(
        'b2c_1a_signup_signin',
        'b2c_1a_passwordreset'
      );
    }
  }

  private async correctUrlBasedOnPreferences(): Promise<boolean> {
    try {
      if (environment.production) {
        const preferredLanguage =
          (await this.preferencesService.unsafeGetPreferences())
            ?.defaultLanguage ?? this.localeId;
        if (preferredLanguage.toLowerCase() !== this.localeId.toLowerCase()) {
          const cleanedPath = this.urlCleanerService.retrieveCurrentUrl(
            undefined,
            this.authStateObject?.path || this.location.path()
          );
          window.location.href =
            window.location.origin +
            `/${preferredLanguage.toLowerCase()}${cleanedPath || '/'}`;
          return false;
        }
      }
    } catch (e) {}
    return true;
  }

  // Required for password reset flow. Might not be needed down the line
  private userHasRequestedPasswordReset(err: OAuthErrorEvent): boolean {
    return (err?.params?.['error_description'] ?? '').startsWith('AADB2C90118');
  }

  get stateString(): string {
    const cleanedPath = this.urlCleanerService.retrieveCurrentUrl(
      undefined,
      this.location.path()
    );
    return btoa(
      JSON.stringify({
        path: cleanedPath,
      })
    );
  }

  get authStateObject(): { path?: string } {
    try {
      const state = decodeURIComponent(this.oauthService.state);
      return state ? (JSON.parse(atob(state)) as { path?: string }) : {};
    } catch {
      return {};
    }
  }
}
