import { Injectable } from '@angular/core';

export enum TrackingEvents {
  ADDITIONAL_PAGE_VIEW_DATA = 'additional_page_view_data',
  APPLY_FILTER = 'apply_filter',
  DOWNLOAD_OVERVIEW_PDF = 'download_overview_pdf',
  DOWNLOAD_DETAIL_PDF = 'download_detail_pdf',
  DOWNLOAD_DATA_SHEET = 'download_data_sheet',
  DOWNLOAD_OPERATING_INSTRUCTIONS = 'download_operating_instructions',
}

export interface TrackingVariables {
  external_customer?: boolean;

  fs_air_flow?: number;
  fs_pressure?: number;
  fs_operating_time?: number;
  fs_index_in_operating_points?: number;
  fs_number_of_operating_points?: number;
  fs_group_id?: number;
  fs_filter_value_in_us_units?: boolean;
  fs_width?: boolean;
  fs_height?: boolean;
  fs_depth?: boolean;

  fs_material_number?: string;
  fs_type_key?: string;

  fs_download_overview_pdf?: boolean;
  fs_download_detail_pdf?: boolean;
  fs_download_data_sheet?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserTrackingService {
  constructor() {}

  public pushEventToDataLayer(
    eventName: TrackingEvents,
    additionalInformation: TrackingVariables = {}
  ): void {
    if (!Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer = [];
    }
    (window as any).dataLayer.push({
      event: eventName,
      ...additionalInformation,
    });
  }

  public pushGroupedEventsToDataLayer(
    eventName: TrackingEvents,
    additionalInformation: TrackingVariables[] = []
  ): void {
    const currentDate = Date.now().toString();
    additionalInformation.forEach((item) =>
      this.pushEventToDataLayer(eventName, {
        ...item,
        fs_group_id: this.createHashCode(currentDate + JSON.stringify(item)),
      })
    );
  }

  // simple hash function - basically copied from stackoverflow:
  private createHashCode = function (argument) {
    var hash = 0,
      i,
      chr;
    if (argument.length === 0) return hash;
    for (i = 0; i < argument.length; i++) {
      chr = argument.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };
}
