import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

@Pipe({
  name: 'currencyByIsoCode',
})
export class CurrencyByIsoCodePipe implements PipeTransform {
  transform(code: string, format?: 'narrow', ...args: unknown[]): string {
    return getCurrencySymbol(code, format);
  }
}
