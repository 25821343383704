<div (keydown)="handleKeyDown($event)">
  <div
    #trigger
    cdkOverlayOrigin
    #comboboxTrigger="cdkOverlayOrigin"
    class="textfield"
    [ngClass]="{
      'bg-gray-100': disabled,
      'text-gray-600': disabled,
      '!border-0': !bordered,
      'cursor-pointer': !multiple
    }"
    (click)="openCombobox(searchInput)"
  >
    <div class="flex-1 selected-wrapper flex items-center" *ngIf="!isOpen">
      <span class="prefix-wrapper">
        <ng-content select="[fsPrefix]"></ng-content>
      </span>
      <span *ngIf="!multiple && !!selectedItem?.viewValue">{{
        selectedItem.viewValue
      }}</span>
      <span *ngIf="multiple && selectedItems" class="flex gap-1 flex-wrap py-1">
        <div
          *ngFor="let item of selectedItems"
          class="chip chip--secondary text-sm flex space-x-2 items-center"
          (click)="$event.stopPropagation()"
        >
          <span>{{ item.viewValue }}</span>
          <a
            (click)="select(item, true); $event.stopPropagation()"
            class="cursor-pointer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon--tiny">
              <use href="#trash"></use>
            </svg>
          </a>
        </div>
      </span>
      <span
        *ngIf="
          !selectedItem?.viewValue &&
          !(multiple && selectedItems.length > 0) &&
          placeholder
        "
        class="text-gray-500 select-none"
        >{{ placeholder }}</span
      >
    </div>

    <input
      [ngClass]="{
        visible: isOpen,
        'overflow-hidden': !isOpen,
        'max-w-0': !isOpen
      }"
      type="search"
      [value]="value"
      #searchInput
      (input)="filter($event)"
      (focusin)="inputHasFocus = true; openCombobox()"
      (focusout)="inputHasFocus = false"
      autocomplete="off"
      [disabled]="disabled"
      [id]="id"
    />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon"
      *ngIf="!this.isOpen"
      (click)="openCombobox(searchInput)"
    >
      <use href="#chevron-down"></use>
    </svg>

    <a
      class="cursor-pointer"
      *ngIf="this.isOpen"
      [ngClass]="{
        'p-1 rounded-full bg-blue-050 text-blue-800 hover:bg-blue-100': multiple
      }"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="icon">
        <use href="#check" *ngIf="multiple"></use>
        <use href="#x" *ngIf="!multiple"></use>
      </svg>
    </a>
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="comboboxTrigger"
    [cdkConnectedOverlayPositions]="overlayPosition"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayMinWidth]="triggerRect?.width!"
    [cdkConnectedOverlayDisableClose]="true"
    (overlayOutsideClick)="
      handleOutsideOverlayClick(); $event.stopPropagation()
    "
  >
    <div
      class="rounded shadow flex flex-col animation-fade-in bg-white border border-gray-100 border-solid w-full mt-1 mb-1 max-h-56 overflow-auto"
      role="menu"
    >
      <ng-content></ng-content>
    </div>
  </ng-template>
</div>
