import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRoleGuard } from './core/auth/auth-role.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/product-overview/product-overview.module').then(
        (module) => module.ProductOverviewModule
      ),
  },
  {
    path: 'details',
    loadChildren: () =>
      import('./modules/product-detail/product-detail.module').then(
        (module) => module.ProductDetailModule
      ),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./modules/user-profile/user-profile.module').then(
        (module) => module.UserProfileModule
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then(
        (module) => module.AdminModule
      ),
    canActivate: [AuthRoleGuard],
    data: {
      expectedRoles: ['fan-scout-admin'],
    },
  },
  {
    path: 'release-notes',
    loadChildren: () =>
      import('./modules/release-notes/release-notes.module').then(
        (module) => module.ReleaseNotesModule
      ),
  },
  {
    path: 'collection-management',
    loadChildren: () =>
      import(
        './modules/collection-management/collection-management.module'
      ).then((module) => module.CollectionManagementModule),
  },
  {
    path: 'help',
    loadChildren: () =>
      import('./modules/help-center/help-center.module').then(
        (module) => module.HelpCenterModule
      ),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then((module) => module.HomeModule),
    canActivate: [AuthRoleGuard],
    data: {
      expectedRoles: ['fan-scout-admin'],
    },
  },
  {
    path: 'guided-search',
    loadChildren: () =>
      import('./modules/guided-search/guided-search.module').then(
        (module) => module.GuidedSearchModule
      ),
  },
  {
    path: 'retrofit',
    loadChildren: () =>
      import('./modules/retrofit/retrofit.module').then(
        (module) => module.RetrofitModule
      ),
  },
  { path: '**', redirectTo: '/' },
];

/**
 * Sets up the main routing of the application
 * @author ebm-papst 2018 Industry Solutions - Web Development Services (dvreinwm)
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
