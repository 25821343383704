<div *ngIf="showEnvironment" class="chip chip--friendly">
  <span [ngSwitch]="environment">
    <span *ngSwitchCase="environments.DEV" i18n="@@Fsw_GeneralEnvironmentDev"
      >Entwicklungsumgebung</span
    >
    <span *ngSwitchCase="environments.QA" i18n="@@Fsw_GeneralEnvironmentQa"
      >Test Umgebung</span
    >
    <span
      *ngSwitchCase="environments.LOCALHOST"
      i18n="@@Fsw_GeneralEnvironmentLocalhost"
      >Lokale Umgebung</span
    >
  </span>
</div>
