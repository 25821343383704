<div class="field-wrapper">
  <div class="flex w-full">
    <label [for]="randomId" [ngClass]="{ required: required }">
      <ng-content select="fs-form-label"></ng-content>
      <ng-content select="[fsFormLabel]"></ng-content>
    </label>
    <ng-content select="[fsFormLabelSuffix]"></ng-content>
  </div>
  <div class="flex space-x-3">
    <div
      [ngClass]="{
        'textfield--error': control.invalid || hasAdditionalErrors
      }"
      class="textfield flex-1"
    >
      <ng-content select="[fsPrefix]"></ng-content>
      <input
        *ngIf="type === 'number'"
        type="number"
        [formControl]="controlBinding"
        [id]="randomId"
        [min]="min ?? ''"
        [max]="max ?? ''"
        [step]="step ?? ''"
        [attr.aria-label]="ariaLabel"
        [title]="title"
        [placeholder]="placeholder"
        [autocomplete]="autocomplete"
      />
      <input
        *ngIf="type === 'text'"
        type="text"
        [formControl]="controlBinding"
        [id]="randomId"
        [min]="min ?? ''"
        [max]="max ?? ''"
        [attr.aria-label]="ariaLabel"
        [title]="title"
        [placeholder]="placeholder"
        [autocomplete]="autocomplete"
      />
      <ng-content select="[fsSuffix]"></ng-content>
    </div>
    <ng-content select="[fsOutlierSuffix]"></ng-content>
  </div>

  <ng-content select="[fsFieldHint]"></ng-content>

  <ng-container *ngIf="!fieldErrorWrapper">
    <ng-content select=".textfield__errors"></ng-content>
  </ng-container>
  <ul class="textfield__errors" *ngIf="fieldErrorWrapper">
    <ng-container
      [ngTemplateOutlet]="fieldErrorWrapper.template"
      [ngTemplateOutletContext]="{
        $implicit: control.errors
      }"
    ></ng-container>
  </ul>
</div>
