<ng-container *ngIf="showBanner">
  <div
    *ngIf="maintenanceMessage | async as message"
    class="py-2 px-6 flex items-center gap-4 text-white text-sm shadow-lg"
    [ngClass]="{
      'bg-blue-800': message.priority === 'info',
      'bg-orange-800': message.priority === 'warning',
      'bg-red-600': message.priority === 'critical'
    }"
  >
    <svg xmlns="http://www.w3.org/2000/svg" class="icon">
      <use href="#exclamation-circle-filled"></use>
    </svg>
    <span
      class="flex-1 font-medium overflow-hidden whitespace-nowrap text-ellipsis clamp-text clamp-text--one-line"
      [title]="message.summary"
      >{{ message.summary }}</span
    >
    <a
      class="btn btn--quiet !text-white btn--small !bg-transparent"
      (click)="showMessageDescription()"
      i18n="@@Fsw_MoreAction"
      >Mehr...</a
    >
    <button
      class="btn btn--quiet !text-white btn--small !bg-transparent"
      (click)="hideBanner()"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="icon">
        <use href="#x"></use>
      </svg>
    </button>
  </div>
</ng-container>
<fs-maintenance-message-modal
  [(showModal)]="showModal"
  [maintenanceMessage]="maintenanceMessage | async"
></fs-maintenance-message-modal>
