<div
  #trigger
  (mouseenter)="type === 'onHover' ? showTooltip() : true"
  (focus)="type === 'onHover' ? showTooltip() : true"
  (mouseleave)="type === 'onHover' ? hideTooltip() : true"
  (blur)="type === 'onHover' ? hideTooltip() : true"
  (click)="type === 'onClick' ? showTooltip() : true"
>
  <ng-content></ng-content>
</div>

<div
  #tooltip
  style="display: none"
  [ngClass]="{
    fixed: this.strategy === 'fixed',
    absolute: strategy === 'absolute'
  }"
  class="w-max top-0 left-0 z-[105]"
>
  <ng-content select="[fsPopoverContent]"></ng-content>
</div>

<div
  class="opacity-20 fixed inset-0 z-[104]"
  *ngIf="!disabled && type === 'onClick' && isDisplayed && hasBackdrop"
  (click)="closeOnBackdropClick ? hideTooltip() : true"
  [ngClass]="{ 'bg-gray-900': isBackdropVisible }"
></div>
