import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.auth.issuer,

  // URL of the SPA to redirect the user to after login
  redirectUri:
    !environment.production || window.location.host.startsWith('localhost:')
      ? window.location.origin
      : `https://${window.location.host}`,

  responseType: 'code',

  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: environment.auth.clientId,

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile email ' + environment.auth.apiScopes,

  showDebugInformation: false,

  postLogoutRedirectUri: window.location.origin + window.location.pathname,

  strictDiscoveryDocumentValidation: false,

  logoutUrl: environment.auth.logoutUrl,
};
