/**
 * FanScout-API-Gateway
 * API Gateway providing access on whole services from the FanScout domain
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { ProductBrand } from '../model/productBrand';
import { ProductConstruction } from '../model/productConstruction';
import { ProductDesignation } from '../model/productDesignation';
import { BASE_PATH } from '../variables';

@Injectable()
export class FilterService {
  protected basePath = 'http://kong-fs.dev.cloud.ebmpapst.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * get all product brands
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public productBrandsGet(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ProductBrand>>;
  public productBrandsGet(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ProductBrand>>>;
  public productBrandsGet(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ProductBrand>>>;
  public productBrandsGet(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const headers = this.defaultHeaders.set(
      'Accept',
      'application/product-brand+v1+json'
    );

    return this.httpClient.get<Array<ProductBrand>>(
      `${this.basePath}/product-brands`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * get all product constructions
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public productConstructionsGet(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ProductConstruction>>;
  public productConstructionsGet(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ProductConstruction>>>;
  public productConstructionsGet(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ProductConstruction>>>;
  public productConstructionsGet(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const headers = this.defaultHeaders.set(
      'Accept',
      'application/product-construction+v1+json'
    );

    return this.httpClient.get<Array<ProductConstruction>>(
      `${this.basePath}/product-constructions`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * get all product designations
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public productDesignationsGet(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ProductDesignation>>;
  public productDesignationsGet(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ProductDesignation>>>;
  public productDesignationsGet(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ProductDesignation>>>;
  public productDesignationsGet(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const headers = this.defaultHeaders.set(
      'Accept',
      'application/product-designation+v1+json'
    );

    return this.httpClient.get<Array<ProductDesignation>>(
      `${this.basePath}/product-designations`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  public manufacturedSizesGet(
    selectedCollectionIds: string[],
    productSearch: string[]
  ): Observable<string[]> {
    const headers = this.defaultHeaders.set('Accept', 'application/json');

    let queryParams = new HttpParams();
    queryParams = queryParams.set(
      'collection-ids',
      selectedCollectionIds.join(',')
    );
    queryParams = queryParams.set('product-search', productSearch.join(','));

    return this.httpClient.get<string[]>(
      `${this.basePath}/manufactured-sizes`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        params: queryParams,
      }
    );
  }
}
