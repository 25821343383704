export interface Price {
  value?: number;
  currency?: Currency;
}

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  CNY = 'CNY', // Chinese Yuan Renminbi
  INR = 'INR', // Indian Rupee
  SEK = 'SEK', // Swedish Krona
  DKK = 'DKK', // Danish Krone
  GBP = 'GBP', // British Pound
  AUD = 'AUD', // Australian Dollar
  CHF = 'CHF', // Swiss Franc
  JPY = 'JPY', // Japanese Yen
  IDR = 'IDR', // Indonesian Rupiah
  MXN = 'MXN', // Mexican Peso
  TRY = 'TRY', // Turkish Lira
  KRW = 'KRW', // South Korean Won
  ILS = 'ILS', // New Israeli Sheqel
  SYP = 'SYP', // Syrian Pound
  SAR = 'SAR', // Saudi Riyal
  NOK = 'NOK', // Norwegian Krone
  BRL = 'BRL', // Brazilian Real
  NZD = 'NZD', // New Zealand Dollar
  ZAR = 'ZAR', // South Africa: Rand
  RON = 'RON', // Romanian Leu
  UAH = 'UAH', // Ukraine: Hryvnia
  MYR = 'MYR', // Malaysian Ringgit
  COP = 'COP', // Colombian Peso
  ARS = 'ARS', // Argentine Peso
  PHP = 'PHP', // Philippine Peso
  RSD = 'RSD', // Serbian Dinar
  RUB = 'RUB', // Russian Ruble
  SGD = 'SGD', // Singapore Dollar
  THB = 'THB', // Thailand: Baht
  VND = 'VND', // Vietnam: Dong
  HUF = 'HUF', // Hungary: Forint
  PLN = 'PLN', // Poland: Zloty
}
