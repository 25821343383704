import { TourSetting } from '../../../../core/onboarding/tour-setting';

export const toolbarTour: TourSetting = {
  name: 'toolbarTour',
  steps: [
    {
      id: 'welcome',
      title: $localize`:@@Fsw_WelcomeTourTitle:Willkommen zum FanScout!`,
      text: $localize`:@@Fsw_WelcomeTourText:Willkommen zum FanScout! In dieser Tour werden Ihnen einige nützliche Features vorgestellt, um den Einstieg in das Tool zu erleichtern.
      Der Fanscout hilft Ihnen, das für Sie bestpassende ebm-papst Produkt zu finden!`,
    },
    {
      id: 'userProfile',
      title: $localize`:@@Fsw_UserProfileTourTitle:Nutzerprofil`,
      text: $localize`:@@Fsw_UserProfileTourText:Hier finden Sie Informationen zu Ihrem Nutzerprofil und den Rahmenbedingungen. Außerdem können die Einstellungen und eine Hilfe-Datei geöffnet werden.`,
      attachTo: {
        element: 'fs-user-display',
        on: 'bottom',
      },
    },
    {
      id: 'settings',
      title: $localize`:@@Fsw_UserSettingsTourTitle:Einstellungen`,
      text: $localize`:@@Fsw_UserSettingsTourText:Hier haben Sie die Möglichkeit, Ihre Nutzereinstellungen (Sprache, Einheit und Währung) zu verwalten und die Einstellungen zu öffnen.`,
      attachTo: {
        element: 'fs-settings',
        on: 'bottom',
      },
    },
    {
      id: 'feedback',
      title: $localize`:@@Fsw_FeedbackTourTitle:Feedback`,
      text: $localize`:@@Fsw_FeedbackTourText:Hier können Sie uns gerne ein Feedback zum FanScout geben, uns Ihre Ideen mitteilen oder uns gegebenenfalls auf einen Fehler hinweisen.`,
      attachTo: {
        element: 'fs-submit-feedback',
        on: 'bottom',
      },
    },
  ],
};
