<div *ngIf="showModal" class="animation-fade-in animation-speed--75">
  <section
    class="backdrop"
    (click)="closeModal(); $event.stopPropagation()"
  ></section>
  <section class="modal flex flex-col gap-y-5">
    <div class="flex gap-x-3 items-center">
      <h6
        class="font-medium flex-1 text-gray-700"
        i18n="@@Fsw_NavMenuUserAbout"
      >
        Information
      </h6>
      <button class="btn btn--quiet" (click)="closeModal()">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon">
          <use href="#x"></use>
        </svg>
      </button>
    </div>
    <div *ngIf="calculationVersionInfo">
      <div class="flex flex-col gap-y-1">
        <span class="subheading">{{ calculationVersionInfo?.title }}</span>
        <span class="text-sm text-gray-600">{{
          calculationVersionInfo?.description
        }}</span>
        <div class="flex mt-4 gap-x-5">
          <div class="flex-1 flex flex-col">
            <label class="text-gray-400" i18n="@@Fsw_WebserviceVersion"
              >Webservice Version</label
            >
            <span class="text font-medium">{{
              calculationVersionInfo?.webserviceVersion
            }}</span>
          </div>
          <div class="flex-1 flex flex-col">
            <label class="text-gray-400" i18n="@@Fsw_SoVersion"
              >SO Version</label
            >
            <span class="text font-medium">{{
              calculationVersionInfo?.soVersion
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
