import { Component } from '@angular/core';

@Component({
  selector: 'fs-product-category-icon-sheet',
  templateUrl: './product-category-icon-sheet.component.html',
  styleUrls: ['./product-category-icon-sheet.component.scss'],
})
export class ProductCategoryIconSheetComponent {
  constructor() {}
}
