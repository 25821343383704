export const environment = {
  production: true,
  name: 'prod',
  auth: {
    issuer:
      'https://ebmpapstadb2c.b2clogin.com/tfp/37ee1609-707c-4f77-95b9-6e2bd75e6b6f/b2c_1a_signup_signin/v2.0/',
    clientId: 'a277091d-1459-46fa-bb3a-b7fa47028ab0',
    apiScopes:
      'https://account.ebmpapst.net/8b0d122a-7c4c-416a-a18c-5183f4169862/Api.Access',
    logoutUrl:
      'https://ebmpapstadb2c.b2clogin.com/account.ebmpapst.net/oauth2/v2.0/logout?p=B2C_1A_SIGNUP_SIGNIN',
    discoveryDocument:
      'https://ebmpapstadb2c.b2clogin.com/account.ebmpapst.net/B2C_1A_signup_signin/v2.0/.well-known/openid-configuration',
  },
};
