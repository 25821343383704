import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CurrencySwitchComponent } from './components/currency-switch/currency-switch.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { UnitSwitchComponent } from './components/unit-switch/unit-switch.component';

/**
 * Contains general components and services for switching general application settings like the language and unit to be used in multiple other modules
 */
@NgModule({
  declarations: [
    LanguageSwitchComponent,
    UnitSwitchComponent,
    CurrencySwitchComponent,
  ],
  imports: [SharedModule],
  exports: [
    LanguageSwitchComponent,
    UnitSwitchComponent,
    CurrencySwitchComponent,
  ],
})
export class SettingsSwitchersModule {}
