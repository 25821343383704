import { Component, OnInit } from '@angular/core';
import { MaintenanceMessageService } from '../../../../fanscout-api/api/maintenance-message.service';
import { MaintenanceMessage } from '../../../../fanscout-api/model/maintenance-message';

@Component({
  selector: 'fs-maintenance-banner',
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.scss'],
})
export class MaintenanceBannerComponent implements OnInit {
  maintenanceMessage: Promise<MaintenanceMessage>;

  showBanner = true;

  showModal = false;

  constructor(private maintenanceMessageService: MaintenanceMessageService) {}

  ngOnInit() {
    this.maintenanceMessage =
      this.maintenanceMessageService.getMaintenanceMessage();
  }

  hideBanner() {
    this.showBanner = false;
  }

  showMessageDescription() {
    this.showModal = true;
  }
}
