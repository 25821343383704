import { Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'fs-skip-tour-confirmation-dialog',
  templateUrl: './skip-tour-confirmation-dialog.component.html',
  styleUrls: ['./skip-tour-confirmation-dialog.component.scss'],
})
export class SkipTourConfirmationDialogComponent {
  constructor(private dialogRef: DialogRef) {}

  cancelSkip() {
    this.dialogRef.close(false);
  }

  confirmSkip() {
    this.dialogRef.close(true);
  }
}
