import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'fs-pagination-bar',
  templateUrl: './pagination-bar.component.html',
  styleUrls: ['./pagination-bar.component.scss'],
})
export class PaginationBarComponent implements OnChanges {
  @Input()
  page = 1;

  @Input()
  itemSize;

  @Input()
  pageSize = 5;

  @Input()
  compact = false;

  @Output()
  pageChange: EventEmitter<PaginationEvent> =
    new EventEmitter<PaginationEvent>();

  pageSizes = [5, 10, 20, 50];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemSize != null) {
      const sendEvent = this.page !== 1;
      this.page = 1;
      if (sendEvent) {
        this.sendEvent();
      }
    }
  }

  handleChangePageSize() {
    this.page = 1;
    this.sendEvent();
  }

  get maxPage(): number {
    return Math.ceil(this.itemSize / this.pageSize) || 1;
  }

  forward(): void {
    if (this.page < this.maxPage) {
      this.page++;
      this.sendEvent();
    }
  }

  backwards(): void {
    if (this.page > 1) {
      this.page--;
      this.sendEvent();
    }
  }

  private sendEvent() {
    this.pageChange.emit({
      page: this.page,
      pageSize: this.pageSize,
    });
  }

  get maxDisplayItem(): number {
    return Math.min(this.page * this.pageSize, this.itemSize);
  }
}

export interface PaginationEvent {
  page: number;
  pageSize: number;
}
