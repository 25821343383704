import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SwitchCurrencyService } from '../../services/switch-currency/switch-currency.service';
import { Currency } from '../../../../fanscout-api/model/price';
import { UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fs-currency-switch',
  templateUrl: './currency-switch.component.html',
  styleUrls: ['./currency-switch.component.scss'],
})
export class CurrencySwitchComponent implements OnInit, OnDestroy {
  currencies = Object.keys(Currency);

  currencyFormControl = new UntypedFormControl(Currency.EUR);

  private destroySubject = new Subject();

  constructor(private switchCurrencyService: SwitchCurrencyService) {}

  ngOnInit(): void {
    this.switchCurrencyService
      .currencyPromise()
      .then((currency) =>
        this.currencyFormControl.setValue(currency, { emitEvent: false })
      );

    this.currencyFormControl.valueChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe((currency) =>
        this.switchCurrencyService.setCurrency(currency)
      );
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
