import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ReleaseNotesService } from '../../../../fanscout-api/api/release-notes.service';
import { PaginationEvent } from '../../../../shared/components/pagination-bar/pagination-bar.component';
import { marked } from 'marked';

@Component({
  selector: 'fs-release-notes',
  templateUrl: './release-notes-overview.component.html',
  styleUrls: ['./release-notes-overview.component.scss'],
})
export class ReleaseNotesOverviewComponent implements OnInit, OnDestroy {
  @Input()
  language: string = 'en';

  private destroySubject = new Subject();

  loading = false;

  initPageSize = 5;

  @Output()
  showAboutModal: EventEmitter<void> = new EventEmitter();

  searchForm = new UntypedFormControl('');

  paginationSubject = new BehaviorSubject<PaginationEvent>({
    page: 1,
    pageSize: this.initPageSize,
  });
  releaseNotes: Observable<any>;

  constructor(private releaseNotesService: ReleaseNotesService) {}

  ngOnInit() {
    // release notes
    this.releaseNotes = this.paginationSubject.pipe(
      distinctUntilChanged(),
      tap(() => (this.loading = true)),
      switchMap((pagination) => {
        const pageSize = pagination.pageSize;
        const start = (pagination.page - 1) * pagination.pageSize;
        return this.releaseNotesService.getReleaseNotes(pageSize, start);
      }),
      map((result) => {
        return result.data.map(
          (attr: {
            attributes: {
              whatsNew: [{ title: string; description: string }];
              comingSoon: [{ title: string; description: string }];
              bugfixes: [{ title: string; description: string }];
            };
            id: number;
          }) => {
            const { whatsNew, comingSoon, bugfixes } = attr.attributes;

            return {
              id: attr.id,
              ...attr.attributes,
              whatsNew: whatsNew.map((item) => {
                return {
                  title: item.title,
                  description: this.parseMarkdownAndChangeUploadPaths(
                    item.description
                  ),
                };
              }),
              comingSoon: comingSoon.map((item) => {
                return {
                  title: item.title,
                  description: this.parseMarkdownAndChangeUploadPaths(
                    item.description
                  ),
                };
              }),
              bugfixes: bugfixes.map((item) => {
                return {
                  title: item.title,
                  description: this.parseMarkdownAndChangeUploadPaths(
                    item.description
                  ),
                };
              }),
              totalCount: result?.meta?.pagination?.total,
            };
          }
        );
      }),
      tap(() => (this.loading = false)),
      takeUntil(this.destroySubject),
      shareReplay(1)
    );
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  parseMarkdownAndChangeUploadPaths(text: string) {
    const regex = /https:\/\/.*\/uploads/gi;
    return marked
      .parse(text)
      .replace(regex, '/uploads')
      .split('/uploads')
      .join('/api/uploads');
  }
}
