import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomFeedbackDataRegisterService {
  private _customLinkGenerator: () => string | Promise<string>;

  constructor() {}

  registerCustomLinkGenerator(generator: () => string | Promise<string>) {
    this._customLinkGenerator = generator;
  }

  unregisterCustomLinkGenerator() {
    this._customLinkGenerator = undefined;
  }

  get hasCustomLinkGenerator(): boolean {
    return this._customLinkGenerator != null;
  }

  get customLinkGenerator(): () => string | Promise<string> {
    return this._customLinkGenerator;
  }
}
