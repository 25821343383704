import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OperatingPointSummaryTextPipe } from './pipes/operating-point-summary-text/operating-point-summary-text.pipe';
import { ProductPriceByCurrencyPipe } from './pipes/product-price-by-currency/product-price-by-currency.pipe';
import { CurrencyByIsoCodePipe } from './pipes/currency-by-iso-code/currency-by-iso-code.pipe';
import { UnsavedChangesConfirmationDialogComponent } from './components/unsaved-changes-confirmation-dialog/unsaved-changes-confirmation-dialog.component';
import { SecureImagePipe } from './pipes/secure-image-pipe/secure-image-pipe';
import { CommaSafeNumberPipe } from './pipes/comma-safe-number/comma-safe-number.pipe';
import { ContentTooltipDirective } from './directives/content-tooltip.directive';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { PhysicalValueInfoDisplayPipe } from './pipes/physical-value-info-display/physical-value-info-display.pipe';
import { OperatingPointChipComponent } from './components/operating-point-chip/operating-point-chip.component';
import { ShareUrlButtonComponent } from './components/share-url-button/share-url-button.component';
import { LoadingIndicatorSnackBarComponent } from './components/loading-indicator-snack-bar/loading-indicator-snack-bar.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DropdownTriggerDirective } from './components/dropdown/dropdown-trigger.directive';
import { PaginationBarComponent } from './components/pagination-bar/pagination-bar.component';
import { SimpleProgressIndicatorComponent } from './components/simple-progress-indicator/simple-progress-indicator.component';
import { IsIncludedPipe } from './pipes/is-included/is-included.pipe';
import { ComboboxComponent } from './components/combobox/combobox.component';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  ComboboxItemComponent,
  ComboboxItemDirective,
} from './components/combobox/combobox-item/combobox-item.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import {
  FormLabelSuffixDirective,
  OutlierSuffixDirective,
  SuffixDirective,
} from './components/form-field/suffix.directive';
import {
  FormLabelComponent,
  FormLabelDirective,
} from './components/form-field/form-label.component';
import { FieldErrorWrapperDirective } from './components/form-field/field-error-wrapper.directive';
import { FieldHintDirective } from './components/form-field/field-hint.directive';
import { PrefixDirective } from './components/form-field/prefix.directive';
import { BoxyRadioGroupComponent } from './components/boxy-radiogroup/boxy-radio-group.component';
import { BoxyRadioGroupOptionComponent } from './components/boxy-radiogroup/boxy-radiogroup-option/boxy-radio-group-option.component';
import { AirDensityFilterFieldComponent } from './components/air-density-filter-field/air-density-filter-field.component';
import { AirDensityCalculatorComponent } from './components/air-density-calculator/air-density-calculator.component';
import { RouterModule } from '@angular/router';
import { LastModifiedCollectionPipe } from './pipes/last-modified-collection/last-modified-collection.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { MultiValueInputFieldComponent } from './components/multi-value-input-field/multi-value-input-field.component';
import { IsCollectionFavoritePipe } from './pipes/is-collection-favorite/is-collection-favorite.pipe';
import { GuardGrillValueTranslatedPipe } from './pipes/guard-grill-value-translated/guard-grill-value-translated.pipe';
import { FavoriteStarButtonComponent } from './components/favorite-heart-button/favorite-heart-button.component';
import { KeysOfObjectPipe } from './pipes/keys-of-object/keys-of-object.pipe';
import { CollapsibleCardsComponent } from './components/collapsible-cards/collapsible-cards.component';
import {
  CollapseCardsActionDirective,
  CollapseCardsIconDirective,
  CollapseCardsTitleDirective,
} from './components/collapsible-cards/collapse-cards.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';

/**
 * Contains common elements required in every sub module
 * @author ebm-papst 2018 Industry Solutions - Web Development Services (dvreinwm)
 */
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule,
    OverlayModule,
    RouterModule,
    FormFieldComponent,
  ],
  declarations: [
    OperatingPointSummaryTextPipe,
    ProductPriceByCurrencyPipe,
    CurrencyByIsoCodePipe,
    SecureImagePipe,
    SafeHtmlPipe,
    UnsavedChangesConfirmationDialogComponent,
    CommaSafeNumberPipe,
    ContentTooltipDirective,
    ConfirmModalComponent,
    PhysicalValueInfoDisplayPipe,
    OperatingPointChipComponent,
    ShareUrlButtonComponent,
    LoadingIndicatorSnackBarComponent,
    DropdownComponent,
    DropdownTriggerDirective,
    PaginationBarComponent,
    SimpleProgressIndicatorComponent,
    IsIncludedPipe,
    ComboboxComponent,
    ComboboxItemComponent,
    ComboboxItemDirective,
    SuffixDirective,
    FormLabelComponent,
    FieldErrorWrapperDirective,
    FieldHintDirective,
    PrefixDirective,
    OutlierSuffixDirective,
    FormLabelDirective,
    FormLabelSuffixDirective,
    BoxyRadioGroupComponent,
    BoxyRadioGroupOptionComponent,
    AirDensityFilterFieldComponent,
    AirDensityCalculatorComponent,
    LastModifiedCollectionPipe,
    MultiValueInputFieldComponent,
    IsCollectionFavoritePipe,
    GuardGrillValueTranslatedPipe,
    GuardGrillValueTranslatedPipe,
    FavoriteStarButtonComponent,
    KeysOfObjectPipe,
    CollapsibleCardsComponent,
    CollapseCardsIconDirective,
    CollapseCardsTitleDirective,
    CollapseCardsActionDirective,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    OperatingPointSummaryTextPipe,
    ProductPriceByCurrencyPipe,
    CurrencyByIsoCodePipe,
    UnsavedChangesConfirmationDialogComponent,
    CommaSafeNumberPipe,
    ContentTooltipDirective,
    ConfirmModalComponent,
    PhysicalValueInfoDisplayPipe,
    OperatingPointChipComponent,
    ShareUrlButtonComponent,
    LoadingIndicatorSnackBarComponent,
    DropdownComponent,
    DropdownTriggerDirective,
    PaginationBarComponent,
    SimpleProgressIndicatorComponent,
    IsIncludedPipe,
    FormLabelComponent,
    SuffixDirective,
    SecureImagePipe,
    SafeHtmlPipe,
    ComboboxComponent,
    ComboboxItemComponent,
    ComboboxItemDirective,
    FieldErrorWrapperDirective,
    FieldHintDirective,
    PrefixDirective,
    OutlierSuffixDirective,
    FormLabelDirective,
    FormLabelSuffixDirective,
    BoxyRadioGroupComponent,
    BoxyRadioGroupOptionComponent,
    AirDensityFilterFieldComponent,
    AirDensityCalculatorComponent,
    LastModifiedCollectionPipe,
    MultiValueInputFieldComponent,
    IsCollectionFavoritePipe,
    GuardGrillValueTranslatedPipe,
    FavoriteStarButtonComponent,
    KeysOfObjectPipe,
    CollapsibleCardsComponent,
    CollapseCardsTitleDirective,
    CollapseCardsActionDirective,
    CollapseCardsIconDirective,
  ],
  providers: [OperatingPointSummaryTextPipe],
})
export class SharedModule {}
