import {
  Component,
  EventEmitter,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Subject } from 'rxjs';
import { OnboardingService } from '../../../../core/onboarding/onboarding.service';
import { AuthStateService } from '../../../../core/auth/auth-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'fs-user-display',
  templateUrl: './user-display.component.html',
  styleUrls: ['./user-display.component.scss'],
})
export class UserDisplayComponent implements OnInit, OnDestroy {
  private destroySubject = new Subject();

  @Output()
  showAboutModal: EventEmitter<void> = new EventEmitter();

  currentLanguage: string;

  constructor(
    private oauthService: OAuthService,
    @Inject(LOCALE_ID) public localeId: string,
    private authStateService: AuthStateService,
    private onboardingService: OnboardingService,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.currentLanguage = this.localeId;
  }

  get isLoggedIn(): boolean {
    return !!this.oauthService.getIdentityClaims();
  }

  get name(): string {
    return this.isLoggedIn && this.oauthService.getIdentityClaims()['name'];
  }

  get isAdmin(): boolean {
    return this.authStateService.isAdmin();
  }

  logout() {
    this.oauthService.logOut();
  }

  openAboutModal() {
    this.showAboutModal.emit();
  }

  async resetTours() {
    await this.onboardingService.resetTours();
    this.matSnackBar.open('Tour storage cleared! Reload page now', undefined, {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
