import { Component, OnInit } from '@angular/core';
import { AuthStateService } from '../../../../core/auth/auth-state.service';
import { AuthIdentity } from '../../../../core/auth/auth-identity';
import { OnboardingService } from '../../../../core/onboarding/onboarding.service';
import { SkipTourConfirmationDialogComponent } from '../../../../core/onboarding/skip-tour-confirmation-dialog/skip-tour-confirmation-dialog.component';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'fs-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss'],
})
export class WelcomeModalComponent implements OnInit {
  showModal = false;

  user: AuthIdentity;

  constructor(
    private authStateService: AuthStateService,
    private onboardingService: OnboardingService,
    private dialog: Dialog
  ) {}

  ngOnInit() {
    this.user = this.authStateService.getAuthIdentity();
    this.onboardingService
      .shouldShowWelcomeDialog()
      .then((showWelcomeDialog) => (this.showModal = showWelcomeDialog));
  }

  async startTour() {
    this.showModal = false;
    await this.onboardingService.markWelcomeDialogAsSeen();
  }

  async skipTour() {
    const skipTourDialogRef = this.dialog.open(
      SkipTourConfirmationDialogComponent,
      {
        disableClose: true,
        hasBackdrop: true,
      }
    );
    const shouldSkipTour = await skipTourDialogRef.closed.toPromise();
    if (shouldSkipTour) {
      await this.onboardingService.skipTour();
      await this.onboardingService.markWelcomeDialogAsSeen();
      this.showModal = false;
    }
  }

  closeModal() {
    this.showModal = false;
  }
}
