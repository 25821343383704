<div class="p-5">
  <div
    class="mx-auto max-w-5xl bg-white p-6 lg:p-8 rounded shadow-2xl flex flex-col gap-6 animation-fade-in"
  >
    <ul class="breadcrumbs">
      <li class="breadcrumb">
        <a [routerLink]="['']" [replaceUrl]="true">
          <span i18n="@@Fsw_GeneralStartPage">Startseite</span>
        </a>
      </li>
      <li class="breadcrumb breadcrumb--active">
        <a i18n="@@Fsw_ReleaseNotes">Versionshinweise</a>
      </li>
    </ul>

    <article
      class="flex flex-col gap-10 prose md:prose-md max-w-none prose-img:mx-auto prose-img:rounded-sm prose-img:bg-white prose-img:shadow-md"
      *ngIf="releaseNotes | async as releaseNotes"
    >
      <div *ngFor="let releaseNote of releaseNotes">
        <h1 class="mb-4 text-blue-800">{{ releaseNote.title }}</h1>

        <div class="flex gap-5">
          <div class="flex gap-2 items-end" title="Release date">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon--small text-gray-500"
            >
              <use href="#calendar"></use>
            </svg>
            <span class="leading-none">{{ releaseNote.showDate | date }}</span>
          </div>
        </div>

        <fs-release-notes-item-list [items]="releaseNote.whatsNew"
          >What's new</fs-release-notes-item-list
        >

        <fs-release-notes-item-list [items]="releaseNote.comingSoon"
          >Coming soon</fs-release-notes-item-list
        >

        <fs-release-notes-item-list [items]="releaseNote.bugfixes"
          >Bugfixes</fs-release-notes-item-list
        >
      </div>

      <fs-pagination-bar
        [itemSize]="releaseNotes[0]?.totalCount ?? 0"
        (pageChange)="this.paginationSubject.next($event)"
        [pageSize]="initPageSize"
      >
      </fs-pagination-bar>
    </article>
  </div>
</div>
