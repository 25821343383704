<!--suppress ALL -->
<div *ngIf="showModal" class="animation-fade-in animation-speed--75">
  <section class="backdrop"></section>
  <section class="modal relative overflow-hidden">
    <img class="w-full" src="/assets/images/FanScout_Startbild_oT.png" alt="" />
    <div class="absolute inset-0 overflow-hidden">
      <img
        class="img-person h-full drop-shadow"
        src="/assets/images/Person_separate_scaled_reduced.png"
        alt=""
      />
      <div class="prose prose-sm text-white welcome-text max-lg:text-xs">
        <h6 i18n="@@Fsw_WelcomeUser">
          Herzlich willkommen {{ user?.given_name }} {{ user?.family_name }} im
          FanScout!
        </h6>
        <p i18n="@@Fsw_WelcomeShortExpl">
          Der FanScout hilft Ihnen, das für Sie bestpassende ebm-papst Produkt
          zu finden!
        </p>
        <ol class="list-decimal text-white">
          <li i18n="@@Fsw_WelcomeFirstStep">
            Im ersten Schritt wählen Sie bitte auf der linken Seite aus,
            innerhalb welches Produktportfolios Sie nach passenden Produkten
            suchen möchten. (Mehrfachauswahl möglich)
          </li>
          <li i18n="@@Fsw_WelcomeSecondStep">
            Anschließend können Sie über diese Produkte individuell filtern.
          </li>
        </ol>
        <p class="text-xs" i18n="@@Fsw_AdditionalHelp">
          Sie benötigen noch weitere Hilfe?! Dann starten Sie direkt unsere
          Guided Tour. Alternativ können Sie die Tour überspringen und
          nachträglich unter dem Help Button in Ihren User Profile anschauen
          oder im dortigen User Manual nachlesen.
        </p>
      </div>
    </div>
    <div class="absolute bottom-0 right-0 flex flex-col gap-3 action-container">
      <button
        class="btn btn--large shadow-xl border border-gray-050 border-solid space-x-2"
        (click)="skipTour()"
      >
        <span i18n="@@Fsw_SkipTour">Tour überspringen</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="icon">
          <use href="#chevron-right"></use>
        </svg>
      </button>
      <button
        class="btn btn--large btn--primary shadow-xl border border-gray-050 border-solid space-x-2"
        (click)="startTour()"
      >
        <span i18n="@@Fsw_StartTour">Tour starten</span>
        <span class="flex-1"></span>
        <svg xmlns="http://www.w3.org/2000/svg" class="icon">
          <use href="#chevron-right"></use>
        </svg>
      </button>
    </div>
  </section>
</div>
