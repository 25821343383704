import { Component, OnInit } from '@angular/core';
import { FeedbackSurveyService } from '../../../../fanscout-api/api/feedback-survey.service';
import { FeedbackSurvey } from '../../../../fanscout-api/model/feedback-survey';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PreferencesService } from '../../../../fanscout-api/api/preferences.service';
import { FeedbackService } from '../../../../fanscout-api/api/feedback.service';

@Component({
  selector: 'fs-feedback-survey-notification',
  templateUrl: './feedback-survey-notification.component.html',
  styleUrls: ['./feedback-survey-notification.component.scss'],
})
export class FeedbackSurveyNotificationComponent implements OnInit {
  showNotification = false;

  expandToForm = false;

  feedbackSurvey: FeedbackSurvey;

  feedbackForm = new FormGroup({
    type: new FormControl(undefined, [Validators.required]),
    text: new FormControl('', [Validators.required]),
  });
  submittingFeedbackInProgress: boolean = false;

  constructor(
    private feedbackSurveyService: FeedbackSurveyService,
    private preferencesService: PreferencesService,
    private feedbackService: FeedbackService
  ) {}

  ngOnInit() {
    Promise.all([
      this.feedbackSurveyService.getFeedbackSurvey(),
      this.preferencesService.getPreferencesCached(),
    ]).then(([survey, preferences]) => {
      const seenSurveys = preferences.seenFeedbackSurveyIds ?? [];
      if (survey?.id && !seenSurveys.includes(survey.id)) {
        this.feedbackSurvey = survey;
        this.showNotification = true;

        // Make sure to mark notification as seen for user by storing id in preferences
        seenSurveys.push(survey.id);
        this.preferencesService
          .patchPreferences({
            seenFeedbackSurveyIds: seenSurveys,
          })
          .catch((e) => console.error(e));
      }
    });
  }

  closeNotification() {
    this.expandToForm = false;
    this.showNotification = false;
  }

  expandNotificationToForm() {
    this.expandToForm = true;
  }

  async submitFeedback() {
    if (this.feedbackForm.invalid || this.feedbackSurvey.id == null) return;
    this.submittingFeedbackInProgress = true;
    const formValue = this.feedbackForm.getRawValue();
    await this.feedbackService.submitFeedback({
      type: formValue.type,
      text: formValue.text,
      surveyId: this.feedbackSurvey.id + '',
    });
    this.submittingFeedbackInProgress = false;
    this.closeNotification();
  }

  collapseNotification() {
    this.expandToForm = false;
  }
}
