<div class="flex items-center space-x-3">
  <div class="field-wrapper">
    <select
      class="basic-select !py-1"
      [(ngModel)]="pageSize"
      (ngModelChange)="handleChangePageSize()"
    >
      <option [ngValue]="size" *ngFor="let size of pageSizes">
        {{ size }}
      </option>
    </select>
  </div>
  <span class="text-sm text-gray-800" i18n="@@Fsw_PerPage">pro Seite</span>
  <div class="flex-1"></div>
  <span
    class="text-xs text-gray-800"
    i18n="@@Fsw_PaginationPageInfo"
    *ngIf="!compact"
    >Zeige {{ (page - 1) * pageSize + 1 }} - {{ maxDisplayItem }} von
    {{ itemSize }} Ergebnisse</span
  >
  <div class="flex space-x-2">
    <button
      class="btn space-x-1"
      [ngClass]="{ 'btn--small': compact }"
      [disabled]="page <= 1"
      (click)="backwards()"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="icon">
        <use href="#chevron-left"></use>
      </svg>
      <span i18n="@@Fsw_Previous" *ngIf="!compact">Zurück</span>
    </button>
    <span class="self-center text-sm max-lg:text-xs" i18n="@@Fsw_PageXOfY"
      >Seite {{ page }} von {{ maxPage }}</span
    >
    <button
      class="btn space-x-1"
      [ngClass]="{ 'btn--small': compact }"
      [disabled]="page >= maxPage"
      (click)="forward()"
    >
      <span i18n="@@Fsw_Next" *ngIf="!compact">Weiter</span>
      <svg xmlns="http://www.w3.org/2000/svg" class="icon">
        <use href="#chevron-right"></use>
      </svg>
    </button>
  </div>
</div>
