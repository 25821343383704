import { Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[fsContentTooltip]',
})
export class ContentTooltipDirective implements OnDestroy {
  el: ElementRef;
  observer: MutationObserver;

  constructor(el: ElementRef) {
    this.el = el;
    setTimeout(() => {
      if (this.el.nativeElement.innerText) {
        this.el.nativeElement.title = this.el.nativeElement.innerText;
      }
      this.observer = new MutationObserver(() => {
        if (this.el.nativeElement.innerText) {
          this.el.nativeElement.title = this.el.nativeElement.innerText;
        }
      });
      this.observer.observe(this.el.nativeElement, {
        attributes: false,
        childList: true,
        subtree: true,
      });
    }, 10);
  }

  ngOnDestroy() {
    this.observer?.disconnect();
  }
}
