import { Inject, Injectable, Optional } from '@angular/core';
import { Configuration } from '../configuration';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpParams,
} from '@angular/common/http';
import { BASE_PATH } from '../variables';
import { Feedback, FeedbackTypes } from '../model/feedback';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Observable } from 'rxjs';
import { FileIoService } from './file-io.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  public configuration = new Configuration();
  protected basePath;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private fileIO: FileIoService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public submitFeedback(feedback: Feedback): Promise<void> {
    const formData = new FormData();
    formData.append('type', feedback.type);
    feedback.text != null && formData.append('text', feedback.text);
    feedback.screenshot != null &&
      formData.append('screenshot', feedback.screenshot);
    feedback.affectedUrl != null &&
      formData.append('affectedUrl', feedback.affectedUrl);
    feedback.surveyId != null && formData.append('surveyId', feedback.surveyId);
    return this.httpClient
      .post<void>(`${this.basePath}/feedback`, formData)
      .toPromise();
  }

  public getFeedbackList(
    type?: FeedbackTypes,
    fromDate?: Date,
    toDate?: Date,
    limit?: number,
    page?: number
  ): Promise<Feedback[]> {
    let query = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    if (type) {
      query = query.append('type', type);
    }

    if (fromDate) {
      query = query.append('from-time', fromDate.toString());
    }

    if (toDate) {
      query = query.append('to-time', toDate.toString());
    }

    if (limit) {
      query = query.append('limit', limit);
    }

    if (page) {
      query = query.append('offset', page);
    }

    return this.httpClient
      .get<Feedback[]>(`${this.basePath}/feedback`, {
        params: query,
      })
      .toPromise();
  }

  getFeedbackReportedByMe(): Promise<Feedback[]> {
    return this.httpClient
      .get<Feedback[]>(`${this.basePath}/feedback/reported-by-me`)
      .toPromise();
  }

  downloadScreenshotById(feedbackId: string): Observable<HttpEvent<Blob>> {
    return this.httpClient.get(
      `${this.basePath}/feedback/${feedbackId}/screenshot`,
      {
        observe: 'events',
        responseType: 'blob',
      }
    );
  }

  deleteFeedbackById(feedbackId: string): Promise<void> {
    return this.httpClient
      .delete<void>(`${this.basePath}/feedback/${feedbackId}`)
      .toPromise();
  }

  updateFeedbackState(
    feedbackId: string,
    done: boolean,
    tag?: string
  ): Promise<Feedback> {
    return this.httpClient
      .put<Feedback>(`${this.basePath}/feedback/${feedbackId}`, {
        done,
        tag,
      })
      .toPromise();
  }

  getExistingTags(): Promise<string[]> {
    return this.httpClient
      .get<string[]>(`${this.basePath}/feedback/existing-tags`)
      .toPromise();
  }

  downloadScreenshotAndOpenFileById(
    feedbackId: string,
    fileName: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const subscription = this.downloadScreenshotById(feedbackId).subscribe(
        (event) => {
          if (event.type === HttpEventType.Response) {
            this.fileIO.saveBlobAs(event.body, fileName);
            subscription.unsubscribe();
            resolve();
          }
        },
        (error) => {
          subscription.unsubscribe();
          reject(error);
        }
      );
    });
  }
}
