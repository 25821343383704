import { Inject, Injectable, Optional } from '@angular/core';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProductImageService {
  public configuration = new Configuration();
  protected basePath = 'https://kong-fanscout-proxy.dev.cloud.ebmpapst.com';

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public getImageUrl(pkProduct: string): string {
    return `${this.basePath}/product-details/${encodeURIComponent(
      String(pkProduct)
    )}/image`;
  }
}
