import { Component, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SwitchUnitsService } from '../../../settings-switchers/services/switch-units/switch-units.service';
import { Currency } from '../../../../fanscout-api/model/price';
import { SwitchCurrencyService } from '../../../settings-switchers/services/switch-currency/switch-currency.service';

@Component({
  selector: 'fs-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnDestroy {
  areUnitsInUSFormat: boolean;

  private destroySubject = new Subject();

  constructor(
    private switchUnitService: SwitchUnitsService,
    private switchCurrencyService: SwitchCurrencyService,
    @Inject(LOCALE_ID) public localeId: string
  ) {}

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  get currentLanguage(): string {
    return this.localeId?.toUpperCase();
  }

  get currentUnit() {
    return this.switchUnitService.areUnitsInUSFormat();
  }

  get currentCurrency(): Currency {
    return this.switchCurrencyService.currency();
  }
}
