import Step from 'shepherd.js/src/types/step';
import { OnboardingService } from './onboarding.service';
import { Dialog } from '@angular/cdk/dialog';
import { SkipTourConfirmationDialogComponent } from './skip-tour-confirmation-dialog/skip-tour-confirmation-dialog.component';

export function defaultStepOptions(
  onboardingService: OnboardingService,
  dialog: Dialog
): Step.StepOptions {
  return {
    classes: 'onboarding-tour-step',
    scrollTo: true,
    highlightClass: 'highlight',
    cancelIcon: {
      enabled: false,
    },
    buttons: [
      {
        classes: 'btn btn--quiet mr-[auto] hover:!text-black',
        text: $localize`:@@Fsw_SkipTour:Tour überspringen`,
        action: function () {
          this.hide();
          const currentId = this.getCurrentStep().id;
          const skipTourDialogRef = dialog.open(
            SkipTourConfirmationDialogComponent,
            {
              disableClose: true,
              hasBackdrop: true,
            }
          );
          skipTourDialogRef.closed.toPromise().then((shouldSkipTour) => {
            if (shouldSkipTour) {
              onboardingService.skipTour().then(() => {
                this.cancel();
              });
            } else {
              this.show(currentId);
            }
          });
        },
      },
      {
        classes: 'btn hover:!text-black',
        text: $localize`:@@Fsw_Previous:Zurück`,
        action: function () {
          this.back();
        },
        disabled: function () {
          return (
            this.tour?.steps?.findIndex((step) => step.id === this.id) <= 0
          );
        },
      },
      {
        classes: 'btn btn--primary',
        text: function () {
          const isLast =
            this.tour?.steps?.[this.tour?.steps?.length - 1].id === this.id;
          return isLast
            ? $localize`:@@Fsw_Done:Fertig`
            : $localize`:@@Fsw_Next:Weiter`;
        },
        action: function () {
          this.next();
        },
      },
    ],
  };
}
