import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import {
  PreferencesService,
  UserPreferences,
} from '../../../../fanscout-api/api/preferences.service';
import { ReleaseNotesService } from '../../../../fanscout-api/api/release-notes.service';
import { ReleaseNoteItem } from '../../../general/models/release-note-item';

@Component({
  selector: 'fs-release-notes-button',
  templateUrl: './release-notes-button.component.html',
  styleUrls: ['./release-notes-button.component.scss'],
})
export class ReleaseNotesButtonComponent implements OnInit, OnDestroy {
  private destroySubject = new Subject();

  releaseNoteItem: ReleaseNoteItem;

  constructor(
    private preferencesService: PreferencesService,
    private releaseNotesService: ReleaseNotesService
  ) {}

  ngOnInit(): void {
    this.initializeReleaseNotes();
  }

  initializeReleaseNotes() {
    this.releaseNotesService
      .getLatestReleaseNote()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(async (latestReleaseNote) => {
        const id = latestReleaseNote?.id;
        const savedReleaseNote =
          await this.getLatestReleaseNoteFromPreferences();

        if (savedReleaseNote && id === savedReleaseNote?.id) {
          this.releaseNoteItem = new ReleaseNoteItem(id, true);
        } else {
          this.releaseNoteItem = new ReleaseNoteItem(id, false);
        }
      });
  }

  async getLatestReleaseNoteFromPreferences(): Promise<ReleaseNoteItem> {
    const preferences =
      (await this.preferencesService.getPreferencesCached()) ??
      ({} as UserPreferences);

    return preferences.releaseNote;
  }

  setReleaseNotesHaveBeenWatched() {
    if (!this.releaseNoteItem) {
      return;
    }
    this.releaseNoteItem.watched = true;
    this.saveLatestReleaseNoteToPreferences();
  }

  saveLatestReleaseNoteToPreferences() {
    this.preferencesService.patchPreferencesIfChanged({
      releaseNote: this.releaseNoteItem,
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
