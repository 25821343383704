import { Injectable } from '@angular/core';

import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import { defaultStepOptions } from './default-step-options';
import { PreferencesService } from '../../fanscout-api/api/preferences.service';
import { TourSetting } from './tour-setting';
import { Dialog } from '@angular/cdk/dialog';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  private alreadySeenTours: string[];
  private showWelcomeDialog = false;
  private skipAllTours = false;

  private readonly initializationPromise: Promise<void>;
  private triggerWelcomeDialogFinished: (
    value: PromiseLike<void> | void
  ) => void;
  private readonly waitUntilWelcomeDialogPromise: Promise<void> = new Promise(
    (resolve) => {
      this.triggerWelcomeDialogFinished = resolve;
    }
  );

  constructor(
    private shepherdService: ShepherdService,
    private preferencesService: PreferencesService,
    private dialog: Dialog
  ) {
    this.shepherdService.defaultStepOptions = defaultStepOptions(this, dialog);
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    // noinspection JSIgnoredPromiseFromCall
    this.initializationPromise = this.initializeFromPreferences();
  }

  private async initializeFromPreferences() {
    const preferences = await this.preferencesService.getPreferencesCached();
    this.alreadySeenTours = [...(preferences?.tourIdsAlreadyWatched ?? [])];
    this.showWelcomeDialog = preferences?.showWelcomeDialog ?? true;
    this.skipAllTours = preferences?.skipAllTours ?? false;
    if (!this.showWelcomeDialog) {
      this.triggerWelcomeDialogFinished();
    }
  }

  public startTour(tourSetting: TourSetting, redoIfSeen = false) {
    // noinspection JSIgnoredPromiseFromCall
    this.startOrAddSteps(tourSetting.name, tourSetting.steps, redoIfSeen);
  }

  public async startOrAddSteps(
    tourName: string,
    steps: Step.StepOptions[],
    redoIfSeen = false
  ) {
    await this.initializationPromise;
    await this.waitUntilWelcomeDialogPromise;

    if (
      this.skipAllTours ||
      (!redoIfSeen && this.alreadySeenTours.includes(tourName))
    )
      return;

    this.markAsSeen(tourName);
    if (this.shepherdService.tourObject?.isActive()) {
      this.shepherdService.tourObject.addSteps(steps);
    } else {
      this.shepherdService.addSteps(steps);
      this.shepherdService.start();
    }
  }

  public async shouldShowWelcomeDialog(): Promise<boolean> {
    await this.initializationPromise;
    return this.showWelcomeDialog;
  }

  public async markWelcomeDialogAsSeen() {
    this.showWelcomeDialog = false;
    // noinspection JSIgnoredPromiseFromCall
    await this.preferencesService
      .patchPreferences({
        showWelcomeDialog: false,
      })
      .catch((e) => console.error(e));
    this.triggerWelcomeDialogFinished();
  }

  public skipTour() {
    this.skipAllTours = true;
    // noinspection JSIgnoredPromiseFromCall
    return this.preferencesService
      .patchPreferences({
        skipAllTours: true,
      })
      .catch((e) => console.error(e));
  }

  private markAsSeen(tourName: string) {
    if (this.alreadySeenTours.includes(tourName)) return;
    this.alreadySeenTours.push(tourName);
    // noinspection JSIgnoredPromiseFromCall
    this.preferencesService
      .patchPreferences({
        tourIdsAlreadyWatched: [...this.alreadySeenTours],
      })
      .catch((e) => console.error(e));
  }

  public async resetTours() {
    await this.preferencesService
      .patchPreferences({
        tourIdsAlreadyWatched: [],
        showWelcomeDialog: true,
        skipAllTours: false,
      })
      .catch((e) => console.error(e));
  }
}
