<div *ngIf="items?.length">
  <h2>
    <ng-content></ng-content>
  </h2>

  <div class="flex flex-col gap-6">
    <div *ngFor="let item of items" class="flex gap-3">
      <div class="w-6 flex flex-col items-center gap-4 mt-4">
        <div class="rounded-full bg-gray-600 w-4 h-4"></div>
        <div
          class="flex-1 border-l border-solid border-l-2 border-gray-100"
        ></div>
      </div>
      <div class="flex-1">
        <h3 class="mt-2">{{ item.title }}</h3>
        <p [innerHTML]="item.description | safeHtml"></p>
      </div>
    </div>
  </div>
</div>
