import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileIoService {
  constructor() {}

  public saveBlobAs(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
