import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
} from '@angular/core';
import { DropdownTriggerDirective } from './dropdown-trigger.directive';

@Component({
  selector: 'fs-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements AfterContentInit {
  @Input()
  anchorLeft = false;

  @Input()
  closeOnClick = true;

  @Input()
  overwriteZIndex;

  @ContentChild(DropdownTriggerDirective, {
    read: ElementRef,
  })
  trigger: ElementRef;

  showMenu = false;

  constructor() {}

  ngAfterContentInit() {
    if (this.trigger) {
      this.trigger.nativeElement.addEventListener(
        'click',
        () => (this.showMenu = true)
      );
    }
  }
}
