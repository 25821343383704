import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthIdentity } from './auth-identity';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  private adminScopeName = 'fan-scout-admin';
  private internalUserScopeName = 'fan-scout-internal';
  private keyUserScopeName = 'fan-scout-keyuser';

  private resolveWaitUntilAuthenticated;

  public waitUntilAuthenticated = new Promise((resolve) => {
    this.resolveWaitUntilAuthenticated = resolve;
  });

  constructor(private oAuthService: OAuthService) {}

  getAuthIdentity(): AuthIdentity {
    return JSON.parse(
      JSON.stringify(this.oAuthService.getIdentityClaims())
    ) as AuthIdentity;
  }

  hasAuth(permissions): boolean {
    if (!this.oAuthService.hasValidIdToken()) return false;

    const resourcePermissions = this.getAuthIdentity()?.roles;

    return this.hasPermissions(resourcePermissions, permissions);
  }

  isInternalUser(): boolean {
    return this.hasAuth([this.internalUserScopeName]);
  }

  isAdmin(): boolean {
    return this.hasAuth([this.adminScopeName]);
  }

  isKeyUser(): boolean {
    return this.hasAuth([this.keyUserScopeName]);
  }

  private hasPermissions(
    resourcePermissions: string[],
    permissions: string[]
  ): boolean {
    if (!resourcePermissions) return false;

    return permissions.some((permission) => {
      return (resourcePermissions as string[]).includes(permission);
    });
  }

  isCustomer(): boolean {
    if (!this.oAuthService.hasValidIdToken()) return false;

    return this.getAuthIdentity()?.customerNumber != null;
  }

  parseJWT(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  public markAsAuthenticated() {
    this.resolveWaitUntilAuthenticated();
  }
}
