import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { SwitchUnitsService } from '../../services/switch-units/switch-units.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fs-unit-switch',
  templateUrl: './unit-switch.component.html',
  styleUrls: ['./unit-switch.component.scss'],
})
export class UnitSwitchComponent implements OnInit, OnDestroy {
  private destroySubject = new Subject();

  unitFormControl = new UntypedFormControl(false);

  constructor(private switchUnitService: SwitchUnitsService) {}

  ngOnInit(): void {
    this.switchUnitService
      .areUnitsInUSFormatAsObservable()
      .pipe(takeUntil(this.destroySubject))
      .subscribe((areUnitsInUSFormat) => {
        this.unitFormControl.setValue(areUnitsInUSFormat, {
          emitEvent: false,
        });
      });

    this.unitFormControl.valueChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe((unitsInUsFormat) => {
        this.switchUnitService.setUnitsInUSFormat(unitsInUsFormat);
      });

    this.switchUnitService
      .lockedPreferenceUIObservable()
      .pipe(distinctUntilChanged(), takeUntil(this.destroySubject))
      .subscribe((isLocked) =>
        isLocked
          ? this.unitFormControl.disable({ emitEvent: false })
          : this.unitFormControl.enable({ emitEvent: false })
      );
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
