import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ReleaseNotesOverviewComponent } from './pages/release-notes-overview/release-notes-overview.component';
import { ReleaseNotesButtonComponent } from './components/release-notes-button/release-notes-button.component';
import { ReleaseNotesItemListComponent } from './components/release-notes-item-list/release-notes-item-list.component';

const routes: Routes = [
  {
    path: '',
    component: ReleaseNotesOverviewComponent,
  },
];

@NgModule({
  declarations: [
    ReleaseNotesOverviewComponent,
    ReleaseNotesButtonComponent,
    ReleaseNotesItemListComponent,
  ],
  imports: [SharedModule, RouterModule.forChild(routes), ReactiveFormsModule],
  exports: [
    RouterModule,
    ReleaseNotesButtonComponent,
    ReleaseNotesItemListComponent,
    ReleaseNotesOverviewComponent,
  ],
})
export class ReleaseNotesModule {}
