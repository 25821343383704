<div
  *ngIf="showModal"
  class="animation-fade-in animation-speed--75 text-gray-1000"
>
  <section
    class="backdrop"
    (click)="closeModal(); $event.stopPropagation()"
  ></section>
  <section class="modal flex flex-col gap-5">
    <header class="flex space-x-3 items-center">
      <span class="flex-1"></span>

      <button class="btn btn--quiet" (click)="closeModal()">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon">
          <use href="#x"></use>
        </svg>
      </button>
    </header>
    <div class="prose max-w-prose overflow-auto">
      <p class="lead">{{ maintenanceMessage.summary }}</p>
      <p [innerHTML]="convertedDescription | safeHtml"></p>
    </div>
  </section>
</div>
