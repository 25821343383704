import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fs-boxy-radio-group-option',
  templateUrl: './boxy-radio-group-option.component.html',
  styleUrls: ['./boxy-radio-group-option.component.scss'],
})
export class BoxyRadioGroupOptionComponent {
  @Input()
  value: any;

  selected = false;

  disabled = false;

  @Output()
  onSelect = new EventEmitter<any>();

  constructor() {}

  select() {
    if (!this.disabled) {
      this.onSelect.emit(this.value);
    }
  }
}
