import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GeneralModule } from './modules/general/general.module';
import { TranslateMockPipe } from './shared/pipes/translate-mock/translate-mock.pipe';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptorService } from './core/loader/loader.interceptor';
import * as Sentry from '@sentry/angular';
import { Router, RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './core/route-reuse/custom-reuse-strategy';
import { ProductCategoryIconModule } from './modules/product-category-icon/product-category-icon.module';

@NgModule({
  declarations: [AppComponent, TranslateMockPipe],
  imports: [CoreModule, GeneralModule, ProductCategoryIconModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(_trace: Sentry.TraceService) {}
}
