import { Component, Input } from '@angular/core';

@Component({
  selector: 'fs-release-notes-item-list',
  templateUrl: './release-notes-item-list.component.html',
  styleUrls: ['./release-notes-item-list.component.scss'],
})
export class ReleaseNotesItemListComponent {
  @Input()
  title: string;

  @Input()
  items: [{ title: string; description: string }];
}
