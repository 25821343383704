// noinspection JSIgnoredPromiseFromCall,JSMethodCanBeStatic

import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Feedback,
  FeedbackTypes,
  IncludeScreenshot,
} from '../../../../fanscout-api/model/feedback';
import { FeedbackService } from '../../../../fanscout-api/api/feedback.service';
import html2canvas from 'html2canvas';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ReplaySubject } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { UrlCleanerService } from '../../../../core/services/url-cleaner/url-cleaner.service';
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CustomFeedbackDataRegisterService } from '../../../../core/services/custom-feedback-data-register/custom-feedback-data-register.service';

@Component({
  selector: 'fs-submit-feedback',
  templateUrl: './submit-feedback.component.html',
  styleUrls: ['./submit-feedback.component.scss'],
  animations: [
    trigger('expandReportedPanel', [
      state('*', style({ width: '0', display: 'none' })),
      state(
        'true',
        style({
          width: AUTO_STYLE,
        })
      ),
      transition('* => true', [
        style({
          display: 'flex',
        }),
        animate('100ms ease-out'),
      ]),
      transition('true => *', [animate('100ms ease-out')]),
    ]),
  ],
})
export class SubmitFeedbackComponent {
  showModal = false;
  showConfirmation = false;

  feedbackTypes = FeedbackTypes;
  includeScreenshotTypes = IncludeScreenshot;

  customScreenshotFile: File;
  clipboardScreenshotBlob: Blob;
  viewableScreenshot = new ReplaySubject<SafeUrl>();
  viewableCustomScreenshot: SafeUrl;
  viewableClipboardScreenshot: SafeUrl;

  submittingFeedbackInProgress = false;

  isReportedFeedbackListOpen = false;
  reportedFeedbackList: Feedback[] = [];
  feedbackSelection = true;

  feedbackForm = new UntypedFormGroup({
    type: new UntypedFormControl(undefined, [Validators.required]),
    text: new UntypedFormControl('', [Validators.required]),
    includeScreenshot: new UntypedFormControl(IncludeScreenshot.NONE),
    affectedUrl: new UntypedFormControl(''),
  });

  constructor(
    private feedbackService: FeedbackService,
    private sanitizer: DomSanitizer,
    private urlCleanerService: UrlCleanerService,
    private customFeedbackDataRegisterService: CustomFeedbackDataRegisterService
  ) {}

  async submitFeedback() {
    const feedback = this.feedbackForm.getRawValue() as Feedback;
    if (feedback.type) {
      this.feedbackSelection = false;
    }
    if (this.submittingFeedbackInProgress || this.feedbackForm.invalid) {
      this.feedbackForm.markAsDirty();
      return;
    }

    try {
      this.submittingFeedbackInProgress = true;
      // Handle taking and/or selecting screenshot blob
      if (feedback.includeScreenshot === IncludeScreenshot.SCREENSHOT) {
        feedback.screenshot = await this.captureScreenshot();
      } else if (
        feedback.includeScreenshot === IncludeScreenshot.CUSTOM &&
        this.customScreenshotFile
      ) {
        feedback.screenshot = this.customScreenshotFile;
      } else if (
        feedback.includeScreenshot === IncludeScreenshot.CLIPBOARD &&
        this.clipboardScreenshotBlob
      ) {
        feedback.screenshot = this.clipboardScreenshotBlob;
      }

      // Get custom share link if required
      if (
        this.feedbackForm.controls.affectedUrl.pristine &&
        this.customFeedbackDataRegisterService.hasCustomLinkGenerator
      ) {
        feedback.affectedUrl =
          await this.customFeedbackDataRegisterService.customLinkGenerator();
      }

      await this.feedbackService.submitFeedback(feedback);
      this.showConfirmation = true;
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
    }
    this.submittingFeedbackInProgress = false;
  }

  handleUpload(event): void {
    this.handleFileChange(event.srcElement.files);
  }

  private async handleFileChange(files): Promise<void> {
    let file = files[0] as File;
    if (file == null) {
      return;
    }
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      this.customScreenshotFile = file;
      let objectURL = URL.createObjectURL(file);
      this.viewableCustomScreenshot =
        this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }
  }

  openModal() {
    this.feedbackSelection = true;
    this.customScreenshotFile = undefined;
    this.clipboardScreenshotBlob = undefined;
    this.viewableScreenshot.next(undefined);
    this.viewableCustomScreenshot = undefined;
    this.viewableClipboardScreenshot = undefined;
    this.isReportedFeedbackListOpen = false;
    this.feedbackService
      .getFeedbackReportedByMe()
      .then((feedbackList) => (this.reportedFeedbackList = feedbackList));
    this.feedbackForm.reset({
      type: undefined,
      text: undefined,
      screenshot: undefined,
      includeScreenshot: IncludeScreenshot.NONE,
      affectedUrl: this.urlCleanerService.retrieveCurrentUrl(),
    });
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
    this.showConfirmation = false;
  }

  selectFeedbackType(type: FeedbackTypes) {
    this.feedbackForm.patchValue({
      type: type,
    });
  }

  get selectedFeedbackType(): FeedbackTypes {
    return this.feedbackForm.getRawValue().type as FeedbackTypes;
  }

  get selectedIncludeScreenshotState(): IncludeScreenshot {
    return this.feedbackForm.getRawValue()
      .includeScreenshot as IncludeScreenshot;
  }

  private captureScreenshot() {
    return new Promise((resolve) => {
      setTimeout(() => {
        html2canvas(document.body).then((canvas) => {
          canvas.toBlob((blob) => {
            resolve(blob);
          });
        });
      }, 100);
    });
  }

  handlePasteImage(event: ClipboardEvent) {
    const items = (event.clipboardData || event['originalEvent'].clipboardData)
      .items;
    for (let index in items) {
      const item = items[index];
      if (item.kind === 'file') {
        this.clipboardScreenshotBlob = item.getAsFile();
        let objectURL = URL.createObjectURL(this.clipboardScreenshotBlob);
        this.viewableClipboardScreenshot =
          this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.feedbackForm.patchValue({
          includeScreenshot: IncludeScreenshot.CLIPBOARD,
        });
      }
    }
  }
}
