import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import { Configuration } from '../configuration';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BASE_PATH } from '../variables';
import { catchError } from 'rxjs/operators';
import { MaintenanceMessage } from '../model/maintenance-message';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceMessageService {
  public configuration = new Configuration();
  protected basePath;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    @Inject(LOCALE_ID) public localeId: string
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  getMaintenanceMessage(): Promise<MaintenanceMessage> {
    let queryParams = new HttpParams();

    const currentDate = new Date().toISOString();

    queryParams = queryParams.append('locale', this.localeId);

    queryParams = queryParams.append(
      'filters[$and][0][showDate][$lte]',
      currentDate
    );

    queryParams = queryParams.append(
      'filters[$and][1][hideDate][$gte]]',
      currentDate
    );

    queryParams = queryParams.append('pagination[limit]', 1);

    queryParams = queryParams.append('populate', '*');

    return this.httpClient
      .get<any>(`${this.basePath}/maintenance-messages`, {
        params: queryParams,
      })
      .pipe(catchError(() => of(undefined)))
      .toPromise()
      .then(
        (messages) =>
          (messages?.data?.pop()?.attributes as MaintenanceMessage) ?? undefined
      );
  }
}
