import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { Currency } from '../../../../fanscout-api/model/price';
import { PreferencesService } from '../../../../fanscout-api/api/preferences.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SwitchCurrencyService {
  private currentCurrency: BehaviorSubject<Currency>;

  private initializeValuePromise;

  constructor(private preferencesService: PreferencesService) {
    this.initializeValuePromise = this.preferencesService
      .getPreferencesCached()
      .then(async (preferences) => {
        this.currentCurrency = new BehaviorSubject(
          preferences.currency ?? Currency.EUR
        );
      })
      .catch(() => {
        this.currentCurrency = new BehaviorSubject(Currency.EUR);
      });
  }

  currency(): Currency {
    return this.currentCurrency?.getValue() ?? Currency.EUR;
  }

  currencyPromise(): Promise<Currency> {
    return this.initializeValuePromise.then(() => this.currency());
  }

  currencyObservable(): Observable<Currency> {
    return from(this.initializeValuePromise).pipe(
      switchMap(() => this.currentCurrency.asObservable())
    );
  }

  setCurrency(currency: Currency) {
    this.preferencesService.getPreferencesCached().then(async (preferences) => {
      if (preferences?.currency !== currency) {
        await this.preferencesService.storePreferencesIfChanged({
          ...preferences,
          currency: currency,
        });
      }
    });

    this.currentCurrency.next(currency);
  }
}
