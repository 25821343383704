// noinspection JSUnusedLocalSymbols

import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ConnectedPosition, ViewportRuler } from '@angular/cdk/overlay';
import { BehaviorSubject, defer, merge, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { ComboboxItemComponent } from './combobox-item/combobox-item.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'fs-combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ComboboxComponent,
    },
  ],
})
export class ComboboxComponent
  implements OnInit, OnDestroy, AfterContentInit, ControlValueAccessor
{
  @Input()
  multiple = false;

  @Input()
  placeholder: string;

  @Input()
  id: string;

  @Input()
  bordered = true;

  @Output()
  internalFilter = new EventEmitter<string>();

  @ViewChild('trigger') trigger: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;

  @ContentChildren(ComboboxItemComponent, { descendants: true })
  items: QueryList<ComboboxItemComponent>;

  value: string = '';
  selectedValues = [];

  private destroySubject = new Subject();

  triggerRect;
  isOpen = false;
  onFilter = new BehaviorSubject<string>('');

  // Subject called when state of component or items changes. Currently unused
  stateChanges = new Subject();

  inputHasFocus = false;

  overlayPosition: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      overlayX: 'start',
    },
    {
      originX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      overlayX: 'start',
    },
  ];

  onChange = (selected) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  readonly optionSelectionChanges: Observable<ComboboxItemSelectionChange> =
    defer(() => {
      const options = this.items;

      if (options) {
        return options.changes.pipe(
          startWith(options),
          switchMap(() =>
            merge(...options.map((option) => option.onSelectionChange))
          )
        );
      }

      return this.zone.onStable.pipe(
        take(1),
        switchMap(() => this.optionSelectionChanges)
      );
    }) as Observable<ComboboxItemSelectionChange>;

  constructor(
    protected viewportRuler: ViewportRuler,
    protected changeDetectorRef: ChangeDetectorRef,
    protected zone: NgZone
  ) {}

  // Control Value Accessor implementation

  writeValue(value: any): void {
    if (!this.multiple) {
      this.value = value;
    } else {
      this.selectedValues = value;
    }
    this.initializeSelection();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  //////////

  ngOnInit(): void {
    this.viewportRuler
      .change()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        if (this.isOpen) {
          this.triggerRect = this.trigger.nativeElement.getBoundingClientRect();
          this.changeDetectorRef.markForCheck();
        }
      });

    this.onFilter
      .pipe(debounceTime(100), takeUntil(this.destroySubject))
      .subscribe((searchValue) => {
        const adjustedSearch = searchValue.toLowerCase().trim();
        this.items.forEach((item) => {
          item.visible =
            (item.value ?? '')
              ?.toString()
              ?.toLowerCase()
              ?.includes(adjustedSearch) ||
            item.viewValue
              ?.toString()
              .toLowerCase()
              .trim()
              .includes(adjustedSearch);
        });
        this.setNewActiveItem(this.filteredItems[0]);
      });
  }

  get filteredItems(): ComboboxItemComponent[] {
    return this.items.filter((item) => item.visible);
  }

  ngAfterContentInit() {
    this.items.changes
      .pipe(startWith(null), takeUntil(this.destroySubject))
      .subscribe(() => {
        this.resetOptions();
        this.initializeSelection();
      });
  }

  private resetOptions(): void {
    const changedOrDestroyed = merge(this.items.changes, this.destroySubject);

    this.optionSelectionChanges
      .pipe(takeUntil(changedOrDestroyed))
      .subscribe((event) => {
        this.select(event.source, event.isUserInput);

        if (event.isUserInput && !this.multiple && this.isOpen) {
          this.closeCombobox();
          this.focusElement(this.searchInput?.nativeElement);
        }
      });

    // Listen to changes in the internal state of the options and react accordingly.
    // Handle cases like the labels of the selected options changing.
    merge(...this.items.map((option) => option.stateChanges))
      .pipe(takeUntil(changedOrDestroyed))
      .subscribe(() => {
        this.changeDetectorRef.markForCheck();
        this.stateChanges.next();
      });
  }

  private initializeSelection() {
    Promise.resolve().then(() => {
      this.updateSelectedItemByValue();
    });
  }

  private updateSelectedItemByValue() {
    if (
      !this.multiple &&
      this.items.some((item) => item.value === this.value)
    ) {
      this.setNewSelectedItem(
        this.items?.find((item) => item.value === this.value)
      );
    } else if (this.multiple && this.selectedValues) {
      this.items?.forEach((item) => {
        item.selected = this.selectedValues.includes(item.value);
      });
    }
  }

  filter(e: Event) {
    if (this.disabled) return;
    this.setNewActiveItem(undefined);
    this.value = e.target['value'];
    const query = this.value;
    if (!this.isOpen) {
      this.openCombobox();
    }
    this.internalFilter.emit(query);
    this.onFilter.next(query);
  }

  select(item: ComboboxItemComponent, isUserInput = false) {
    this.markAsTouched();
    if (this.disabled || item.disabled) return;
    this.setNewActiveItem(item);
    this.setNewSelectedItem(item);
    this.value = '';
    if (!this.multiple) {
      this.onChange(item.value);
      this.closeCombobox();
    } else {
      this.selectedValues = this.selectedItems.map((item) => item.value);
      this.onChange(this.selectedValues);
    }
  }

  openCombobox(searchInput?: HTMLInputElement) {
    if (this.disabled || this.isOpen) return;
    this.isOpen = true;
    this.value = '';
    this.triggerRect = this.trigger.nativeElement.getBoundingClientRect(); // Set immediate for inputs with set width
    setTimeout(() => {
      this.triggerRect = this.trigger.nativeElement.getBoundingClientRect(); // Set again in next tick to fix dynamic width inputs
    }, 10);
    this.internalFilter.emit('');
    this.onFilter.next('');
    if (searchInput) {
      this.focusElement(searchInput);
    }
  }

  closeCombobox() {
    if (!this.isOpen) return;
    this.isOpen = false;
  }

  resetInput() {
    if (this.disabled) return;
    this.value = this.selectedItem?.viewValue ?? this.selectedItem?.value ?? '';
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  handleKeyDown(keyboardEvent: KeyboardEvent) {
    if (this.disabled) return;
    switch (keyboardEvent.key) {
      case 'Escape':
        keyboardEvent.preventDefault();
        if (this.isOpen) {
          this.closeCombobox();
          this.resetInput();
        }
        break;
      case 'ArrowUp':
        if (!this.isOpen) {
          this.openCombobox();
        }
        keyboardEvent.preventDefault();
        if (this.activeItem == null) {
          this.setNewActiveItem(
            this.filteredItems[this.filteredItems.length - 1]
          );
        } else {
          const activeItemIndex = this.filteredItems.findIndex(
            (item) => item === this.activeItem
          );
          const newIndex = Math.max(activeItemIndex - 1, 0);
          this.setNewActiveItem(this.filteredItems[newIndex]);
          this.scrollElementIntoViewByIndex(newIndex);
        }
        break;
      case 'ArrowDown':
        if (!this.isOpen) {
          this.openCombobox();
        }
        keyboardEvent.preventDefault();
        if (this.activeItem == null) {
          this.setNewActiveItem(this.filteredItems[0]);
        } else {
          const activeItemIndex = this.filteredItems.findIndex(
            (item) => item === this.activeItem
          );
          const newIndex = Math.min(
            activeItemIndex + 1,
            this.filteredItems.length - 1
          );
          this.setNewActiveItem(this.filteredItems[newIndex]);
          this.scrollElementIntoViewByIndex(newIndex);
        }
        break;
      case 'Enter':
        keyboardEvent.preventDefault();
        this.select(this.activeItem);
        break;
      case 'Tab':
        this.closeCombobox();
        this.resetInput();
        break;
    }
  }

  private scrollElementIntoViewByIndex(index: number) {
    // @ts-ignore
    this.items.get(index).getHostElement().scrollIntoViewIfNeeded();
  }

  focusElement(searchInput: HTMLInputElement) {
    searchInput.focus();
    setTimeout(() => searchInput.focus(), 100);
  }

  handleOutsideOverlayClick() {
    if (this.disabled) return;
    if (this.inputHasFocus) return;
    this.closeCombobox();
    this.resetInput();
  }

  private setNewActiveItem(item: ComboboxItemComponent) {
    if (this.activeItem) {
      this.activeItem.active = false;
    }
    if (item != null) item.active = true;
  }

  private setNewSelectedItem(item: ComboboxItemComponent) {
    if (this.selectedItem && !this.multiple) {
      this.selectedItem.selected = false;
    }
    if (item != null) item.selected = !item.selected;
  }

  get selectedItem(): ComboboxItemComponent {
    return this.items?.find((item) => item.selected);
  }

  get selectedItems(): ComboboxItemComponent[] {
    return this.items?.filter((item) => item.selected);
  }

  get activeItem(): ComboboxItemComponent {
    return this.items?.find((item) => item.active);
  }
}

export class ComboboxItemSelectionChange {
  constructor(
    public source: ComboboxItemComponent,
    public isUserInput = false
  ) {}
}
