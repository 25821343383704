// noinspection JSIgnoredPromiseFromCall

import { Location } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthStateService } from '../../../../core/auth/auth-state.service';
import { LanguageCodes } from '../../../../core/language-codes';
import { PreferencesService } from '../../../../fanscout-api/api/preferences.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageSwitcherService {
  public static POSSIBLE_LANGUAGES = [
    LanguageCodes.EN,
    LanguageCodes.FR,
    LanguageCodes.DE,
    LanguageCodes.ZH,
    LanguageCodes.ES,
    LanguageCodes.JA,
    LanguageCodes.IT,
  ];
  public static ADDITIONAL_LANGUAGES = [];

  constructor(
    private preferencesService: PreferencesService,
    private location: Location,
    private authService: AuthStateService,
    @Inject(LOCALE_ID) public localeId: string
  ) {}

  public async setLanguage(language: string) {
    this.preferencesService.lockPreferenceUI(true);
    await this.preferencesService.patchPreferencesIfChanged({
      defaultLanguage: language,
    });
    if (environment.production) {
      window.location.href =
        window.location.origin + `/${language}${this.location.path() || '/'}`;
    }
    setTimeout(() => this.preferencesService.lockPreferenceUI(false), 1000);
  }

  lockedPreferenceUIObservable(): Observable<boolean> {
    return this.preferencesService.lockedPreferenceUIObservable();
  }

  public getAllowedLanguages(): string[] {
    return [
      ...LanguageSwitcherService.POSSIBLE_LANGUAGES,
      ...(this.authService.isKeyUser() ||
      this.authService.isAdmin() ||
      this.authService.isInternalUser()
        ? LanguageSwitcherService.ADDITIONAL_LANGUAGES
        : []),
    ];
  }
}
