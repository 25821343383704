export enum FeedbackTypes {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  IDEA = 'idea',
  ERROR = 'error',
}

export enum IncludeScreenshot {
  NONE,
  SCREENSHOT,
  CUSTOM,
  CLIPBOARD,
}

export interface Feedback {
  id?: string;

  type: FeedbackTypes;

  text?: string;

  userEmail?: string;

  screenshot?: any;

  createdAt?: Date;

  hasScreenshot?: boolean;

  includeScreenshot?: IncludeScreenshot;

  affectedUrl?: string;

  done?: boolean;

  tag?: string;

  surveyId?: string;
}
