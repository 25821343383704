import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
  handlers: { [key: string]: DetachedRouteHandle } = {};

  calcKey(route: ActivatedRouteSnapshot) {
    if (route.data.reuse && route.data.reuseId) {
      return route.data.reuseId;
    }
    return (route['_routerState'].url ?? '').split('?')[0];
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data.reuse;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (!!route.data.reuse) this.handlers[this.calcKey(route)] = handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data.reuse && !!this.handlers[this.calcKey(route)];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig) return null;
    return this.handlers[this.calcKey(route)];
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future?.data?.reuse || this.calcKey(curr) === this.calcKey(future);
  }
}
