import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import { Configuration } from '../configuration';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BASE_PATH } from '../variables';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { FeedbackSurvey } from '../model/feedback-survey';

@Injectable({
  providedIn: 'root',
})
export class FeedbackSurveyService {
  public configuration = new Configuration();
  protected basePath;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    @Inject(LOCALE_ID) public localeId: string
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  getFeedbackSurvey(): Promise<FeedbackSurvey> {
    let queryParams = new HttpParams();

    const currentDate = new Date().toISOString();

    queryParams = queryParams.append('locale', this.localeId);

    queryParams = queryParams.append(
      'filters[$and][0][showDate][$lte]',
      currentDate
    );

    queryParams = queryParams.append(
      'filters[$and][1][hideDate][$gte]]',
      currentDate
    );

    queryParams = queryParams.append('pagination[limit]', 1);

    queryParams = queryParams.append('populate', '*');

    return this.httpClient
      .get<any>(`${this.basePath}/feedback-surveys`, {
        params: queryParams,
      })
      .pipe(catchError(() => of(undefined)))
      .toPromise()
      .then((messages) => {
        const firstSurvey = messages?.data?.pop();
        return firstSurvey
          ? ({
              ...firstSurvey?.attributes,
              id: firstSurvey?.id,
            } as FeedbackSurvey)
          : undefined;
      });
  }

  getReducedFeedbackSurveysByIds(
    ids: string[] | number[]
  ): Promise<FeedbackSurvey[]> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('locale', 'all');

    queryParams = queryParams.append('pagination[limit]', 100);
    queryParams = queryParams.append('fields[0]', 'question');
    queryParams = queryParams.append('fields[1]', 'internalName');

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      queryParams = queryParams.append(`filters[id][$in][${i}]`, id);
    }

    return this.httpClient
      .get<any>(`${this.basePath}/feedback-surveys`, {
        params: queryParams,
      })
      .pipe(catchError(() => of(undefined)))
      .toPromise()
      .then(
        (messages) =>
          messages?.data?.map(
            (data) =>
              ({
                id: data?.id,
                ...data?.attributes,
              } as FeedbackSurvey)
          ) ?? []
      );
  }
}
