import { Component, OnInit } from '@angular/core';

enum ENVIRONMENTS {
  DEV = 'dev',
  QA = 'qa',
  LOCALHOST = 'localhost',
}

@Component({
  selector: 'fs-display-environment',
  templateUrl: './display-environment.component.html',
  styleUrls: ['./display-environment.component.scss'],
})
export class DisplayEnvironmentComponent implements OnInit {
  showEnvironment: boolean = false;
  environment: string;
  environments = ENVIRONMENTS;

  ngOnInit(): void {
    const parsedUrl = new URL(window.location.href);
    const originUrl = parsedUrl.origin;

    if (originUrl.includes(ENVIRONMENTS.DEV)) {
      this.showEnvironment = true;
      this.environment = ENVIRONMENTS.DEV;
    } else if (originUrl.includes(ENVIRONMENTS.QA)) {
      this.showEnvironment = true;
      this.environment = ENVIRONMENTS.QA;
    } else if (originUrl.includes(ENVIRONMENTS.LOCALHOST)) {
      this.showEnvironment = true;
      this.environment = ENVIRONMENTS.LOCALHOST;
    }
  }
}
