import {
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingService } from '../../../../core/onboarding/onboarding.service';
import { toolbarTour } from './toolbar-tour';

@Component({
  selector: 'fs-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnChanges {
  @Input()
  authenticated = false;

  showAboutModal = false;

  constructor(
    private router: Router,
    @Inject(LOCALE_ID) public localeId: string,
    private onboardingService: OnboardingService
  ) {}

  navigateHome() {
    // noinspection JSIgnoredPromiseFromCall
    this.router.navigate([''], {
      replaceUrl: true,
    });
  }

  get language(): string {
    return this.localeId;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.authenticated?.currentValue === true) {
      this.onboardingService.startTour(toolbarTour);
    }
  }
}
