import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { ComboboxItemSelectionChange } from '../combobox.component';
import { Subject } from 'rxjs';

@Directive({
  selector: '[fsComboboxItem]',
})
export class ComboboxItemDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'fs-combobox-item',
  templateUrl: './combobox-item.component.html',
  styleUrls: ['./combobox-item.component.scss'],
})
export class ComboboxItemComponent {
  @Input()
  public value;

  @Input()
  public disabled = false;

  @Output()
  onSelectionChange = new EventEmitter<ComboboxItemSelectionChange>();

  selected = false;
  active = false;
  visible = true;

  /** Emits when the state of the option changes and any parents have to be notified. */
  readonly stateChanges = new Subject<void>();

  constructor(private _element: ElementRef<HTMLElement>) {}

  get viewValue(): string {
    return (this.getHostElement().textContent || '').trim();
  }

  /** Gets the host DOM element. */
  getHostElement(): HTMLElement {
    return this._element.nativeElement;
  }

  handleItemClick() {
    this.onSelectionChange.emit(new ComboboxItemSelectionChange(this, true));
  }
}
