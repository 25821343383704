<a
  class="select-group-option flex space-x-3"
  [ngClass]="{
    'select-group-option--selected': selected,
    'select-group-option--disabled': disabled
  }"
  (click)="select()"
>
  <svg xmlns="http://www.w3.org/2000/svg" class="icon">
    <use href="#radio-button-checked" *ngIf="selected"></use>
    <use href="#radio-button-unchecked" *ngIf="!selected"></use>
  </svg>
  <div class="text-sm">
    <ng-content></ng-content>
  </div>
</a>
