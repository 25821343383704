import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LanguageSwitcherService } from '../../services/language-switcher/language-switcher.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'fs-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent implements OnInit, OnDestroy {
  private destroySubject = new Subject();

  languageFormControl = new UntypedFormControl('en');

  constructor(
    private languageSwitcherService: LanguageSwitcherService,
    @Inject(LOCALE_ID) public localeId: string
  ) {}

  ngOnInit(): void {
    this.languageFormControl.reset(this.localeId, { emitEvent: false });

    this.languageFormControl.valueChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe((language) =>
        this.languageSwitcherService.setLanguage(language)
      );

    this.languageSwitcherService
      .lockedPreferenceUIObservable()
      .pipe(distinctUntilChanged(), takeUntil(this.destroySubject))
      .subscribe((isLocked) =>
        isLocked
          ? this.languageFormControl.disable({ emitEvent: false })
          : this.languageFormControl.enable({ emitEvent: false })
      );
  }

  get possibleLanguages(): string[] {
    return this.languageSwitcherService.getAllowedLanguages();
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
