<div class="flex flex-col gap-1">
  <label i18n="@@Fsw_GeneralUnitFormat">Einheitenformat</label>

  <fs-boxy-radio-group [formControl]="unitFormControl">
    <fs-boxy-radio-group-option [value]="true">
      <span>I-P</span>&nbsp;
      <span class="text--normal" title="I-P Inch-Pound Unit System"
        >(inch-pound)</span
      >
    </fs-boxy-radio-group-option>
    <fs-boxy-radio-group-option [value]="false">
      <span>SI</span>&nbsp;
      <span class="text--normal" title="SI International System of Units"
        >(international)</span
      >
    </fs-boxy-radio-group-option>
  </fs-boxy-radio-group>
</div>
