import { Inject, Injectable, Optional } from '@angular/core';
import { Configuration } from '../configuration';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BASE_PATH } from '../variables';
import { Observable } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ReleaseNoteStrapi } from '../model/release-note-strapi';

@Injectable({
  providedIn: 'root',
})
export class ReleaseNotesService {
  public configuration = new Configuration();
  protected basePath;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  getReleaseNotes(limit = 10, start = 0): Observable<ReleaseNoteStrapi> {
    let queryParams = new HttpParams();

    const pagSortConf = {
      sort: ['showDate:desc', 'id:desc'],
      filters: {
        $and: [
          {
            showDate: {
              $lte: new Date().toISOString(),
            },
          },
          {
            endDate: {
              $gte: new Date().toISOString(),
            },
          },
        ],
      },
      pagination: {
        start: start,
        limit: limit,
      },
    };

    for (let index = 0; index < pagSortConf.sort.length; index++) {
      const param = `sort[${index}]`;
      const value = pagSortConf.sort[index];
      queryParams = queryParams.append(param, value);
    }

    queryParams = queryParams.append(
      'filters[$and][0][showDate][$lte]',
      pagSortConf.filters.$and[0].showDate.$lte
    );

    queryParams = queryParams.append(
      'filters[$and][1][endDate][$gte]]',
      pagSortConf.filters.$and[1].endDate.$gte
    );

    queryParams = queryParams.append(
      'pagination[start]',
      pagSortConf.pagination.start
    );

    queryParams = queryParams.append(
      'pagination[limit]',
      pagSortConf.pagination.limit
    );

    queryParams = queryParams.append('populate', '*');

    return this.httpClient
      .get<any>(`${this.basePath}/release-notes`, {
        params: queryParams,
      })
      .pipe(catchError(() => []));
  }

  getLatestReleaseNote() {
    return this.getReleaseNotes(1, 0).pipe(
      map((result) => {
        return result.data.pop();
      })
    );
  }
}
