/**
 * FanScout-API-Gateway
 * API Gateway providing access on whole services from the FanScout domain
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, Subject, Subscription } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

import { take } from 'rxjs/operators';
import { EmissionOverviewData } from '../../modules/emission/models/emission-overview-data';
import { LifeCycleCostWithColor } from '../../modules/life-cycle-cost/models/product-with-life-cycle-cost';
import { Filter } from '../../modules/product-overview/components/filter-components/product-filter/filter';
import { SwitchUnitsService } from '../../modules/settings-switchers/services/switch-units/switch-units.service';
import { Configuration } from '../configuration';
import { ExtendedProduct } from '../model/extended-product';
import { FilterUnits } from '../model/filter-units';
import { LoadingStatus } from '../model/loading-status';
import { ProductOverview } from '../model/productOverview';
import { BASE_PATH } from '../variables';
import {
  AcceptTypes,
  ApiHeaderHelperService,
} from './api-header-helper.service';
import { FileIoService } from './file-io.service';
import { LocalizedUnit } from './localized-unit';
import { ProductUserInformation } from '../model/product-user-information';
import { FanId } from '../model/fanId';

@Injectable()
export class ProductOverviewService {
  protected basePath = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    private switchUnitsService: SwitchUnitsService,
    private apiHeaderHelper: ApiHeaderHelperService,
    private oauthService: OAuthService,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private fileIO: FileIoService,
    @Inject(LOCALE_ID) public localeId: string
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  private activeSocket: Socket;
  private socketResultSubject: Subject<ProductOverview>;
  private loadingStatusSubject: Subject<LoadingStatus> =
    new Subject<LoadingStatus>();

  private cancelLoadingSubject: Subject<any> = new Subject<any>();

  cancelLoading() {
    this.cancelLoadingSubject.next();
  }

  public socketProductOverviewGet(
    {
      operatingPoint: { operatingPoints, airDensity, searchRange },
      mechanical: {
        width,
        depth,
        height,
        productType,
        airflowDirection,
        manufacturedSizes,
        calcMethod,
        installationLosses,
        guardGrill,
      },
      electrical: { voltage, frequency, motorType, phase },
      fanGrid: fanGrid,
      soundLevel: soundLevel,
      conditionsOfUse: conditionsOfUse,
    }: Filter,
    collectionIds: string[],
    productSearch: string[],
    requestId: string
  ): Observable<ProductOverview> {
    if (this.activeSocket) {
      this.activeSocket.disconnect();
    }

    /**
     * TODO Remove after tests are over
     * This is a temporary solution to use the complete measurement data way of retrieving data in the backend
     */
    const useCompleteMeasurementData =
      window.localStorage.getItem('useCompleteMeasurementData') === 'true';

    const withoutSoundData =
      window.localStorage.getItem('withoutSoundData') === 'true';

    const ignoreCentralCalcServiceQueue =
      window.localStorage.getItem('useSharedQueue') === 'true';

    this.socketResultSubject = new Subject<ProductOverview>();

    // if cancel was triggert, disconnect from the socket
    const cancelSubscription: Subscription = this.cancelLoadingSubject
      .pipe(take(1))
      .subscribe(() => {
        socket.disconnect();
      });

    const result = {
      products: [],
    };
    const socket = io('', {
      autoConnect: true,
      reconnection: false,
      reconnectionAttempts: 0,
      path: '/api/socket/',
      transports: ['websocket'],
      auth: {
        token: this.oauthService.getAccessToken(),
      },
    });
    socket.on('connect', () => {
      this.activeSocket = socket;

      socket.emit('product-overview', {
        airDensity,
        correlationId: requestId,
        contentType: this.apiHeaderHelper.createAcceptHeader(),
        airflow: operatingPoints?.length
          ? operatingPoints.map((point) => point.airFlow)
          : undefined,
        pressure: operatingPoints?.length
          ? operatingPoints.map((point) => point.pressure)
          : undefined,
        operatingTime: operatingPoints?.length
          ? operatingPoints.map((point) => point.operatingTime)
          : undefined,
        materialNumbersTypeKeys:
          productSearch?.length > 0 ? productSearch : undefined,
        soundLevel: soundLevel?.totalSoundLevel,
        numMinFans: fanGrid?.numMinFans,
        numMaxFans: fanGrid?.numMaxFans,
        numRedundantFans: fanGrid?.numRedundantFans,
        height,
        width,
        depth,
        manufacturedSizes,
        voltage,
        frequencies: frequency,
        motorTypes: motorType,
        phases: phase,
        pageSize: undefined,
        pageOffset: undefined,
        unit: this.switchUnitsService.areUnitsInUSFormat()
          ? LocalizedUnit.US
          : LocalizedUnit.SI,
        collectionIds: productSearch?.length > 0 ? undefined : collectionIds,
        installationLosses,
        calcMethod,
        backwardFlow: fanGrid?.redundantFansBackwardFlow ? true : undefined,
        guardGrill,
        productType,
        airflowDirection,
        searchRange,
        language: this.localeId,
        madeOfMetal: conditionsOfUse?.madeOfMetal ?? false,
        humcatClasses: conditionsOfUse?.humcatClasses ?? [],
        certificateTypes: conditionsOfUse?.certificateTypes ?? [],
        useCompleteMeasurementData,
        withoutSoundData,
        ignoreCentralCalcServiceQueue,
      });
    });

    socket.on('overview-chunk', (data) => {
      result.products.push(...data.products);
      loadingStatus.successfulCount += data.chunkSize;
      this.loadingStatusSubject.next(loadingStatus);
    });

    let loadingStatus: LoadingStatus = {};

    socket.once('overview-loading-status-init', (loadingStatusData) => {
      loadingStatus = {
        productsInCollectionsCount:
          loadingStatusData.productsInCollectionsCount,
        excludedCount: loadingStatusData.excludedCount,
        successfulCount: 0,
        isLoading: true,
      } as LoadingStatus;
      this.loadingStatusSubject.next(loadingStatus);
    });

    this.loadingStatusSubject.next({
      productsInCollectionsCount: 0,
      excludedCount: 0,
      successfulCount: 0,
      isLoading: true,
    } as LoadingStatus);

    const finishCall = () => {
      loadingStatus.isLoading = false;
      this.loadingStatusSubject.next(loadingStatus);
      this.socketResultSubject.next(result);
      this.socketResultSubject.complete();
    };

    socket.on('disconnect', () => {
      cancelSubscription.unsubscribe();
      this.activeSocket = undefined;
      finishCall();
    });

    socket.on('error', (error) => {
      cancelSubscription.unsubscribe();
      console.error(error);
      socket.disconnect();
    });

    return this.socketResultSubject;
  }

  get loadingStatusObservable(): Observable<LoadingStatus> {
    return this.loadingStatusSubject.asObservable();
  }

  public productOverviewGetPdf(
    units: LocalizedUnit,
    filter: Filter,
    selectedProducts: ExtendedProduct[],
    includeProductComparison?: boolean,
    includeLifeCycleCost?: boolean,
    pdfProjectData?: string,
    selectedOperatingPointIndex: number = 0,
    language?: string,
    lifeCycleCostsData?: LifeCycleCostWithColor[],
    includeEmissions?: boolean,
    emissionData?: EmissionOverviewData,
    filterUnits?: FilterUnits,
    sharedURL?: string,
    unitsNameConverson?: FilterUnits
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.defaultHeaders
        .set(
          'Accept',
          this.apiHeaderHelper.createAcceptHeader(
            AcceptTypes.APPLICATION_PDF,
            units
          )
        )
        .set('x-correlation-id', `${uuidv4()}`);

      const requestBody = {
        units: units,
        language: language ?? this.localeId,

        selectedOperatingPoint: selectedOperatingPointIndex,
        lifeCycleCostsData: lifeCycleCostsData,

        airflow: [],
        pressure: [],
        operatingTime: [],

        selectedMaterialNumber: [],
        selectedMeasurement: [],
        selectedTypeKey: [],
        selectedNumberOfFans: [],

        includeProductComparison: includeProductComparison,
        includeLifeCycleCosts: includeLifeCycleCost,
        soundLevelChart: true,
        projectData: pdfProjectData.trim(),

        includeEmissions: includeEmissions,
        emissionData: includeEmissions ? emissionData : undefined,
        sharedURL: sharedURL,

        airDensity: filter.operatingPoint.airDensity,
        width: filter.mechanical.width,
        depth: filter.mechanical.depth,
        height: filter.mechanical.height,
        voltage: filter.electrical.voltage,
        productType: filter.mechanical.productType,
        frequencies: filter.electrical.frequency,
        motorTypes: filter.electrical.motorType,
        manufacturedSizes: filter.mechanical.manufacturedSizes,
        phases: filter.electrical.phase,
        backwardFlow: filter.fanGrid.redundantFansBackwardFlow,
        searchRange: filter.operatingPoint.searchRange,
        numMinFans: filter.fanGrid.numMinFans,
        numMaxFans: filter.fanGrid.numMaxFans,
        numRedundantFans: filter.fanGrid.numRedundantFans,
        guardGrill: filter.mechanical.guardGrill,
        calcMethod: filter.mechanical.calcMethod,
        installationLosses: filter.mechanical.installationLosses,
        madeOfMetal: filter?.conditionsOfUse?.madeOfMetal,
      };

      if (selectedProducts?.length > 0) {
        for (const selectedProduct of selectedProducts) {
          requestBody.selectedMaterialNumber.push(
            selectedProduct.fanId.materialNumber ?? ''
          );
          requestBody.selectedMeasurement.push(
            selectedProduct.measurement.measurementId ?? ''
          );
          requestBody.selectedTypeKey.push(selectedProduct.fanId.typeKey ?? '');
          requestBody.selectedNumberOfFans.push(
            selectedProduct.numberOfFans ?? ''
          );
        }
      }

      if (filter.operatingPoint.operatingPoints?.length > 0) {
        for (const operatingPoint of filter.operatingPoint.operatingPoints) {
          requestBody.airflow.push(operatingPoint.airFlow ?? '');
          requestBody.pressure.push(operatingPoint.pressure ?? '');
          requestBody.operatingTime.push(operatingPoint.operatingTime ?? '');
        }
      }

      const pdfNameList = [];

      if (pdfProjectData) {
        pdfNameList.push(pdfProjectData);
      }

      if (includeProductComparison) {
        pdfNameList.push(
          $localize`:@@Fsw_GeneralPdfCompDownload:Produktvergleich`
        );
      }

      if (!includeLifeCycleCost && !includeEmissions) {
        const selectedOperatingPoint =
          filter.operatingPoint.operatingPoints[selectedOperatingPointIndex];
        pdfNameList.push(
          `${selectedOperatingPoint?.airFlow}${unitsNameConverson.airflow}_${selectedOperatingPoint?.pressure}${unitsNameConverson.pressure}`
        );
      }

      if (includeLifeCycleCost) {
        pdfNameList.push('LCC');
      }

      if (includeEmissions) {
        pdfNameList.push('CO2');
      }

      const downloadSubscription = this.httpClient
        .post(`${this.basePath}/product-overview/pdf`, requestBody, {
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: 'events',
          responseType: 'blob',
        })
        .subscribe(
          (event: HttpEvent<Blob>) => {
            if (event.type === HttpEventType.Response) {
              this.fileIO.saveBlobAs(
                event.body,
                this.createFileName(pdfNameList.join('_'))
              );
              downloadSubscription.unsubscribe();
              resolve();
            }
          },
          (error) => {
            downloadSubscription.unsubscribe();
            reject(error);
          }
        );
    });
  }

  private createFileName(pdfName: string): string {
    return `${pdfName}.pdf`.trim().replace(/ /g, '_');
  }

  public storeProductUserInformation(
    customerProductInformation: ProductUserInformation
  ): Promise<ProductUserInformation> {
    return this.httpClient
      .post(
        `${this.basePath}/product-user-information`,
        customerProductInformation,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      )
      .toPromise();
  }

  public getProductUserInformation(
    fanId: FanId
  ): Promise<ProductUserInformation> {
    const { typeKey, materialNumber } = fanId;

    return this.httpClient
      .get(`${this.basePath}/product-user-information`, {
        params: {
          ...(materialNumber && { materialNumber }),
          ...(typeKey && { typeKey }),
        },
      })
      .toPromise();
  }

  public deleteProductUserInformation(fanId: FanId): Promise<void> {
    const { typeKey, materialNumber } = fanId;

    return this.httpClient
      .delete<void>(`${this.basePath}/product-user-information`, {
        params: {
          ...(materialNumber && { materialNumber }),
          ...(typeKey && { typeKey }),
        },
      })
      .toPromise();
  }
}
