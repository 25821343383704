<svg
  class="logo"
  height="40px"
  width="120px"
  id="Ebene_1"
  data-name="Ebene 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 506.85769 124.00172"
>
  <g>
    <path
      class="cls-2"
      d="m214.43524,84.38719v3.50908c0,11.19641,2.66241,18.38185,13.31205,18.38185,6.16741,0,12.05327-4.68013,12.05327-14.70548,0-10.86182-4.62505-14.87278-17.9371-20.38937-17.9371-7.35274-26.20587-16.20704-26.20587-36.09045,0-23.22724,11.91046-35.09282,30.96965-35.09282,17.9371,0,30.96965,9.19093,30.96965,32.08358v3.34178h-19.33869c0-11.19437-3.22345-17.71268-10.93118-17.71268-9.10728,0-11.49019,6.85086-11.49019,13.87106,0,7.3507,1.82186,11.86354,10.37014,16.20704l15.55419,8.02191c15.13392,7.85462,19.05919,17.04351,19.05919,31.5817,0,25.23272-12.89382,36.59641-34.75415,36.59641-22.84165,0-31.8102-12.70204-31.8102-34.76027v-4.84334h20.17924Z"
    />
    <path
      class="cls-2"
      d="m306.89739,64.16511c0-8.68701-1.40363-18.04523-8.69109-18.04523-9.52755,0-11.06992,10.02739-11.06992,30.74727,0,22.22552,1.68109,31.4144,10.37218,31.4144,6.44283,0,9.38882-6.51628,9.38882-21.05447h18.4961c0,23.55978-8.26673,36.76371-28.72547,36.76371-19.34073,0-28.86828-11.19641-28.86828-47.29094,0-36.92896,12.75101-47.2889,30.54938-47.2889,17.79429,0,27.04438,13.53443,27.04438,34.75415h-18.4961Z"
    />
    <path
      class="cls-2"
      d="m361.94911,29.41097c23.26192-.50188,29.70883,16.04179,29.70883,47.2889,0,30.74523-7.56492,47.29094-29.70883,47.29094-23.26192.4978-29.70883-16.04383-29.70883-47.29094,0-30.74727,7.569-47.2889,29.70883-47.2889Zm0,78.8706c7.85054,0,10.37218-9.18889,10.37218-31.5817s-2.52164-31.5817-10.37218-31.5817c-9.52755,0-10.3681,13.19984-10.3681,31.5817s.84055,31.5817,10.3681,31.5817Z"
    />
    <path
      class="cls-2"
      d="m439.72006,111.12147h-.28154c-4.064,8.85838-11.06992,12.86934-19.19792,12.86934-12.04919,0-19.19792-7.85462-19.19792-25.5673V31.74899h19.34073v61.99438c0,9.02567,2.52164,12.53475,8.26673,12.53475,6.58564,0,10.22937-4.84742,10.22937-14.87278V31.74899h19.33665v89.89971h-18.4961v-10.52723Z"
    />
    <path
      class="cls-2"
      d="m476.30831,6.01644h19.33665v25.73256h11.21273v15.70924h-11.21273v48.62317c0,7.01816,1.26082,9.86214,6.86718,9.86214,1.54236,0,3.08472-.16729,4.34554-.33459v16.03975c-4.20273.50188-8.128,1.00376-12.61228,1.00376-15.13392,0-17.9371-5.51252-17.9371-23.72708v-51.46715h-9.8091v-15.70924h9.8091V6.01644Z"
    />
  </g>
  <path
    class="cls-1"
    d="m0,2.34007h55.9147v19.71816H20.17924v28.07262h33.63206v19.71816H20.17924v51.7997H0V2.34007Z"
  />
  <path
    class="cls-1"
    d="m62.53911,59.32177v-2.17481c0-20.71784,11.91046-27.73599,26.48537-27.73599,22.98242,0,27.32592,11.36166,27.32592,27.5687v47.29094c0,8.3565.42027,12.20016,2.38291,17.37809h-18.49814c-1.26082-2.8399-1.82186-6.34898-2.38291-9.35618h-.2795c-4.48427,9.35618-9.94987,11.69829-19.19792,11.69829-13.45282,0-18.21864-13.7058-18.21864-25.7346,0-16.87621,5.74509-23.73116,19.05919-28.07262l10.92914-3.50908c5.74713-1.84023,7.70773-4.51284,7.70773-10.52723,0-6.85086-2.52164-11.02911-8.96855-11.02911-5.88587,0-8.96855,4.67809-8.96855,11.6942v2.5094h-17.37605Zm35.31315,17.37809c-2.66241,2.33802-5.88587,3.84366-8.26673,4.51284-7.8485,2.00344-10.93118,6.51628-10.93118,14.53819,0,6.85086,2.10137,12.53067,7.28746,12.53067,5.18405,0,11.91046-4.01096,11.91046-13.86902v-17.71268Z"
  />
  <path
    class="cls-1"
    d="m128.39755,31.74899h18.4961v10.52927h.28154c4.064-8.85838,11.06992-12.8673,19.19792-12.8673,12.05123,0,19.19792,7.85258,19.19792,25.56526v66.67247h-19.33869v-61.99234c0-9.02363-2.52164-12.53271-8.26673-12.53271-6.58768,0-10.23141,4.84538-10.23141,14.87074v59.65432h-19.33665V31.74899Z"
  />
</svg>
