import { NgModule } from '@angular/core';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SettingsComponent } from './components/settings/settings.component';

import { SharedModule } from '../../shared/shared.module';
import { UserDisplayComponent } from './components/user-display/user-display.component';
import { RouterModule } from '@angular/router';
import { AboutInfoModalComponent } from './components/about-info-modal/about-info-modal.component';
import { NavigationItemsComponent } from './components/navigation-items/navigation-items.component';
import { SubmitFeedbackComponent } from './components/submit-feedback/submit-feedback.component';
import { DisplayEnvironmentComponent } from './components/display-environment/display-environment.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { PrivacyProtectionComponent } from './components/privacy-protection/privacy-protection.component';
import { LogoComponent } from './components/logo/logo.component';
import { ReleaseNotesModule } from '../release-notes/release-notes.module';
import { SettingsSwitchersModule } from '../settings-switchers/settings-switchers.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { WelcomeModalComponent } from './components/welcome-modal/welcome-modal.component';
import { MaintenanceBannerComponent } from './components/maintenance-banner/maintenance-banner.component';
import { MaintenanceMessageModalComponent } from './components/maintenance-message-modal/maintenance-message-modal.component';
import { FeedbackSurveyNotificationComponent } from './components/feedback-survey-notification/feedback-survey-notification.component';
import { FeedbackRatingRadioBoxesComponent } from './components/feedback-rating-radio-boxes/feedback-rating-radio-boxes.component';

@NgModule({
  declarations: [
    ToolbarComponent,
    SettingsComponent,
    UserDisplayComponent,
    AboutInfoModalComponent,
    NavigationItemsComponent,
    SubmitFeedbackComponent,
    DisplayEnvironmentComponent,
    ImprintComponent,
    PrivacyProtectionComponent,
    LogoComponent,
    WelcomeModalComponent,
    MaintenanceBannerComponent,
    MaintenanceMessageModalComponent,
    FeedbackSurveyNotificationComponent,
    FeedbackRatingRadioBoxesComponent,
  ],
  exports: [
    ToolbarComponent,
    WelcomeModalComponent,
    MaintenanceBannerComponent,
    FeedbackSurveyNotificationComponent,
  ],
  imports: [
    SharedModule,
    ReleaseNotesModule,
    RouterModule,
    SettingsSwitchersModule,
    MatSnackBarModule,
  ],
})
export class GeneralModule {}
