import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import packageJson from '../package.json';

Sentry.init({
  dsn:
    environment.name !== 'local'
      ? 'https://53b6feafd390447b9f22297c404d4648@o1021797.ingest.sentry.io/6028871'
      : null,
  environment: environment.name,
  release: packageJson.name + '@' + packageJson.version,
  denyUrls: [
    '/api',
    /iam-kc\.ebmpapst.com/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  tracePropagationTargets: [
    'localhost',
    'fanscout.*.ebmpapst.loc',
    'fanscout.ebmpapst.com',
  ],
  integrations: [
    Sentry.browserTracingIntegration({
      beforeStartSpan(context): any {
        const normalizedPath = location.pathname
          .replace(/^\/\w{2}\//, '/') // Remove the first part of the path containing the language like /en if it's only two characters
          .replace(new RegExp('/details/.*'), '/details');
        return {
          ...context,
          name: normalizedPath,
        };
      },
    }),
  ],
  tracesSampleRate: 0.1, // Be sure to lower this in production,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
  ],
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // .then(module => enableDebugTools(module.injector.get(ApplicationRef).components[0])) // Comment in to activate angular debug tools
  .catch((err) => console.error(err));
