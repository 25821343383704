import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { FilterUnits } from '../../../fanscout-api/model/filter-units';
import {
  AIRFLOW_SI_UNIT,
  PRESSURE_SI_UNIT,
} from '../../static-helpers/shared-constants';

@Pipe({
  name: 'operatingPointSummaryText',
})
export class OperatingPointSummaryTextPipe implements PipeTransform {
  transform(
    operatingPoint: any,
    locale = 'de',
    filterUnits: FilterUnits = {
      airflow: AIRFLOW_SI_UNIT,
      pressure: PRESSURE_SI_UNIT,
    },
    hideOperatingTime = false,
    ...args: unknown[]
  ): string {
    return operatingPoint != null
      ? `${formatNumber(
          operatingPoint.airFlow ?? operatingPoint.airflow ?? 0,
          locale,
          '1.0-20'
        )} ${filterUnits?.airflow || AIRFLOW_SI_UNIT} - ${formatNumber(
          operatingPoint.pressure,
          locale,
          '1.0-20'
        )} ${filterUnits?.pressure || PRESSURE_SI_UNIT}${
          operatingPoint.operatingTime && !hideOperatingTime
            ? ` - ${formatNumber(
                operatingPoint.operatingTime,
                locale,
                '1.0-20'
              )} h/a`
            : ''
        }`
      : '';
  }
}
