import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { AuthStateService } from '../../core/auth/auth-state.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ReleaseNoteItem } from '../../modules/general/models/release-note-item';
import { Currency } from '../model/price';
import { AirDensityValue } from '../../modules/general/models/air-density-value';
import { TableColumnsConfig } from '../model/table-column-configs';

export interface UserPreferences {
  airDensity: AirDensityValue;
  usUnits?: boolean;
  defaultLanguage?: string;
  selectedCollectionIds?: string[];
  viewedCollectionIds?: string[];
  energyCosts?: number;
  releaseNote?: ReleaseNoteItem;
  currency?: Currency;
  favoriteCollections?: string[];
  productOverviewTableColumnConfig?: TableColumnsConfig;
  tourIdsAlreadyWatched: string[];
  showWelcomeDialog: boolean;
  skipAllTours: boolean;
  seenFeedbackSurveyIds?: number[];
}

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  public configuration = new Configuration();
  protected basePath;
  private preferenceCache: Promise<UserPreferences>;

  private airDensityHasChangedSubject = new Subject<unknown>();
  private energyCostsHasChangedSubject = new Subject<unknown>();

  private lockPreferenceUISubject = new BehaviorSubject<boolean>(false);

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private authStateService: AuthStateService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public async getPreferences(): Promise<UserPreferences> {
    await this.authStateService.waitUntilAuthenticated;
    return this.unsafeGetPreferences();
  }

  public unsafeGetPreferences() {
    return this.httpClient
      .get<UserPreferences>(`${this.basePath}/user-preferences`, {})
      .toPromise()
      .catch((err: HttpErrorResponse) => {
        if (err.status !== 404) {
          console.error(err);
        }
        return {} as UserPreferences;
      });
  }

  public getPreferencesCached(): Promise<UserPreferences> {
    if (!this.preferenceCache) {
      this.updatePreferenceCache();
    }

    return this.preferenceCache.then((preference) =>
      JSON.parse(JSON.stringify(preference))
    );
  }

  public updatePreferenceCache() {
    this.preferenceCache = this.getPreferences();
  }

  public async storePreferencesIfChanged(
    userPreferences: UserPreferences
  ): Promise<UserPreferences> {
    const oldPreferences = await this.getPreferencesCached();
    if (
      oldPreferences &&
      JSON.stringify(oldPreferences) !== JSON.stringify(userPreferences)
    ) {
      return await this.storePreferences(userPreferences);
    }
    return oldPreferences;
  }

  public async storePreferences(
    userPreferences: UserPreferences
  ): Promise<UserPreferences> {
    const newPreference = await this.httpClient
      .post<UserPreferences>(
        `${this.basePath}/user-preferences`,
        userPreferences,
        {
          headers: { 'content-type': 'application/json' },
        }
      )
      .toPromise();
    this.updatePreferenceCache();
    return newPreference;
  }

  public async patchPreferencesIfChanged(
    userPreferences: Partial<UserPreferences>
  ): Promise<UserPreferences> {
    const oldPreferences = await this.getPreferencesCached();

    if (JSON.stringify(oldPreferences) !== JSON.stringify(userPreferences)) {
      const newUserPreferences = await this.patchPreferences(userPreferences);

      if (
        userPreferences.airDensity != null &&
        JSON.stringify(userPreferences?.airDensity) !==
          JSON.stringify(oldPreferences?.airDensity)
      ) {
        this.airDensityHasChangedSubject.next();
      }

      if (
        userPreferences.energyCosts != null &&
        userPreferences.energyCosts !== oldPreferences.energyCosts
      ) {
        this.energyCostsHasChangedSubject.next();
      }
      return newUserPreferences;
    }
  }

  public async patchPreferences(
    userPreferences: Partial<UserPreferences>
  ): Promise<UserPreferences> {
    return await this.getPreferencesCached()
      .catch(() => ({} as UserPreferences))
      .then((preferences) =>
        this.storePreferencesIfChanged({
          ...preferences,
          ...userPreferences,
        })
      );
  }

  get hasAirDensityChanged(): Observable<unknown> {
    return this.airDensityHasChangedSubject.asObservable();
  }

  get hasEnergyCostsChanged(): Observable<unknown> {
    return this.energyCostsHasChangedSubject.asObservable();
  }

  public lockedPreferenceUIObservable(): Observable<boolean> {
    return this.lockPreferenceUISubject.asObservable();
  }

  public lockPreferenceUI(value: boolean) {
    this.lockPreferenceUISubject.next(value);
  }
}
