<!--suppress HtmlUnknownAttribute -->
<div class="flex space-x-3 items-center p-3 bg-white text-gray-900 shadow">
  <div (click)="navigateHome()" class="title flex items-center cursor-pointer">
    <fs-logo></fs-logo>
  </div>
  <fs-release-notes-button></fs-release-notes-button>
  <fs-display-environment></fs-display-environment>
  <div class="flex-1"></div>
  <section
    class="tools flex items-center justify-end space-x-4"
    *ngIf="authenticated"
  >
    <fs-navigation-items [language]="language"></fs-navigation-items>
    <fs-submit-feedback class="submit-feedback-touranchor"></fs-submit-feedback>
    <fs-settings class="settings-touranchor"></fs-settings>
    <fs-user-display (showAboutModal)="showAboutModal = true"></fs-user-display>
  </section>
</div>
<fs-about-info-modal [(showModal)]="showAboutModal"></fs-about-info-modal>
