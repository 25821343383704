<div class="field-wrapper" *ngIf="formControl">
  <label class="required" i18n="@@Fsw_FeedbackType">Art</label>
  <div class="box-options-grid">
    <a
      class="box-option option--green"
      (click)="selectFeedbackType(feedbackTypes.POSITIVE)"
      [ngClass]="{
        'box-option--selected': selectedFeedbackType === feedbackTypes.POSITIVE
      }"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="icon">
        <use href="#thumbs-up"></use>
      </svg>
      <span i18n="@@Fsw_FeedbackPositive">Positiv</span>
    </a>

    <a
      class="box-option option--red"
      (click)="selectFeedbackType(feedbackTypes.NEGATIVE)"
      [ngClass]="{
        'box-option--selected': selectedFeedbackType === feedbackTypes.NEGATIVE
      }"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="icon">
        <use href="#thumbs-down"></use>
      </svg>
      <span i18n="@@Fsw_FeedbackNegative">Negativ</span>
    </a>

    <a
      class="box-option option--yellow"
      (click)="selectFeedbackType(feedbackTypes.IDEA)"
      [ngClass]="{
        'box-option--selected': selectedFeedbackType === feedbackTypes.IDEA
      }"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="icon">
        <use href="#light-bulb"></use>
      </svg>
      <span i18n="@@Fsw_FeedbackIdea">Idee</span>
    </a>
  </div>
  <ul class="textfield__errors">
    <li
      *ngIf="parentForm.dirty && formControl.errors?.required"
      i18n="@@Fsw_ErrorsRequired"
    >
      Pflichtangabe
    </li>
  </ul>
</div>
