import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { AirDensity } from '../model/airDensity';
import { CalcServiceVersionInfo } from '../model/calc-service-version-info';
import { ApiHeaderHelperService } from './api-header-helper.service';
import { PointCoordinate } from '../model/point-coordinate';
import { CurvePoints } from '../model/curve-points';
import { CalcInterpolationPoints } from '../model/calc-interpolation-points';

@Injectable({
  providedIn: 'root',
})
export class CalculationService {
  public configuration = new Configuration();
  protected basePath;
  private cachedVersion: Promise<CalcServiceVersionInfo>;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private apiHeaderHelper: ApiHeaderHelperService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  calculateAirDensity(
    temperature: number,
    height: number
  ): Promise<AirDensity> {
    const headers = new HttpHeaders().set(
      'Accept',
      this.apiHeaderHelper.createAcceptHeader()
    );
    return this.httpClient
      .post<AirDensity>(
        `${this.basePath}/calculation/airDensity`,
        {
          temperature,
          height,
        },
        {
          headers,
        }
      )
      .toPromise();
  }

  calculateInterpolation(points: PointCoordinate[] = []): Promise<CurvePoints> {
    const headers = new HttpHeaders().set(
      'Accept',
      this.apiHeaderHelper.createAcceptHeader()
    );
    return this.httpClient
      .post<CurvePoints>(
        `${this.basePath}/calculation/calculate-interpolation`,
        {
          points,
        } as CalcInterpolationPoints,
        {
          headers,
        }
      )
      .toPromise();
  }

  getVersion(): Promise<CalcServiceVersionInfo> {
    return this.httpClient
      .get<CalcServiceVersionInfo>(`${this.basePath}/calculation/version`)
      .toPromise();
  }

  getVersionCached(): Promise<CalcServiceVersionInfo> {
    if (!this.cachedVersion) {
      this.cachedVersion = this.getVersion();
    }
    return this.cachedVersion;
  }
}
