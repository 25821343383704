<fs-dropdown [overwriteZIndex]="110">
  <div
    trigger
    *ngIf="isLoggedIn"
    class="user-display text-sm font-medium flex items-center bg-gray-050 hover:bg-gray-100 cursor-pointer space-x-2 transition-colors"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon--small text-gray-500"
    >
      <use href="#profile"></use>
    </svg>
    <span id="user-name" data-hj-suppress>{{ name }}</span>
    <svg
      class="text-gray-500"
      fill="none"
      height="22px"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 9l-7 7-7-7"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  </div>
  <a [routerLink]="['/user']" class="dropdown-item">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon">
      <use href="#user-filled"></use>
    </svg>
    <span i18n="@@Fsw_NavMenuUserUserprofile">Nutzerprofil</span>
  </a>
  <a [routerLink]="['/user/preferences']" class="dropdown-item">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon">
      <use href="#cog-filled"></use>
    </svg>
    <span i18n="@@Fsw_NavMenuUserPreferences">Einstellungen</span>
  </a>
  <a (click)="resetTours()" class="dropdown-item">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon">
      <use href="#tour"></use>
    </svg>
    <span i18n="@@Fsw_RestartTour">Hilfe-Tour neu starten</span>
  </a>
  <a [routerLink]="['/help']" class="dropdown-item">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon">
      <use href="#question-circle"></use>
    </svg>
    <span i18n="@@Fsw_Help">Hilfe</span>
  </a>
  <a
    [href]="'/assets/docs/' + currentLanguage + '/conditions-info.pdf'"
    target="_blank"
    class="dropdown-item"
    (click)="$event.stopPropagation()"
  >
    <svg xmlns="http://www.w3.org/2000/svg" class="icon">
      <use href="#document"></use>
    </svg>
    <span i18n="@@Fsw_GeneralTechnicalParameters">Rahmenbedingungen</span>
  </a>
  <a (click)="openAboutModal()" class="dropdown-item">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon">
      <use href="#information-circle"></use>
    </svg>
    <span i18n="@@Fsw_NavMenuUserAbout">Information</span>
  </a>
  <a (click)="logout()" class="dropdown-item">
    <span class="flex-1 text-red-700" i18n="@@Fsw_NavMenuUserLogout"
      >Ausloggen</span
    >
    <svg xmlns="http://www.w3.org/2000/svg" class="icon text-red-600">
      <use href="#logout"></use>
    </svg>
  </a>
</fs-dropdown>
