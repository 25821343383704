import {
  Component,
  ContentChild,
  Injector,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { FieldErrorWrapperDirective } from './field-error-wrapper.directive';
import { v4 as uuidv4 } from 'uuid';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'fs-form-field',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, NgClass, NgTemplateOutlet],
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormFieldComponent,
    },
  ],
})
export class FormFieldComponent implements OnInit, ControlValueAccessor {
  @Input()
  controlName: string;

  @Input()
  control: UntypedFormControl | AbstractControl;

  @Input()
  type: 'text' | 'number' = 'text';

  @Input()
  min: number;

  @Input()
  max: number;

  @Input()
  step: number;

  @Input()
  hasAdditionalErrors: boolean = false;

  @Input()
  required: boolean = false;

  @Input()
  ariaLabel = '';

  @Input()
  placeholder = '';

  @Input()
  title = '';

  @Input()
  autocomplete = 'off';

  @ContentChild(FieldErrorWrapperDirective)
  fieldErrorWrapper: FieldErrorWrapperDirective;

  randomId = 'form-field-' + uuidv4();

  constructor(
    @Optional() private controlContainer: ControlContainer,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    if (this.controlContainer && !this.control && this.controlName) {
      const parentForm = this.controlContainer.control as UntypedFormGroup;
      this.control = parentForm.get(this.controlName) as UntypedFormControl;
    } else {
      try {
        const ngControl = this.injector.get(NgControl);

        if (ngControl instanceof FormControlName) {
          this.control = this.injector
            .get(FormGroupDirective)
            .getControl(ngControl);
        } else {
          this.control = (ngControl as FormControlDirective)
            .form as UntypedFormControl;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  writeValue(value: any): void {
    // Not implemented as formControl will be forwarded
  }

  registerOnChange(onChange: any): void {
    // Not implemented as formControl will be forwarded
  }

  registerOnTouched(onTouched: any): void {
    // Not implemented as formControl will be forwarded
  }

  get controlBinding(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }
}
