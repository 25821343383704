import { Injectable } from '@angular/core';
import { NewrelicScript } from './newrelic-script-const';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewrelicScriptService {
  private allowedEnvironments = ['staging'];

  public injectNewrelicScript(): void {
    // Only inject newrelic script in staging environment
    if (this.allowedEnvironments.includes(environment.name)) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = NewrelicScript;
      document.head.appendChild(script);
    }
  }
}
